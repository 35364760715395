import { Icon, withBadge } from 'react-native-elements';

import styled from 'styled-components/native';

import theme from 'app/util/theme';

export const HamburgerIcon = styled(Icon).attrs(({ theme }) => {
  return {
    size: 36,
    name: 'menu',
    color: theme.colors.primary,
  };
})``;

export const HamburgerIconWithBadge = withBadge(' ', {
  badgeStyle: {
    backgroundColor: theme.colors.badge,
  },
  status: 'primary',
  right: -2,
})(HamburgerIcon);
