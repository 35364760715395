import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dimensions, Platform } from 'react-native';
import { Text } from 'react-native-elements';
import { Redirect, withRouter } from '@cross-platform/react-router-native';

import { logEvent, EVENTS } from 'app/util/analytics';
import { EpisodePropTypes, HistoryPropTypes } from 'app/util/propTypes';
import { DEFAULT_PHYSICIAN_IMAGE } from 'app/util/constants';
import theme from 'app/util/theme';

import FeatureSwitch from 'app/components/Common/FeatureSwitch';
import PhysicianBiography from 'app/components/Common/PhysicianBiography';
import TitleBar from 'app/components/Common/TitleBar';

import HospitalCard from './HospitalCard';
import SurgeonConfirmationModal from './SurgeonConfirmationModal';
import SurgeonRating from './SurgeonRating';
import SurgeonVideo from './SurgeonVideo';
import { Routes } from 'app/util/routes';

import {
  Avatar,
  AvatarContainer,
  BiographySectionContainer,
  BiographySectionTitle,
  Container,
  ContentContainer,
  ContentSection,
  FloatingActionButton,
  MapViewContainer,
  MapView,
  Marker,
  ScrollView,
  Spacer,
} from './styles';

const formatCoords = ({ lat, lng, latitude, longitude }) => ({
  lat: lat || parseFloat(latitude),
  lng: lng || parseFloat(longitude),
  latitude: lat || parseFloat(latitude),
  longitude: lng || parseFloat(longitude),
  latitudeDelta: 6,
  longitudeDelta: 6,
});

const sections = {
  education: 'Medical Education',
  residencies: 'Residencies',
  internships: 'Internships',
  fellowships: 'Fellowships',
  certifications: 'Certifications',
  affiliations: 'Affiliations',
};

export class SurgeonDetail extends Component {
  static propTypes = {
    getPhysicianRating: PropTypes.func.isRequired,
    resetPhysicianRating: PropTypes.func.isRequired,
    selectPhysician: PropTypes.func.isRequired,
    updatePhysician: PropTypes.func.isRequired,
    form: PropTypes.shape({
      episode: PropTypes.shape({
        values: PropTypes.shape(EpisodePropTypes),
      }).isRequired,
    }).isRequired,
    physician: PropTypes.object,
    history: PropTypes.shape(HistoryPropTypes).isRequired,

    hasEpisode: PropTypes.bool,
  };

  static defaultProps = {
    hasEpisode: false,
  };

  state = {
    dimensions: Dimensions.get('window'),
    loading: false,
    saved: false,
    shouldShowModal: false,
  };

  constructor(props) {
    super(props);

    if (props.physician) props.getPhysicianRating(props.physician.id);

    this.subscription = Dimensions.addEventListener(
      'change',
      this.onDimensionsChange
    );
  }

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout);
    clearTimeout(this.savedTimeout);
    this.props.resetPhysicianRating();
    this.subscription?.remove();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.loading !== nextState.loading ||
      this.state.saved !== nextState.saved ||
      nextState.dimensions.width !== this.state.dimensions.width ||
      this.state.shouldShowModal !== nextState.shouldShowModal
    );
  }

  /**
   * Log the COE selection in Google Analytics.
   */
  logEvent = () => {
    const hospital = this.props.physician.hospitals[0];
    logEvent(EVENTS.episode.selectProvider, {
      hospital: hospital.name,
      location: `${hospital.address.city}, ${hospital.address.state}`,
    });
  };

  onBackPress = () => {
    this.props.history.goBack();
  };

  onDimensionsChange = (event) => {
    this.setState({ dimensions: event.window });
  };

  /**
   * Creates the episode in the care service,
   * and navigates to the confirmation route.
   */
  onSubmit = async () => {
    this.setState({ loading: true, shouldShowModal: false });

    await this.props.selectPhysician(this.props.physician);

    if (this.props.hasEpisode) {
      const updatePhysicianSuccess = await this.props.updatePhysician(
        this.props.physician
      );

      const episodeDistanceSuccess = await this.props.getEpisodeDistance();

      if (!updatePhysicianSuccess || !episodeDistanceSuccess) {
        return this.setState({ loading: false });
      }
    }

    this.logEvent();

    // Defer state changes to allow animations to complete.
    this.loadingTimeout = setTimeout(() => this.setState({ saved: true }), 480);
    this.savedTimeout = setTimeout(
      () => this.props.history.push(`/${Routes.Confirmation}`),
      1080
    );
  };

  toggleModal = () => {
    this.setState({ shouldShowModal: !this.state.shouldShowModal });
  };

  render() {
    const { physician } = this.props;
    const { width } = this.state.dimensions;

    if (!physician) return <Redirect to={Routes.Home} />;

    const profileImage = physician.profileImage || DEFAULT_PHYSICIAN_IMAGE;

    return (
      <Container testID="SurgeonDetail">
        <TitleBar
          title={physician.name}
          subtitle={physician.title}
          onBackPress={this.props.history.goBack}
        />

        <ScrollView>
          <MapViewContainer pointerEvents="none">
            <MapView region={formatCoords(physician.hospital)} zoom={6}>
              <Marker
                coordinate={formatCoords(physician.hospital)}
                position={formatCoords(physician.hospital)}
              />
            </MapView>
          </MapViewContainer>

          <AvatarContainer>
            <Avatar
              source={Platform.select({
                web: profileImage,
                default: { uri: profileImage },
              })}
            />

            <FeatureSwitch name="surgeon-recommendations" state="on">
              {physician.rating && <SurgeonRating {...physician.rating} />}
            </FeatureSwitch>
          </AvatarContainer>

          <ContentContainer wide={width > theme.breakpoints.xsmall}>
            <ContentSection isColumn={width <= theme.breakpoints.xsmall}>
              {physician.details && (
                <BiographySectionContainer>
                  <Text h4>Biography</Text>
                  <PhysicianBiography text={physician.details} />
                </BiographySectionContainer>
              )}

              {Object.keys(sections).map(
                (section) =>
                  physician[section]?.length > 0 && (
                    <BiographySectionContainer key={section}>
                      <BiographySectionTitle>
                        {sections[section]}
                      </BiographySectionTitle>

                      {physician[section].map((text) => (
                        <PhysicianBiography key={text} text={text} />
                      ))}
                    </BiographySectionContainer>
                  )
              )}
            </ContentSection>

            <Spacer />

            <ContentSection isColumn={width <= theme.breakpoints.xsmall}>
              <HospitalCard {...physician.hospitals[0]} />

              {physician.hospitals[0].videos.map((video) => (
                <SurgeonVideo key={video.url} {...video} />
              ))}

              {physician.videos.map((video) => (
                <SurgeonVideo key={video.url} {...video} />
              ))}
            </ContentSection>
          </ContentContainer>
        </ScrollView>

        <FloatingActionButton
          show={!this.state.saved}
          disabled={this.state.loading}
          loading={this.state.loading}
          color={theme.colors.primary}
          onPress={this.toggleModal}
          label="Select This Surgeon"
        />
        <FloatingActionButton
          disabled
          show={this.state.saved}
          color={theme.colors.success}
        />

        <SurgeonConfirmationModal
          isVisible={this.state.shouldShowModal}
          onClose={() => this.toggleModal(false)}
          onConfirm={this.onSubmit}
        />
      </Container>
    );
  }
}

export default withRouter(SurgeonDetail);
