import { getAccountSettingsFormValuesFromUser } from 'app/components/CompleteYourProfile/helpers';

import {
  AccountSettingsForm,
  AccountSettingsWrapper,
  FormWrapper,
  InputWrapper,
} from 'app/components/CompleteYourProfile/SaveProgress/styles';

export const SaveProgress = ({
  headerComponent,
  nextRoute,
  submitButtonText = 'Create Account',
  testIDRoot,
  enableStickyNavFooter = false,
}) => {
  return (
    <AccountSettingsForm
      accountSettingsWrapper={AccountSettingsWrapper}
      formWrapper={FormWrapper}
      getFormValuesFromUser={getAccountSettingsFormValuesFromUser}
      headerComponent={headerComponent}
      inputWrapper={InputWrapper}
      nextRoute={nextRoute}
      submitButtonText={submitButtonText}
      testIDRoot={testIDRoot}
      enableStickyNavFooter={enableStickyNavFooter}
    />
  );
};

export default SaveProgress;
