import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import { Card, Icon, Image, Text } from 'react-native-elements';
import MapView, { Marker } from '@cross-platform/react-native-maps';

import PropTypes from 'prop-types';

import home from 'app/assets/images/home.png';
import homeSmall from 'app/assets/images/home-small.png';
import pin from 'app/assets/images/pin.png';
import pinSmall from 'app/assets/images/pin-small.png';
import { DotIndicator } from 'app/components/Common/svg-components';
import { teaserOffset } from 'app/components/Common/TeaserPanel/styles';
import { toAddressString } from 'app/util/methods';

import theme from 'app/util/theme';

import { formatCoords, getMapCenter, getZoomFactor } from './helpers';

const homeIcon = Platform.select({ default: home, web: homeSmall });
const pinIcon = Platform.select({ default: pin, web: pinSmall });

// Re-render if the location or hosptials list changes.
const arePropsEqual = (prevProps, nextProps) =>
  prevProps.hospitals.length === nextProps.hospitals.length &&
  JSON.stringify(prevProps.location) === JSON.stringify(nextProps.location);

/**
 * Displays a background map view with hospital markers.
 * The map center can be offset to accomodate various
 * device sizes.
 */
const ProcedureLocations = memo(({ location, hospitals }) => {
  const { width } = useWindowDimensions();
  const [expandList, setExpandList] = useState(true);
  const [zoom, setZoom] = useState(getZoomFactor(hospitals));
  const mapView = useRef(null);
  const markers = hospitals.map(formatCoords);
  const homeMarker = location ? formatCoords(location) : null;

  // Offset the map center to make room for search results.
  const offset = {
    x: width > theme.breakpoints.small ? -0.6 : 0,
    y: width > theme.breakpoints.small ? 0 : -0.4,
  };

  const [center, setCenter] = useState(getMapCenter(hospitals, offset, zoom));

  // Center the map around all markers.
  const onMapReady = useCallback(() => {
    if (markers.length < 2) return;

    setTimeout(() => {
      if (Platform.OS === 'web') {
        fitMapToMarkersWeb();
      } else {
        fitMapToMarkersNative();
      }
    }, 1000);
  }, [markers, fitMapToMarkersWeb, fitMapToMarkersNative]);

  const fitMapToMarkersNative = useCallback(() => {
    const edgePadding = {
      top: 100,
      right: 100,
      left: width > theme.breakpoints.small ? 400 : 100,
      bottom: width > theme.breakpoints.small ? 100 : 400,
    };

    mapView?.current?.fitToCoordinates(markers, {
      edgePadding,
      animated: true,
    });
  }, [markers, width]);

  const fitMapToMarkersWeb = useCallback(() => {
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();

    markers.forEach((marker) => bounds.extend(marker));
    mapView && mapView.current && mapView.current.fitBounds(bounds);
  }, [markers]);

  // Center the map on the given latitude and longitude.
  const zoomToRegion = (coordinates) => {
    if (!mapView) return;

    // TODO: include offset
    const region = formatCoords(coordinates);

    if (Platform.OS === 'web') {
      setCenter(region);
      setZoom(10);
    } else {
      mapView && mapView.current && mapView.current.animateToRegion(region);
    }
  };

  // Recenter the map if hospitals load after the component mounts.
  useEffect(onMapReady, [hospitals, location]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Constrain the hospitals list height to prevent overlap
   * with the app navigation and the map controls on web.
   */
  const maxHeight =
    theme.spacing *
    Platform.select({
      default: 22,
      web: width > theme.breakpoints.small ? 18 : 14,
    });
  const listHeight = useRef(new Animated.Value(maxHeight)).current;

  useEffect(() => {
    Animated.timing(listHeight, {
      easing: Easing.out(Easing.ease),
      toValue: expandList ? maxHeight : 0,
      duration: 100,
      // The native driver does not support height animations in this context on mobile devices
      useNativeDriver: false,
    }).start();
  }, [expandList, listHeight, maxHeight]);

  return (
    <View style={styles.container}>
      <MapView
        style={styles.map}
        zoom={zoom}
        ref={mapView}
        mapRef={mapView}
        onMapReady={onMapReady}
        initialRegion={center}
        maxZoomLevel={10}
        options={{
          fullscreenControl: false,
          scrollwheel: false,
          streetViewControl: false,
          // Put zoom controls in top-right corner to avoid FAB
          zoomControlOptions: { position: 7 },
        }}
      >
        {homeMarker?.lat && homeMarker?.lng ? (
          <Marker
            coordinate={homeMarker}
            position={homeMarker}
            onPress={() => zoomToRegion(homeMarker)}
            icon={{ url: homeIcon }}
            image={homeIcon}
          />
        ) : null}

        {markers.map((marker) => (
          <Marker
            key={`${marker.latitude}-${marker.longitude}`}
            coordinate={marker}
            position={marker}
            onPress={() => zoomToRegion(marker)}
            icon={{ url: pinIcon }}
            image={pinIcon}
          />
        ))}
      </MapView>

      <Card
        containerStyle={StyleSheet.flatten([
          styles.hospitalsWrapper,
          width > theme.breakpoints.small ? styles.hospitalsWrapperWide : {},
        ])}
      >
        <View style={styles.hospitalsListTitle}>
          <Text style={{ fontSize: theme.fontSizes.body3 }}>
            {hospitals.length === 0
              ? 'Looking for matching Centers for Excellence...'
              : `${hospitals.length} ${
                  hospitals.length === 1 ? 'Center' : 'Centers'
                } of Excellence Found`}
          </Text>

          {hospitals.length > 0 && (
            <Icon
              name={expandList ? 'expand-more' : 'expand-less'}
              onPress={() => setExpandList(!expandList)}
            />
          )}
        </View>

        <View style={styles.hospitalsDivider} />

        <Animated.View style={{ maxHeight: listHeight }}>
          <ScrollView contentContainerStyle={styles.hospitals}>
            {hospitals.length === 0 ? (
              <View style={StyleSheet.flatten(styles.hospital)}>
                <DotIndicator />
              </View>
            ) : (
              hospitals.map((hospital, index) => (
                <TouchableOpacity
                  onPress={() => zoomToRegion(hospital)}
                  key={hospital.id}
                  style={StyleSheet.flatten([
                    styles.hospital,
                    {
                      borderBottomWidth: index === hospitals.length - 1 ? 0 : 1,
                    },
                  ])}
                >
                  {hospital.images && hospital.images.logoIcon ? (
                    <Image
                      source={{ uri: hospital.images.logoIcon }}
                      style={styles.hospitalLogo}
                    />
                  ) : (
                    <Icon
                      name="hospital-o"
                      type="font-awesome"
                      size={36}
                      containerStyle={styles.hospitalIcon}
                    />
                  )}
                  <View style={{ flex: 1 }}>
                    <Text h4 h4Style={styles.hospitalTitle}>
                      {hospital.name}
                    </Text>
                    <Text style={styles.hospitalAddress}>
                      {toAddressString(hospital.address)}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))
            )}
          </ScrollView>
        </Animated.View>
      </Card>
    </View>
  );
}, arePropsEqual);

ProcedureLocations.displayName = 'ProcedureLocations';

/**
 * @property {array} hospitals An array of hospitals offering the current procedure (required).
 */
ProcedureLocations.propTypes = {
  hospitals: PropTypes.array.isRequired,
};

const styles = {
  container: {
    flex: 1,
  },

  map: {
    flex: 1,
  },

  hospitalsWrapper: {
    // Allow extra space below hospitals for <TeaserPanel />
    bottom: teaserOffset,
    left: 0,
    overflow: 'hidden',
    paddingBottom: 0,
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
    right: 0,
  },

  hospitalsWrapperWide: {
    right: '60%',
  },

  hospitals: {
    paddingHorizontal: theme.spacing * 0.75,
  },

  hospitalsDivider: {
    borderTopColor: '#ccc',
    borderTopWidth: 1,
  },

  hospitalsListTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing * 1.25,
  },

  hospital: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing / 4,
    borderBottomColor: '#eee',
    paddingVertical: theme.spacing / 2,
  },

  hospitalIcon: {
    height: 48,
    width: 48,
    marginRight: theme.spacing / 2,
    justifyContent: 'center',
  },

  hospitalLogo: {
    height: 48,
    width: 48,
    marginRight: theme.spacing / 2,
  },

  hospitalAddress: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: theme.fontSizes.small,
  },

  hospitalTitle: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: theme.fontSizes.small,
  },
};

export default ProcedureLocations;
