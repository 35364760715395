import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

interface Props {
  height?: number;
  style?: any;
  width?: number;
}

export const ChairAndPot = ({
  height = 271,
  width = 401,
  style = {},
}: Props) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 401 271"
      fill="none"
      style={style}
    >
      <Path
        d="M254.595 180.477L255.419 179.068L242.52 181.85L254.595 260.684H261.719L254.595 180.477Z"
        fill="#7C4236"
      />
      <Path
        d="M169.327 105.392C167.131 105.892 165.179 107.143 163.811 108.928C162.442 110.713 161.742 112.92 161.832 115.166C162.239 125.419 163.516 143.359 167.598 153.874C173.545 169.23 175.03 175.138 186.426 174.65C197.822 174.163 358.413 174.65 358.413 174.65C358.413 174.65 372.787 175.147 378.734 154.371C384.681 133.594 398.051 52.9715 390.619 38.6265C383.188 24.2816 351.47 19.8733 337.638 20.8218C323.807 21.7703 298.009 17.8228 264.318 59.8639L231.088 97.6865C210.295 98.1904 189.605 100.772 169.327 105.392Z"
        fill="#ED7136"
      />
      <G opacity="0.3">
        <Path
          opacity="0.3"
          d="M280.474 42.4567L248.376 132.149H188.905C188.905 132.149 184.207 102.013 175.562 104.145C175.562 104.145 219.138 96.9186 231.033 97.7045L280.474 42.4567Z"
          fill="black"
        />
      </G>
      <G opacity="0.3">
        <Path
          opacity="0.3"
          d="M300.414 175.328C300.414 175.328 306.859 166.891 309.837 151.552C312.815 136.214 320.238 103.576 305.121 104.073C305.121 104.073 348.987 105.455 357.903 98.3369C366.819 91.2186 391.847 43.0619 391.847 43.0619C391.847 43.0619 394.246 61.1828 390.526 87.9124C386.806 114.642 381.555 146.629 378.098 156.114C375.482 163.341 371.046 173.566 358.401 174.641C345.067 175.78 300.414 175.328 300.414 175.328Z"
          fill="black"
        />
      </G>
      <Path
        d="M188.379 174.479C188.379 174.479 191.33 183.512 210.031 183.512H337.636C337.636 183.512 353.83 183.151 358.365 174.479H188.379Z"
        fill="#C17560"
      />
      <Path
        d="M309.836 151.552C309.839 151.395 309.866 151.24 309.918 151.092C309.999 150.739 310.099 150.297 310.234 149.746C310.515 148.499 310.904 146.765 311.411 144.56C312.82 138.199 313.77 131.744 314.254 125.247C314.444 121.155 314.57 116.557 313.348 111.914C312.834 109.516 311.515 107.365 309.61 105.816C307.618 104.281 304.812 104.326 302.205 104.751C299.497 105.257 296.969 106.462 294.873 108.246C292.687 110.128 290.849 112.379 289.442 114.895C286.486 120.257 284.526 126.108 283.658 132.167L283.595 132.546H281.929L216.167 132.42L196.253 132.275L190.822 132.194L189.409 132.149C189.251 132.174 189.089 132.174 188.93 132.149C189.089 132.133 189.25 132.133 189.409 132.149H190.822L196.253 132.067L216.167 131.923L281.929 131.796H283.224L282.789 132.176C283.678 126.006 285.684 120.05 288.709 114.597C290.171 111.996 292.083 109.675 294.357 107.741C296.572 105.886 299.234 104.641 302.079 104.127C302.763 104 303.456 103.922 304.152 103.892H305.193L306.233 103.983C306.928 104.048 307.611 104.197 308.27 104.425C308.587 104.543 308.886 104.723 309.175 104.868C309.505 104.997 309.811 105.18 310.081 105.41C312.12 107.063 313.526 109.366 314.063 111.932C315.304 116.738 315.132 121.363 314.896 125.482C314.368 132.004 313.323 138.474 311.773 144.831C311.239 147.081 310.769 148.815 310.434 149.989C310.271 150.531 310.144 150.965 310.035 151.308C309.974 151.394 309.907 151.475 309.836 151.552Z"
        fill="#3A4750"
      />
      <Path
        d="M198.86 179.068L199.675 180.477L192.551 260.684H199.675L211.75 181.85L198.86 179.068Z"
        fill="#C17560"
      />
      <Path
        d="M289.392 180.477L290.215 179.068L277.316 181.85L289.392 260.684H296.516L289.392 180.477Z"
        fill="#C17560"
      />
      <Path
        d="M338.821 180.477L339.636 179.068L326.746 181.85L345.945 260.684H353.078L338.821 180.477Z"
        fill="#C17560"
      />
      <Path
        d="M360.194 174.669C360.194 174.894 319.986 175.075 270.408 175.075C220.831 175.075 180.613 174.894 180.613 174.669C180.613 174.443 220.813 174.271 270.408 174.271C320.004 174.271 360.194 174.452 360.194 174.669Z"
        fill="#3A4750"
      />
      <Path
        d="M276.762 183.494C276.762 183.72 262.089 183.901 243.994 183.901C225.9 183.901 211.227 183.72 211.227 183.494C211.227 183.268 225.891 183.088 243.994 183.088C262.098 183.088 276.762 183.268 276.762 183.494Z"
        fill="#3A4750"
      />
      <Path
        d="M327.001 183.494C327.001 183.72 318.674 183.901 308.4 183.901C298.126 183.901 289.789 183.72 289.789 183.494C289.789 183.268 298.126 183.088 308.4 183.088C318.674 183.088 327.001 183.268 327.001 183.494Z"
        fill="#3A4750"
      />
      <Path
        d="M135.019 23.7035C122.645 30.0991 113.158 41.1197 106.342 53.2425C99.5262 65.3652 95.118 78.6713 90.764 91.878L86.9531 160.007C101.346 135.563 112.905 106.828 116.028 78.6532L106.442 77.7951C112.869 76.8195 118.264 72.1763 121.694 66.666C125.125 61.1557 126.89 54.8052 128.61 48.5993L116.924 50.3337C126.164 44.1101 132.644 34.5725 135.019 23.7035Z"
        fill="#6D98A4"
      />
      <G opacity="0.3">
        <Path
          opacity="0.3"
          d="M135.019 23.7035C122.645 30.0991 113.158 41.1197 106.342 53.2425C99.5262 65.3652 95.118 78.6713 90.764 91.878L86.9531 160.007C101.346 135.563 112.905 106.828 116.028 78.6532L106.442 77.7951C112.869 76.8195 118.264 72.1763 121.694 66.666C125.125 61.1557 126.89 54.8052 128.61 48.5993L116.924 50.3337C126.164 44.1101 132.644 34.5725 135.019 23.7035Z"
          fill="black"
        />
      </G>
      <Path
        d="M84.8176 175.969C84.8176 175.969 53.5703 138.644 53.9324 102.98H66.5508C66.5508 102.98 52.8462 93.9467 53.9324 88.9512C55.0186 83.9558 55.3717 64.8322 55.3717 64.8322L66.9129 67.75C66.9129 67.75 58.2592 63.4321 57.173 56.5848C56.0868 49.7375 59.7437 18.0667 59.7437 18.0667C59.7437 18.0667 73.7652 32.8271 78.101 47.9399C82.4369 63.0527 93.788 80.6948 90.4479 126.765C87.1077 172.835 84.8176 175.969 84.8176 175.969Z"
        fill="#6D98A4"
      />
      <Path
        d="M100.665 179.492C100.665 179.492 134.519 156.99 142.041 128.264L131.894 125.5C131.894 125.5 144.883 121.263 145.119 116.973C145.354 112.682 149.246 97.2709 149.246 97.2709L139.335 97.0632C139.335 97.0632 147.237 95.4823 149.609 90.2249C151.98 84.9675 156.026 58.7167 156.026 58.7167C156.026 58.7167 141.48 67.5061 134.682 78.7165C127.884 89.9268 114.894 101.625 107.472 139.393C100.049 177.162 100.665 179.492 100.665 179.492Z"
        fill="#6D98A4"
      />
      <Path
        d="M68.2352 50.5867C68.2352 50.5867 68.2985 50.7131 68.3619 50.9661C68.4253 51.219 68.5339 51.6526 68.6425 52.1133C68.896 53.1883 69.2309 54.6697 69.6563 56.5396C70.5615 60.3878 71.693 65.9704 73.0146 72.899C75.7302 86.7381 78.835 105.97 81.3152 127.343C83.7954 148.716 85.117 168.165 85.6782 182.257C85.9769 189.293 86.1217 195.003 86.158 198.941C86.158 200.865 86.158 202.383 86.158 203.458C86.158 203.937 86.158 204.361 86.158 204.641C86.1796 204.776 86.1796 204.913 86.158 205.048C86.1291 204.914 86.1139 204.778 86.1127 204.641C86.1127 204.334 86.1127 203.946 86.0403 203.467C86.0403 202.365 85.9407 200.847 85.8683 198.95C85.7416 195.012 85.5062 189.312 85.1351 182.284C84.429 168.21 83.0079 148.797 80.5458 127.433C78.0837 106.069 74.9788 86.8826 72.4172 73.0255C71.2042 66.0969 70.127 60.5233 69.3486 56.63L68.4434 52.1765C68.3619 51.7068 68.2895 51.3184 68.2352 51.0112C68.2237 50.8699 68.2237 50.728 68.2352 50.5867Z"
        fill="#3A4750"
      />
      <Path
        d="M80.4823 198.833C80.4823 198.833 51.8873 166.448 43.0074 146.873L49.7148 144.018C44.8539 144.813 39.8754 142.736 36.137 139.565C32.3985 136.394 29.6015 132.239 26.8859 128.138L35.7206 126.647C27.6065 124.335 20.6958 118.994 16.4219 111.733C26.8406 113.441 36.2727 119.159 44.0393 126.295C50.3756 132.076 55.6619 138.788 60.7763 145.644C63.2666 148.976 65.4338 152.536 67.2484 156.277C72.055 166.213 80.5004 186.936 80.4823 198.833Z"
        fill="#6D98A4"
      />
      <Path
        d="M142.495 77.5512C142.466 77.67 142.423 77.7851 142.368 77.8944L141.943 78.7978L140.214 82.4111C138.693 85.5276 136.539 90.0533 133.877 95.6721C128.636 106.891 121.721 122.564 114.868 140.188C108.016 157.812 102.603 174.063 98.9189 185.888C97.1085 191.796 95.615 196.601 94.6373 199.926C94.1576 201.534 93.7322 202.807 93.4878 203.747C93.361 204.144 93.2524 204.46 93.1709 204.731C93.137 204.847 93.0915 204.959 93.0352 205.066C93.0352 205.066 93.0352 204.939 93.0895 204.713C93.1438 204.488 93.2343 204.108 93.3339 203.702C93.5783 202.753 93.9132 201.461 94.3386 199.835C95.2438 196.484 96.5926 191.66 98.3667 185.716C101.924 173.837 107.283 157.55 114.117 139.899C120.951 122.248 127.976 106.602 133.352 95.4281C136.068 89.8365 138.286 85.3469 139.888 82.2666C140.675 80.7851 141.291 79.6017 141.753 78.7345L142.25 77.8312C142.317 77.7261 142.4 77.6316 142.495 77.5512Z"
        fill="#3A4750"
      />
      <Path
        d="M82.9174 212.708C82.69 211.953 82.5354 211.179 82.4557 210.395C82.2204 208.905 81.8402 206.782 81.3061 204.072C79.9105 197.187 77.9672 190.424 75.4948 183.846C72.9876 177.278 69.9607 170.92 66.4429 164.831C65.0851 162.492 63.9536 160.622 63.1389 159.348C62.6822 158.708 62.2853 158.028 61.9531 157.315C62.4898 157.892 62.9744 158.515 63.4014 159.176C64.3066 160.396 65.5105 162.23 66.9317 164.533C70.612 170.575 73.724 176.943 76.228 183.557C78.7284 190.172 80.6101 197.003 81.8492 203.964C82.3109 206.674 82.6096 208.815 82.7544 210.287C82.8843 211.087 82.9388 211.898 82.9174 212.708Z"
        fill="#3A4750"
      />
      <Path
        d="M120.653 205.093H58.0586V217.83H120.653V205.093Z"
        fill="#ED7136"
      />
      <Path
        d="M64.0156 217.83L70.7865 260.684H108.614L114.924 217.83H64.0156Z"
        fill="#ED7136"
      />
      <Path
        d="M116.769 217.83C116.769 218.056 104.929 218.236 90.3286 218.236C75.7279 218.236 63.8789 218.056 63.8789 217.83C63.8789 217.604 75.7188 217.432 90.3286 217.432C104.938 217.432 116.769 217.604 116.769 217.83Z"
        fill="#3A4750"
      />
    </Svg>
  );
};

export default ChairAndPot;
