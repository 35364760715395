import { Image } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

import DisclaimerTextRaw from 'app/components/Common/DisclaimerText';

export const DisclaimerText = styled(DisclaimerTextRaw)`
  max-width: ${({ theme }) => theme.breakpoints.medium}px;
`;

interface TestimonialImageProps {
  first?: boolean;
}

export const Greeting = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-size: ${theme.fontSizes.h1}px;
    margin-bottom: ${theme.spacing}px;
  `};
  text-align: left;
`;

export const Paragraph = styled(Text).attrs({
  size: 'body1',
})`
  margin-bottom: ${({ theme }) => theme.spacing + 5}px;
  margin-top: ${({ theme }) => theme.spacing + 5}px;
  max-width: ${({ theme }) => theme.breakpoints.medium}px;
`;

export const TestimonialImage = styled(Image).attrs({
  resizeMode: 'contain',
})<TestimonialImageProps>`
  ${({ first, theme: { breakpoints, spacing, windowDimensions } }) => css`
    height: ${spacing * 9}px;
    margin-right: ${first ? spacing * 1.25 : 0}px;
    margin-top: ${!first && windowDimensions.width <= breakpoints.xlarge
      ? spacing * 1.25
      : 0}px;
    max-width: 100%;
    width: ${spacing * 26}px;
  `};
`;

export const TestimonialWrapper = styled(View)`
  ${({ theme }) =>
    theme.windowDimensions.width > theme.breakpoints.xlarge
      ? css`
          flex: 0 0 auto;
          flex-direction: row;
          margin-bottom: ${({ theme }) => theme.spacing * 2}px;
          margin-top: ${({ theme }) => theme.spacing * 2}px;
        `
      : css`
          flex: 1 0 auto;
          flex-direction: column;
        `};
  justify-content: flex-start;
`;
