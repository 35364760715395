import {
  initProfile,
  profileStepRoutes as availableSteps,
  Routes,
} from 'app/components/CompleteYourProfile/constants';

import {
  calculateBmi,
  getIsDifferentAddress,
  getUserInsurance,
  getUserLocation,
  getUserMailingAddress,
  getUserPhoneNumber,
  getUserProfile,
  merge,
  updateUserDetails,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useMemo,
  useParams,
  useSession,
  useState,
} from 'app/components/CompleteYourProfile/hooks';

import {
  QuestionnaireProfile,
  QuestionnaireProps,
  TellUsMoreValues,
} from 'app/components/CompleteYourProfile/types';

import {
  Redirect,
  Route,
  Switch,
} from 'app/components/CompleteYourProfile/styles';

import AlreadyScheduled from './AlreadyScheduled';
import ChronicPain from './ChronicPain';
import CurrentlyLiveHerePage from './CurrentlyLiveHere';
import InsuranceInfoPage from './InsuranceInfo';
import OncologyPage from './Oncology';
import PatientCheck from './PatientCheck';
import Progress from './Progress';
import Recommendation from './Recommendation';
import SmokingStatus from './SmokingStatus';
import TellUsMorePage from './TellUsMore';

import { AlignmentWrapper, Wrapper } from './styles';

export const Questionnaire = ({
  submitFinalStep,
  onGoToStopScreen,
}: QuestionnaireProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const session = useSession();
  const stepSlug = useParams().step;

  const sessionInsurance = getUserInsurance(session);
  const phoneNumber = getUserPhoneNumber(session);
  const profile = getUserProfile(session);
  const sessionUser = session?.user || {};
  const userAddress = getUserLocation(session);
  const userMailingAddress = getUserMailingAddress(session);

  /**
   * Assign the initial step based on the database value. Show the first step if
   * the profile is complete.
   *
   * NOTE: The profile is considered complete when `step === 0`.
   */
  const currentStep = useMemo(
    () =>
      !profile?.currentStep
        ? 1
        : availableSteps.findIndex((s) => s === stepSlug) + 1,
    [availableSteps, stepSlug, profile]
  );

  const sessionMembership = merge(sessionInsurance, profile?.insurance);

  const [localProfile, setLocalProfile] = useState<QuestionnaireProfile>({
    ...initProfile,
    chronicPain: profile?.chronicPain,
    doctorHasRecommended: undefined, // reset each visit to avoid escalation spamming
    grantVoicemailPermission: !!profile?.voicemailsAllowed,
    hasAlreadyScheduled: undefined, // reset each visit to avoid escalation spamming
    isUnderActiveTreatmentForOncology: undefined,
    hasConfirmedDiagnosisForOncology: undefined,
    howToHelpWithDiagnosisForOncology: undefined,
    isThePatient: profile?.currentStep > 1 ? true : undefined,
    height: {
      feet: !isNaN(profile?.heightFeet) ? profile?.heightFeet : null,
      inches: !isNaN(profile?.heightInches) ? profile?.heightInches : null,
    },
    insurance: {
      // @ts-ignore
      membership: {
        ...sessionMembership,
        medicareOrMedicaid: false,
      },
    },
    location: {
      physical: userAddress,
      mailing: userMailingAddress,
      isDifferentLocation: getIsDifferentAddress(
        userAddress,
        userMailingAddress
      ),
    },
    phoneNumber,
    smoking: profile?.smokingStatus || '',
    weight: profile?.weight || null,
  });

  const patientName = sessionUser?.name || '';

  /** Navigate to the stop screen if one should be shown or to the next page. */
  useEffect(() => {
    if (currentStep > availableSteps.length) submitFinalStep();
  }, [currentStep, availableSteps]);

  /** Save the profile information that was collected. */
  const saveProfile = useCallback(async () => {
    await setIsSaving(true);

    // setting to zero triggers background job to update `profile_complete`
    // task on the episode
    const nextStep =
      currentStep === availableSteps.length ? 0 : currentStep + 1;

    const {
      height,
      weight,
      smoking,
      phoneNumber,
      location,
      chronicPain,
      grantVoicemailPermission,
      hasAlreadyScheduled,
      doctorHasRecommended,
      insurance,
      isUnderActiveTreatmentForOncology,
      hasConfirmedDiagnosisForOncology,
      howToHelpWithDiagnosisForOncology,
    } = localProfile;

    const updatedProfile = {
      ...profile,
      recommendedForSurgery: doctorHasRecommended,
      voicemailsAllowed: grantVoicemailPermission,
      alreadyScheduledSurgery: hasAlreadyScheduled,
      isUnderActiveTreatmentForOncology,
      hasConfirmedDiagnosisForOncology,
      howToHelpWithDiagnosisForOncology,
      smokingStatus: smoking,
      chronicPain: chronicPain,
      bmi: calculateBmi(height.feet, height.inches, weight),
      weight,
      address: location.physical.address,

      location: {
        latitude: location.physical.lat,
        longitude: location.physical.lng,
      },

      insurance: { ...insurance.membership },
      heightFeet: height.feet,
      currentStep: nextStep, // Set to zero to indicate completion
      phoneNumber: phoneNumber.toString(), // must be a string => otherwise, it creates an issue in the care app
      heightInches: height.inches,

      mailingAddress: location.isDifferentLocation
        ? location.mailing.address
        : location.physical.address,
    };

    // update the profile with call
    const success = await dispatch(
      await updateUserDetails({
        profile: updatedProfile,
      })
    );

    // set the saving state to false
    await setIsSaving(false);
    return success;
  }, [localProfile, currentStep, availableSteps]);

  const handleTellUsMoreChange = (values: TellUsMoreValues) => {
    setLocalProfile({
      ...localProfile,
      ...values,
    });
  };

  if (!profile || !localProfile)
    return <Redirect to={`/${Routes.ProfileCreate}/${Routes.Overview}`} />;

  return (
    <Wrapper>
      <AlignmentWrapper>
        <Progress
          currentStep={currentStep}
          totalSteps={availableSteps.length}
        />
        <Switch>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.PatientCheck}`}>
            <PatientCheck
              onGoToStopScreen={onGoToStopScreen}
              isFirstStep
              isSaving={isSaving}
              isNextDisabled={typeof localProfile.isThePatient === 'undefined'}
              value={localProfile.isThePatient}
              patientName={patientName}
              onNext={saveProfile}
              onChange={(value: boolean) =>
                setLocalProfile({
                  ...localProfile,
                  isThePatient: value,
                })
              }
            />
          </Route>
          <Route
            exact
            path={`/${Routes.ProfileCreate}/${Routes.Recommendation}`}
          >
            <Recommendation
              isSaving={isSaving}
              isNextDisabled={
                typeof localProfile.doctorHasRecommended === 'undefined'
              }
              value={localProfile.doctorHasRecommended}
              onNext={saveProfile}
              onChange={(value: boolean) =>
                setLocalProfile({
                  ...localProfile,
                  doctorHasRecommended: value,
                })
              }
            />
          </Route>
          <Route
            exact
            path={`/${Routes.ProfileCreate}/${Routes.AlreadyScheduled}`}
          >
            <AlreadyScheduled
              isSaving={isSaving}
              value={localProfile.hasAlreadyScheduled}
              onNext={saveProfile}
              onChange={(value: boolean) =>
                setLocalProfile({
                  ...localProfile,
                  hasAlreadyScheduled: value,
                })
              }
            />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.TellUsMore}`}>
            <TellUsMorePage
              isSaving={isSaving}
              onChange={handleTellUsMoreChange}
              onNext={saveProfile}
              values={{
                ...localProfile,
              }}
            />
          </Route>
          <Route
            exact
            path={`/${Routes.ProfileCreate}/${Routes.SmokingStatus}`}
          >
            <SmokingStatus
              isSaving={isSaving}
              value={localProfile.smoking}
              onNext={saveProfile}
              onChange={(value: string) =>
                setLocalProfile({
                  ...localProfile,
                  smoking: value,
                })
              }
            />
          </Route>
          <Route
            exact
            path={`/${Routes.ProfileCreate}/${Routes.ChronicPainCheck}`}
          >
            <ChronicPain
              isSaving={isSaving}
              value={localProfile.chronicPain}
              onNext={saveProfile}
              onChange={(value: boolean) =>
                setLocalProfile({
                  ...localProfile,
                  chronicPain: value,
                })
              }
            />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.Address}`}>
            <CurrentlyLiveHerePage
              isSaving={isSaving}
              values={{ ...localProfile }}
              onNext={saveProfile}
              onChange={(values) =>
                setLocalProfile({
                  ...localProfile,
                  ...values,
                })
              }
            />
          </Route>
          <Route
            exact
            path={`/${Routes.ProfileCreate}/${Routes.InsuranceInfo}`}
          >
            <InsuranceInfoPage
              isSaving={isSaving}
              values={{ ...localProfile }}
              onNext={saveProfile}
              onGoToStopScreen={onGoToStopScreen}
              onChange={(values) =>
                setLocalProfile({
                  ...localProfile,
                  insurance: { ...values },
                })
              }
            />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.Oncology}`}>
            <OncologyPage
              isSaving={isSaving}
              values={{ ...localProfile }}
              onNext={saveProfile}
              onGoToStopScreen={onGoToStopScreen}
              onChange={(values) =>
                setLocalProfile({
                  ...localProfile,
                  ...values,
                })
              }
            />
          </Route>
        </Switch>
      </AlignmentWrapper>
    </Wrapper>
  );
};

export default Questionnaire;
