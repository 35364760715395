import { useEffect, useHistory } from 'app/components/Login/hooks';

import {
  Bold,
  ImageContainer,
  MagicLinkSent,
  MagicLinkView,
  ParagraphText,
} from 'app/components/Login/styles';

import { MagicLinkConfirmationProps } from 'app/components/Login/types';

const MagicLinkConfirmation = ({
  formValues = {},
}: MagicLinkConfirmationProps) => {
  const history = useHistory();

  const email = formValues?.email;

  useEffect(() => {
    if (!email) history.replace('/login');
  }, []);

  return (
    <MagicLinkView>
      <ImageContainer>
        <MagicLinkSent />
      </ImageContainer>
      <ParagraphText>
        We sent an email to you at <Bold>{email}</Bold>. It has a magic link
        that will sign you in.
      </ParagraphText>
    </MagicLinkView>
  );
};

export default MagicLinkConfirmation;
