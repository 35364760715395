import { Easing, Platform } from 'react-native';

import { breakpoints } from 'app/util/breakpoints';
import { useNativeDriver } from 'app/util/constants';
import { Routes } from 'app/util/routes';
import { EnvironmentTypes } from 'types/environments';
import { PlatformTypes } from 'types/platforms';

export const animationConfig = {
  duration: 200,
  useNativeDriver,
};

export const animationValues = {
  opening: {
    fadeTo: 1,
    slideTo: 0,
  },
  closing: {
    fadeTo: 0,
    slideTo: 280,
  },
};

export const headerAnimationConfig = {
  delay: 120,
  duration: 300,
  easing: Easing.in(Easing.ease),
  useNativeDriver,
};

export const nativePlatforms = [PlatformTypes.Android, PlatformTypes.iOS];

export const navigationLinkStyles = {
  underlineHeight: 3,
  badgeHeight: 18,
};

export const navigationPaths = {
  careSummary: `/${Routes.Confirmation}`,
  carrumId: `/${Routes.CarrumID}`,
  forms: `/${Routes.Forms}`,
  help: `/${Routes.LearnMore}`,
  home: `/${Routes.Dashboard}`,
  logIn: `/${Routes.Login}`,
  messages: `/${Routes.Messages}`,
  profile: `/${Routes.Profile}`,
  register: `/${Routes.Register}`,
  settings: `/${Routes.MySettings}`,
  travel: `/${Routes.Travel}`,
};

export const nonProdEnvironments = [
  EnvironmentTypes.Demo,
  EnvironmentTypes.Development,
  EnvironmentTypes.Staging,
  EnvironmentTypes.UserAcceptanceTesting,
];

export const screenSizes = {
  narrow: breakpoints.small - 1,
  wide: breakpoints.small + 1,
};
