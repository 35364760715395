import React from 'react';
import { View } from 'react-native';

import CheckBox from 'app/components/Common/CheckBox';
import Input from 'app/components/Common/Input';
import PhoneInput from 'app/components/Common/PhoneInput/';
import SectionHeader from 'app/components/Profile/SectionHeader';

import { Field } from './styles';

interface EmergencyContactFormProps {
  formErrors: any;
  showBackLink?: boolean;
  showTitle?: boolean;
}

const defaultProps = {
  formErrors: {},
  showBackLink: false,
  showTitle: false,
};

const EmergencyContactForm = ({
  formErrors,
  showBackLink,
  showTitle,
}: EmergencyContactFormProps) => (
  <View testID="EmergencyContactForm">
    <SectionHeader
      showBackLink={showBackLink}
      title="Emergency Contact"
      showTitle={showTitle}
    />

    <Field
      error={formErrors['profile.emergencyContact.name']}
      name="profile.emergencyContact.name"
      label="Emergency Contact Name"
      component={Input}
    />

    <Field
      error={formErrors['profile.emergencyContact.email']}
      name="profile.emergencyContact.email"
      label="Emergency Contact Email"
      component={Input}
    />

    <Field
      error={formErrors['profile.emergencyContact.phone']}
      name="profile.emergencyContact.phone"
      label="Emergency Contact Phone Number"
      component={PhoneInput}
    />

    <Field
      error={formErrors['profile.emergencyContact.relationship']}
      name="profile.emergencyContact.relationship"
      label="Emergency Contact Relationship"
      component={Input}
    />

    <Field
      name="profile.emergencyContact.allowedToSharePhi"
      label="I give Carrum Health permission to speak to my emergency contact regarding my health and case details."
      component={CheckBox}
    />
  </View>
);

EmergencyContactForm.defaultProps = defaultProps;

export default EmergencyContactForm;
