import styled from 'styled-components/native';

import { Card as RNECard } from 'react-native-elements';

import SizedImage from 'app/components/Common/SizedImage';
import Text from 'app/components/Common/Styled/Text';

export const Card = styled(RNECard).attrs(({ theme }) => ({
  containerStyle: {
    marginHorizontal: 0,
    maxWidth: theme.breakpoints.xsmall,
    overflow: 'hidden',
  },
  wrapperStyle: {
    overflow: 'hidden',
  },
}))``;

export const CardContent = styled.View`
  padding: ${({ theme }) => theme.spacing / 2}px;
`;

export const HospitalAddress = styled(Text)``;

export const HospitalLogo = styled(SizedImage).attrs({ maxHeight: 50 })`
  align-items: flex-start;
  flex: 1 0 auto;
  flex-direction: row;
`;

export const HospitalName = styled(Text).attrs({ bold: true })``;
