import axios from 'axios';

import { CORE_API_URL } from 'app/util/constants';
import { fetchCurrentUser, trackRememberMe } from 'app/actions/sessionActions';
import { Platform } from 'react-native';
import { push } from 'app/util/history';
import {
  receiveCoreLogin,
  receiveLoading,
} from 'app/actions/sessionActions/action-types';
import { showLocalNotification } from 'app/actions/uiActions';
import { updateSavedSingleClickRegistrations } from 'app/util/userUtils';
import { updateUserDetails } from 'app/actions/userActions';

export const updateSecureStoreForSingleClickRegistration = () => {
  return async (dispatch, getState) => {
    const {
      session: { user },
    } = getState();

    if (!user?.email) return;

    await updateSavedSingleClickRegistrations(user.email);
    await dispatch(trackRememberMe(true, user.email));
  };
};

export const verifySingleClickRegistration = (
  token,
  phoneNumber,
  challenge?
) => {
  return async (dispatch, getState) => {
    await dispatch(receiveLoading(true));
    const { user } = getState().session;
    try {
      const response = await axios.patch(`${CORE_API_URL}/scr/${token}`, {
        challenge,
      });

      await dispatch(receiveCoreLogin(response.data.jwt));
      await dispatch(fetchCurrentUser());
      await dispatch(updateSecureStoreForSingleClickRegistration());
      await push('/');

      const updatedUserData = {
        ...user,
        profile: { ...user.profile, phoneNumber },
        acceptedMemberAgreement: true,
        acceptedTerms: true,
        has_mobile_app: Platform.OS !== 'web',
      };

      await dispatch(updateUserDetails(updatedUserData));
    } catch (error) {
      dispatch(
        showLocalNotification({
          body: 'We were unable to confirm your information. Please select "Create Account" in the navigation to register, or contact us for more help at 1-888-855-7806.',
          title: 'Member verification failed.',
          id: 'single-click-registration',
          dismissIn: 10000,
          icon: 'chain-broken',
          status: 'warning',
        })
      );
    } finally {
      await dispatch(receiveLoading(false));
    }
  };
};

export default verifySingleClickRegistration;
