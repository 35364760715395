import React, { Component } from 'react';
import {
  Animated,
  PanResponder,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Button, Icon, Text } from 'react-native-elements';

import PropTypes from 'prop-types';

import { useNativeDriver } from 'app/util/constants';
import { playSound } from 'app/util/soundUtils';
import theme from 'app/util/theme';

/**
 * Renders an in-app notification that can be
 * pressed or swiped away to dismiss.
 */
export default class Toast extends Component {
  /**
   * @property {string} title The title of the notification (required).
   * @property {string} count The total number of notifications (required).
   * @property {function} onDismiss function to call when a notification has been swiped away (required).
   * @property {function} onPress A function to call when a notification has been pressed (required).
   * @property {string} body The body of the notification (defaults to '').
   * @property {number} dismissIn Automatically dismiss the notification after a certain timeout.
   * @property {string} icon The name of an icon to display next to the title.
   */
  static propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onPress: PropTypes.func.isRequired,

    actions: PropTypes.array,
    body: PropTypes.string,
    dismissIn: PropTypes.number,
    icon: PropTypes.string,
    status: PropTypes.string,
  };

  static defaultProps = {
    actions: [],
    body: '',
    dismissIn: null,
    icon: '',
    status: 'info',
  };

  state = {
    fade: new Animated.Value(1),
    pan: new Animated.ValueXY(),
    slide: new Animated.Value(89),
  };

  panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (evt, { dx }) => dx >= 2,
    onPanResponderGrant: () => {
      clearTimeout(this.dismissTimeout);
    },
    onPanResponderMove: (_, { dx }) => {
      if (dx) this.state.pan.setValue({ x: dx, y: 0 });
    },
    onPanResponderRelease: (_, { dx }) => {
      if (dx > 160) {
        this.springOutOfView();
      } else {
        this.springBackToInitialLocation();
        this.autoDismiss();
      }
    },
  });

  componentDidMount() {
    this.autoDismiss();
    this.slideUp();
    this.chime();
  }

  /**
   * Handles animating this item in the list when
   * another item is added or removed from the list.
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.count < this.props.count) return this.slideUp();
    if (
      prevProps.count > this.props.count &&
      prevProps.index === this.props.index
    )
      return this.slideDown();
  };

  componentWillUnmount() {
    clearTimeout(this.dismissTimeout);
  }

  autoDismiss = () => {
    if (this.props.dismissIn) {
      this.dismissTimeout = setTimeout(this.fadeOut, this.props.dismissIn);
    }
  };

  chime = () => {
    playSound(this.props.status);
  };

  fadeOut = () => {
    Animated.timing(this.state.fade, {
      toValue: 0,
      useNativeDriver,
    }).start(this.props.onDismiss);
  };

  slideDown = () => {
    this.setState({ slide: new Animated.Value(-89) }, () => {
      Animated.spring(this.state.slide, {
        toValue: 0,
        friction: 5,
        useNativeDriver,
      }).start();
    });
  };

  slideUp = () => {
    this.setState({ slide: new Animated.Value(89) }, () => {
      Animated.spring(this.state.slide, {
        toValue: 0,
        friction: 5,
        useNativeDriver,
      }).start();
    });
  };

  springOutOfView = () => {
    Animated.spring(this.state.pan, {
      friction: 5,
      toValue: { x: 2000, y: 0 },
      useNativeDriver,
    }).start(this.props.onDismiss);
  };

  springBackToInitialLocation = () => {
    Animated.spring(this.state.pan, {
      toValue: 0,
      friction: 5,
      useNativeDriver,
    }).start();
  };

  render() {
    const fadeStyle = { opacity: this.state.fade };
    const slideStyle = { translateY: this.state.slide };

    const panStyle = {
      transform: [...this.state.pan.getTranslateTransform(), slideStyle],
    };

    const textColor =
      this.props.status === 'warning'
        ? theme.colors.bodyText
        : theme.colors.white;

    return (
      <Animated.View
        {...this.panResponder.panHandlers}
        style={[
          fadeStyle,
          panStyle,
          styles.toast,
          styles[`${this.props.status}Toast`],
        ]}
        testID={`Toast--${this.props.id}`}
      >
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={this.props.onPress}
          style={{
            alignItems: this.props.actions.length > 0 ? 'stretch' : 'center',
            justifyContent: 'space-between',
            flexDirection: this.props.actions.length > 0 ? 'column' : 'row',
            width: '100%',
          }}
        >
          <View style={styles.toastContent}>
            <View style={styles.titleRow}>
              {this.props.icon ? (
                <Icon
                  color={textColor}
                  type="font-awesome"
                  containerStyle={styles.icon}
                  name={this.props.icon}
                />
              ) : null}
              <Text
                style={{
                  fontSize: this.props.body
                    ? theme.fontSizes.body3 // Title should be smaller if there is `body` text
                    : theme.fontSizes.body2,
                  color: textColor,
                }}
              >
                {this.props.title}
              </Text>
            </View>

            {this.props.body ? (
              <Text
                style={{
                  fontSize: theme.fontSizes.body2,
                  color: textColor,
                }}
              >
                {this.props.body}
              </Text>
            ) : null}
          </View>

          {this.props.actions.length > 0 ? (
            <View style={styles.actionWrapper}>
              {this.props.actions.map(({ iconName, ...action }) => (
                <Button
                  icon={
                    iconName && (
                      <Icon color={textColor} size={40} name={iconName} />
                    )
                  }
                  key={action.title}
                  testID="Toast--action-button"
                  titleStyle={{ color: textColor }}
                  {...action} // Must be above onPress to avoid overwriting it, since there's an `action.onPress`
                  onPress={() => {
                    action.onPress();
                    this.props.onDismiss();
                  }}
                />
              ))}
            </View>
          ) : (
            <Button
              type="clear"
              icon={<Icon color={textColor} size={40} name="close" />}
              onPress={this.props.onDismiss}
              testID="Toast--dismiss-button"
            />
          )}
        </TouchableOpacity>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  toast: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: theme.border.radiusTen,
    padding: theme.spacing / 2,
    marginLeft: theme.spacing / 2,
    marginRight: theme.spacing / 2,
    marginTop: theme.spacing / 2,
    maxWidth: theme.breakpoints.small - theme.spacing,
  },

  toastPressable: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },

  toastContent: {
    flex: 1,
  },

  infoToast: {},

  warningToast: {
    backgroundColor: theme.colors.warning,
  },

  errorToast: {
    backgroundColor: theme.colors.error,
  },

  successToast: {
    backgroundColor: theme.colors.success,
  },

  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing / 4,
  },

  title: {
    color: '#fff',
  },

  icon: {
    marginRight: theme.spacing / 4,
  },

  actionWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing / 4,
  },
});
