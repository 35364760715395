import { useEffect, useState } from 'app/components/CompleteYourProfile/hooks';

import {
  BenefitsOverview,
  CompletedText,
  TitleText,
  View,
} from 'app/components/CompleteYourProfile/styles';

import { BenefitsCompleteProps } from 'app/components/CompleteYourProfile/types';

export const BenefitsComplete = ({ onNextPage }: BenefitsCompleteProps) => {
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    return () => {
      setIsSaving(false);
    };
  }, []);

  return (
    <BenefitsOverview
      allowModal
      description={
        <CompletedText>
          Thank you for completing your profile. You can now get the Carrum Gold
          Experience, which includes:
        </CompletedText>
      }
      isSaving={isSaving}
      mode="dark"
      onContinue={() => {
        setIsSaving(true);
        onNextPage();
      }}
      showDetailsFooter={true}
      title={
        <View>
          <TitleText>Profile completed!</TitleText>
        </View>
      }
    />
  );
};

export default BenefitsComplete;
