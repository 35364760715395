import { isEmpty } from 'lodash';

/**
 * A helper function to convert a user object into
 * a pre-populated JSON profile.
 *
 * @param {object} user A user from the current session.
 * @return {object} A formatted profile object with pre-populated values.
 */
export const extractProfileValues = ({
  eligiblePatient,
  location,
  profile,
}) => {
  const result = {};
  [
    'ethnicity',
    'gender',
    'heightFeet',
    'heightInches',
    'language',
    'interpreterRequired',
    'maritalStatus',
    'phoneNumber',
    'religion',
    'voicemailsAllowed',
    'weight',
    'dependent',
    'subscriberFullSsn',
    'spokenNameUrl',
  ].forEach((field) => {
    result[field] = profile[field] || eligiblePatient[field] || '';
  });

  ['emergencyContact', 'insurance', 'mailingAddress'].forEach((field) => {
    if (profile[field] && !isEmpty(profile[field])) {
      result[field] = profile[field];
    } else if (eligiblePatient[field] && !isEmpty(eligiblePatient[field])) {
      result[field] = eligiblePatient[field];
    } else {
      result[field] = {};
    }
  });

  result.location = {
    lat:
      (profile.location && profile.location.latitude) ||
      (location && location.lat) ||
      '',
    lng:
      (profile.location && profile.location.longitude) ||
      (location && location.lng) ||
      '',
    address:
      profile.address ||
      (location && location.address) ||
      eligiblePatient.address,
  };

  // ensure that an empty `planType` gets set to "Unknown" in state
  result.insurance = {
    ...result.insurance,
    planType:
      result.insurance?.planType ??
      eligiblePatient.insurance?.planType ??
      'Unknown',
  };

  result.address =
    profile.address ||
    (location && location.address) ||
    eligiblePatient.address;
  result.mailingAddressSame =
    result.address &&
    JSON.stringify(result.address) === JSON.stringify(result.mailingAddress);
  result.bmi =
    profile.bmi ||
    calculateBmi(profile.heightFeet, profile.heightInches, profile.weight);
  result.preferredName =
    profile.preferredName ||
    `${eligiblePatient.firstName} ${eligiblePatient.lastName}`;

  return result;
};

/**
 * A helper function to convert a user object into
 * a pre-populated JSON profile.
 *
 * @param {object} formValues An object with form values for the current user.
 * @return {number} A number between 1-100 representing the profile completion rate.
 */
export const getProfileProgress = ({ location, profile }) => {
  let progress = 0;
  const total = 29; // Total number of fields in the profile

  [
    'ethnicity',
    'gender',
    'heightFeet',
    'heightInches',
    'language',
    'maritalStatus',
    'phoneNumber',
    'preferredName',
    'religion',
    'weight',
  ].forEach((field) => {
    if (profile && profile[field]) progress += 1;
  });

  if (location && location.lat && location.lng) progress += 5;
  if (profile && profile.insurance && profile.insurance.memberId) progress += 5;
  if (
    profile &&
    profile.mailingAddress &&
    profile.mailingAddress.street &&
    profile.mailingAddress.city &&
    profile.mailingAddress.state &&
    profile.mailingAddress.postalCode
  )
    progress += 5;
  if (
    profile &&
    profile.emergencyContact &&
    profile.emergencyContact.name &&
    profile.emergencyContact.email &&
    profile.emergencyContact.phone &&
    profile.emergencyContact.relationship
  )
    progress += 4;

  return Math.round((progress / total) * 100);
};

/**
 * Returns a BMI calculation for a given height and weight.
 *
 * @param {string|number} heightFeet The height of the person (feet).
 * @param {string|number} heightInches The height of the person (inches).
 * @param {string|number} weight The weight of the person in pounds.
 * @example
 * calculateBmi('5', '11', '165'); // => "23.01"
 */
export const calculateBmi = (heightFeet, heightInches, weight) => {
  if (!heightFeet || (!heightInches && heightInches !== 0) || !weight) return;

  const height = Number(heightFeet) * 12 + Number(heightInches);
  const heightSquared = height * height;

  return ((Number(weight) / heightSquared) * 703).toFixed(2);
};

/**
 * Returns the BMI categorization based on the gievn BMI.
 *
 * @param {string|number} bmi The BMI to categorize.
 * @return {string|number} The BMI categorization for the given BMI.
 */
export const categorizeBmi = (bmi) =>
  Number(bmi) < 18.5
    ? 'underweight'
    : Number(bmi) >= 18.5 && Number(bmi) < 25
    ? 'healthy'
    : Number(bmi) >= 25 && Number(bmi) < 30
    ? 'overweight'
    : Number(bmi) >= 30 && Number(bmi) < 40
    ? 'obese'
    : Number(bmi) >= 40
    ? 'morbidly_obese'
    : '';
