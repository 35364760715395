export type InsurancePlanType = 'PPO' | 'EPO' | 'HDHP' | 'Other' | 'Unknown';

interface InsurancePlanTypeOption {
  label: InsurancePlanType;
  value: InsurancePlanType;
}

export const planTypeOptions: InsurancePlanTypeOption[] = [
  { label: 'PPO', value: 'PPO' },
  { label: 'EPO', value: 'EPO' },
  { label: 'HDHP', value: 'HDHP' },
  { label: 'Other', value: 'Other' },
  { label: 'Unknown', value: 'Unknown' },
];
