import { Alert, Platform } from 'react-native';
import { Camera } from 'expo-camera';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as Linking from 'expo-linking';

import * as SecureStore from '@cross-platform/expo-secure-store';
import { logError } from 'app/util/methods';

export const RECEIVE_AUTOLOGOUT = 'RECEIVE_AUTOLOGOUT';
export const RECEIVE_PANEL_OPEN = 'RECEIVE_PANEL_OPEN';
export const RECEIVE_PROFILE_MENU_OPEN = 'RECEIVE_PROFILE_MENU_OPEN';
export const RECEIVE_LOCAL_NOTIFICATION = 'RECEIVE_LOCAL_NOTIFICATION';
export const REMOVE_LOCAL_NOTIFICATION = 'REMOVE_LOCAL_NOTIFICATION';
export const RECEIVE_SHOULD_REMEMBER_DEVICE = 'RECEIVE_SHOULD_REMEMBER_DEVICE';
export const RECEIVE_SET_CALL_US_MODAL_VISIBILITY =
  'RECEIVE_SET_CALL_US_MODAL_VISIBILITY';
export const RECEIVE_SET_CONTACT_ME_MODAL_VISIBILITY =
  'RECEIVE_SET_CONTACT_ME_MODAL_VISIBILITY';

/**
 * Updates the value of `autoLogout` in the store.
 *
 * @param {boolean} autoLogout - a boolean indicating if the app should automatically log out.
 * @return {promise} a promise that resolves when the store is updated.
 */
export const setAutoLogout = (autoLogout) => {
  return async (dispatch) => dispatch(receiveAutoLogout(autoLogout));
};

/**
 * Updates the value of `shouldRememberDevice` in the store.
 *
 * @param {string} userEmail - email of the user to pre-populate
 * @return {promise} a promise that resolves when the store is updated.
 */
export const syncShouldRememberFromLocalStorage = (userEmail) => {
  return async (dispatch) => {
    const defaultJSONValue = JSON.stringify({
      shouldRememberDevice: false,
      shouldRememberDateSet: null,
    });

    if (Platform.OS !== 'web') return JSON.parse(defaultJSONValue);

    const setting =
      (await SecureStore.getItemAsync(`localRememberDeviceSetting`)) ||
      defaultJSONValue;

    const { shouldRememberDateSet, shouldRememberDevice } = JSON.parse(setting);

    return dispatch(
      receiveShouldRemember(
        shouldRememberDevice,
        shouldRememberDateSet,
        userEmail
      )
    );
  };
};

/**
 * Updates the value of `shouldRememberDevice` in the store.
 *
 * @param {string} userEmail - email adderss to prepopulate
 * @param {boolean} shouldRememberDevice - a boolean indicating if the app should remember that it's logged in when the Patient App tab loses focus.
 * @return {promise} a promise that resolves when the store is updated.
 */
export const setShouldRememberDevice = (userEmail, shouldRememberDevice) => {
  return async (dispatch) => {
    if (Platform.OS !== 'web') return;

    const shouldRememberDateSet = new Date();

    const setting = JSON.stringify({
      shouldRememberDevice,
      shouldRememberDateSet,
      userEmail,
    });

    await SecureStore.setItemAsync(`localRememberDeviceSetting`, setting);

    return dispatch(
      receiveShouldRemember(
        shouldRememberDevice,
        shouldRememberDateSet,
        userEmail
      )
    );
  };
};

/**
 * Updates the value of `panelOpen` in the store.
 *
 * @param {integer} panelOpen - true if the side panel should be open.
 * @return {promise} a promise that resolves when the store is updated.
 */
export const setPanelOpen = (panelOpen) => {
  return async (dispatch) => dispatch(receivePanelOpen(panelOpen));
};

/**
 * Updates the value of `profileMenuOpen` in the store.
 *
 * @param {integer} profileMenuOpen - true if the profile menu should be open.
 * @return {promise} a promise that resolves when the store is updated.
 */
export const setProfileMenuOpen = (profileMenuOpen) => {
  return async (dispatch) => dispatch(receiveProfileMenuOpen(profileMenuOpen));
};

/**
 * Displays a notification.
 *
 * @param {object} notification A notification object to display.
 * @return {promise} A promise that resolves after adding the notification.
 */
export const showLocalNotification = (notification) => {
  return async (dispatch) => dispatch(receiveLocalNotification(notification));
};

/**
 * Removes notifications by index or redirect.
 *
 * @param {object} notification Should have an `index` or `redirect` property.
 * @return {promise} A promise that resolves after removing the matching notifications.
 */
export const dismissLocalNotification = (notification) => {
  return async (dispatch) => dispatch(removeLocalNotification(notification));
};

/**
 * Displays a global error message.
 *
 * @param {object|string} error An error object or string.
 */
export function showGlobalError(error) {
  return (dispatch) => {
    if (error?.response?.status === 401) return;

    logError(error);

    setTimeout(() => {
      dispatch(
        showLocalNotification({
          body: 'If this error continues, please call us at 1-888-855-7806.',
          title: 'Oops!  Something went wrong.',
          id: Math.random(),
          dismissIn: 5000,
          icon: 'bug',
          status: 'error',
        })
      );
    }, 480);
  };
}

/**
 * Launches the camera roll to allow users to
 * send photos already on their device.
 */
export function launchImageLibrary(options = {}) {
  return (dispatch) =>
    dispatch(
      launchMediaBrowser(
        'launchImageLibraryAsync',
        [ImagePicker.requestMediaLibraryPermissionsAsync],
        options
      )
    );
}

/**
 * Launches the camera app to allow users to
 * take and send photos using their device.
 */
export function launchCamera(options = {}) {
  return (dispatch) =>
    dispatch(
      launchMediaBrowser(
        'launchCameraAsync',
        [
          Camera.requestCameraPermissionsAsync, // Enables access to the camera
          ImagePicker.requestCameraPermissionsAsync, // Enables access to the camera roll
          ImagePicker.requestMediaLibraryPermissionsAsync, // Enables access to the media library
        ],
        options
      )
    );
}

/**
 * Launches the file explorer to allow users to
 * upload documents stored on their device.
 */
export function launchDocumentPicker(options = {}) {
  return (dispatch) =>
    dispatch(launchMediaBrowser('getDocumentAsync', [], options));
}

/**
 * Disables the auto logout while opening a media browser.
 *
 * @param {function} mediaFunction An async function to launch a media browser
 * @example const { cancelled, rejected, name, uri } = await launchMediaBrowserAsync(ImagePicker.launchCameraAsync);
 */
export function launchMediaBrowser(
  mediaFunction,
  permissions = [],
  options = {}
) {
  return async (dispatch) => {
    const hasAllPermissionsNeeded = await requestPermissions(permissions);

    if (!hasAllPermissionsNeeded) return { cancelled: true, rejected: true };

    await dispatch(setAutoLogout(false));

    let result;
    try {
      if (mediaFunction === 'launchImageLibraryAsync') {
        result = await ImagePicker.launchImageLibraryAsync(options);
      } else if (mediaFunction === 'launchCameraAsync') {
        result = await ImagePicker.launchCameraAsync(options);
      } else if (mediaFunction === 'getDocumentAsync') {
        result = await DocumentPicker.getDocumentAsync(options);
      }
    } catch (error) {
      logError(`Unable to execute ${mediaFunction.name}`, error);
      result = { cancelled: true };
    }

    await dispatch(setAutoLogout(true));

    if (result.type === 'cancel') result.cancelled = true;

    return result;
  };
}

/**
 * Requests a list of permissions and returns early
 * if one is denied by the user.  If the user denies,
 * an Alert is shown letting the user know they can
 * go to their settings to enable the permission later.
 *
 * @param {array} permissions An array of permission request functions.
 * @return {promise} A promise that resolves when the permissions are accepted or denied.
 */
const requestPermissions = async (permissions = []) => {
  let hasAllPermissionsNeeded = Platform.OS === 'web';

  if (!hasAllPermissionsNeeded) {
    const results = await Promise.all(
      permissions.map(async (permission) => await permission())
    );
    hasAllPermissionsNeeded = !results.find(
      (result) => !result?.granted || result?.accessPrivileges === 'none'
    );
  }

  if (!hasAllPermissionsNeeded) onPermissionReject();

  return hasAllPermissionsNeeded;
};

/**
 * Displays an Alert letting the user know they can
 * go to their settings page to enable permissions
 * that have been denied.
 */
const onPermissionReject = () => {
  Alert.alert(
    'Required Permissions Denied',
    'Access to the camera is required to take photos and videos, and ' +
      'access to photos is required to select items from your library.  ' +
      'You can enable this at any time from your app settings.',
    [
      { text: 'Cancel', style: 'cancel' },
      { text: 'Open Settings', onPress: Linking.openSettings },
    ]
  );
};

const receiveAutoLogout = (autoLogout) => {
  return {
    type: RECEIVE_AUTOLOGOUT,
    autoLogout,
  };
};

const receiveShouldRemember = (
  shouldRememberDevice,
  shouldRememberDateSet,
  userEmail
) => {
  return {
    type: RECEIVE_SHOULD_REMEMBER_DEVICE,
    shouldRememberDevice,
    shouldRememberDateSet,
    userEmail,
  };
};

const receivePanelOpen = (panelOpen) => {
  return {
    type: RECEIVE_PANEL_OPEN,
    panelOpen,
  };
};

const receiveProfileMenuOpen = (profileMenuOpen) => {
  return {
    type: RECEIVE_PROFILE_MENU_OPEN,
    profileMenuOpen,
  };
};

export const receiveLocalNotification = (notification) => ({
  type: RECEIVE_LOCAL_NOTIFICATION,
  notification,
});

export const removeLocalNotification = (options) => ({
  type: REMOVE_LOCAL_NOTIFICATION,
  ...options,
});

export const receiveSetCallUsModalVisibility = (isVisible) => ({
  type: RECEIVE_SET_CALL_US_MODAL_VISIBILITY,
  isVisible,
});

export const receiveSetContactMeModalVisibility = (isVisible) => ({
  type: RECEIVE_SET_CONTACT_ME_MODAL_VISIBILITY,
  isVisible,
});
