import React, { useEffect, useState } from 'react';
import {
  DeviceEventEmitter,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  View,
  ViewProps,
} from 'react-native';

/**
 * Provides a top-level wrapper that emits `blur` events when pressed,
 * enabling a focus/blur event life-cycle in native that mirrors the web.
 *
 * Use with the `<FocusableView />` component to allow any arbitrary component
 * tree to be focusable and blurrable.
 *
 * Events are emitted using the `DeviceEventEmitter` component, and can be listened
 * to directly or hooked into using the `onBlur` and `onFocus` props.
 *
 * @examples
 * const App = () => (
 *   <FocusBlurProvider>
 *     <Content />
 *   </FocusBlurProvider>
 * );
 *
 * const Content = () => {
 *   const [isFocused, setIsFocused] = useState(false);
 *
 *   return (
 *     <FocusableView
 *       onBlur={(event) => setIsFocused(false)}
 *       onFocus={(event) => setIsFocused(true)}
 *     >
 *       <Text>This content {isFocused ? 'is' : 'is not'} focused</Text>
 *     </FocusableView>
 *   );
 * }
 *
 * @example
 * const App = () => (
 *   <FocusBlurProvider>
 *     <Content />
 *   </FocusBlurProvider>
 * );
 *
 * const Content = () => {
 *   useEffect(() => {
 *     const blurListener = DeviceEventEmitter.addListener('blur', (event) => {
 *       // `event.target` is a ref to this instance of <FocusableView />
 *       console.log(event);
 *     });
 *
 *    const focusListener = DeviceEventEmitter.addListener('focus', (event) => {
 *       // `event.parent` is a ref to this instance of <FocusableView />
 *       console.log(event);
 *    });
 *
 *    return () => {
 *      blurListener.remove();
 *      focusListener.remove();
 *    };
 *  }, []);
 *
 *   return (
 *     <FocusableView>
 *       <Text>This content can be focused</Text>
 *     </FocusableView>
 *   );
 * }
 */
const FocusBlurProvider = ({ children, ...props }: ViewProps) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const blurListener = DeviceEventEmitter.addListener('blur', (event) => {
      setIsFocused(false);
    });

    const focusListener = DeviceEventEmitter.addListener('focus', (event) => {
      setIsFocused(true);
    });

    return () => {
      blurListener.remove();
      focusListener.remove();
    };
  }, []);

  /**
   * Emit a blur event whenever this top-level wrapper is pressed.
   */
  const onPress = (event: NativeSyntheticEvent<any>) =>
    DeviceEventEmitter.emit('blur', event);

  /**
   * Disable the `<Pressable />` component until a focus event is emitted
   * to prevent capturing scroll and press events on mobile devices.
   */
  const disabled = Platform.select({ web: false, default: !isFocused });

  return (
    <Pressable
      {...props}
      disabled={disabled}
      style={{ flex: 1 }}
      onPress={isFocused ? onPress : null}
    >
      {children}
    </Pressable>
  );
};

export default FocusBlurProvider;
