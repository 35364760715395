import { Platform } from 'react-native';
import styled from 'styled-components/native';

import { ignoreConsoleWarning } from 'app/util/methods';

/**
 * React Native Web applies a z-index of 0 to all <View /> components, which
 * can lead to unexpected behavior on the web.  This behavior is documented
 * in the following Pull Requst for React Native Web:
 *   https://github.com/necolas/react-native-web/issues/689
 *
 * Instead of using the default <View /> from React Native or styled.View,
 * this component should be used.
 */
const View = styled.View`
  ${Platform.OS === 'web' ? 'z-index: auto;' : ''}
`;

/**
 * z-index: auto is invalid in native, which causes css-to-react-native
 * to report a warning when applying the styles using css styles.  This issue
 * is visible in the library source code:
 *   https://github.com/styled-components/css-to-react-native/blob/master/src/index.js#L24-L27
 */
ignoreConsoleWarning('Expected style "zIndex: auto" to be unitless');

export default View;
