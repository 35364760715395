import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated, StyleSheet } from 'react-native';
import { BlurView } from 'expo-blur';

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

export default class BlurLayer extends Component {
  static propTypes = {
    blur: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      intensity: new Animated.Value(props.blur ? 95 : 0),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.blur === this.props.blur) return;

    if (this.props.blur) {
      this.blur();
    } else {
      this.unblur();
    }
  }

  unblur = () => {
    Animated.timing(this.state.intensity, {
      duration: 800,
      toValue: 0,
      useNativeDriver: false,
    }).start();
  };

  blur = () => {
    Animated.timing(this.state.intensity, {
      duration: 200,
      toValue: 95,
      useNativeDriver: false,
    }).start();
  };

  render() {
    const opacity = this.state.intensity.interpolate({
      inputRange: [0, 95],
      outputRange: [0.0, 1.0],
    });

    return (
      <AnimatedBlurView
        pointerEvents="none"
        intensity={this.state.intensity}
        style={StyleSheet.flatten([StyleSheet.absoluteFill, { opacity }])}
      />
    );
  }
}
