import {
  boolOptions,
  Routes,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import {
  getNextProfileRoute,
  getPreviousProfileRoute,
} from 'app/components/CompleteYourProfile/helpers';

import { useHistory } from 'app/components/CompleteYourProfile/hooks';

import {
  ButtonList,
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import { ChronicPainProps } from 'app/components/CompleteYourProfile/types';

import { DisclaimerText } from '../styles';

const ChronicPain = ({
  value,
  isSaving,
  onChange,
  onNext,
}: ChronicPainProps) => {
  const history = useHistory();

  return (
    <StepScreen testID={TestID.CompleteYourProfile.ChronicPain}>
      <FormContainer>
        <Header title="Do you suffer from chronic pain?" />
        <DisclaimerText>
          Chronic pain is pain that lasts for an extended period of time,
          typically more than 3 months. The discomfort can be as bothersome as a
          slight headache or can be severe enough to require medical attention.
        </DisclaimerText>
        <ButtonList
          mode="single"
          initialValue={value}
          key={'Chronic pain'}
          onChange={onChange}
          options={boolOptions}
        />
      </FormContainer>
      <ProfileCTAs
        isNextDisabled={typeof value === 'undefined'}
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() =>
            history.push(getNextProfileRoute(Routes.ChronicPainCheck))
          );
        }}
        onPrevious={() =>
          history.push(getPreviousProfileRoute(Routes.ChronicPainCheck))
        }
      />
    </StepScreen>
  );
};

export default ChronicPain;
