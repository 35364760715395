import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setPanelOpen } from 'app/actions/uiActions';
import {
  extractIncompleteFormCount,
  extractUnreadMessageCount,
} from 'app/components/App/Navigation/helpers';
import {
  HamburgerIcon,
  HamburgerIconWithBadge,
} from 'app/components/App/Navigation/MenuIcon/styles';
import { useMessage, useSession, useSubmission, useUi } from 'app/hooks';
import { getIsLoggedIn } from 'app/selectors/session';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

/**
 * Render the "hamburger menu" icon which toggles the display of the side nav.
 */
const MenuIcon = () => {
  const dispatch = useDispatch();
  const message = useMessage();
  const { user } = useSession();
  const submission = useSubmission();
  const { panelOpen: panelIsOpen } = useUi();

  const togglePanelDisplay = () => {
    dispatch(setPanelOpen(!panelIsOpen));
  };

  const conversation = message.conversations[0];
  const incompleteFormsCount = extractIncompleteFormCount(
    submission.submissions
  );
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const unreadMessagesCount = conversation
    ? extractUnreadMessageCount(user.id, conversation.messages)
    : 0;

  const hasIncompleteForms = incompleteFormsCount > 0;
  const hasUnreadMessages = unreadMessagesCount > 0;

  const menuHasIndicator =
    isLoggedIn && (hasUnreadMessages || hasIncompleteForms);

  return menuHasIndicator ? (
    <HamburgerIconWithBadge
      // @ts-ignore
      testID={TestID.Navigation.MenuIconWithBadge}
      onPress={togglePanelDisplay}
    />
  ) : (
    <HamburgerIcon
      testID={TestID.Navigation.MenuIcon}
      onPress={togglePanelDisplay}
    />
  );
};

export default MenuIcon;
