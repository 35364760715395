import { FlatList as FlatListRaw, TouchableOpacity } from 'react-native';
import { Image } from 'react-native-elements';

import styled, { css } from 'styled-components/native';

import { Fade } from 'app/components/Common/Animated';
import { Text, View } from 'app/components/Common/Styled';

export const Button = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacing / 2}px;
    display: flex;
    height: ${theme.spacing * 2.5}px;
    justify-content: center;
    width: 100%;
  `}
`;

export const FillerButton = styled(View)`
  ${({ theme }) => css`
    visibility: hidden;
    width: ${theme.spacing * 5}px;
  `}
`;

export const CategoryIcon = styled(Image)`
  ${({ theme }) => css`
    height: ${theme.spacing * 1.8}px;
    width: ${theme.spacing * 1.8}px;
  `}
`;

export const ButtonContainer = styled(TouchableOpacity)`
  ${({ theme }) => css`
    cursor: pointer;
    flex-direction: column;
    margin-top: ${theme.spacing}px;
    width: ${theme.spacing * 5}px;
  `}
`;

export const FlatList = styled(FlatListRaw).attrs({
  columnWrapperStyle: { justifyContent: 'space-between' },
})``;

export const Label = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.spacing * 0.75}px;
    padding-top: ${theme.spacing / 2}px;
    text-align: center;
  `}
`;

export const ListContainer = styled(Fade).attrs({ in: true, delay: 100 })`
  flex: 1 0 auto;
`;
