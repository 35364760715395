import { Platform, StyleProp, Pressable, View, ViewStyle } from 'react-native';
import { Button, ButtonProps, Icon } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import DisclaimerTextRaw from 'app/components/Common/DisclaimerText';
import { ScrollView, Text } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

import { getPadding } from './helpers';
import { ModalSize } from './types';

interface ButtonsWrapperProps {
  hasMarginTop?: boolean;
  stackedButtons?: boolean;
}

interface ModalCTAButtonProps extends ButtonProps {
  keepCasing?: boolean;
  stackButtons?: boolean;
}

export const ButtonsWrapper = styled(View)<ButtonsWrapperProps>`
  align-items: center;
  flex-direction: ${({ stackedButtons }) =>
    stackedButtons ? 'column' : 'row'};
  margin-top: ${({ hasMarginTop, theme }) =>
    hasMarginTop ? theme.spacing * 2.5 : 0}px;

  ${Platform.OS === 'web' &&
  css`
    cursor: pointer;
  `}
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.mediumBlue,
  name: 'close',
  size: 30,
}))``;

export const CloseIconButton = styled(Pressable)<{ modalSize: ModalSize }>`
  ${({ modalSize, theme }) => {
    let inset = getPadding(modalSize, theme.windowDimensions.width);

    return css`
      position: absolute;
      right: ${inset}px;
      top: ${inset}px;
    `;
  }}
`;

export const ContentScrollWrapper = styled(ScrollView).attrs({
  keyboardShouldPersistTaps: 'always',
  listViewDisplayed: false,
})`
  flex: 1 0 auto;
`;

export const DisclaimerText = styled(DisclaimerTextRaw)`
  margin-top: ${({ theme }) => theme.spacing}px;
`;

export const ModalCTAButton = styled(Button).attrs<ModalCTAButtonProps>(
  ({ stackButtons = false, keepCasing = false }) => {
    const optionalContainerStyle: StyleProp<ViewStyle> = {};
    if (stackButtons) {
      optionalContainerStyle.flexShrink = 0;
      optionalContainerStyle.flexBasis = 'auto';
      optionalContainerStyle.width = '100%';
    } else {
      optionalContainerStyle.flex = 1;
    }

    const optionalTitleStyle: StyleProp<ViewStyle> = {};
    if (keepCasing) {
      (optionalTitleStyle as any).textTransform = 'none';
    }

    return {
      containerStyle: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing / 4,
        ...optionalContainerStyle,
      },
      titleStyle: {
        fontSize: theme.fontSizes.body3,
        ...optionalTitleStyle,
      },
    };
  }
)<ModalCTAButtonProps>``;

export const Title = styled(Text).attrs({
  align: 'center',
  medium: true,
  size: 'h1',
})`
  margin-bottom: ${({ theme }) => theme.spacing * 1.25}px;
  margin-top: ${({ theme }) =>
    theme.isNarrow ? theme.spacing * 3 : theme.spacing * 1.25}px;
`;
