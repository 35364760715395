import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Container, PeopleIcon, RatingLabel, ThumbsUpIcon } from './styles';

/**
 * @property {number} coworkers The number of coworker selections.
 * @property {number} total The total number of selections.
 */
const propTypes = {
  coworkers: PropTypes.number,
  total: PropTypes.number,
};

/**
 * Shows the number of recommendations for a surgeon.
 *
 * @example
 * <SurgeonRating coworkers={80} total={200} />
 */
const SurgeonRating = ({ coworkers = 0, total = 0 }) => (
  <Fragment>
    {total && total > 0 ? (
      <Container marginTop>
        <PeopleIcon />
        <RatingLabel>{`${total} patients recommend this surgeon`}</RatingLabel>
      </Container>
    ) : null}

    {coworkers && coworkers > 0 ? (
      <Container>
        <ThumbsUpIcon />
        <RatingLabel>
          {`${coworkers} coworkers selected this surgeon`}
        </RatingLabel>
      </Container>
    ) : null}
  </Fragment>
);

SurgeonRating.propTypes = propTypes;
export default SurgeonRating;
