import styled from 'styled-components/native';

import { Icon } from 'react-native-elements';

import Text from 'app/components/Common/Styled/Text';

const iconProps = {
  color: '#2991DE',
  name: 'people',
  size: 30,
};

export const Container = styled.View`
  align-items: center;
  flex: 1 0 auto;
  flex-direction: row;
  margin-top: ${({ marginTop, theme }) => (marginTop ? theme.spacing : 0)}px;
`;

export const PeopleIcon = styled(Icon).attrs({
  ...iconProps,
  name: 'people',
})``;

export const RatingLabel = styled(Text).attrs({ size: 'small' })`
  padding-left: ${({ theme }) => theme.spacing / 2}px;
`;

export const ThumbsUpIcon = styled(Icon).attrs({
  ...iconProps,
  name: 'thumb-up',
})``;
