import { usePlatform, useSession } from 'app/hooks';

import { PATIENT_FAQ_URL } from 'app/util/constants';
import { TestID } from 'app/util/test-id';

import { navigationPaths } from '../../constants';
import { NavigationLink } from './styles';
import { getFaqUrl } from 'app/util/methods';

const HelpLink = () => {
  const { isPlatformTwo } = usePlatform();
  const {
    user: { employer },
  } = useSession();
  const faqUrl = getFaqUrl(employer);
  const path = isPlatformTwo ? faqUrl : navigationPaths.help;

  return (
    <NavigationLink
      key={PATIENT_FAQ_URL}
      name="Help"
      path={path}
      testID={TestID.Navigation.HelpLink}
    />
  );
};

export default HelpLink;
