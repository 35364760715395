import { LogBox } from 'react-native';

/**
 * Disable noisy warnings that cannot be immediately resolved.
 */
LogBox.ignoreLogs([
  /**
   * @TODO
   * Ignore deprecation warnings after upgrading to React v18.
   */
  'Constants.platform.ios.model has been deprecated',
  /**
   * @TODO
   * Ignore deprecation warnings after upgrading to React v18.
   */
  'Sending `onAnimatedValueUpdate` with no listeners registered.',
  /**
   * @TODO
   * Ignore require cycle errors temporarily until they are resolved.
   */
  'Require cycle',
  /**
   * @TODO
   * Ignore dismissable errors that occur due to the `<SearchBar />` component
   * from 'react-native-elements' not fully complying with the latest version
   * of React Native.
   *
   * The following issue in Github describes the fix, which will be available
   * after upgrading react-native-elements to version 4.0.0.
   * https://github.com/react-native-elements/react-native-elements/pull/3215
   */
  "Cannot read properties of null (reading 'blur')",
]);
