import { TestID } from 'app/components/Register/constants';

import { callCarrum } from 'app/components/Register/helpers';

import {
  Anchor,
  BodyText,
  Container,
  EmailText,
  Icon,
  IconContainer,
  FooterText,
  FooterWrapper,
} from 'app/components/Register/styles';

const Success = ({ email }) => {
  return (
    <Container testID={TestID.Register.Page}>
      <IconContainer>
        <Icon name="mail-outline" />
      </IconContainer>
      <BodyText>Thank you! An email has been sent to</BodyText>
      <EmailText>{email}</EmailText>
      <BodyText testID={TestID.Register.SuccessMessage}>
        Please check your email inbox for a confirmation email to activate your
        account.{' '}
      </BodyText>
      <FooterWrapper>
        <FooterText>
          Note: Activation emails can sometimes take a few minutes to arrive. If
          you didn't get one after 15 min, call us at{' '}
          <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" /> for
          assistance.{' '}
        </FooterText>
      </FooterWrapper>
    </Container>
  );
};

export default Success;
