import { GestureResponderEvent } from 'react-native';
import {
  HTMLContentModel,
  HTMLElementModel,
  RenderersProps,
} from 'react-native-render-html';

import { routeTo } from 'app/util/routeUtils';
import theme, { fontFamily } from 'app/util/theme';

/**
 * Passed to an `<Html />` component from `react-native-render-html`
 * as the `baseStyle` prop.
 *
 * Sets the font style to match the rest of the application.
 */
export const baseStyle = {
  fontFamily,
  fontSize: theme.fontSize,
  fontWeight: '300' as '300',
};

/**
 * Passed to an `<Html />` component from `react-native-render-html`
 * as the `customHTMLElementModels` prop.
 *
 * Registers custom HTML tags defined in the `renderers` prop and
 * specifies details about how it should render as HTML.
 */
export const customHTMLElementModels = {
  /* Register <react-video> as a tag that renders as a block element */
  'react-video': HTMLElementModel.fromCustomModel({
    tagName: 'react-video',
    contentModel: HTMLContentModel.block,
  }),
};

/**
 * Passed to an `<Html />` component from `react-native-render-html`
 * as the `renderersProps` prop.
 *
 * Sets up mappings between HTML tags and custom props that should
 * be passed to the component which will be rendered.
 */
export const renderersProps = {
  /* Pass a custom `onPress` prop to <a> tags */
  a: {
    onPress: (event: GestureResponderEvent, href: string) => {
      event.preventDefault();

      // For some reason, relative paths include "about://" erroneously
      const path = href.replace('about://', '');
      routeTo(path);
    },
  },
};
