import React, { ReactNode } from 'react';

import Modal from 'app/components/Common/Modal';
import { TestID } from 'app/util/test-id';

interface Props {
  children?: ReactNode;
  disclaimer?: ReactNode;
  isVisible: boolean;
  onClose: () => void;
  subtitle?: string;
  title: string;
}

const BenefitModalBase = ({
  children,
  disclaimer = null,
  isVisible = false,
  onClose,
  title,
}: Props) => {
  return (
    <Modal
      backgroundColor="white"
      disclaimer={disclaimer}
      heightOverride={785} // Smallest height that avoids scrolling behavior on tablet+ size screens
      isVisible={isVisible}
      onClose={onClose}
      options={[
        {
          onPress: onClose,
          title: 'OK',
          type: 'solid',
        },
      ]}
      // Styles specific to profile benefit modals
      overlayStyleOverrides={{
        borderRadius: 18,
      }}
      showCloseX={false}
      size="medium"
      testID={TestID.BenefitModal.Component}
      title={title}
    >
      {children}
    </Modal>
  );
};

export default BenefitModalBase;
