import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Avatar, Card, Icon, Text } from 'react-native-elements';

import PropTypes from 'prop-types';

import FeatureSwitch from 'app/components/Common/FeatureSwitch';
import { DEFAULT_PHYSICIAN_IMAGE } from 'app/util/constants';
import { SurgeonPropTypes } from 'app/util/propTypes';
import theme from 'app/util/theme';

import SurgeonCardDetail from './SurgeonCardDetail';

const SurgeonCard = ({
  education,
  highAvailability,
  hospital,
  fellowships,
  name,
  profileImage,
  residencies,
}) => (
  <Card>
    <Card.Title>{name}</Card.Title>
    <Card.Divider />

    <FeatureSwitch name="surgeon-availability" state="on">
      {highAvailability && (
        <View style={styles.availability}>
          <Icon
            size={20}
            name="calendar-star"
            type="material-community"
            containerStyle={styles.availabilityIcon}
          />
          <Text style={styles.availabilityText}>High Availability</Text>
        </View>
      )}
    </FeatureSwitch>

    <View style={styles.container}>
      <Avatar
        rounded
        size="xlarge"
        source={{ uri: profileImage || DEFAULT_PHYSICIAN_IMAGE }}
        containerStyle={styles.avatar}
      />

      <View>
        {hospital && (
          <View
            style={StyleSheet.flatten([
              styles.hospital,
              hospital.images.logoIcon ? styles.hospitalRow : {},
            ])}
          >
            {(hospital?.images?.logoIcon || hospital?.images?.logo) && (
              <Image
                source={{
                  uri: hospital.images.logoIcon || hospital.images.logo,
                }}
                style={StyleSheet.flatten([
                  styles.hospitalLogo,
                  hospital.images.logoIcon ? styles.hospitalLogoIcon : {},
                ])}
              />
            )}

            <Text h2 h2Style={{ display: 'flex', flex: 1, marginBottom: 0 }}>
              {hospital.name}
            </Text>
          </View>
        )}

        {education && (
          <SurgeonCardDetail
            title="Medical Education"
            icon="graduation-cap"
            items={education}
          />
        )}

        {residencies && (
          <SurgeonCardDetail
            title="Residencies"
            icon="hospital-o"
            items={residencies}
          />
        )}

        {fellowships && (
          <SurgeonCardDetail
            title="Fellowships"
            icon="md-ribbon"
            iconType="ionicon"
            items={fellowships}
          />
        )}
      </View>
    </View>
  </Card>
);

SurgeonCard.propTypes = {
  ...SurgeonPropTypes,
  children: PropTypes.node,
};

export default SurgeonCard;

const styles = StyleSheet.create({
  container: {
    marginBottom: theme.spacing / 2,
  },

  avatar: {
    marginVertical: theme.spacing / 2,
    alignSelf: 'center',
  },

  hospital: {
    display: 'flex',
    flex: 1,
    flexBasis: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing * 1.25,
    marginBottom: theme.spacing * 1.25,
    paddingHorizontal: theme.spacing * 1.25,
  },

  hospitalRow: {
    flexDirection: 'row',
  },

  hospitalName: {
    display: 'flex',
    flex: 1,
    marginBottom: 0,
  },

  hospitalLogo: {
    height: 60,
    width: 200,
    marginBottom: theme.spacing / 2,
    resizeMode: 'contain',
  },

  hospitalLogoIcon: {
    width: 60,
    marginRight: theme.spacing / 2,
    marginLeft: theme.spacing / 4,
    marginBottom: 0,
  },

  availability: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing / 2,
    marginBottom: theme.spacing / 2,
    marginTop: -theme.spacing,
    backgroundColor: 'rgba(50,200,50,0.1)',
  },

  availabilityIcon: {
    marginRight: theme.spacing / 4,
  },

  availabilityText: {
    fontSize: theme.fontSizes.small,
  },
});
