export const DisplayScreens = {
  NarrowAndShort: 'narrowAndShort',
  NarrowAndWide: 'narrowAndWide',
  NarrowOnly: 'narrowOnly',
};

export enum NavigationAlignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export interface NavigationConfig {
  disabled?: boolean;
  onPress: Function;
  testID?: string;
  text: string;
}

export enum NavigationMode {
  Centered = 'centered',
  Continue = 'continue',
  PreviousAndNext = 'previousAndNext',
  DoubleButton = 'doubleButton',
  SkipAndNext = 'skipAndNext',
}

export enum NavigationPosition {
  LeftAndRight = 'LeftAndRight',
  RightOnly = 'RightOnly',
  RightOrCenter = 'RightOrCenter',
}

export enum NavigationType {
  Flex = 'flex',
  NarrowFlexWideStandard = 'narrowFlexWideStandard',
  Standard = 'standard',
}

export interface StickyNavFooterProps {
  navigationConfig: NavigationConfig[];
  navigationMode: NavigationMode;
  testID: string;
}
