import React, { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from '@cross-platform/react-router-native';

import {
  fetchPossibleProcedures,
  receivePossibleProcedures,
} from 'app/actions/episodeActions';
import { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
import { useSession } from 'app/hooks';
import { getOfferedProcedures } from 'app/selectors/episode';
import { getIsLoggedIn } from 'app/selectors/session';

import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';
import { State } from 'types/state';

import {
  CenteredText,
  ContinueButton,
  DotIndicator,
  GoBackLink,
  LetUsKnow,
  LetUsKnowButton,
  NotWhatYoureLookingForMobileText,
  ProcedureSearchPageWrapper,
  ScrollView,
  SearchResultItemSubText,
  SearchResultItemText,
  SearchResultItemView,
  SearchResultsContainer,
  SearchResultsWrapper,
  SearchText,
  SelectOptionsText,
  StickyNavFooter,
  TopBackLink,
} from './styles';

interface MatchParams {
  search: string;
}

/**
 * Used to keep the test and "ProcedureSearchResultsPage" component in sync for text result expectations
 */
export const getSubtitleText = (text: string) => {
  if (text) {
    return `(${text})`;
  }

  return '';
};

export const ProcedureSearchResultsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useParams<MatchParams>();
  const { width } = useWindowDimensions();
  const session = useSession();

  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [isLoadingProcedures, setIsLoadingProcedures] = useState(true);
  const [isLetUsKnowVisible, setIsLetUsKnowVisible] = useState(false);

  const offeredProcedures = useSelector((state: State.RootState) =>
    getOfferedProcedures(state)
  );

  const isNarrow = useMemo(() => {
    return width <= theme.breakpoints.xsmall;
  }, [width]);

  const fetchProcedures = async () => {
    await dispatch(fetchPossibleProcedures(search));
  };

  const goBackToSearch = () => history.push(Routes.Home);

  const goToProcedureDetailsPage = (procedureKey: string) => {
    history.push(`/${Routes.Procedures}/${procedureKey}`);
  };

  /**
   * Navigate to the selected procedure when pressing "Continue"
   */
  const onContinue = (event: Event) => {
    event?.preventDefault();

    if (!selectedProcedure) return;

    goToProcedureDetailsPage(selectedProcedure);
  };

  /**
   * Reset the list of procedures when this page first loads.
   */
  useEffect(() => {
    dispatch(receivePossibleProcedures([]));
  }, []);

  /**
   * Reload the offered procedures if logged in.
   */

  useEffect(() => {
    if (!getIsLoggedIn(session)) return;

    setIsLoadingProcedures(true);
    fetchProcedures();
  }, [session]);

  /** Set the loading state to false after fetching the procedure. */
  useEffect(() => {
    setIsLoadingProcedures(false);
  }, [offeredProcedures]);

  if (isLoadingProcedures || offeredProcedures.length === 0) {
    return (
      <SearchResultsContainer testID={TestID.ProcedureSearchResults.Page}>
        <DotIndicator testID={TestID.ProcedureSearchResults.LoadingIndicator} />
      </SearchResultsContainer>
    );
  }

  return (
    <>
      <ScrollView>
        <ProcedureSearchPageWrapper>
          <SearchResultsContainer testID={TestID.ProcedureSearchResults.Page}>
            {isNarrow && <TopBackLink onPress={goBackToSearch} />}

            <CenteredText>You searched for:</CenteredText>
            <SearchText>{search}</SearchText>

            {!isNarrow && (
              <CenteredText>
                Not what you were looking for?{' '}
                <GoBackLink onPress={goBackToSearch} />
              </CenteredText>
            )}

            <SelectOptionsText>
              Please select one of the following options to continue. You can
              always change it later.
            </SelectOptionsText>

            <SearchResultsWrapper
              testID={TestID.ProcedureSearchResults.ResultsWrapper}
            >
              {offeredProcedures.map((procedure) => {
                let title: string = '',
                  subTitle: string = '';

                if (procedure?.colloquialName) {
                  title = procedure.colloquialName;
                  subTitle = procedure?.name;
                } else if (procedure?.name) {
                  title = procedure.name;
                }

                return (
                  <SearchResultItemView
                    isSelected={procedure.key === selectedProcedure}
                    key={title}
                    onPress={() => setSelectedProcedure(procedure.key)}
                    testID={TestID.ProcedureSearchResults.ProcedureOption}
                  >
                    <SearchResultItemText
                      isSelected={procedure.key === selectedProcedure}
                    >
                      {title}
                    </SearchResultItemText>

                    {!!subTitle && (
                      <SearchResultItemSubText
                        isSelected={procedure.key === selectedProcedure}
                      >
                        {getSubtitleText(subTitle)}
                      </SearchResultItemSubText>
                    )}
                  </SearchResultItemView>
                );
              })}
            </SearchResultsWrapper>

            <NotWhatYoureLookingForMobileText>
              Still not what you're looking for?
            </NotWhatYoureLookingForMobileText>

            <LetUsKnowButton
              title="Let us know"
              onPress={() => setIsLetUsKnowVisible(true)}
            />

            {!isNarrow && (
              <ContinueButton
                disabled={!selectedProcedure}
                onPress={onContinue}
                testID={TestID.ProcedureSearchResults.ContinueButton}
              />
            )}

            {isLetUsKnowVisible && (
              <LetUsKnow
                visible={true}
                onClose={() => setIsLetUsKnowVisible(false)}
              />
            )}
          </SearchResultsContainer>
        </ProcedureSearchPageWrapper>
      </ScrollView>
      <StickyNavFooter
        navigationConfig={[
          {
            disabled: !selectedProcedure,
            onPress: onContinue,
            testID: TestID.ProcedureSearchResults.NavFooterContinueButton,
            text: 'Continue',
          },
        ]}
        navigationMode={NavigationMode.Continue}
        testID={TestID.ProcedureSearchResults.NavFooter}
      />
    </>
  );
};

export default ProcedureSearchResultsPage;
