import { flatten, uniqBy } from 'lodash';

import {
  RECEIVE_BUNDLE_PRICES_ERROR,
  RECEIVE_BUNDLE_PRICES_LOADING,
  RECEIVE_BUNDLE_PRICES,
  RECEIVE_COST_ESTIMATE_LOADING,
  RECEIVE_COST_ESTIMATE,
  RECEIVE_DISTANCE,
  RECEIVE_EPISODE_JOURNEY_PHASES,
  RECEIVE_EPISODE_LOADING,
  RECEIVE_EPISODE_STEPS,
  RECEIVE_EPISODE_TASK_COMPLETE,
  RECEIVE_EPISODE,
  RECEIVE_ESTIMATES_ERROR,
  RECEIVE_HOSPITAL_BUNDLE_PRICES_LOADING,
  RECEIVE_HOSPITAL_BUNDLE_PRICES,
  RECEIVE_INQUIRY,
  RECEIVE_PHYSICIAN_RATING,
  RECEIVE_POSSIBLE_HOSPITALS,
  RECEIVE_POSSIBLE_PHYSICIANS,
  RECEIVE_POSSIBLE_PROCEDURES,
  RECEIVE_PROCEDURE,
  RECEIVE_PRODUCT_OFFERINGS,
  RECEIVE_REFERRAL_SOURCES,
  RECEIVE_TEMP_COE_SELECTION,
} from 'app/actions/episodeActions';
import { RECEIVE_LOGOUT } from 'app/actions/sessionActions';
import { formatData } from 'app/util/reducerUtils';
import { Episode } from 'types/episode';

export const defaultTempCoeSelection: Episode.EpisodeState['tempCoeSelection'] =
  {
    doctor: undefined,
    facility: undefined,
    productOffering: {
      active: true,
      code: 'gold',
      id: 3,
      name: 'Gold',
    },
  };

const nullState = Object.freeze({
  episode: null,
  bundlePrices: null,
  bundlePricesLoading: false,
  bundlePricesError: null,
  costEstimate: null,
  costEstimateLoading: false,
  distance: null,
  estimatesError: null,
  hospitalBundlePrices: null,
  hospitalBundlePricesLoading: false,
  inquiry: null,
  loading: false,
  oopEstimate: null,
  physicianRating: {},
  possibleHospitals: [],
  possibleHospitalsPage: 1,
  possibleHospitalsNextPage: null,
  possibleHospitalsTotal: 0,
  possiblePhysicians: [],
  possiblePhysiciansPage: 1,
  possiblePhysiciansNextPage: null,
  possiblePhysiciansTotal: 0,
  possibleProcedureCategories: [],
  possibleProcedures: [],
  procedure: null,
  productOfferingsList: [],
  referralSources: [],
  steps: [],
  tempCoeSelection: defaultTempCoeSelection,
});

export { nullState };

const sortProcedures = (a, b) =>
  b.key === 'other' ? -999 : a.name > b.name ? 1 : -1;

const formatPhysician = (physician, hospital?) => ({
  ...physician,
  name: `${physician.name.prefix} ${physician.name.first} ${physician.name.last}`,
  lastName: physician.name.last,
  hospital: hospital || physician.hospitals[0],
});

const episodeReducer = (state = nullState, action) => {
  switch (action.type) {
    case RECEIVE_TEMP_COE_SELECTION:
      return {
        ...state,
        tempCoeSelection: action.tempCoeSelection,
      };
    case RECEIVE_POSSIBLE_HOSPITALS: {
      const newHospitals = action.possibleHospitals.map(formatData);
      const newPhysicians = flatten(
        newHospitals.map(({ physicians, ...hospital }) =>
          physicians.map((physician) => formatPhysician(physician, hospital))
        )
      );

      const possibleHospitals =
        action.page === 1
          ? newHospitals
          : [...state.possibleHospitals, ...newHospitals];

      const possiblePhysicians =
        action.page === 1
          ? newPhysicians
          : [...state.possiblePhysicians, ...newPhysicians];

      return {
        ...state,
        possibleHospitals: uniqBy(possibleHospitals, 'id'),
        possiblePhysicians: uniqBy(possiblePhysicians, 'id'),
        possibleHospitalsPage: action.page,
        possibleHospitalsNextPage: action.nextPage,
        possibleHospitalsTotal: action.total,
        loading: false,
      };
    }
    case RECEIVE_POSSIBLE_PHYSICIANS: {
      const newPhysicians = action.possiblePhysicians.map((data) =>
        formatPhysician(formatData(data))
      );

      const possiblePhysicians =
        action.page === 1
          ? newPhysicians
          : [...state.possiblePhysicians, ...newPhysicians];

      return {
        ...state,
        possiblePhysicians,
        possiblePhysiciansPage: action.page,
        possiblePhysiciansNextPage: action.nextPage,
        possiblePhysiciansTotal: action.total,
        loading: false,
      };
    }
    case RECEIVE_BUNDLE_PRICES:
      return {
        ...state,
        bundlePrices: action.bundlePrices,
      };
    case RECEIVE_BUNDLE_PRICES_LOADING:
      return {
        ...state,
        bundlePricesLoading: action.loading,
      };
    case RECEIVE_HOSPITAL_BUNDLE_PRICES:
      return {
        ...state,
        hospitalBundlePrices: action.bundlePrices,
      };
    case RECEIVE_BUNDLE_PRICES_ERROR:
      return {
        ...state,
        bundlePricesError: action.error,
      };
    case RECEIVE_HOSPITAL_BUNDLE_PRICES_LOADING:
      return {
        ...state,
        hospitalBundlePricesLoading: action.loading,
      };

    case RECEIVE_POSSIBLE_PROCEDURES:
      const possibleProcedures = action.possibleProcedures
        .map(formatData)
        .sort(sortProcedures);

      if (action.fetchPossibleProcedureCategories) {
        const possibleProcedureCategories = uniqBy(
          possibleProcedures.map(({ images: { icon }, subcategory: name }) => ({
            icon,
            name,
          })),
          'name'
        );
        return { ...state, possibleProcedures, possibleProcedureCategories };
      }

      return { ...state, possibleProcedures };

    case RECEIVE_ESTIMATES_ERROR:
      return {
        ...state,
        estimatesError: action.error,
      };
    case RECEIVE_COST_ESTIMATE: {
      const costEstimate = formatData(action.costEstimate);
      return {
        ...state,
        costEstimate,
        costEstimateLoading: false,
        oopEstimate: {
          coinsurance: costEstimate.carrumCoinsuranceAmount,
          copay: costEstimate.carrumCopay,
          deductible: costEstimate.carrumDeductible,
          estimatedAt: new Date(),
          savings:
            costEstimate.nonCarrumCoinsuranceAmount +
            costEstimate.nonCarrumCopay +
            costEstimate.nonCarrumDeductible,
          finalAmount: null,
          paid: false,
          travel: 0,
        },
      };
    }
    case RECEIVE_EPISODE_STEPS:
      return {
        ...state,
        steps: action.steps.map(formatData),
      };
    case RECEIVE_EPISODE_JOURNEY_PHASES:
      return {
        ...state,
        journeyPhases: action.journeyPhases.map(formatData),
      };
    case RECEIVE_EPISODE_TASK_COMPLETE: {
      const steps = [...state.steps];
      const step = steps.find(({ id }) => id === action.stepId);
      const task = step && step.tasks.find(({ id }) => id === action.taskId);

      if (task) task.complete = true;

      return { ...state, steps };
    }
    case RECEIVE_PHYSICIAN_RATING:
      return { ...state, physicianRating: formatData(action.rating) };
    case RECEIVE_COST_ESTIMATE_LOADING:
      return { ...state, costEstimateLoading: action.costEstimateLoading };
    case RECEIVE_PROCEDURE:
      return { ...state, procedure: formatData(action.procedure) };
    case RECEIVE_DISTANCE:
      return { ...state, distance: action.distance };
    case RECEIVE_EPISODE: {
      const episode = action.episode ? formatData(action.episode) : null;

      return {
        ...state,
        episode,
        oopEstimate: episode?.oopEstimate ?? state.oopEstimate,
      };
    }
    case RECEIVE_INQUIRY:
      return {
        ...state,
        inquiry: action.inquiry ? formatData(action.inquiry) : null,
      };
    case RECEIVE_REFERRAL_SOURCES:
      return {
        ...state,
        referralSources: action.referralSources.map(formatData),
      };
    case RECEIVE_EPISODE_LOADING:
      return { ...state, loading: action.loading };
    case RECEIVE_LOGOUT:
      return action.hard ? nullState : { ...state, loading: false };
    case RECEIVE_PRODUCT_OFFERINGS:
      return {
        ...state,
        productOfferingsList: action.productOfferingsList.map(formatData),
      };
    default:
      return state;
  }
};

export default episodeReducer;
