import React from 'react';
import PropTypes from 'prop-types';

import { KeyboardAvoidingView, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';

import Anchor from 'app/components/Common/Anchor';
import theme from 'app/util/theme';
import { usePlatform } from 'app/hooks';
import { Routes } from 'app/util/routes';

interface Props {
  onLetUsKnowPress: () => void;
  testID?: string;
}

/**
 * Displays the default content when a user first arrives
 * on the procedure search page.
 */
export const ProcedureSearchEmptyContent = ({
  onLetUsKnowPress,
  testID,
}: Props) => {
  const { isPlatformOne } = usePlatform();

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={180}
      behavior="position"
      testID={testID}
    >
      <View style={styles.centered}>
        <Icon
          containerStyle={{ marginBottom: theme.spacing * 1.25 }}
          type="material"
          name="search"
          reverse
          iconProps={{ name: 'search', size: 116 }}
          size={82}
        />
        <Text h2 h2Style={StyleSheet.flatten([styles.paragraph])}>
          Try typing &quot;weight loss&quot; or &quot;knee replacement.&quot;
        </Text>
      </View>

      <View style={styles.centered}>
        <Text>Still can&apos;t find what you&apos;re looking for?</Text>

        {isPlatformOne ? (
          <Anchor
            to={`/${Routes.OtherProcedure}`}
            title="Let us know"
            titleStyle={styles.large}
          />
        ) : (
          <Anchor
            onPress={onLetUsKnowPress}
            title="Let us know"
            titleStyle={styles.large}
          />
        )}
      </View>
    </KeyboardAvoidingView>
  );
};

ProcedureSearchEmptyContent.propTypes = {
  onLetUsKnowPress: PropTypes.func.isRequired,
};

export default ProcedureSearchEmptyContent;

const styles = StyleSheet.create({
  centered: {
    alignItems: 'center',
  },

  container: {
    flex: 1,
    justifyContent: 'space-around',
    paddingBottom: theme.spacing * 6,
  },

  large: {
    fontSize: theme.fontSizes.body2,
  },

  paragraph: {
    marginBottom: theme.spacing * 1.25,
    textAlign: 'center',
  },
});
