import React from 'react';

import {
  useHistory,
  // @ts-ignore
} from '@cross-platform/react-router-native';

import GetHelpModal from '../App/Navigation/GetHelpModal';

const CallUs = () => {
  const history = useHistory();
  const onClose = () => history.push('/');

  return (
    <>
      <GetHelpModal visible onClose={onClose} />
    </>
  );
};

export default CallUs;
