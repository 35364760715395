import React, { Component } from 'react';

import { withRouter } from '@cross-platform/react-router-native';
import PropTypes from 'prop-types';

import { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
import { parameterize } from 'app/util/methods';
import { HistoryPropTypes } from 'app/util/propTypes';
import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';
import HtmlView from 'app/components/Common/HtmlView';

import {
  AcknowledgementText,
  CheckBox,
  CheckBoxGroup,
  Field,
  FlexWrapper,
  FormQuestions,
  FormSteps,
  GooglePlacesInput,
  Input,
  NavigationButtons,
  NextButton,
  PreviousButton,
  QuestionText,
  QuestionWrapper,
  RadioGroup,
  SaveLink,
  SaveLinkWrapper,
  Select,
  SignatureInput,
  StickyNavFooter,
  TextArea,
} from '../styles';

import {
  findNextLinkedQuestion,
  findNextUnlinkedQuestion,
  findPreviousLinkedQuestion,
  getCurrentQuestionNumber,
  getTotalQuestionCount,
} from '../helpers';

/**
 * Return the correct form component for a given type.
 *
 * @param {string} type The type of component to render.
 * @return {Component} A React component for the given type.
 */
const formComponentFromString = (type) => {
  switch (type) {
    case 'address':
      return GooglePlacesInput;
    case 'confirmation':
      return CheckBox;
    case 'checkbox':
      return CheckBoxGroup;
    case 'radio':
      return RadioGroup;
    case 'select':
      return Select;
    case 'signature':
      return SignatureInput;
    case 'string':
      return Input;
    case 'text':
      return TextArea;
  }
};

/**
 * Render the details of a patient's form.  Allow entries to be changed and
 * submitted through the API.
 */
export class FormDetails extends Component {
  /**
   * @property {object} history A react-router history object (required).
   * @property {function} initialize A function to initialize the redux-form values (required).
   * @property {array} iterations An array of iterations of the same form (defaults to [])
   * @property {boolean} loading True if the current form is loading (defaults to false).
   * @property {function} onSave A function to call when saving the form (required).
   * @property {function} onSubmit A function to call when submitting the form (required).
   * @property {string} patientName The patient's name (required).
   * @property {object} values The redux-form values (required).
   */
  static propTypes = {
    history: PropTypes.shape(HistoryPropTypes).isRequired,
    initialize: PropTypes.func.isRequired,
    iterations: PropTypes.array,
    loading: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    patientName: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    iterations: [],
    loading: false,
  };

  constructor(props) {
    super(props);

    const submissions = [...props.iterations];

    const earliestIncompleteForm =
      submissions.find(({ completedOn }) => !completedOn) ||
      submissions.reverse()[submissions.length - 1];

    const currentOrder = earliestIncompleteForm.order;
    const submission = props.iterations[currentOrder];

    this.state = {
      currentQuestionIndex: 0,
      currentOrder,
      error: null,
      hasChanges: false,
      saved: false,
      showProgress: false,
    };

    this.initializeTimeout = 0;
    this.savedTimeout = 0;

    this.props.initialize(submission);
  }

  componentWillUnmount = () => {
    clearTimeout(this.initializeTimeout);
    clearTimeout(this.savedTimeout);
  };

  // Capitalize the first letter of the string and the first letter after each
  // space.
  // NOTE: This transformation was added per product request for TEC-2326.
  capitalizeFirstLetters = (input) =>
    input
      .split(' ')
      .map((substring) => {
        const word = substring.toLowerCase();
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

  // Reset the form and redirects to the forms page.
  cancel = () => {
    this.initializeTimeout = setTimeout(() => {
      this.props.history.goBack();
    });
  };

  /**
   * Returns the current question being asked based on
   * the `currentOrder` and `currentQuestionIndex` state
   * variables.
   */
  currentQuestion() {
    const { currentQuestionIndex } = this.state;
    const submission = this.currentIteration();

    if (!submission) return;

    return submission.formQuestions[currentQuestionIndex];
  }

  /**
   * Returns the current version of the form being edited.
   * In some cases, forms are recurring and can have multiple
   * "instances" of the same form, so return the correct instance
   * based on the `currentOrder` state variable.
   */
  currentIteration() {
    const { iterations } = this.props;
    const { currentOrder } = this.state;

    return iterations[currentOrder];
  }

  /**
   * Navigates to the next question in the form, if any, or submits
   * the form if there are no more questions.
   *
   * Some questions are conditional based on a user's response, so
   * this function will naviagate to 1 of 3 possible destinations:
   *
   * 1. If this question has no nested `nextQuestions` property, simply
   * move to the next question in sequence.
   *
   * 2. If this question has a nested `nextQuestions` property, find the
   * index of a question with a `label` that matches the current question's
   * `response`.
   *
   * 3. In the event that the next question is conditional, but won't show
   * based on a previous repsonse, the form is submitted.
   */
  goToNextQuestion = async () => {
    if (!this.validate()) return;

    // Save the form changes.
    await this.onSave();

    const question = this.currentQuestion();
    const submission = this.currentIteration();
    const nextQuestion =
      findNextLinkedQuestion(question, submission.formQuestions) ||
      findNextUnlinkedQuestion(question, submission.formQuestions);
    const nextQuestionIndex = submission.formQuestions.indexOf(nextQuestion);

    // Submit the form when reaching the last question.
    if (nextQuestionIndex === -1) return await this.onSubmit();

    // Move to the next question if the form is not complete.
    this.setState({ currentQuestionIndex: nextQuestionIndex });
  };

  /**
   * Navigates to the previous question in the form, if any.
   *
   * Some questions are conditional based on a user's response, so
   * this function will naviagate to 1 of 2 possible destinations:
   *
   * 1. If this question has a `label` property that is not included in
   * a previous quetion's `nextQuestions` property, navigate to the previous
   * question in sequence.
   *
   * 2. If this question has a `label` property that is included in a previous
   * question's `nextQuestions` property, navigate directly to that question
   * that the current question is linked to.
   */
  goToPreviousQuestion = () => {
    const question = this.currentQuestion();
    const submission = this.currentIteration();
    const nextQuestion = findPreviousLinkedQuestion(
      question,
      submission.formQuestions
    );

    const nextQuestionIndex = nextQuestion
      ? submission.formQuestions.indexOf(nextQuestion)
      : this.state.currentQuestionIndex - 1;

    this.setState({ currentQuestionIndex: nextQuestionIndex, error: null });
  };

  // Immediately return to the forms list.
  onCancel = () => {
    if (!this.state.hasChanges) return this.cancel();
  };

  // Kick off form validation as users update the form.
  onChange = () => {
    this.setState({ hasChanges: true });
    setTimeout(this.validate); // timeout avoid race conditions with redux-forms
  };

  // Reset the form state.
  onComplete = (isSuccess) => {
    if (!isSuccess) return;

    this.setState({ hasChanges: false });
  };

  onSave = async () => {
    if (!this.validate()) return;

    const submission = this.currentIteration();
    const isSuccess = await this.props.onSave(
      submission.formKey,
      submission.order
    );

    return this.onComplete(isSuccess);
  };

  onSubmit = async () => {
    if (!this.validate()) return;

    const submission = this.currentIteration();
    const isSuccess = await this.props.onSubmit(
      submission.formKey,
      submission.order
    );

    if (!isSuccess) return;

    this.onComplete(isSuccess);
    this.cancel();
  };

  validate = () => {
    const question = this.currentQuestion();
    const key = parameterize(question.label);
    const value = this.props.values[key];
    const hasValue = Array.isArray(value) ? value.length > 0 : Boolean(value);

    switch (true) {
      case question.inputType === 'signature' &&
        value?.toUpperCase() !== this.props.patientName:
        this.setState({
          error: `Please enter "${this.capitalizeFirstLetters(
            this.props.patientName
          )}" to confirm.`,
        });
        return false;
      case question.required && !hasValue:
        this.setState({ error: 'A response is required.' });
        return false;
      default:
        this.setState({ error: null });
        return true;
    }
  };

  renderAcknowledgement = ({ label, content }) => {
    return (
      <>
        {content ? (
          <HtmlView html={content} />
        ) : (
          <AcknowledgementText>{label}</AcknowledgementText>
        )}
      </>
    );
  };

  /**
   * Render an input for a given form question.
   *
   * @return {Component} Return a React Component for the question.
   */
  renderInput = ({ inputType, label, options, required, content }) => {
    const FormComponent = formComponentFromString(inputType);

    const name = parameterize(label);

    const inputOptions = (options || []).map((value) => ({
      label: value,
      value,
    }));

    const optionsStyle =
      inputType === 'checkbox' ? { flexDirection: 'column' } : {};

    const containerStyle =
      inputType === 'confirmation'
        ? { flexDirection: 'column-reverse', marginLeft: 0 }
        : {};

    const submission = this.currentIteration();

    const isFinalQuestion =
      this.state.currentQuestionIndex === submission.formQuestions.length - 1;

    return (
      <>
        {content ? (
          <HtmlView html={content} />
        ) : (
          <QuestionText hasError={this.state.error}>
            {required ? `${label} *` : label}
          </QuestionText>
        )}
        {inputType === 'signature' && (
          <QuestionText>
            Enter &quot;{this.capitalizeFirstLetters(this.props.patientName)}
            &quot; to confirm.
          </QuestionText>
        )}

        <Field
          component={FormComponent}
          containerStyle={containerStyle}
          disabled={Boolean(submission.completedOn)}
          error={this.state.error}
          label={inputType === 'confirmation' ? 'Confirm' : ''}
          name={name}
          onBlur={(e) => e?.preventDefault()}
          onCancel={this.onCancel}
          onChange={this.onChange}
          options={inputOptions}
          optionsStyle={optionsStyle}
          returnKeyType={isFinalQuestion ? 'done' : 'next'}
          unSelectedOption={inputType === 'select' ? true : undefined}
        />
      </>
    );
  };

  render() {
    const submission = this.currentIteration();
    const currentQuestion = this.currentQuestion();

    const currentStep = getCurrentQuestionNumber(
      currentQuestion,
      submission.formQuestions
    );
    const totalSteps = getTotalQuestionCount(submission.formQuestions);
    const isFirstQuestion = currentStep === 1;

    return (
      <FlexWrapper testID={TestID.FormDetails.Component}>
        <QuestionWrapper>
          <SaveLinkWrapper>
            <SaveLink
              onPress={this.onSave}
              testID={TestID.FormDetails.SaveLink}
              title={'Save and close'}
              to={`/${Routes.Dashboard}`}
            />
          </SaveLinkWrapper>
          <FormSteps value={currentStep / totalSteps} />

          <FormQuestions>
            {currentQuestion.inputType === 'acknowledgement'
              ? this.renderAcknowledgement(currentQuestion)
              : this.renderInput(currentQuestion)}
          </FormQuestions>

          <NavigationButtons
            isFirstQuestion={isFirstQuestion}
            testID={TestID.FormDetails.NavButtons}
          >
            <PreviousButton
              isHidden={isFirstQuestion}
              onPress={this.goToPreviousQuestion}
              testID={TestID.FormDetails.PreviousButton}
            />
            <NextButton
              disabled={!!this.state.error}
              onPress={this.goToNextQuestion}
              testID={TestID.FormDetails.NextButton}
            />
          </NavigationButtons>
        </QuestionWrapper>
        <StickyNavFooter
          navigationConfig={[
            {
              disabled: isFirstQuestion,
              onPress: this.goToPreviousQuestion,
              text: 'Previous',
            },
            {
              disabled: !!this.state.error,
              onPress: this.goToNextQuestion,
              text: 'Next',
            },
          ]}
          navigationMode={NavigationMode.PreviousAndNext}
          testID={TestID.FormDetails.NavFooter}
        />
      </FlexWrapper>
    );
  }
}

export default withRouter(FormDetails);
