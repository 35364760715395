import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Avatar, Card, Text } from 'react-native-elements';

import AnchorWithIcon from 'app/components/Common/AnchorWithIcon';

import { DEFAULT_PHYSICIAN_IMAGE } from 'app/util/constants';
import { Routes } from 'app/util/routes';
import theme from 'app/util/theme';

const SurgeonCard = ({ profileImage, name }) => (
  <Card>
    <Card.Title>Surgeon</Card.Title>
    <Card.Divider />

    <View style={styles.cardContainer}>
      <Avatar
        rounded
        size="large"
        source={{ uri: profileImage || DEFAULT_PHYSICIAN_IMAGE }}
        containerStyle={styles.surgeonAvatar}
      />

      <View style={styles.textContainer}>
        <Text h2 h2Style={{ flex: 1, flexWrap: 'wrap', marginBottom: 0 }}>
          {name || 'Surgeon not selected'}
        </Text>

        {!name && (
          <AnchorWithIcon
            title="Select your surgeon"
            to={`/${Routes.SelectSurgeon}`}
            icon="open-in-new"
            containerStyle={{ marginVertical: 0 }}
            style={{ alignItems: 'flex-start', flex: 1 }}
            titleStyle={{ textAlign: 'left' }}
          />
        )}
      </View>
    </View>
  </Card>
);

SurgeonCard.propTypes = {
  profileImage: PropTypes.string,
  name: PropTypes.string,
};

SurgeonCard.defaultProps = {
  profileImage: '',
  name: '',
};

export default SurgeonCard;

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: theme.spacing / 2,
  },

  surgeonAvatar: {
    marginHorizontal: theme.spacing * 1.25,
  },

  bold: {
    fontWeight: '500',
    marginBottom: theme.spacing / 2,
  },

  instructions: {
    paddingHorizontal: theme.spacing * 1.25,
    textAlign: 'center',
    marginBottom: theme.spacing * 2.5,
  },

  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing / 4,
    paddingHorizontal: theme.spacing,
  },

  message: {
    marginTop: theme.spacing / 2,
    paddingHorizontal: theme.spacing,
  },

  center: {
    textAlign: 'center',
  },

  anchor: {
    color: '#2196f3',
  },

  divider: {
    margin: theme.spacing / 2,
  },

  textContainer: {
    flex: 1,
    paddingRight: theme.spacing / 2,
    paddingBottom: theme.spacing / 2,
  },
});
