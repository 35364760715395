import React from 'react';

import { useLocation } from '@cross-platform/react-router-native';
import { useDispatch } from 'react-redux';

import { setPanelOpen, setProfileMenuOpen } from 'app/actions/uiActions';
import { routeTo } from 'app/util/routeUtils';
import { TestID } from 'app/util/test-id';

import { NavigationLinkProps } from '../../types';
import { Badge, NavigationLinkPressable, NavigationLinkText } from './styles';

const NavigationLink = ({
  badgeCount = 0,
  layout = 'row',
  name,
  onPress = null,
  path,
  testID = TestID.Navigation.NavigationLinkPressable,
}: NavigationLinkProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Navigate to route if a path has been provided. If not, invoke the event
  // handler that has been passed in as a prop.
  const handleLinkPress = (path: string) => {
    dispatch(setPanelOpen(false));
    dispatch(setProfileMenuOpen(false));
    if (path) {
      routeTo(path);
    } else {
      onPress && onPress();
    }
  };

  const isActive = location?.pathname === path; // link path matches current

  return (
    <NavigationLinkPressable
      isActive={isActive}
      layout={layout}
      onPress={() => handleLinkPress(path)}
      testID={testID}
    >
      <NavigationLinkText isActive={isActive} layout={layout}>
        {name}
      </NavigationLinkText>
      {badgeCount > 0 && <Badge value={badgeCount} />}
    </NavigationLinkPressable>
  );
};

export default NavigationLink;
