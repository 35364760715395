import React from 'react';

import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Field } from 'redux-form';

import GooglePlacesInput from 'app/components/Common/GooglePlacesInput';
import Modal from 'app/components/Common/Modal';
import { Text } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

const Container = Platform.select({
  default: ScrollView,
  web: View,
});

const MissingLocationModal = ({ ...modalProps }) => {
  return (
    <Modal
      isVisible
      title="Enter Your Address"
      overlayStyleOverrides={styles.overlay}
      size="large"
      {...modalProps}
    >
      <Container disableScrollViewPanResponder>
        <Text size="body2">
          In order to find the best surgeons for your location, please enter
          your physical address.
        </Text>

        <View style={styles.inputContainer}>
          <Field
            name="location"
            label="Physical Address"
            component={GooglePlacesInput}
          />
        </View>
      </Container>
    </Modal>
  );
};

export default MissingLocationModal;

const styles = StyleSheet.create({
  overlay: {
    overflow: 'visible',
  },

  inputContainer: {
    marginTop: theme.spacing * 2.5,
  },
});
