import React, { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { useTheme } from 'styled-components/native';

import CategoryButton from './SubCategoryButton';
import { FillerButton, FlatList, ListContainer } from './styles';

const SubCategoryButtons = ({ possibleProcedureCategories }) => {
  const {
    windowDimensions: { width },
    breakpoints,
  } = useTheme();

  const numColumns = useMemo(() => {
    if (width > breakpoints.medium) {
      return 6;
    }
    if (width > breakpoints.xsmall) {
      return 5;
    }
    return 4;
  }, [width]);

  const fillerCount = possibleProcedureCategories.length % numColumns;

  const paddedCategoryOptions = possibleProcedureCategories.concat([
    ...Array(fillerCount).keys(),
  ]);

  return (
    <ListContainer>
      <FlatList
        key={numColumns}
        data={paddedCategoryOptions}
        renderItem={({ item }: any) =>
          item.icon ? (
            <CategoryButton category={item.name} icon={item.icon} />
          ) : (
            <FillerButton />
          )
        }
        numColumns={numColumns}
        keyExtractor={(item: any) => item.name}
      />
    </ListContainer>
  );
};

export default SubCategoryButtons;
