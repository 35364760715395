import { initialFormValues, TestID } from 'app/components/Messages/constants';

import {
  createMessage,
  handleAlbumPress,
  handleCameraPress,
  handleDocumentPress,
  logMessageSent,
  playSound,
  scrollToBottom,
} from 'app/components/Messages/helpers';

import { useDispatch, useState } from 'app/components/Messages/hooks';

import {
  Footer,
  MessageActionIcon,
  MessageActionsContainer,
  Platform,
  SendButton,
  TextArea,
  TextAreaRow,
  TouchableOpacity,
} from 'app/components/Messages/styles';

import { MessageActionsProps } from 'app/components/Messages/types';

/**
 * Render the input and action icons for submitting text messages and
 * attachments.
 */
const MessageActions = ({
  conversation,
  currentUserId,
  formValues,
  isRendered = false,
  scrollView,
  setFormValues,
}: MessageActionsProps) => {
  if (!isRendered) return null;

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const actionPressParams = {
    conversation,
    dispatch,
    formValues,
    setFormValues,
  };

  /**
   * Create a new message for a conversation, then play a sound and clear out
   * the input.
   *
   * @return  {promise}  a promise that resolves after creating the message
   */
  const sendMessage = async () => {
    if (!conversation) return;

    const { attachments, body } = formValues;

    if (submitting || (!body.length && !attachments.length)) return;

    await setSubmitting(true);
    await createMessage(dispatch, conversation.id, {
      attachments,
      body,
      authorId: currentUserId,
    });
    playSound('sent');
    logMessageSent(body, attachments);
    setSubmitting(false);
    scrollToBottom(false, scrollView.current);
    setFormValues(initialFormValues);
  };

  return (
    <MessageActionsContainer>
      <TextAreaRow>
        <TextArea
          name="body"
          blurOnSubmit={false}
          disabled={!sendMessage}
          input={{
            onChange: (body) => setFormValues({ ...formValues, body }),
            value: formValues.body,
            name: TestID.Messages.TextArea,
          }}
          placeholder="Type a message..."
        />

        {Platform.OS !== 'web' && (
          <SendButton
            title="Send"
            disabled={submitting}
            onPress={sendMessage}
            testID={TestID.Messages.SendButton}
          />
        )}
      </TextAreaRow>

      <Footer>
        {Platform.OS !== 'web' && (
          <TouchableOpacity
            disabled={submitting}
            onPress={() => handleCameraPress(actionPressParams)}
            testID={TestID.Messages.TakePhotoButton}
          >
            <MessageActionIcon name="photo-camera" />
          </TouchableOpacity>
        )}

        <TouchableOpacity
          disabled={submitting}
          onPress={() => handleAlbumPress(actionPressParams)}
          testID={TestID.Messages.PickPhotoButton}
        >
          <MessageActionIcon name="photo-library" />
        </TouchableOpacity>

        <TouchableOpacity
          disabled={submitting}
          onPress={() => handleDocumentPress(actionPressParams)}
          testID={TestID.Messages.UploadFileButton}
        >
          <MessageActionIcon name="insert-drive-file" />
        </TouchableOpacity>

        {Platform.OS === 'web' && (
          <SendButton
            title="Send"
            disabled={submitting}
            onPress={sendMessage}
            testID={TestID.Messages.SendButton}
          />
        )}
      </Footer>
    </MessageActionsContainer>
  );
};

export default MessageActions;
