import { Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

import AnchorRaw, { AnchorProps } from 'app/components/Common/Anchor';
import Input from 'app/components/Common/Input';
import { default as PhoneInputRaw } from 'app/components/Common/PhoneInput';
import { Text, View } from 'app/components/Common/Styled';

export { default as CheckBox } from 'app/components/Common/CheckBox';
export { default as DisclaimerText } from 'app/components/Common/DisclaimerText';

const InputWithMargin = styled(Input).attrs(({ theme }) => {
  return {
    containerStyle: { marginBottom: theme.spacing },
  };
})``;

const InputHalfWidth = styled(InputWithMargin).attrs({
  containerStyle: {
    flex: 1,
    flexShrink: 1,
  },
})``;

export const Anchor = styled(AnchorRaw).attrs<AnchorProps>(({ theme }) => ({
  titleStyle: {
    fontSize: theme.fontSizes.small,
  },
}))<AnchorProps>``;

export const TellUsMoreWrapper = styled(KeyboardAwareScrollView)`
  flex: 1 0 auto;
`;

export const GrantPermissionWrapper = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const HeightFeetInput = styled(InputHalfWidth).attrs(
  ({ containerStyle, theme }) => ({
    containerStyle: {
      ...containerStyle,
      /* Use `marginRight` instead of `gap` on native devices */
      marginRight: Platform.select({ native: theme.spacing }),
    },
  })
)``;

export const HeightInchesInput = styled(InputHalfWidth)``;

export const HeightInputsWrapper = styled(View)`
  flex: 1 0 auto;
  flex-direction: row;
  /* Gap only works in web, not native */
  gap: ${({ theme }) => `${theme.spacing}px`};
  justify-content: space-between;
  margin-bottom: ${({ theme }) => `${theme.spacing}px`};
  margin-top: ${({ theme }) =>
    theme.isNarrow ? `${theme.spacing * 2}px` : `${theme.spacing}px`};
`;

export const PhoneInput = styled(PhoneInputRaw)``;

export const PhoneNumberText = styled(Text).attrs({ size: 'body1' })`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const PhoneNumberWrapper = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const PhysicalAttributesWrapper = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const WeightInput = styled(InputWithMargin)``;

export const WeightInputWrapper = styled(View)``;
