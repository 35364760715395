import { Platform, TouchableOpacity } from 'react-native';
import { Avatar as RNEAvatar, Icon as RNEIcon } from 'react-native-elements';
import styled from 'styled-components/native';

import theme from 'app/util/theme';
import Text from 'app/components/Common/Styled/Text';

export const Avatar = styled(RNEAvatar).attrs({
  borderColor: theme.colors.default,
  borderWidth: 5,
  borderRadius: 50,
})`
  background-color: #fff;
  border-color: ${theme.colors.default};
  border-width: 5px;
  border-radius: 50px;
  height: 100px;
  width: 100px;
`;

export const AvatarButton = styled(TouchableOpacity).attrs({
  activeOpacity: 0.8,
})`
  align-items: center;
  align-self: center;
`;

export const CameraIcon = styled(RNEIcon).attrs({
  color: theme.colors.default,
  name: 'camera-alt',
})`
  background-color: #fff;
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing / 4}px;
`;

export const CameraIconContainer = styled.View`
  align-items: flex-end;
  border-radius: 72px;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

export const Container = styled.View`
  background-color: ${theme.colors.primary};
  flex: 1 0 auto;
  padding: ${({ theme }) => theme.spacing}px;
  overflow: visible;
  margin-bottom: ${({ theme }) => theme.spacing * 6}px;
`;

export const DetailsWrapper = styled.View`
  position: ${Platform.select({ web: 'fixed', default: 'absolute' })};
  left: 0;
  right: 0;
  top: ${({ theme }) => theme.spacing}px;
  margin-top: ${({ theme }) => theme.spacing * 1.5}px;
`;

export const Subtitle = styled(Text).attrs({
  numberOfLines: 1,
  size: 'body2',
})`
  text-align: center;
`;

export const Title = styled(Text).attrs({
  color: 'white',
  size: 'body2',
})`
  margin: ${({ theme }) => theme.spacing}px 0;
`;
