import React from 'react';

import { useSelector } from 'react-redux';

import { usePlatform, useSession } from 'app/hooks';
import { getIsLoggedIn } from 'app/selectors/session';
import { isEmpty } from 'app/util/methods';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import {
  ProfileAvatar,
  ProfileEmail,
  ProfileIdentity,
  ProfileInfoWrapper,
  ProfileName,
} from 'app/components/App/Navigation/ProfileInfo/styles';

/**
 * Render the profile details for an authenticated user.
 *
 * 1. for wide screens as part of the top nav
 * 2. for narrow screens as part of the side nav
 */
const ProfileInfo = ({ onPress }) => {
  const { isPlatformOne, isPlatformTwo } = usePlatform();
  const { user } = useSession();

  const { email } = user;
  const showProfileInfo = isPlatformOne || (!isEmpty(user) && isPlatformTwo);

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn || !showProfileInfo) return null;

  const getUserName = (user) => {
    const { profile, name } = user;
    return (profile && profile.preferredName) || name;
  };

  return (
    <ProfileInfoWrapper
      onPress={onPress}
      testID={TestID.Navigation.ProfileInfo}
    >
      {/* The RN `Avatar` component can't be styled with `styled(Avatar)` */}
      <ProfileAvatar />
      <ProfileIdentity>
        <ProfileName testID={TestID.Navigation.ProfileName}>
          {getUserName(user)}
        </ProfileName>
        <ProfileEmail testID={TestID.Navigation.ProfileEmail}>
          {email}
        </ProfileEmail>
      </ProfileIdentity>
    </ProfileInfoWrapper>
  );
};

export default ProfileInfo;
