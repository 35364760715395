import { Platform } from 'react-native';
import { Field as FieldRaw } from 'redux-form';
import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export const AssociatedFieldLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.small}px;
`;

export const AssociatedFieldView = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
    margin-top: ${theme.spacing / -2}px;
    padding-right: ${Platform.OS === 'web' ? 0 : theme.spacing}px;
  `};
`;

export const Field = styled(FieldRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;
