import { Platform, TestID } from 'app/components/Login/constants';

import { isGuestAccount } from 'app/components/Login/helpers';

import { useState } from 'app/components/Login/hooks';

import {
  Button,
  ButtonContainer,
  CheckBox,
  FormErrorContainer,
  FormView,
  Input,
  Message,
  Text,
} from 'app/components/Login/styles';

import { EmailProps } from 'app/components/Login/types';

const Email = ({
  error = '',
  formValues = {},
  loading = false,
  onChange,
  softLogout = false,
  setLoginStep,
}: EmailProps) => {
  // Store the initial soft logout state to persist message while loading.
  const [showSoftLogout] = useState(softLogout);

  /**
   * Move to the correct next page when the form is submitted.
   *
   * If this user started their registration process using the
   * single click registration workflow, but has not yet set
   * a password, we'll skip showing a password form and simply
   * allow them to request a "Magic Link" to log in.
   */
  const onSubmit = async () => {
    if (!formValues?.email) return;

    const isGuest = await isGuestAccount(formValues.email);
    const nextStep = isGuest ? 'magic-link' : 'password';

    await setLoginStep(nextStep);
  };

  return (
    <FormView testID={TestID.Login.EmailForm}>
      {showSoftLogout ? (
        <Message>
          You have been automatically logged out for your protection. Log in
          again to resume your progress.
        </Message>
      ) : null}

      {error ? (
        <FormErrorContainer>
          <Message>{error}</Message>
        </FormErrorContainer>
      ) : softLogout ? (
        <Text></Text>
      ) : null}

      <Input
        autoComplete="username"
        blurOnSubmit={false}
        input={{
          name: TestID.Login.EmailInput, // passed on to `testID`
          onChange: (value) => onChange('email', value),
        }}
        keyboardType="email-address"
        label="Email"
        onSubmitEditing={onSubmit}
        returnKeyType="next"
        value={formValues.email || ''}
      />

      {Platform.OS == 'web' && (
        <CheckBox
          label="Remember me for 30 days"
          input={{
            value: formValues.shouldRememberDevice,
            onChange: (value) => onChange('shouldRememberDevice', value),
          }}
        />
      )}

      <ButtonContainer>
        <Button
          disabled={!formValues?.email}
          loading={loading}
          onPress={onSubmit}
          testID={TestID.Login.SubmitEmailButton}
          title="Next"
        />
      </ButtonContainer>
    </FormView>
  );
};

export default Email;
