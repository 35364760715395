import { Routes, TestID } from 'app/components/CompleteYourProfile/constants';

import { useHistory } from 'app/components/CompleteYourProfile/hooks';

import { ProfileCTAsProps } from 'app/components/CompleteYourProfile/types';

import {
  Button,
  NavigationButtonsWrapper,
  StickyNavFooter,
  NavigationMode,
} from './styles';

const ProfileCTAs = ({
  isFirstStep,
  isLastStep,
  isNextDisabled,
  isSaving,
  onNext,
  onPrevious,
  submitFinalStep,
}: ProfileCTAsProps) => {
  const { push } = useHistory();

  const goToBenefitsOverview = () => {
    push(`/${Routes.ProfileCreate}/${Routes.Overview}`);
  };

  return (
    <>
      <NavigationButtonsWrapper>
        <Button
          disabled={isSaving}
          onPress={isFirstStep ? goToBenefitsOverview : onPrevious}
          testID={TestID.CompleteYourProfile.PreviousButton}
          title="Previous"
          type="outline"
        />
        <Button
          disabled={isNextDisabled || isSaving}
          onPress={onNext}
          testID={TestID.CompleteYourProfile.NextButton}
          title="Next"
          type="solid"
        />
      </NavigationButtonsWrapper>
      <StickyNavFooter
        navigationConfig={[
          {
            disabled: isSaving,
            onPress: isFirstStep ? goToBenefitsOverview : onPrevious,
            text: 'Previous',
            testID: TestID.CompleteYourProfile.PreviousButtonMobile,
          },
          {
            disabled: isNextDisabled || isSaving,
            onPress: isLastStep ? submitFinalStep : onNext,
            text: 'Next',
            testID: TestID.CompleteYourProfile.NextButtonMobile,
          },
        ]}
        navigationMode={NavigationMode.PreviousAndNext}
        testID={TestID.CompleteYourProfile.NavFooter}
      />
    </>
  );
};

export default ProfileCTAs;
