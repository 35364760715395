import { Platform } from 'react-native';
import styled from 'styled-components/native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export const BackgroundContainer = styled.View`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.breakpoints.xsmall + theme.spacing}px;
  margin: 0 auto;
  flex: 1 0 auto;
`;

export const FormContainer = styled.View`
  margin: 0px ${({ theme }) => theme.spacing}px;
`;

export const SummaryContainer = styled.View<{ isEditing: boolean }>`
  flex: 1 0 auto;
  position: relative;
`;

export const SummaryInnerContainer = styled(KeyboardAwareScrollView).attrs(
  ({ theme }) => ({
    // @ts-ignore
    contentContainerStyle: {
      paddingBottom: theme.spacing * 4,
    },
  })
)``;

export const FABContainer = styled.View`
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  right: 4%;
  bottom: 4%;
`;
