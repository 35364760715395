import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from '@cross-platform/react-router-native';

import ProcedureDetails from './ProcedureDetails';
import ProcedureNotFound from './ProcedureNotFound';
import ProcedureSearch from './ProcedureSearch';

const Procedures = memo((props) => {
  return (
    <Switch>
      <Route
        exact
        path="/procedures/:key"
        render={({ match: { params } }) => {
          if (!props.loggedIn) return null;

          const procedure = props.possibleProcedures.find(
            ({ key }) => params.key === key
          );

          return procedure ? (
            <ProcedureDetails {...props} procedure={procedure} />
          ) : (
            <ProcedureNotFound {...props} procedureKey={params.key} />
          );
        }}
      />

      <Route
        exact
        path="/procedures"
        render={() => {
          return <ProcedureSearch {...props} />;
        }}
      />
    </Switch>
  );
});

Procedures.displayName = 'Procedures';

Procedures.propTypes = {
  loggedIn: PropTypes.bool,
  possibleProcedures: PropTypes.array,
};

Procedures.defaultProps = {
  loggedIn: false,
  possibleProcedures: [],
};

export default Procedures;
