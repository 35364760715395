import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from '@cross-platform/react-router-native';
import { Routes } from 'app/util/routes';

import { Episode } from 'types/episode';
import { State } from 'types/state';
import { getBundlePrices } from 'app/actions/episodeActions';
import { ModalOption } from 'app/components/Common/Modal';
import { useEpisode } from 'app/hooks';
import { TestID } from 'app/util/test-id';

import {
  BodyText,
  Column,
  Container,
  Divider,
  Header,
  HeaderText,
  HeaderTitle,
  List,
  Modal,
  RadioGroup,
  SearchBar,
  SearchButton,
  SearchContainer,
  SendMessageLink,
  Table,
  Text,
} from './styles';

export enum PageOptions {
  ProcedureModal = 'procedure',
  SearchModal = 'search',
}

interface NsaModalProps {
  /**
   * Prompts the modal to close
   */
  onClose: () => void;
  visible: boolean;
}

export function NsaModal({ onClose, visible }: NsaModalProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { procedure } = useSelector(({ episode }: State.RootState) => episode);

  const [search, setSearch] = useState('');
  const { bundlePrices } = useEpisode();
  const [filterKey, setFilterKey] = useState(
    Episode.BundlePriceFilter.Facility
  );

  const [pageOption, setPageOption] = useState<PageOptions>(
    PageOptions.ProcedureModal
  );

  const onSubmit = async () => {
    setPageOption(PageOptions.SearchModal);
  };

  const showNoResults = bundlePrices?.length === 0;
  const showResults = bundlePrices?.length > 0;

  const modalOptions: ModalOption[] = [];
  if (pageOption === PageOptions.ProcedureModal) {
    modalOptions.push({
      onPress: onSubmit,
      raised: true,
      title: 'Continue',
      type: 'solid',
    });
  } else {
    modalOptions.push();
  }

  return (
    <Modal
      testID={TestID.SelectYourDoctor.NsaModal}
      isVisible={visible}
      onClose={onClose}
      title={procedure?.name}
      options={modalOptions}
      size="medium"
    >
      <Container disableScrollViewPanResponder>
        {pageOption === PageOptions.ProcedureModal && (
          <>
            <BodyText mt={2.5}>
              Carrum provides competitive prices at top-quality care facilities
              for all it’s members. We are able to do this by negotiating prices
              on behalf of your employer and passing the savings onto you.
            </BodyText>
            <BodyText mt={2.5}>
              Please use the{' '}
              <Text bold size="body2">
                search
              </Text>{' '}
              on the next screen to see our contracted prices, but note that
              they may differ from what your health insurance company provides.
              This is because Carrum’s prices include all needed services for
              the procedure (for example, anesthesia is usually not included in
              insurance provider quotes).
            </BodyText>
          </>
        )}

        {pageOption === PageOptions.SearchModal && (
          <>
            <RadioGroup
              input={{
                onChange: setFilterKey,
                value: filterKey,
              }}
              label="Search by:"
              options={[
                {
                  label: 'Facility',
                  value: Episode.BundlePriceFilter.Facility,
                },
                {
                  label: 'Billing Code',
                  value: Episode.BundlePriceFilter.Billing,
                },
                {
                  label: 'Zip Code',
                  value: Episode.BundlePriceFilter.Zip,
                },
              ]}
            />

            <SearchContainer>
              <SearchBar
                onChangeText={setSearch as any} // the typings are incorrect from the lib
                value={search}
              />
              <SearchButton
                title="Search"
                onPress={() =>
                  dispatch(getBundlePrices(procedure.key, filterKey, search))
                }
              />
            </SearchContainer>

            {showNoResults && (
              <Text>Your Search did not match any results.</Text>
            )}
            {showResults && (
              <>
                <HeaderTitle>
                  <Column>
                    <Header>Facility</Header>
                  </Column>
                  <Column>
                    <Header>Contracted Rate</Header>
                  </Column>
                </HeaderTitle>

                {bundlePrices.map((bundlePrice) => {
                  return (
                    <Table>
                      <Column>
                        <List>{bundlePrice.hospital}</List>
                      </Column>
                      <Column>
                        <List>${bundlePrice.price.toLocaleString()}</List>
                      </Column>
                    </Table>
                  );
                })}
              </>
            )}
            <Divider />
            <HeaderText>Note:</HeaderText>
            <Text>
              &bull; The rates showed in the results are given as a range
              because exact prices depend on the procedure specifics that are
              determined after your consultation with the Carrum contracted COE.
              <Text bold color="darkGrey" size="body2">
                You do not have to pay this rate
              </Text>
              - it’s covered by your employee benefit.
              {'\n\n'} &bull; If you would like to request a printed version of
              the information provided on this page, please{' '}
              <SendMessageLink
                title="message us"
                onPress={() => {
                  history.push(`/${Routes.Messages}`);
                }}
              />
            </Text>
          </>
        )}
      </Container>
    </Modal>
  );
}
