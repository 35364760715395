import styled, { css } from 'styled-components/native';

import { ChairAndPot as ChairAndPotRaw } from 'app/components/Common/svg-components';
import { Text, View } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';

const getIsGraphicDisplayed = ({ breakpoints, spacing, windowDimensions }) => {
  const graphicIsHidden =
    windowDimensions.height <= breakpoints.shortScreen + spacing * 11 ||
    (windowDimensions.width <= breakpoints.medium - spacing * 25 &&
      windowDimensions.height <= breakpoints.shortScreen + spacing * 10);

  return graphicIsHidden ? 'none' : 'flex';
};

export const BodyText = styled(Text).attrs({
  align: 'center',
  size: 'body1',
})`
  margin-top: ${({ theme }) => theme.spacing}px;
`;

export const ChairAndPot = styled(ChairAndPotRaw)`
  ${({ theme }) => css`
    display: ${getIsGraphicDisplayed(theme)};
  `}
`;

export const ContentWrapper = styled(View)`
  max-width: ${({ theme }) => theme.breakpoints.small}px;
`;

export const CtaButton = styled(TrackableButton).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginTop: theme.spacing * 2.5,
    },
  };
})`
  min-width: ${({ theme }) => theme.spacing * 10}px;
`;

export const Description = styled(Text)`
  text-align: center;
`;

export const FooterText = styled(Text).attrs({
  align: 'center',
  size: 'small',
})`
  max-width: ${({ theme }) => theme.breakpoints.small}px;
`;

export const FooterWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing}px;
  `}
`;

export const GraphicWrapper = styled(View)`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing * 3}px;
    right: ${theme.spacing * 3}px;
  `}
`;

export const InnerPageWrapper = styled(View)`
  background: ${({ theme }) => theme.colors.white};
  flex: 1 0 auto;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing * 8}px 0;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const PageWrapper = styled(View)`
  background: ${({ theme }) => theme.colors.sand};
  flex: 1 0 auto;
`;

export const TitleText = styled(Text).attrs({
  align: 'center',
  medium: true,
  size: 'hero',
})``;
