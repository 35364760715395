import { TouchableHighlight as RNTouchableHighlight, View } from 'react-native';
import { ListItem as RNEListItem } from 'react-native-elements';

import styled from 'styled-components/native';

import AnchorRaw from 'app/components/Common/Anchor';
import AnchorWithIconRaw from 'app/components/Common/AnchorWithIcon';
import HtmlView from 'app/components/Common/HtmlView';
import ProcedureIconRaw from 'app/components/Common/ProcedureIcon';
import Text from 'app/components/Common/Styled/Text';

import theme from 'app/util/theme';

const iconSize = theme.spacing * 2.5;

export const Anchor = styled(AnchorRaw).attrs({
  titleStyle: { fontSize: theme.fontSizes.body3, marginTop: theme.spacing / 4 },
})``;

export const AnchorWithIcon = styled(AnchorWithIconRaw).attrs({
  icon: 'chevron-right',
  iconRight: true,
  iconSize,
  title: '',
})``;

export const ListItem = styled(RNEListItem).attrs({
  containerStyle: { backgroundColor: 'transparent' },
})``;

export const ProcedureContentColumn = styled(View)`
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing}px;
  text-align: left;
`;

export const ProcedureDescription = styled(HtmlView).attrs({
  tagsStyles: {
    body: { fontSize: theme.fontSizes.body2 },
    p: {},
  },
})``;

export const ProcedureIcon = styled(ProcedureIconRaw).attrs({
  size: iconSize,
})``;

export const ProcedureTitle = styled(Text).attrs(({ isLargeScreen }) => ({
  bold: isLargeScreen,
  color: 'primary',
  numberOfLines: 1,
  size: isLargeScreen ? 'medium' : 'small',
}))``;

export const ProcedureTitleRow = styled(View)`
  align-items: flex-start;
  flex-direction: row;
`;

export const TouchableHighlight = styled(RNTouchableHighlight).attrs({
  underlayColor: '#eee',
})`
  background-color: #fff;
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  padding: ${({ theme }) => theme.spacing / 2}px 0;
`;
