/**
 * Common components - the keys here should match the name of the component in
 * the `app/components/Common/` directory.
 */
enum Components {
  AccordionCheckboxGroup = 'AccordionCheckboxGroup',
  BackAnchor = 'BackAnchor',
  BenefitModal = 'BenefitModal',
  ButtonList = 'ButtonList',
  ExperienceLabel = 'ExperienceLabel',
  FormDetails = 'FormDetails',
  FormsList = 'FormsList',
  Modal = 'Modal',
  Navigation = 'Navigation',
  Onboarding = 'Onboarding',
  Progress = 'Progress',
  StickyNavFooter = 'StickyNavFooter',
}

/**
 * Top level pages - the keys here should match the name of the component in
 * the `app/components/` directory.
 */
enum Pages {
  CompleteYourProfile = 'CompleteYourProfile',
  Forms = 'Forms',
  GetHelpModal = 'GetHelpModal',
  Insurance = 'Insurance',
  JourneyPhaseIntroPage = 'JourneyPhaseIntroPage',
  Login = 'Login',
  Messages = 'Messages',
  MySettings = 'MySettings',
  NotAvailable = 'NotAvailable',
  ProcedureConfirmation = 'ProcedureConfirmation',
  ProcedureDescription = 'ProcedureDescription',
  ProcedureSearch = 'ProcedureSearch',
  ProcedureSearchResults = 'ProcedureSearchResults',
  Register = 'Register',
  ResetPassword = 'ResetPassword',
  SelectYourDoctor = 'SelectYourDoctor',
  Welcome = 'Welcome',
  OneClickWelcome = 'OneClickWelcome',
}

/**
 * Naming conventions:
 *
 * 1. Add a new key to the TestID object matching the name of the component
 *    (eg., "Register" is used to for components in `app/components/Register`)
 * 2. Use a top level "Page" or "Component" key to map to the top-level
 *    component id (eg., Register.Page: "Register").
 * 3. Add nested keys with semantic names, and map them to strings with a format
 *    that includes the component name preceded by two dashes
 *    (eg., "Register--continue-button").
 */
export const TestID = {
  /**
   * Components' TestIds
   */
  [Components.AccordionCheckboxGroup]: {
    CheckboxItem: `${Components.AccordionCheckboxGroup}--checkbox--`,
  },
  [Components.BackAnchor]: {
    Component: Components.BackAnchor,
  },
  [Components.BenefitModal]: {
    Component: Components.BenefitModal,
  },
  [Components.ButtonList]: {
    Component: Components.ButtonList,
    Button: `${Components.ButtonList}--button--`,
  },
  [Components.ExperienceLabel]: {
    InfoIcon: `${Components.ExperienceLabel}--info-icon`,
  },
  [Components.FormDetails]: {
    // NOTE: here, non-interpolated values are necessary only because they are
    // tightly coupled to seed data currently
    Component: Components.FormDetails,
    CheckboxGroup: `${Pages.Forms}--checkbox-group`,
    Confirmation: `what-is-your--confirmation--response`,
    FormField: `${Components.FormDetails}--form-field`,
    LongText: `what-is-your--long-text--response`,
    NavButtons: `${Components.FormDetails}--nav-buttons`,
    NavFooter: `${Components.FormDetails}--nav-footer`,
    NextButton: `${Components.FormDetails}--next-button`,
    SaveButton: `${Components.FormDetails}--save-button`,
    PreviousButton: `${Components.FormDetails}--previous-button`,
    RadioGroup: `${Pages.Forms}--radio-group`,
    SaveLink: `${Components.FormDetails}--save-link`,
    Select: `what-is-your--select-menu--response`,
    ShortText: `what-is-your--short-text--response`,
    Signature: `what-is-your--signature--response`,
  },
  [Components.FormsList]: {
    Component: Components.FormsList,
  },
  [Pages.Login]: {
    Page: Pages.Login,
    EmailInput: `${Pages.Login}--email-input`,
    EmailForm: `${Pages.Login}--email-form`,
    Form: `${Pages.Login}--form`,
    GoToMagicLinkRequest: `${Pages.Login}--go-to-magic-link-request`,
    OpenMailApp: `${Pages.Login}--open-mail-app`,
    PasswordForm: `${Pages.Login}--password-form`,
    PasswordInput: `${Pages.Login}--password-input`,
    ResetPasswordButton: `${Pages.Login}--reset-password-button`,
    SendMagicLink: `${Pages.Login}--send-magic-link`,
    SubmitButton: `${Pages.Login}--submit-button`,
    SubmitEmailButton: `${Pages.Login}--submit-email-button`,
    SubmitPasswordButton: `${Pages.Login}--submit-password-button`,
    TopBackLink: `${Pages.Login}--top-back-link--button`,
  },
  [Components.Modal]: {
    CloseButton: `${Components.Modal}-CloseButton`,
    CloseIcon: `${Components.Modal}-CloseIcon`,
  },
  [Components.Navigation]: {
    Component: Components.Navigation,
    AnimatedOverlay: `${Components.Navigation}--animated-overlay`,
    AnimatedPanel: `${Components.Navigation}--animated-panel`,
    CarrumLogoNarrow: `${Components.Navigation}--carrum-logo-narrow`,
    CarrumLogoWide: `${Components.Navigation}--carrum-logo-wide`,
    ClientLogo: `${Components.Navigation}--client-logo`,
    ContentLinks: `${Components.Navigation}--content-links`,
    CreateAccountLink: `${Components.Navigation}--create-account-link`,
    CurrentEnvironment: `${Components.Navigation}--current-environment`,
    DevToolsLink: `${Components.Navigation}--dev-tools-link`,
    FormsLink: `${Components.Navigation}--forms-link`,
    GuestLinks: `${Components.Navigation}--guest-links`,
    HelpLink: `${Components.Navigation}--help-link`,
    HomeButton: `${Components.Navigation}--home-button`,
    HomeLink: `${Components.Navigation}--home-link`,
    LogInLink: `${Components.Navigation}--log-in-link`,
    LogOutLink: `${Components.Navigation}--log-out-link`,
    MenuIcon: `${Components.Navigation}--menu-icon`,
    MenuIconWithBadge: `${Components.Navigation}--menu-icon-with-badge`,
    MessagesLink: `${Components.Navigation}--messages-link`,
    MyCareSummaryLink: `${Components.Navigation}--my-care-summary-link`,
    MyCarrumIDCardLink: `${Components.Navigation}--my-carrum-id-card-link`,
    MyProfileLink: `${Components.Navigation}--my-profile-link`,
    MySettingsLink: `${Components.Navigation}--my-settings-link`,
    NavigationHeaderContainer: `${Components.Navigation}--navigation-header-container`,
    NavigationLinkPressable: `${Components.Navigation}--navigation-link-container`,
    NavigationLinks: `${Components.Navigation}--navigation-links`,
    NeedHelpCta: `${Components.Navigation}--need-help-cta`,
    NeedHelpCtaNarrow: `${Components.Navigation}--need-help-cta-narrow`,
    NeedHelpCtaWide: `${Components.Navigation}--need-help-cta-wide`,
    OverlayToggleSwitch: `${Components.Navigation}--overlay-toggle-switch`,
    ProfileAvatar: `${Components.Navigation}--profile-avatar`,
    ProfileInfo: `${Components.Navigation}--profile-info`,
    ProfileLinks: `${Components.Navigation}--profile-links`,
    ProfileMenu: `${Components.Navigation}--profile-menu`,
    ProfileMenuPanel: `${Components.Navigation}--profile-menu-panel`,
    ProfileMenuToggleContainer: `${Components.Navigation}--profile-menu-toggle-container`,
    ProfileEmail: `${Components.Navigation}--profile-email`,
    ProfileName: `${Components.Navigation}--profile-name`,
    SidePanel: `${Components.Navigation}--side-panel`,
    TopNav: `${Components.Navigation}--top-nav`,
    TravelLink: `${Components.Navigation}--travel-link`,
  },
  [Components.Onboarding]: {
    Component: Components.Onboarding,
    ContentTitle: `${Components.Onboarding}--content-title`,
    NavFooter: `${Components.Onboarding}--nav-footer`,
    NavFooterNextButton: `${Components.Onboarding}--nav-footer--next-button`,
    NavFooterSkipButton: `${Components.Onboarding}--nav-footer--skip-button`,
    NextButton: `${Components.Onboarding}--next-button`,
    SkipButton: `${Components.Onboarding}--skip-button`,
  },
  [Components.Progress]: {
    Component: Components.Progress,
    ManualTaskButton: `${Components.Progress}--manual-task-button`,
  },
  [Components.StickyNavFooter]: {
    Component: Components.StickyNavFooter,
    OptionalNavigation: `${Components.StickyNavFooter}--optional-navigation`,
    RequiredNavigation: `${Components.StickyNavFooter}--required-navigation`,
  },

  /**
   * Pages' TestIds
   */
  [Pages.CompleteYourProfile]: {
    Page: Pages.CompleteYourProfile,
    BenefitsOverview: `${Pages.CompleteYourProfile}--BenefitsOverview`,
    NavFooter: `${Pages.CompleteYourProfile}--nav-footer`,
    NextButton: `${Pages.CompleteYourProfile}--next-button`,
    NextButtonMobile: `${Pages.CompleteYourProfile}--next-button-mobile`,
    PreviousButton: `${Pages.CompleteYourProfile}--previous-button`,
    PreviousButtonMobile: `${Pages.CompleteYourProfile}--previous-button-mobile`,

    // Complete Your Profile Questionnaire, in order of appearance
    AlreadyScheduled: `${Pages.CompleteYourProfile}--AlreadyScheduled`,
    ChronicPain: `${Pages.CompleteYourProfile}--ChronicPain`,
    CurrentlyLiveHere: `${Pages.CompleteYourProfile}--CurrentlyLiveHere`,
    Insurance: `${Pages.CompleteYourProfile}--Insurance`,
    Oncology: `${Pages.CompleteYourProfile}--Oncology`,
    PatientCheck: `${Pages.CompleteYourProfile}--PatientCheck`,
    Recommendation: `${Pages.CompleteYourProfile}--Recommendation`,
    SmokingStatus: `${Pages.CompleteYourProfile}--SmokingStatus`,
    TellUsMore: `${Pages.CompleteYourProfile}--TellUsMore`,
    AccountSettings: `${Pages.CompleteYourProfile}--account-settings`,
    AccountSettingsInlineSubmitButton: `${Pages.CompleteYourProfile}--account-settings--inline-submit-button`,
    AccountSettingsNavFooter: `${Pages.CompleteYourProfile}--account-settings--nav-footer`,
    AccountSettingsNavFooterSubmitButton: `${Pages.CompleteYourProfile}--account-settings--nav-footer--submit-button`,

    // Complete Your Profile form inputs, in order of appearance
    GrantVoicemailPermission: `${Pages.CompleteYourProfile}--grant-voicemail-permission`,
    GroupNumber: `${Pages.CompleteYourProfile}--group-number`,
    HeightFeet: `${Pages.CompleteYourProfile}--height-feet`,
    HeightInches: `${Pages.CompleteYourProfile}--height-inches`,
    HowToHelpWithDiagnosis: `${Pages.CompleteYourProfile}--how-to-help`,
    HasConfirmedDiagnosis: `${Pages.CompleteYourProfile}--has-confirmed-diagnosis`,
    IsUnderActiveTreatment: `${Pages.CompleteYourProfile}--under-active-treatment`,
    MailingAddress: `${Pages.CompleteYourProfile}--mailing-address`,
    MailingAddressSame: `${Pages.CompleteYourProfile}--mailing-address-same`,
    MedicareOrMedicaid: `${Pages.CompleteYourProfile}--medicare-or-medicaid`,
    MemberID: `${Pages.CompleteYourProfile}--member-id`,
    PhoneInput: `${Pages.CompleteYourProfile}--phone-input`,
    PhysicalAddress: `${Pages.CompleteYourProfile}--physical-address`,
    PhysicianName: `${Pages.CompleteYourProfile}--physician-name`,
    PlanType: `${Pages.CompleteYourProfile}--plan-type`,
    PrimaryOrSecondary: `${Pages.CompleteYourProfile}--primary-or-secondary`,
    ScheduledProcedureDate: `${Pages.CompleteYourProfile}--scheduled-procedure-date`,
    ScheduledProcedureDetails: `${Pages.CompleteYourProfile}--scheduled-procedure-details`,
    Weight: `${Pages.CompleteYourProfile}--weight`,

    // modal
    CareBenefitCard: `${Pages.CompleteYourProfile}--care-benefit-card`,
    CareConciergeFallbackImage: `${Pages.CompleteYourProfile}--care-concierge-fallback-image`,
    CareConciergeImage: `${Pages.CompleteYourProfile}--care-concierge-image`,
    CostBenefitCard: `${Pages.CompleteYourProfile}--cost-benefit-card`,
    QualityBenefitCard: `${Pages.CompleteYourProfile}--quality-benefit-card`,
    StopScreen: `${Pages.CompleteYourProfile}--StopScreen`,
  },
  [Pages.Forms]: {
    Page: Pages.Forms,
  },
  [Pages.GetHelpModal]: {
    CallMeButton: `${Pages.GetHelpModal}--call-me-button`,
    CarrumPhoneLink: `${Pages.GetHelpModal}--carrum-phone-link`,
    GetHelpButton: `${Pages.GetHelpModal}--get-help-button`,
    OkButton: `${Pages.GetHelpModal}--ok-button`,
    OkCloseButton: `${Pages.GetHelpModal}--ok-close-button`,
    PhoneInput: `${Pages.GetHelpModal}--phone-input`,
    TextArea: `${Pages.GetHelpModal}--text-area`,
  },
  [Pages.Insurance]: {
    GroupIDInput: `${Pages.Insurance}--group-id`,
    MemberIDInput: `${Pages.Insurance}--member-id`,
  },
  [Pages.JourneyPhaseIntroPage]: {
    Page: Pages.JourneyPhaseIntroPage,
    ContinueButton: `${Pages.JourneyPhaseIntroPage}--continue-button`,
    ContinueButtonNav: `${Pages.JourneyPhaseIntroPage}--continue-button-nav`,
  },
  [Pages.Messages]: {
    Page: Pages.Messages,
    MessageGroup: `${Pages.Messages}--message-group`,
    NotificationGroup: `${Pages.Messages}--notification-group`,
    PickPhotoButton: `${Pages.Messages}--pick-photo-button`,
    SendButton: `${Pages.Messages}--send-button`,
    TakePhotoButton: `${Pages.Messages}--take-photo-button`,
    TextArea: `${Pages.Messages}--text-area`,
    UploadFileButton: `${Pages.Messages}--upload-file-button`,
  },
  [Pages.MySettings]: {
    Page: Pages.MySettings,
    AccountSettings: `${Pages.MySettings}--account-settings`,
    AccountSettingsInlineSubmitButton: `${Pages.MySettings}--account-settings--inline-submit-button`,
    AccountSettingsNavFooter: `${Pages.MySettings}--account-settings--nav-footer`,
    AccountSettingsNavFooterSubmitButton: `${Pages.MySettings}--account-settings--nav-footer--submit-button`,
    Home: `${Pages.MySettings}--home`,
    HomeImagePicker: `${Pages.MySettings}--home--image-picker`,
    PersonalInformation: `${Pages.MySettings}--personal-information`,
    PersonalInformationNavFooter: `${Pages.MySettings}--personal-information--nav-footer`,
    PersonalInformationSaveButton: `${Pages.MySettings}--personal-information--nav-footer--save-button`,
    SectionHeaderBackLink: `${Pages.MySettings}--section-header--back-link`,
  },
  [Pages.NotAvailable]: {
    Page: Pages.NotAvailable,
    LogOutButton: `${Pages.NotAvailable}--log-out-button`,
  },
  [Pages.ProcedureConfirmation]: {
    Page: Pages.ProcedureConfirmation,
    ContinueButton: `${Pages.ProcedureConfirmation}--continue-button`,
    NavFooter: `${Pages.ProcedureConfirmation}--nav-footer`,
    NavFooterContinueButton: `${Pages.ProcedureConfirmation}--nav-footer-continue-button`,
    NextSteps: `${Pages.ProcedureConfirmation}--next-steps`,
    ProcedureName: `${Pages.ProcedureConfirmation}--procedure-name`,
  },
  [Pages.ProcedureDescription]: {
    Page: Pages.ProcedureDescription,
    BackLink: `${Pages.ProcedureDescription}--back-link`,
    DescriptionHeaderText: `${Pages.ProcedureDescription}--header-text`,
    LoadingIndicator: `${Pages.ProcedureDescription}--loading-indicator`,
    NavFooter: `${Pages.ProcedureDescription}--nav-footer`,
    NavFooterNextButton: `${Pages.ProcedureDescription}--nav-footer--next-button`,
    NavFooterSkipButton: `${Pages.ProcedureDescription}--nav-footer--skip-button`,
    NonSurgicalButton: `${Pages.ProcedureDescription}--nav-footer--non-surgical-button`,
  },
  [Pages.ProcedureSearch]: {
    EmptyContentWrapper: `${Pages.ProcedureSearch}--empty-content-wrapper`,
    LetUsKnowAddress: `${Pages.ProcedureSearch}--let-us-know--address`,
    LoadingIndicator: `${Pages.ProcedureSearchResults}--loading-indicator`,
    Page: Pages.ProcedureSearch,
    ProcedureList: `${Pages.ProcedureSearch}--procedures-list`,
    SearchInput: `${Pages.ProcedureSearch}--search-input`,
    SearchResult: `${Pages.ProcedureSearch}--search-result`,
    LoadingAnimation: `${Pages.ProcedureSearch}--loading-animation`,
  },
  [Pages.ProcedureSearchResults]: {
    Page: Pages.ProcedureSearchResults,
    ContinueButton: `${Pages.ProcedureSearchResults}--continue-button`,
    LoadingIndicator: `${Pages.ProcedureSearchResults}--loading-indicator`,
    NavFooter: `${Pages.ProcedureSearchResults}--nav-footer`,
    NavFooterContinueButton: `${Pages.ProcedureSearchResults}--nav-footer-continue-button`,
    ProcedureName: `${Pages.ProcedureSearchResults}--procedure-name`,
    ProcedureOption: `${Pages.ProcedureSearchResults}--procedure-option`,
    ResultsWrapper: `${Pages.ProcedureSearchResults}--results-wrapper`,
  },
  [Pages.Register]: {
    Page: Pages.Register,
    ContinueButton: `${Pages.Register}--continue-button`,
    DateOfBirthInput: `${Pages.Register}--date-of-birth-input`,
    EmailInput: `${Pages.Register}--email-input`,
    EmployerRegistrationIdInput: `${Pages.Register}--employer-registration-id-input`,
    ErrorMessage: `${Pages.Register}--error-message`,
    FirstNameInput: `${Pages.Register}--first-name-input`,
    LastNameInput: `${Pages.Register}--last-name-input`,
    PasswordConfirmationInput: `${Pages.Register}--password-confirmation-input`,
    PasswordInput: `${Pages.Register}--password-input`,
    PhoneNumberInput: `${Pages.Register}--phone-number-input`,
    ReferralSourceInput: `${Pages.Register}--referral-source-input`,
    SingleClickRegistrationExpired: `${Pages.Register}--single-click-registration-expired`,
    StepOne: `${Pages.Register}--step-one`,
    StepTwo: `${Pages.Register}--step-two`,
    SubmitButton: `${Pages.Register}--submit-button`,
    SuccessMessage: `${Pages.Register}--success-message`,
    SkipEmailValidationCheckBox: `${Pages.Register}--skip-email-validation-check-box`,
  },
  [Pages.ResetPassword]: {
    Page: Pages.ResetPassword,
    RequestPasswordButton: `${Pages.ResetPassword}--request-password-button`,
    RequestPasswordEmailInput: `${Pages.ResetPassword}--request-password-email-input`,
    RequestPasswordHeader: `${Pages.ResetPassword}--request-password-header`,
    RequestPasswordPage: `${Pages.ResetPassword}--request-password-page`,
    RequestPasswordSuccess: `${Pages.ResetPassword}--request-password-success`,
    UpdatePasswordButton: `${Pages.ResetPassword}--update-password-button`,
    UpdatePasswordPage: `${Pages.ResetPassword}--update-password-page`,
    UpdatePasswordPasswordInput: `${Pages.ResetPassword}--update-password-password-input`,
    UpdatePasswordConfirmationInput: `${Pages.ResetPassword}--update-password-confirmation-input`,
    UpdatePasswordSuccess: `${Pages.ResetPassword}--update-password-success`,
  },
  [Pages.SelectYourDoctor]: {
    Page: Pages.SelectYourDoctor,
    BackButton: `${Pages.SelectYourDoctor}--back-button`,
    ButtonGroup: `${Pages.SelectYourDoctor}--button-group`,
    ConfirmationButton: `${Pages.SelectYourDoctor}--confirmation-button`,
    ConfirmationButtonNav: `${Pages.SelectYourDoctor}--confirmation-button-nav`,
    ConfirmationPage: `${Pages.SelectYourDoctor}--confirmation-page`,
    DoctorCard: `${Pages.SelectYourDoctor}--doctor-card`,
    DoctorDetails: `${Pages.SelectYourDoctor}--doctor-details`,
    DoctorLearnMore: `${Pages.SelectYourDoctor}--learn-more`,
    FacilityCard: `${Pages.SelectYourDoctor}--facility-card`,
    FacilityDetails: `${Pages.SelectYourDoctor}--facility-details`,
    FacilityLearnMore: `${Pages.SelectYourDoctor}--facility`,
    Introduction: `${Pages.SelectYourDoctor}--introduction`,
    NsaModal: `${Pages.SelectYourDoctor}--nsa-modal`,
    OpenNsaModalButton: `${Pages.SelectYourDoctor}--open-nsa-modal-button`,
    SelectButton: `${Pages.SelectYourDoctor}--select-this-doctor`,
    TravelDuration: `${Pages.SelectYourDoctor}--travel-duration`,
    ShowExtendedOptionsModal: `${Pages.SelectYourDoctor}--show-extended-options-modal`,
    ShowSilverButton: `${Pages.SelectYourDoctor}--show-silver-button`,
  },
  [Pages.Welcome]: {
    Page: Pages.Welcome,
    CreateAccountButton: `${Pages.Welcome}--create-account-button`,
    LoginButton: `${Pages.Welcome}--login-button`,
  },
  [Pages.OneClickWelcome]: {
    Page: Pages.OneClickWelcome,
    ContinueButton: `${Pages.OneClickWelcome}--continue-button`,
    DateOfBirthInput: `${Pages.OneClickWelcome}--dob-input`,
    StickyNavFooter: `${Pages.OneClickWelcome}--sticky-nav-footer`,
  },
};
