import React, { useRef } from 'react';
import { Animated, useWindowDimensions } from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { headerAnimationConfig } from 'app/components/App/Navigation/constants';
import {
  CarrumLogo,
  CenterContainer,
  ClientLogo,
  LeftContainer,
  MenuIcon,
  NavigationHeader,
  NavigationHeaderAnimation,
  NavigationLinks,
  NeedHelpCta,
  ProfileMenu,
  RightContainer,
} from 'app/components/App/Navigation/styles';
import { useUi } from 'app/hooks';
import breakpoints from 'app/util/breakpoints';
import { TestID } from 'app/util/test-id';

/**
 * Navigation that toggles responsively to display at top or side
 * of the screen based on screen width.
 */
export const Navigation = () => {
  const slide = useRef(new Animated.Value(-100)).current;
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;
  const { profileMenuOpen: profileMenuIsOpen } = useUi();
  const { top } = useSafeAreaInsets();

  Animated.timing(slide, { ...headerAnimationConfig, toValue: 0 }).start();

  return (
    <NavigationHeaderAnimation
      // TODO: handle animation in SC - https://carrumhealth.atlassian.net/browse/TEC-1648
      style={{ transform: [{ translateY: slide }] }}
      testID={TestID.Navigation.Component}
    >
      <NavigationHeader topInset={top}>
        <LeftContainer>
          <CarrumLogo />
          <ClientLogo />
        </LeftContainer>
        <CenterContainer>
          {hasTopNavLinks && <NavigationLinks />}
        </CenterContainer>
        <RightContainer>
          <NeedHelpCta />
          {hasTopNavLinks ? (
            <ProfileMenu isOpen={profileMenuIsOpen} />
          ) : (
            <MenuIcon />
          )}
        </RightContainer>
      </NavigationHeader>
    </NavigationHeaderAnimation>
  );
};

export default Navigation;
