import { Input } from 'react-native-elements';
import styled from 'styled-components/native';

export { default as FloatingLabel } from 'app/components/Common/FloatingLabel';

export const Container = styled.View`
  align-items: flex-start;
  flex: 1 0 auto;
  flex-direction: row;
`;

export const InputWrapper = styled(Input).attrs(
  ({ disabled, error, theme, inputStyle }) => {
    return {
      containerStyle: {
        borderColor: error ? theme.colors.error : null,
      },
      errorStyle: {
        display: error ? null : 'none',
      },
      inputStyle: {
        color: !disabled ? theme.colors.bodyText : theme.colors.dimText,
        marginBottom: error ? theme.spacing / 2 : null,
        //@ts-ignore
        ...inputStyle, // ensure we pick up the prop-ified styles
      },
      placeholderTextColor: theme.colors.placeholderText,
    };
  }
)`
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
`;
