import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { Field } from 'redux-form';

import Input from 'app/components/Common/Input';
import theme from 'app/util/theme';

/**
 * Renders a form for entering the member ID for
 * the current patient's insurance provider.
 */
export default class MemberIDForm extends Component {
  /**
   * @property {function} onSubmit A function to call when submitting the form (required).
   * @property {boolean} disabled If true, disables the submit button (defaults to false).
   * @property {string} title An optional title to display above the form (defaults to '').
   */
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    title: '',
  };

  state = {
    loading: false,
  };

  onSubmit = () => {
    if (this.state.loading) return;

    this.setState({ loading: true });

    return this.props.onSubmit();
  };

  render() {
    return (
      <Fragment>
        {Boolean(this.props.title) && <Text h1>Enter Your Member ID</Text>}

        <Text style={styles.message}>
          In order to calculate your cost breakdown, enter the member ID for
          your insurance.
        </Text>

        <Field
          name="memberId"
          label="Member ID"
          component={Input}
          containerStyle={styles.input}
        />

        <Button
          raised
          title="Continue"
          loading={this.state.loading}
          disabled={this.props.disabled}
          onPress={this.onSubmit}
        />
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  message: {
    textAlign: 'center',
  },

  input: {
    marginTop: theme.spacing * 2.5,
    marginLeft: 0,
    marginRight: 0,
  },
});
