import { change } from 'redux-form';

import { formatEpisodeCoordinates } from 'app/util/episodeUtils';

/**
 * Updates the redux-form state with values
 * from the current episode.
 */
export default () => {
  return (dispatch, getState) => {
    const {
      episode: { episode, procedure },
      session: { user },
    } = getState();

    const location = episode?.location || user?.location;

    dispatch(change('episode', 'location', formatEpisodeCoordinates(location)));
    dispatch(change('episode', 'procedure', procedure));
  };
};
