import * as React from 'react';
import { StyleProp, TextInputProps, ViewStyle } from 'react-native';

import { Container, FloatingLabel, InputWrapper } from './styles';

export interface InputFields {
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (event: any) => void;
  onChangeText?: (value: string) => void;
}

export interface InputProps extends TextInputProps {
  autoCapitalize?: string;
  blurOnSubmit?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  error?: string;
  input?: InputFields;
  inputContainerStyle?: any;
  inputStyle?: StyleProp<ViewStyle>;
  label?: string;
  rightIcon?: any;
  textInputRef?: React.Ref<any>;
}

export const defaultInputProps = {
  error: '',
  label: '',
  onBlur: null,
};

/** An input component wrapper for interacting with redux-form.  */

const Input = (props: InputProps) => {
  const {
    autoCapitalize,
    blurOnSubmit,
    textInputRef,
    input: {
      onBlur = () => {},
      onChange = () => {},
      onChangeText = () => {},
      ...inputProps
    },
    ...remainingProps
  } = props;

  return (
    <Container style={props.containerStyle}>
      <InputWrapper
        {...inputProps}
        {...remainingProps}
        autoCapitalize={autoCapitalize || 'none'}
        blurOnSubmit={typeof blurOnSubmit === 'undefined' ? true : blurOnSubmit}
        editable={!props.disabled}
        errorMessage={props.error}
        label={<FloatingLabel>{props.label}</FloatingLabel>}
        onBlur={onBlur}
        onChangeText={onChange as any}
        passwordRules=""
        ref={textInputRef}
        testID={inputProps.name}
      />
    </Container>
  );
};

Input.defaultProps = defaultInputProps;

export default Input;
