import styled from 'styled-components/native';

import { Platform, ScrollView as ScrollViewRaw } from 'react-native';
import { Avatar as RNEAvatar } from 'react-native-elements';
import MapViewRaw from '@cross-platform/react-native-maps';

import FloatingActionButtonRaw from 'app/components/Common/FloatingActionButton';
import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

export { Marker } from '@cross-platform/react-native-maps';

export const Avatar = styled(RNEAvatar).attrs({
  rounded: true,
  size: 'xlarge',
})`
  border-color: #fff;
  border-width: 10px;
  border-radius: 70px;
  height: 140px;
  width: 140px;
`;

export const AvatarContainer = styled.View`
  align-items: center;
  flex: 1 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing}px;
  transform: translateY(-${({ theme }) => theme.spacing}px);
`;

export const BiographySectionContainer = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const BiographySectionTitle = styled(Text).attrs({ h3: true })``;

export const Container = styled.View`
  flex: 1 0 auto;
`;

export const ContentContainer = styled.View`
  align-self: center;
  flex: 1 0 auto;
  flex-direction: ${({ wide }) => (wide ? 'row' : 'column')};
  max-width: ${({ theme }) => theme.breakpoints.medium}px;
  padding-bottom: ${({ theme }) => theme.spacing * 7.5}px;
  padding-horizontal: ${({ theme }) => theme.spacing}px;
`;

export const ContentSection = styled.View`
  flex: ${({ isColumn }) => (isColumn ? '1 0 auto' : '1 1 50%')};
`;

export const FloatingActionButton = styled(FloatingActionButtonRaw).attrs({
  containerStyle: {
    right: theme.spacing * 1.25,
    bottom: theme.spacing * 1.25,
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
  },
  icon: 'check',
  reverse: true,
  reverseColor: '#fff',
})``;

export const MapView = styled(MapViewRaw)`
  height: 100%;
  width: 100%;
`;

export const MapViewContainer = styled.View`
  height: 240px;
  left: 0;
  position: ${Platform.select({
    default: 'absolute',
    web: 'fixed',
  })};
  right: 0;
  top: 0;
`;

export const ScrollView = styled(ScrollViewRaw).attrs({
  contentContainerStyle: {
    paddingBottom: theme.spacing * 6,
    paddingTop: theme.spacing * 11,
  },
})``;

export const Spacer = styled.View`
  padding: 2%;
`;
