import { Animated, FlatList, Platform } from 'react-native';

import { Text } from 'react-native-elements';
import styled from 'styled-components/native';

import FloatingActionButtonRaw from 'app/components/Common/FloatingActionButton';
import { View } from 'app/components/Common/Styled';
import { DotIndicator as DotIndicatorRaw } from 'app/components/Common/svg-components';
import { default as TitleBarRaw } from 'app/components/Common/TitleBar';
import theme from 'app/util/theme';

const AnimatedView = Animated.createAnimatedComponent(View);

export const Container = styled.View`
  flex: 1 1 auto;
`;

export const Content = styled(AnimatedView)`
  flex: 1 0 auto;
`;

export const EmptyList = styled(View)`
  flex: 0 0 auto;
`;

export const FloatingActionButton = styled(FloatingActionButtonRaw).attrs({
  containerStyles: {
    left: '50%',
    marginLeft: -theme.spacing * 1.5,
    bottom: '4%',
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
  },
})``;

export const DotIndicator = styled(DotIndicatorRaw).attrs({ color: '#888' })`
  align-self: center;
  margin-top: ${({ theme }) => theme.spacing * 1.25}px;
`;

export const SurgeonsList = styled(FlatList).attrs<{
  loading: boolean;
  height: number;
  width: number;
}>(({ height, loading, width, theme }) => ({
  contentContainerStyle: {
    paddingBottom: theme.spacing * 7.5,
    paddingHorizontal: theme.spacing * 1.25,
    ...(width > height && { maxWidth: 480 }),
  },
}))``;

export const Notification = styled(Text)`
  color: #888;
  margin-top: ${({ theme }) => theme.spacing * 1.25}px;
  text-align: center;
`;

export const TitleBar = TitleBarRaw;
