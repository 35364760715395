import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { Field } from 'redux-form';
import { RouteComponentProps } from '@cross-platform/react-router-native';
import { withRouter } from '@cross-platform/react-router-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import Stepper from 'app/components/Common/Stepper';
import TextArea from 'app/components/Common/TextArea';
import { HistoryPropTypes } from 'app/util/propTypes';
import { Routes } from 'app/util/routes';
import { GoogleLocationDTO } from 'types/location';
import { Episode } from 'types/episode';
import theme from 'app/util/theme';

export interface FormValues {
  contactMethodPreference?: Episode.ContactMethod;
  description: string;
  location: GoogleLocationDTO;
  procedure?: any;
}

export interface OtherProcedureProps extends RouteComponentProps {
  formValues: FormValues;
}

export const OtherProcedure = ({
  formValues,
  history,
}: OtherProcedureProps) => (
  <View style={{ flex: 1 }}>
    <View style={{ backgroundColor: '#fff' }}>
      {/** @ts-ignore */}
      <Stepper bordered step={1} steps={['', '', '']} />
    </View>

    <KeyboardAwareScrollView enableOnAndroid>
      <View style={otherProcedureStyles.container}>
        <Text h1>Additional Information</Text>

        <Text style={StyleSheet.flatten([otherProcedureStyles.message])}>
          To help us understand your case better, please describe your condition
          and any treatment options (including surgery) recommended by your
          physician. We will review your information and if Carrum is able to
          arrange treatment for your condition, we will contact you shortly.
        </Text>

        <Field
          multiline
          name="description"
          placeholder="Condition summary and treatment"
          returnKeyType="go"
          component={TextArea}
          onSubmitEditing={() => history.push(`/${Routes.Location}`)}
        />

        <Button
          raised
          disabled={!formValues.description}
          title="Continue"
          onPress={() => history.push(`/${Routes.Location}`)}
        />

        <Text style={StyleSheet.flatten([otherProcedureStyles.helpText])}>
          Need help? Call us at 1-888-855-7806
        </Text>
      </View>
    </KeyboardAwareScrollView>
  </View>
);

OtherProcedure.propTypes = {
  formValues: PropTypes.object.isRequired,
  history: PropTypes.shape(HistoryPropTypes).isRequired,
};

export const otherProcedureStyles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: theme.spacing * 1.25,
    paddingBottom: theme.spacing * 1.25,
    maxWidth: theme.breakpoints.small,
    alignSelf: 'center',
  },

  message: {
    textAlign: 'center',
    marginBottom: theme.spacing * 2.5,
  },

  helpText: {
    marginVertical: theme.spacing * 2.5,
    textAlign: 'center',
  },
});

export default withRouter(OtherProcedure);
