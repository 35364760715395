import React from 'react';

import { isEmpty } from 'lodash';

import { useEpisode } from 'app/hooks';
import { TestID } from 'app/util/test-id';

import BenefitModalBase from './BenefitModalBase';
import {
  BodyText,
  CareConciergeImage,
  CareConciergeImageFallback,
  Header,
} from './styles';

const CareModal = ({ isVisible, onClose }) => {
  const { episode } = useEpisode();
  const careConcierge = episode?.careConcierge;

  if (!careConcierge || isEmpty(careConcierge)) {
    return null;
  }

  return (
    <BenefitModalBase
      isVisible={isVisible}
      onClose={onClose}
      title="Dedicated Care Specialist"
    >
      {!isEmpty(careConcierge.profileImage) ? (
        <CareConciergeImage
          testID={TestID.CompleteYourProfile.CareConciergeImage}
          source={{ uri: careConcierge.profileImage }}
          resizeMode="cover"
        />
      ) : (
        <CareConciergeImageFallback
          testID={TestID.CompleteYourProfile.CareConciergeFallbackImage}
        />
      )}

      <Header>{careConcierge.name}</Header>

      <BodyText mt={2.5}>
        Meet {careConcierge.name} - your dedicated care specialist - who can
        support and guide you through the process. Feel free to message them
        whenever you need help!
      </BodyText>
    </BenefitModalBase>
  );
};

export default CareModal;
