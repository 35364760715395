import React, { Component } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
  View,
} from 'react-native';

// @ts-ignore TODO: https://carrumhealth.atlassian.net/browse/TEC-1590
import { Redirect, withRouter } from '@cross-platform/react-router-native';
import { uniqBy } from 'lodash';

import { logEvent, EVENTS } from 'app/util/analytics';
import { useNativeDriver } from 'app/util/constants';
import { toAddressString } from 'app/util/methods';
import { Routes } from 'app/util/routes';

import MissingLocationModal from './MissingLocationModal';
import SurgeonMap from './SurgeonMap';
import SurgeonResult from './SurgeonResult';
import {
  Container,
  Content,
  EmptyList,
  FloatingActionButton,
  SurgeonsList,
  Notification,
  TitleBar,
} from './styles';

/**
 * Render the default content when there are no surgeons.
 */
const ListEmptyComponent = () => (
  <EmptyList>
    <Notification>No surgeons found.</Notification>
  </EmptyList>
);

interface SelectSurgeonProps {
  clearPhysician: () => Promise<void>;
  formValues: any;
  getPossiblePhysicians: (
    procedureId: string,
    surgeonSortBy: string,
    page: number
  ) => Promise<any>;
  history: { entries: any[]; goBack: () => void; push: (url: string) => void };
  initializeSortValue: (...args: any[]) => any;
  isPlatformTwo: boolean;
  skipPhysician: (...args: any[]) => any;
  sortOptions: any[];
  updateCurrentPatient: (...args: any[]) => any;
  updateEpisodeLocation: (...args: any[]) => any;

  hasEpisode?: boolean;
  physicians?: any[];
  nextPage?: number;
}
interface SelectSurgeonState {
  dimensions: { height: number; width: number };
  loading: boolean;
  fetching: boolean;
  showScroll: boolean;
  slide: Animated.Value;
}

export class SelectSurgeon extends Component<
  SelectSurgeonProps,
  SelectSurgeonState
> {
  static defaultProps = {
    hasEpisode: false,
    physicians: [],
    nextPage: null,
  };

  refreshTimeout: ReturnType<typeof setTimeout>;
  scrollTimeout: ReturnType<typeof setTimeout>;
  scrollView;
  resultHeights = [];

  constructor(props) {
    super(props);

    this.state = {
      dimensions: Dimensions.get('window'),
      loading: !props.physicians.length,
      fetching: false,
      showScroll: false,
      slide: new Animated.Value(Dimensions.get('window').height),
    };

    props.initializeSortValue(props.sortOptions[0].value);

    this.subscription = Dimensions.addEventListener(
      'change',
      this.onDimensionsChange
    );
  }

  componentDidMount() {
    const { formValues, history } = this.props;

    if (!formValues.location) return;
    if (!formValues.procedure) return;
    if (!formValues.surgeonSortBy) return;

    const previousPage =
      history.entries && history.entries[this.props.history.entries.length - 1];
    const previousSurgeon = previousPage && previousPage.pathname.split('/')[2];

    if (previousSurgeon) {
      this.scrollTimeout = setTimeout(
        () => this.scrollToPhysician(previousSurgeon),
        150
      );
    } else {
      this.fetchPhysicians(1);
    }
  }

  componentDidUpdate = (prevProps) => {
    const locationChanged =
      this.props.formValues.location !== prevProps.formValues.location;
    const sortChanged =
      this.props.formValues.surgeonSortBy !==
      prevProps.formValues.surgeonSortBy;

    if (locationChanged) this.onLocationChange();
    if (locationChanged || sortChanged) this.fetchPhysicians(1);
  };

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
    clearTimeout(this.scrollTimeout);
    this.subscription?.remove();
  }

  /**
   * Retrieves the stored layout (or default) for a given item.
   *
   * @param   {object}   data   The item data.
   * @param   {integer}  index  The item index.
   * @return  {object}          The item layout.
   */
  getItemLayout = (data, index) => {
    const length = this.resultHeights[index] || 500;
    const offset = this.resultHeights
      .slice(0, index + 1)
      .reduce((sum, item) => sum + item);
    return { length, offset: offset + 500, index };
  };

  /**
   * Fetch a page of physicians using the current sort and procedure selections.
   *
   * @param   {integer}  page  The page to fetch.
   * @return  {promise}        A promise that resolves after fetching physicians.
   */
  fetchPhysicians = async (page) => {
    if (this.state.fetching) return;

    const { procedure, surgeonSortBy } = this.props.formValues;

    this.onFetchStart(page);

    await this.props.getPossiblePhysicians(procedure.id, surgeonSortBy, page);

    this.onFetchEnd(page);
  };

  /**
   * Loads the next page of physicians.
   */
  fetchMorePhysicians = () => {
    if (!this.props.nextPage || this.props.nextPage === 1) return;

    this.fetchPhysicians(this.props.nextPage);
  };

  revealPhysicians = () => {
    const config = {
      duration: 500,
      easing: Easing.in(Easing.ease),
      useNativeDriver,
    };

    Animated.sequence([
      Animated.timing(this.state.slide, {
        ...config,
        duration: 0,
        toValue: this.state.dimensions.height,
      }),
      Animated.timing(this.state.slide, { ...config, toValue: 0 }),
    ]).start();
  };

  /**
   * Scroll to a specific physician in the list.
   *
   * @param  {integer}  id  The ID of the surgeon to scroll to.
   */
  scrollToPhysician = (id) => {
    const index = this.props.physicians.findIndex(
      (physician) => physician.id == id
    );

    if (!this.scrollView || index === -1) return;

    try {
      this.scrollView.scrollToIndex({ index, animated: false });
    } catch (error) {
      /* Ignore scrolling errors when the view is not ready. */
    }
  };

  scrollToTop = () => {
    if (!this.scrollView) return;

    this.scrollView.scrollToOffset(0);
    setTimeout(() => this.setState({ showScroll: false }), 280);
  };

  onDimensionsChange = (event) => {
    this.setState({ dimensions: event.window });
  };

  /**
   * Set the loading and fetching state for a given page.
   *
   * @param  {integer}  page  The page being fetched.
   */
  onFetchStart = (page) => {
    this.setState({
      fetching: true,
      loading: page === 1,
    });
  };

  /**
   * After a small timeout, reset the fetching and loading states.
   */
  onFetchEnd = (page) => {
    this.refreshTimeout = setTimeout(() => {
      this.setState({
        fetching: false,
        loading: false,
      });

      if (page === 1) this.revealPhysicians();
    }, 300);
  };

  /**
   * Store the height of each item internally for scrolling.
   *
   * @param  {object}   event  A native onLayout event object.
   * @param  {integer}  index  The index of the item triggering the event.
   */
  onItemLayout = (event, index) => {
    this.resultHeights[index] = event.nativeEvent.layout.height;
  };

  /**
   * Save the selected address on the patient/episode when it changes.
   */
  onLocationChange = () => {
    const { location } = this.props.formValues;

    if (!location?.lat || !location?.lng) return;

    this.props.updateCurrentPatient({ address: location.address });

    if (this.props.hasEpisode) this.props.updateEpisodeLocation(location);
  };

  /**
   * Show the scroll to top FAB if the user scrolls down.
   */
  onScroll = (event) => {
    if (
      !event.nativeEvent ||
      !event.nativeEvent.contentOffset ||
      !event.nativeEvent.contentOffset.y
    )
      return;

    const showScroll = event.nativeEvent.contentOffset.y > 0;

    this.setState({ showScroll });
  };

  /**
   * Creates or updates an episode in the care service, and navigates to the
   *  confirmation route.
   */
  onSkipSurgeon = async () => {
    await this.props.clearPhysician();

    if (this.props.hasEpisode) {
      const isSuccess = await this.props.skipPhysician();
      if (!isSuccess) return;
    }

    logEvent(EVENTS.episode.skipProvider);
    this.props.history.push(`/${Routes.Confirmation}`);
  };

  renderHeader = () => {
    const { height, width } = this.state.dimensions;

    return (
      <View
        style={{ height: width > height ? 80 : height / 3, width: '100%' }}
      />
    );
  };

  render() {
    const { location, procedure } = this.props.formValues;

    const hasAddress = location?.lat && location?.lng && location?.address;

    const hospitals = uniqBy(
      this.props.physicians.map(({ hospital }) => hospital),
      'id'
    );

    if (!procedure) return <Redirect to={Routes.Home} />;

    const { height, width } = this.state.dimensions;

    return (
      <Container testID="SelectSurgeon">
        <SurgeonMap
          hospitals={hospitals}
          offset={{
            x: width > height ? -3 : 0,
            y: width > height ? 0 : -4,
          }}
        />

        <TitleBar
          onBackPress={this.props.history.goBack}
          subtitle={
            hasAddress
              ? toAddressString(location.address)
              : 'Please enter your location'
          }
          title={hasAddress ? 'Showing Surgeons Near' : 'Finding Surgeons'}
        />

        <Content
          style={StyleSheet.flatten([
            { transform: [{ translateY: this.state.slide }] },
          ])}
        >
          <SurgeonsList
            //@ts-ignore TODO: investigate this TS error `enableOnAndroid`
            enableOnAndroid
            height={height}
            keyboardShouldPersistTaps="always"
            data={this.state.loading ? [] : this.props.physicians}
            onEndReached={this.fetchMorePhysicians}
            onEndReachedThreshold={0.5}
            onScroll={this.onScroll}
            onScrollToTop={() => this.setState({ showScroll: false })}
            scrollEventThrottle={300}
            getItemLayout={
              this.resultHeights.length > 0 ? this.getItemLayout : undefined
            }
            keyExtractor={({ id }) => id}
            renderItem={({ index, item }: any) => (
              <SurgeonResult
                {...item}
                onPress={() =>
                  this.props.history.push(`/${Routes.SelectSurgeon}/${item.id}`)
                }
                onLayout={(event) => this.onItemLayout(event, index)}
              />
            )}
            ref={(ref) => (this.scrollView = ref)}
            ListEmptyComponent={ListEmptyComponent}
            ListHeaderComponent={this.renderHeader}
            loading={this.state.loading}
            width={width}
          />
        </Content>

        {!hasAddress && (
          <MissingLocationModal onClose={this.props.history.goBack} />
        )}

        <FloatingActionButton
          show={this.state.showScroll}
          onPress={this.scrollToTop}
          icon={Platform.select({
            default: 'arrow-upward',
            ios: 'expand-less',
          })}
        />
      </Container>
    );
  }
}

export default withRouter(SelectSurgeon);
