import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  createInquiryFromState,
  updateInquiry,
} from 'app/actions/episodeActions';

import Location from 'app/components/Location';

const mapStateToProps = ({ episode, form, session }) => ({
  episode,
  form,
  session,
});

const mapDispatchToProps = (dispatch) => ({
  createInquiryFromState: () => dispatch(createInquiryFromState()),
  updateInquiry: (inquiryId, inquiry) =>
    dispatch(updateInquiry(inquiryId, inquiry)),
});

const LocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Location);

export default reduxForm({
  form: 'episode',
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(LocationContainer);
