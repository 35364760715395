import theme, { fontFamily, fontFamilyBold } from 'app/util/theme';

// Define custom tags that should be replaced with HTML templates
const customTags = {
  // Transform {image: my-image.png} into <img src="my-image.png"></img>
  image: {
    pattern: /{image: (.+)}/g,
    template: `<img src="$1"></img>`,
  },
  // Transform {video: my-video.mp4} into <react-video source="my-video.mp4"></react-video>
  video: {
    pattern: /(?:<p>|){video: (.+)}(?:<\/p>|)/g,
    template: `<react-video key="$1" source="$1"></react-video>`,
  },
};

// Match all height and width values for images
export const heightAndWidthPatterns = {
  // Match all height="" or width="" values
  attribute: /(height|width) *= *"[^"]*?"/g,
  // Match all height or width values declared in inline styles style="height:192px;width:400px;s"
  inline: /(height|width) *: *[^;"']*?[;"']/g,
};

// Replace text alignment on <div> elements with flex styles
const textAlignToFlexMapping = {
  left: {
    pattern: /<div style="text-align:left;">/g,
    template: '<div style="flex-direction:column;justify-content:flex-start;">',
  },
  center: {
    pattern: /<div style="text-align:none;">/g,
    template: '<div style="flex-direction:column;justify-content:center;">',
  },
  right: {
    pattern: /<div style="text-align:right;">/g,
    template: '<div style="flex-direction:column;justify-content:flex-end;">',
  },
};

/**
 * Build an object that maps HTML tag names to React Native styles
 * in a way that matches the rest of the application.
 */
export const htmlStyles = ({ maxWidth, textColor }) => ({
  html: {
    height: '100%',
    maxWidth,
  },
  body: {
    height: '100%',
    color: textColor || theme.colors.bodyText,
    fontFamily,
    fontSize: theme.fontSize,
  },
  i: {
    fontFamily,
    fontStyle: 'italic',
  },
  em: {
    fontFamily,
    fontStyle: 'italic',
  },
  strong: {
    fontFamily: fontFamilyBold,
  },
  p: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing * 1.25,
  },
  a: {
    fontFamily,
    fontSize: theme.fontSize,
    color: theme.colors.primary,
    textDecorationLine: 'none',
  },
  img: {
    minWidth: 100,
    minHeight: 100,
  },
  li: { paddingLeft: 0 },
  h1: { ...theme.Text.h1Style },
  h2: { ...theme.Text.h1Style },
  h3: { ...theme.Text.h3Style },
  h4: { ...theme.Text.h4Style },
  h5: { ...theme.Text.h4Style },
  h6: { ...theme.Text.h4Style },
});

/**
 * Takes a given text string and interpolates custom "tags" into HTML tags.
 *
 * @example
 * interpolateHtml('{image: my-image.png}'); // '<img src="my-image.png"></img>'
 * interpolateHtml('{video: my-video.mp4}'); // '<react-video source="my-video.mp4"></react-video>'
 */
export const interpolateHtml = (text: string) =>
  text
    /**
     * Some HTML elements, <video> in particular, require "custom tags"
     * to allow embedding this type of content into the native and web
     * applications in a way that's supported across platforms.
     *
     * The following lines replace custom tag definitions with an HTML string.
     */
    .replace(customTags.image.pattern, customTags.image.template)
    .replace(customTags.video.pattern, customTags.video.template)
    /**
     * Sometimes the HTML editor we use inserts hard-coded height/weight
     * properties that prevent the styles from rendering in a fluid way
     * that works across devices.
     *
     * The following lines remove these attributes from the HTML string.
     */
    .replace(heightAndWidthPatterns.attribute, '')
    .replace(heightAndWidthPatterns.inline, '')
    /**
     * Sometimes the HTML editor we use inserts "text-align" styles for
     * block components like `<div>`s, which causes rendering errors in
     * React Native.
     *
     * The following lines replace these attributes with a corresponding
     * flex style that works in web and mobile.
     */
    .replace(
      textAlignToFlexMapping.left.pattern,
      textAlignToFlexMapping.left.template
    )
    .replace(
      textAlignToFlexMapping.right.pattern,
      textAlignToFlexMapping.right.template
    )
    .replace(
      textAlignToFlexMapping.center.pattern,
      textAlignToFlexMapping.center.template
    );

/**
 * Render an HTML fragment with `<html>`, `<head>`, and `<body>` tags
 * and interpolate HTML values into a string that can be passed to an
 * `<Html />` component from `react-native-render-html`.
 */
export const renderHtmlTemplate = (html: string) => `
  <html>
    <head> </head>
    <body>
      ${interpolateHtml(html)}
    </body>
  </html>
`;
