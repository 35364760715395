import React from 'react';
import { useWindowDimensions } from 'react-native';

import { useSelector } from 'react-redux';

import { getIsLoggedIn } from 'app/selectors/session';
import { breakpoints } from 'app/util/breakpoints';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import {
  ProfileInfo,
  ProfileLinks,
  ProfileMenuContainer,
  ProfileMenuPanel,
  ProfileMenuToggle,
} from './styles';
import { ProfileMenuProps } from '../types';

/**
 * Provide access for authenticated users to account-related screens.
 *
 * Responsively render the navigation links based on several factors:
 *
 * 1. The screen size. This determines whether this component should be rendered.
 * 2. The patient's situation and position in their journey of care. This
 *    logic is encapsulated downstream where relevant.
 * 3. The user's authentication status.
 * 4. Whether this component has been toggled open to display.
 */

const ProfileMenu = ({ isOpen = true }: ProfileMenuProps) => {
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn || !hasTopNavLinks) return null;

  return (
    <ProfileMenuContainer testID={TestID.Navigation.ProfileMenu}>
      <ProfileMenuToggle />
      {isOpen && (
        <ProfileMenuPanel testID={TestID.Navigation.ProfileMenuPanel}>
          <ProfileInfo />
          <ProfileLinks layout="column" />
        </ProfileMenuPanel>
      )}
    </ProfileMenuContainer>
  );
};

export default ProfileMenu;
