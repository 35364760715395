import axios from 'axios';

import { CARE_API_URL } from 'app/util/constants';
import { showGlobalError } from 'app/actions/uiActions';

import { receivePhysicianRating } from './action-types';

/**
 * Fetches the rating for a physician.
 *
 * @param {integer} physicianId The ID of the physician to fetch the rating for.
 * @return {promise} A promise that resolves after fetching the rating.
 */
export default (physicianId) => {
  return (dispatch, getState) => {
    const { coreToken, user } = getState().session;
    const { code } = user.employer;

    return axios
      .get(
        `${CARE_API_URL}/physician_ratings/${physicianId}/${encodeURIComponent(
          code
        )}`,
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      )
      .then((response) => dispatch(receivePhysicianRating(response.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
};
