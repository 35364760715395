import { Platform } from 'react-native';

/**
 * Different objects and platforms may use `lat` and `lng` or they may use
 * `latitude` and `longitude`, so handle both and return an object with both
 * at a given offest.
 *
 * @param {object} location A location object with latitude and longitude coordinates.
 * @param {number} offset A number to use to offset the coordinate.
 * @return {object} An object that can be passed to a Google Maps component.
 */
export const formatCoords = ({ lat, lng, latitude, longitude }, offset) => ({
  lat: (lat || parseFloat(latitude)) + (offset && offset.y ? offset.y : 0),
  lng: (lng || parseFloat(longitude)) + (offset && offset.x ? offset.x : 0),
  latitude: (lat || parseFloat(latitude)) + (offset && offset.y ? offset.y : 0),
  longitude:
    (lng || parseFloat(longitude)) + (offset && offset.x ? offset.x : 0),
  latitudeDelta: 10,
  longitudeDelta: 10,
});

export const getMapCenter = (hospitals, offset) => {
  if (hospitals.length === 0) return null;
  if (hospitals.length === 1) return formatCoords(hospitals[0], offset);

  let lat = 0;
  let lng = 0;

  hospitals.forEach((hospital) => {
    const marker = formatCoords(hospital);
    lat += marker.lat;
    lng += marker.lng;
  });

  lat /= hospitals.length;
  lng /= hospitals.length;

  return formatCoords({ lat, lng }, offset);
};

export const getZoomFactor = (hospitals) =>
  Platform.select({
    default: Math.max(hospitals.length / 2, 4),
    web: Math.min((1 / hospitals.length) * 16, 8),
  });
