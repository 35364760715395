import React from 'react';
import PropTypes from 'prop-types';

import { AddressPropTypes } from 'app/util/propTypes';
import { toAddressString } from 'app/util/methods';

const propTypes = {
  address: PropTypes.shape(AddressPropTypes).isRequired,
  name: PropTypes.string.isRequired,

  images: PropTypes.shape({
    logo: PropTypes.string,
    mainImage: PropTypes.string,
  }),
};

import {
  Card,
  CardContent,
  HospitalAddress,
  HospitalLogo,
  HospitalName,
} from './styles';

const HospitalCard = ({ address, images = {}, name }) => (
  <Card>
    {images?.mainImage ? (
      <Card.Image source={{ uri: images.mainImage }} />
    ) : null}

    <CardContent>
      {images?.logo ? <HospitalLogo source={images.logo} /> : null}
      <HospitalName>{name}</HospitalName>
      <HospitalAddress>{toAddressString(address)}</HospitalAddress>
    </CardContent>
  </Card>
);

HospitalCard.propTypes = propTypes;
export default HospitalCard;
