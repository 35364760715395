import { Platform } from 'react-native';
import { Button, LinearProgress as ProgressRaw } from 'react-native-elements';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

export const ActionButton = styled(Button).attrs({
  /**
   * The backgroundColor and opacity won't be changed in Android using styles
   * on the styled component.  Instead, use the `disabledStyle` prop to ensure
   * proper styling on all platforms.
   */
  disabledStyle: { backgroundColor: 'transparent', opacity: 0.4 },
  disabledTitleStyle: { color: 'white' },
})`
  background-color: transparent;
`;

export const ActionRow = styled.View`
  background-color: ${theme.colors.primary};
  height: 80px;
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonRow = styled.View`
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;
`;
export const FloatingButton = styled(Button)`
  width: ${({ theme }) => theme.spacing * 10}px;
`;

export const Container = styled.View`
  flex: 1 0 auto;
`;

export const FormContainer = styled.View`
  margin: 0px ${({ theme }) => theme.spacing}px;
`;

export const Info = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const LinearProgress = styled(ProgressRaw)`
  margin: ${({ theme }) => `${theme.spacing * 3}px 0 ${theme.spacing}px`};
`;

export const StepLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.h1}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const StepNumber = styled(Text)`
  margin: ${({ theme }) => theme.spacing}px 0px;
`;
