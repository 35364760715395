import { expiredAuthLinkConfig, TestID } from 'app/components/Login/constants';

import { loginCoreUser, parseQueryParams } from 'app/components/Login/helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useLocation,
  useNextPhaseRoute,
  useSession,
  useState,
} from 'app/components/Login/hooks';

import {
  DotIndicator,
  ExpiredAuthLink,
  MagicLinkRedirectContainer,
  Redirect,
  TitleText,
} from 'app/components/Login/styles';

const Magic = () => {
  const currentUrl = useLocation();
  const dispatch = useDispatch();
  const { error } = useSession();
  const history = useHistory();
  const { nextRoute } = useNextPhaseRoute();

  const [loading, setLoading] = useState(true);

  const { email, token } = parseQueryParams(currentUrl);

  useEffect(() => {
    if (email && token) loginUser();
  }, []);

  /** Send a login request using the token as the password param. */
  const loginUser = useCallback(async () => {
    await dispatch(loginCoreUser({ email, password: token }, true));

    setLoading(false);
  }, [email, loading, loginCoreUser, setLoading, token]);

  /** Display progress indicator while response to login request is pending. */
  if (loading) {
    return (
      <MagicLinkRedirectContainer>
        <TitleText>Attempting to log you in</TitleText>
        <DotIndicator />
      </MagicLinkRedirectContainer>
    );
  }

  /** Display possible pivots for user after failed login request. */
  if (error) {
    return (
      <ExpiredAuthLink
        guestEmail={email}
        guidanceText={expiredAuthLinkConfig.guidanceText}
        headerText={expiredAuthLinkConfig.headerText}
        history={history}
        linkText={expiredAuthLinkConfig.linkText}
        loading={loading}
        testId={TestID.Register.SingleClickRegistrationExpired}
      />
    );
  }

  return <Redirect to={nextRoute} />;
};

export default Magic;
