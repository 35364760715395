import { Dimensions, Platform } from 'react-native';
import { colors } from 'react-native-elements';

export { DefaultTheme } from 'styled-components';

import { FontSizes, Palette, Shadows, ZIndexes } from 'types/styled';

import WebOverlayWrapper from 'app/components/Common/WebOverlayWrapper';
import WebTooltipWrapper from 'app/components/Common/WebTooltipWrapper';

import { breakpoints } from 'app/util/breakpoints';

export const fontFamily = 'inter';
export const fontFamilyBold = `${fontFamily}-bold`;
export const fontFamilyMedium = `${fontFamily}-medium`;
export const fontFamilySignature = 'squarepeg';

/**
 * NOTE: This variable was intended to be private is now public because:
 *
 * 1. The `fontSizes` approach does not scale dynamically with re-renders.
 * 2. The breakpoints within `getResponsiveFontSize` don't meet design
 *    requirements for pages and components such as the benefits overview.
 */
const rawFontSizes = {
  xxxsmall: 8,
  xxsmall: 12,
  xsmall: 14,
  small: 16,
  medium: 18,
  large: 20,
  xlarge: 23,
  xxlarge: 26,
  xxxlarge: 33,
  xxxxlarge: 47,
};

/**
 * Some font sizes scale up depending on the screen width. This function helps in
 * these situations.
 */
const getResponsiveFontSize = (
  smallSize: string = 'small',
  midSize: string = smallSize,
  largeSize: string = midSize
): number => {
  const screenWidth = Dimensions.get('window').width;

  switch (true) {
    case screenWidth < breakpoints.xsmall:
      return rawFontSizes[smallSize]; // 0-419
    case screenWidth < breakpoints.large:
      return rawFontSizes[midSize]; // 420-1199
    default:
      return rawFontSizes[largeSize]; // 1200+
  }
};

/**
 * `fontSizes` and `theme.fontSizes` should match Aimee/Design's font size naming.
 */
const fontSizes: FontSizes = {
  hero: getResponsiveFontSize('xxlarge', 'xxxlarge', 'xxxxlarge'), // 26, 33, 47
  h1: getResponsiveFontSize('xxlarge', 'xxxlarge'), // 26, 26, 33
  h2: getResponsiveFontSize('xxlarge'), // 26, 26, 26
  h3: getResponsiveFontSize('large', 'xlarge'), // 20, 20, 23
  body1: getResponsiveFontSize('small', 'medium', 'large'), // 16, 18, 20
  body2: getResponsiveFontSize('small', 'medium', 'medium'), // 16, 18, 18
  body3: getResponsiveFontSize('small'), // 16, 16, 16
  small: getResponsiveFontSize('xsmall'), // 14, 14, 14
  xsmall: getResponsiveFontSize('xxsmall'), // 12, 12, 12
  xxsmall: getResponsiveFontSize('xxxsmall'), // 8, 8, 8
};

const border = {
  radiusTen: 10,
  width: 1,
};

const fontSize = fontSizes.body3;

colors.platform.web = colors.platform.web || colors.platform.android;

// Palette maps *literal* color names to values (private).
const palette: Palette = {
  bgBlue1: '#E4EDF3',
  black: '#000000',
  brightGreen: '#1ac600',
  darkBlue: '#3A4750',
  darkGrey: '#50514F',
  darkOrange: '#D44503',
  gold: '#B68949',
  green: '#4A8410',
  lightBlue: '#7B98A3',
  lightGrey: '#e0e0e0',
  lightGreen: '#DBE6CF',
  lightOrange: '#fdefe1',
  lightPink: '#dbafa7',
  lighterPink: '#A5372359',
  mediumGrey: '#8D8E8C',
  mediumBlue: '#5F7881',
  offWhite: '#f4f4f4',
  orange: '#EF7136',
  opacity70: 'B3',
  opacity85: 'D9',
  red: '#A53723',
  sand: '#F7F2EB',
  silver: '#909290',
  white: '#ffffff',
  yellow: '#f7e463',
};

export const shadows: Shadows = {
  small: {
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },

  medium: {
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 2, height: 0 },
    shadowOpacity: 0.8,
    shadowRadius: 8,
  },

  mediumBottomString: '0px 8px 8px rgba(0, 0, 0, 0.25)',
};

const spacing = 16;

/**
 * Define the navigation height as a constant so we can reference
 * this for height and offset calculations throughout the app.
 */
const navigationHeight = spacing * 3.5;

export const zIndexes: ZIndexes = {
  appNavigation: 5,
  base: 1, // this is to mitigate z-index issues as React Native gets translated to React Native Web
  dashboardProgressHelpIcon: 2,
  tooltip: 4000,
  overlay: 10,
  progressNavigation: 4,
  titleBar: 3,
};

export default {
  border,
  breakpoints,
  fontFamily,
  fontFamilyBold,
  fontFamilyMedium,
  fontFamilySignature,
  fontSize,
  fontSizes,
  navigationHeight,
  rawFontSizes,
  shadows,
  spacing,
  zIndexes,

  // Colors maps *semantic* names to literal colors (exported).
  colors: {
    backgroundLightBlue: palette.bgBlue1,
    badge: palette.orange,
    black: palette.black,
    bodyText: palette.darkGrey,
    brightGreen: palette.brightGreen,
    darkBlue: palette.darkBlue,
    darkGrey: palette.darkGrey,
    default: palette.lightGrey,
    defaultDark: palette.lightGrey,
    defaultLight: palette.offWhite,
    dimText: palette.mediumGrey,
    disabledNavBackground: palette.lightPink,
    disabledNavText: palette.lighterPink,
    error: palette.darkOrange, // (status)
    gold: palette.gold,
    lightBlue: palette.lightBlue,
    lightGreen: palette.lightGreen,
    lightGrey: palette.lightGrey,
    lightOrange: palette.lightOrange,
    mediumBlue: palette.mediumBlue,
    mediumGrey: palette.mediumGrey,
    opacity70: palette.opacity70, // Concatenate to hex values for opacity shift
    opacity85: palette.opacity85, // Concatenate to hex values for opacity shift
    placeholderText: palette.mediumGrey,
    primary: palette.red,
    primaryDark: palette.red,
    sand: palette.sand,
    silver: palette.silver,
    success: palette.green, // (status)
    warning: palette.yellow, // (status)
    white: palette.white,
    yellow: palette.yellow,
  },

  Button: {
    buttonStyle: {
      borderRadius: 4,
      padding: spacing * 0.75,
    },
    titleStyle: {
      fontFamily: fontFamilyMedium,
      fontSize,
      lineHeight: fontSize * 1.2,
      textTransform: 'capitalize',
    },
    disabledStyle: {
      backgroundColor: palette.lightPink,
    },
    disabledTitleStyle: {
      color: palette.white,
    },
  },

  Card: {
    containerStyle: {
      borderRadius: 10,
      padding: 0,
      paddingBottom: spacing,
      ...shadows.small,
    },
  },

  CardTitle: {
    style: {
      fontSize,
      fontFamily: fontFamilyBold,
      // https://carrumhealth.atlassian.net/browse/TEC-1581
      // @ts-ignore
      color: palette.bodyText,
      padding: spacing,
      marginBottom: 0,
      textAlign: 'left',
    },
  },

  CheckBox: {
    containerStyle: {
      backgroundColor: 'transparent',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: spacing / 4,
      padding: 0,
    },

    textStyle: {
      flex: 1,
      flexWrap: 'wrap',
      fontWeight: 'normal',
      fontSize: fontSizes.body2,
    },
  },

  Divider: {
    backgroundColor: colors.grey4,
  },

  Icon: {
    color: colors.grey2,
  },

  Input: {
    containerStyle: {
      backgroundColor: colors.white,
      borderRadius: spacing / 4,
      borderWidth: 0,
      paddingBottom: spacing / 2,
      paddingHorizontal: spacing,
      paddingTop: spacing,
    },

    errorStyle: {
      fontFamily,
      fontSize: fontSizes.small,
      marginHorizontal: 0,
      marginBottom: spacing,
      marginTop: 0,
    },

    inputStyle: {
      color: palette.black,
      fontFamily,
      fontSize,
      ...Platform.select({
        web: {
          outline: 'none',
        },
      }),
    },

    inputContainerStyle: {
      borderBottomWidth: 0,
      width: '100%',
    },
  },

  ListItemTitle: {
    style: {
      fontFamily,
      fontSize,
    },
  },

  // overlayStyle is primarily managed from the Common Modal component where responsive styles are applied
  // These are bare minimum style expectations
  Overlay: {
    ...Platform.select({
      default: {
        backdropStyle: {
          position: 'relative',
        },
        overlayStyle: {
          backgroundColor: palette.sand, // Needed in order to show border radius
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: 'absolute',
          bottom: 0,
          width: '100%',
        },
      },
      web: {
        backdropStyle: {
          alignItems: 'center',
          position: 'fixed',
        },
        overlayStyle: {
          backgroundColor: palette.sand, // Needed in order to show border radius
          borderRadius: 8,
          maxWidth: '100%',
        },
        ModalComponent: WebOverlayWrapper,
      },
    }),
  },

  SearchBar: {
    inputContainerStyle: {
      width: 'auto',
    },

    inputStyle: {
      color: palette.black,
    },

    ...Platform.select({
      web: {
        containerStyle: {
          borderBottomWidth: 0,
          borderTopWidth: 0,
          backgroundColor: palette.white,
        },
        inputContainerStyle: {
          backgroundColor: 'transparent',
        },
      },
    }),
  },

  Text: {
    style: {
      // https://carrumhealth.atlassian.net/browse/TEC-1581
      // @ts-ignore
      color: palette.bodyText,
      fontFamily,
      fontSize,
    },
    h1Style: {
      // https://carrumhealth.atlassian.net/browse/TEC-1581
      // @ts-ignore
      color: palette.bodyText,
      fontFamily: fontFamilyMedium,
      fontSize: fontSizes.h1,
      marginVertical: spacing * 2,
      textAlign: 'center',
    },
    h2Style: {
      // https://carrumhealth.atlassian.net/browse/TEC-1581
      // @ts-ignore
      color: palette.bodyText,
      fontFamily: fontFamilyMedium,
      fontSize: fontSizes.h2,
      letterSpacing: 0.25,
      marginBottom: spacing * 2,
    },
    h3Style: {
      // https://carrumhealth.atlassian.net/browse/TEC-1581
      // @ts-ignore
      color: palette.bodyText,
      fontFamily: fontFamilyMedium,
      fontSize: fontSizes.h3,
      paddingLeft: 0,
      paddingRight: 0,
    },
    h4Style: {
      // https://carrumhealth.atlassian.net/browse/TEC-1581
      // @ts-ignore
      color: palette.bodyText,
      fontFamily: fontFamilyBold,
      fontSize: fontSizes.body2,
      letterSpacing: 0.15,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  Tooltip: {
    ...Platform.select({
      web: {
        containerStyle: {
          position: 'fixed',
        },
        ModalComponent: WebTooltipWrapper,
        withOverlay: false,
      },
    }),
  },
};
