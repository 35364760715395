import {
  boolOptions,
  Routes,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import {
  dispatchCreateEscalation,
  getNextProfileRoute,
  getPreviousProfileRoute,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useState,
} from 'app/components/CompleteYourProfile/hooks';

import {
  ButtonList,
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import { AlreadyScheduledProps } from 'app/components/CompleteYourProfile/types';

import { DatePicker, Input, Text, TextArea } from '../styles';

const AlreadyScheduled = ({
  value,
  isFirstStep,
  isLastStep,
  isSaving,
  onChange,
  onNext,
}: AlreadyScheduledProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [physicianName, setPhysicianName] = useState('');
  const [scheduledProcedureDetails, setScheduledProcedureDetails] =
    useState('');
  const [scheduledProcedureDate, setScheduledProcedureDate] = useState('');

  useEffect(() => {
    return () => {
      setPhysicianName('');
      setScheduledProcedureDetails('');
      setScheduledProcedureDate('');
    };
  }, []);

  const createAlreadyScheduledEscalation = useCallback(() => {
    const splitDate = scheduledProcedureDate.split('-');
    const formattedDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;

    dispatchCreateEscalation(
      `Patient has already scheduled surgery with\n-Physician Name: ${physicianName}\n-Date: ${formattedDate}\n-Details: ${
        scheduledProcedureDetails
          ? ` ${scheduledProcedureDetails} `
          : 'not specified'
      }`,
      'surgery_scheduled',
      dispatch
    );
  }, [physicianName, scheduledProcedureDetails, scheduledProcedureDate]);

  return (
    <StepScreen testID={TestID.CompleteYourProfile.AlreadyScheduled}>
      <FormContainer>
        <Header title="Have you already scheduled your procedure?" />
        <ButtonList
          key={TestID.CompleteYourProfile.AlreadyScheduled}
          initialValue={value}
          mode="single"
          onChange={onChange}
          options={boolOptions}
        />
        {value && (
          <>
            <Text>
              Would you please provide us with information about your scheduled
              procedure?
            </Text>
            <Input
              input={{
                name: TestID.CompleteYourProfile.PhysicianName, // passed on to `testID`
                onChange: setPhysicianName,
                value: physicianName,
              }}
              label="Physician Name"
            />
            <DatePicker
              input={{
                name: TestID.CompleteYourProfile.ScheduledProcedureDate,
                onChange: (value) => {
                  if (!value) return;
                  setScheduledProcedureDate(value);
                },
                value: scheduledProcedureDate,
              }}
              label="Prodecure Date"
            />
            <TextArea
              input={{
                name: TestID.CompleteYourProfile.ScheduledProcedureDetails, // passed on to `testID`
                onChange: setScheduledProcedureDetails,
                value: scheduledProcedureDetails,
                placeholder:
                  'Optional: Anything else about the scheduled procedure (eg. hospital, location, time)',
              }}
              label="Procedure Details"
            />
          </>
        )}
      </FormContainer>
      <ProfileCTAs
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        isNextDisabled={
          typeof value === 'undefined' ||
          (value && (!physicianName || !scheduledProcedureDate))
        }
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() => {
            if (typeof value === 'boolean' && value) {
              createAlreadyScheduledEscalation();
            }
            history.push(getNextProfileRoute(Routes.AlreadyScheduled));
          });
        }}
        onPrevious={() =>
          history.push(getPreviousProfileRoute(Routes.AlreadyScheduled))
        }
      />
    </StepScreen>
  );
};

export default AlreadyScheduled;
