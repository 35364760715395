import { Routes, TestID } from './constants';

import {
  AccountSettings,
  MySettingsHome,
  PersonalInformation,
  Route,
  SectionHeader,
  Switch,
} from './styles';

const MySettings = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/${Routes.MySettings}`}
        render={() => <MySettingsHome />}
      />

      <Route
        path={`/${Routes.MySettings}/personal-information`}
        render={() => (
          <PersonalInformation
            headerComponent={
              <SectionHeader
                title="Personal Information"
                showBackLink
                showTitle
              />
            }
            nextRoute={`/${Routes.MySettings}`}
            testIDRoot={TestID.MySettings}
          />
        )}
      />

      <Route
        path={`/${Routes.MySettings}/account-settings`}
        render={() => (
          <AccountSettings
            headerComponent={
              <SectionHeader title="Account Settings" showBackLink showTitle />
            }
            nextRoute={`/${Routes.MySettings}`}
            testIDRoot={TestID.MySettings}
          />
        )}
      />
    </Switch>
  );
};

export default MySettings;
