import { View } from 'react-native';
import { LinearProgress as LinearProgressRaw } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { Text as TextRaw } from 'app/components/Common/Styled';

export const LinearProgress = styled(LinearProgressRaw).attrs(({ theme }) => {
  return {
    color: theme.colors.lightBlue,
    trackColor: theme.colors.white,
    variant: 'determinate',
  };
})``;

export const ProgressText = styled(TextRaw)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyMedium};
  `}
`;

export const ProgressTextWrapper = styled(View)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing}px;
  `}
`;

export const ProgressWrapper = styled(View)`
  ${({ theme }) => css`
    padding: ${theme.spacing}px ${theme.isNarrow ? theme.spacing : 0}px;
  `}
`;
