import styled, { css } from 'styled-components/native';

import { AccessibilityRole } from 'react-native';
import Anchor from 'app/components/Common/Anchor';
import { Button as ButtonRaw } from 'react-native-elements';
import CheckBoxRaw from 'app/components/Common/CheckBox';
import { DotIndicator as DotIndicatorRaw } from 'app/components/Common/svg-components';
import FormError from 'app/components/Common/FormError';
import { Icon as IconRaw } from 'react-native-elements';
import { default as InputRaw } from 'app/components/Common/Input';
import { KeyboardAvoidingView } from 'react-native';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import { Text } from 'app/components/Common/Styled';
import { TouchableOpacity } from 'react-native';
import { View } from 'app/components/Common/Styled';

const StyledLink = styled(Anchor).attrs(({ theme }) => {
  return {
    titleStyle: {
      paddingBottom: theme.spacing * 1.5,
      paddingTop: theme.spacing / 4,
      textAlign: 'center',
    },
  };
})``;

export { ActivityIndicator } from 'react-native';

export { default as Alert } from '@cross-platform/react-native-alert';

export { Anchor };

export { default as Biometrics } from 'app/components/Login/Biometrics';

export const Bold = styled(Text)`
  font-weight: bold;
`;

export const Button = styled(ButtonRaw).attrs(({ theme }) => {
  return {
    buttonStyle: {
      height: 66,
    },
    disabledStyle: {
      backgroundColor: theme.colors.disabledNavBackground,
    },
    disabledTitleStyle: {
      color: theme.colors.white,
    },
    raised: true,
  };
})``;

export const ButtonContainer = styled.View`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2}px;
  `}
`;

export { CenteredScrollScreen } from 'app/components/Common/Layout';

export const CheckBox = styled(CheckBoxRaw).attrs(({ theme }) => ({
  containerStyle: { marginBottom: theme.spacing },
}))``;

export const DotIndicator = styled(DotIndicatorRaw).attrs(({ theme }) => ({
  color: theme.colors.lightBlue,
}))``;

export { default as Email } from 'app/components/Login/Email';

export { default as EnvironmentSwitch } from 'app/components/Common/EnvironmentSwitch';

export { ExpiredAuthLink } from 'app/components/Common/ExpiredAuthLink';

export const FormErrorContainer = styled(FormError)`
  ${({ theme }) => css`
    text-align: center;
    padding: ${`5px 0px ${theme.spacing}px`};
    color: ${theme.colors.error};
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const FormView = styled.View.attrs({
  accessibilityRole: Platform.select({
    web: 'form' as AccessibilityRole,
  }),
})`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

export const Header = styled(Text).attrs({ size: 'h3' })`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
    text-align: center;
  `}
`;

export const HelpText = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyMedium};
    text-align: center;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const Icon = styled(IconRaw).attrs(() => {
  return {
    color: '#afafaf',
  };
})`
  ${({ theme }) => css`
    position: relative;
    bottom: ${theme.spacing / 4}px;
  `}
`;

export const ImageContainer = styled(View)`
  margin: 0 auto;
`;

export const Input = styled(InputRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export { KeyboardAvoidingView };

export { Linking };

export const LogoContainer = styled(View)`
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: center;
    margin-top: 12%;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const LogoContainerOneStep = styled(LogoContainer)`
  margin-bottom: 0px;
`;

export const MagicLinkButton = styled(ButtonRaw).attrs(({ theme }) => {
  return {
    buttonStyle: {
      height: 66,
      marginRight: theme.spacing * 5,
      marginLeft: theme.spacing * 5,
    },
    disabledStyle: {
      backgroundColor: theme.colors.disabledNavBackground,
    },
    disabledTitleStyle: {
      color: theme.colors.primary,
    },
    type: 'outline',
  };
})``;

export { default as MagicLinkConfirmation } from 'app/components/Login/MagicLinkConfirmation';

export const MagicLinkCta = StyledLink;

export const MagicLinkRedirectContainer = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    margin: ${theme.spacing * 4}px auto;
  `}
`;

export { default as MagicLinkRequest } from 'app/components/Login/MagicLinkRequest';

export { MagicLinkSent } from 'app/components/Common/svg-components';

export { MagicLink as MagicLinkSvg } from 'app/components/Common/svg-components';

export const MagicLinkView = styled(View)`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

export const Message = styled(Text).attrs({ color: 'error' })`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacing * 5}px;
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

export const ParagraphText = styled(Text)`
  ${({ theme }) => css`
    margin: ${theme.spacing}px 0;
    text-align: center;
  `}
`;

export { default as Password } from 'app/components/Login/Password';

export { Redirect } from '@cross-platform/react-router-native';

export { RedVerticalLogo } from 'app/components/Common/svg-components';

export const ResetPasswordLink = StyledLink;

export { Route } from '@cross-platform/react-router-native';

export { Routes } from 'app/util/routes';

export { Switch } from '@cross-platform/react-router-native';

export { Text };

export const TitleText = styled(Text).attrs(({ theme }) => {
  return {
    h1: true,
    h1Style: {
      color: theme.colors.bodyText,
      marginTop: theme.spacing * 4,
      textAlign: 'center',
    },
  };
})``;

export const TitleTextOneStep = styled(Text).attrs(({ theme }) => {
  return {
    h1: true,
    h1Style: {
      color: theme.colors.bodyText,
      textAlign: 'center',
    },
  };
})``;

export const TogglePasswordButton = styled(TouchableOpacity)`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spacing / 2}px;
    top: ${theme.spacing / 2}px;
  `}
`;

export { TopBackLink } from 'app/components/Common/TopBackLink';

export const TopBackLinkWrapper = styled(View)`
  ${({ theme }) => css`
    position: absolute;
    padding: ${theme.spacing}px;
  `}
`;

export { View };
