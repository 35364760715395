import React, { useEffect, useState } from 'react';

//@ts-ignore
import { useHistory } from '@cross-platform/react-router-native';
import { Text } from 'react-native-elements';

import { useSession } from 'app/hooks';
import { toAddressString } from 'app/util/methods';
import { Routes } from 'app/util/routes';

import DoctorsList from './DoctorsList';
import HospitalsList from './HospitalsList';

import {
  ButtonGroup,
  OptionsWrapper,
  PaddingWrapper,
  SectionWrapper,
} from './styles';
import { TestID } from 'app/util/test-id';

const OptionsSection = ({ type = 'facilities' }) => {
  const { push } = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(
    type === 'facilities' ? 0 : 1
  );

  const {
    user: {
      location: { address },
    },
  } = useSession();

  useEffect(() => {
    const slug = selectedIndex === 1 ? 'doctors' : 'facilities';
    push(`/${Routes.SelectYourDoctor}/${slug}`);
  }, [selectedIndex]);

  return (
    <SectionWrapper>
      <PaddingWrapper>
        <Text>{address ? toAddressString(address) : 'No address'}</Text>

        <ButtonGroup
          buttons={['Facilities', 'Doctors']}
          onPress={setSelectedIndex}
          selectedIndex={selectedIndex}
          testID={TestID.SelectYourDoctor.ButtonGroup}
        />

        <Text>Sorted by Distance</Text>

        <OptionsWrapper>
          {type === 'facilities' ? <HospitalsList /> : <DoctorsList />}
        </OptionsWrapper>
      </PaddingWrapper>
    </SectionWrapper>
  );
};

export default OptionsSection;
