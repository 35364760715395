import {
  Routes,
  stopScreenPageValues,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import { useHistory } from 'app/components/CompleteYourProfile/hooks';

import { StopScreenPageProps } from 'app/components/CompleteYourProfile/types';

import {
  BodyText,
  ChairAndPot,
  ContentWrapper,
  FooterText,
  FooterWrapper,
  GraphicWrapper,
  InnerPageWrapper,
  CtaButton,
  PageWrapper,
  TitleText,
} from './styles';

export const StopScreenPage = ({
  body = stopScreenPageValues.default.body,
  ctaTitle = stopScreenPageValues.default.ctaTitle,
  title = stopScreenPageValues.default.title,
  footer = stopScreenPageValues.default.footer,
  ...props
}: StopScreenPageProps) => {
  const history = useHistory();
  const openMessages = () => {
    history.push(`/${Routes.Messages}`);
  };
  const onPress = props?.onPress || openMessages;

  return (
    <PageWrapper testID={TestID.CompleteYourProfile.StopScreen}>
      <InnerPageWrapper>
        <ContentWrapper>
          <TitleText>{title}</TitleText>
          <BodyText>{body}</BodyText>
        </ContentWrapper>
        <CtaButton onPress={onPress || openMessages} title={ctaTitle} />
        {footer && (
          <FooterWrapper>
            <FooterText>{footer}</FooterText>
          </FooterWrapper>
        )}
      </InnerPageWrapper>
      <GraphicWrapper>
        <ChairAndPot />
      </GraphicWrapper>
    </PageWrapper>
  );
};

export default StopScreenPage;
