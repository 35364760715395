import React from 'react';

import EnvironmentSwitch from 'app/components/Common/EnvironmentSwitch';
import { showDevTools } from 'app/util/devUtils';
import { TestID } from 'app/util/test-id';

import { NavigationLinkPressable, NavigationLinkText } from './styles';
import { nonProdEnvironments, nativePlatforms } from '../../constants';

/**
 * Only render a "Developer Tools" link on native devices in non-prod
 * environments.
 */
const DevToolsLink = () => {
  return (
    <EnvironmentSwitch
      environments={nonProdEnvironments}
      platforms={nativePlatforms}
    >
      <NavigationLinkPressable
        testID={TestID.Navigation.DevToolsLink}
        onPress={showDevTools}
      >
        <NavigationLinkText>Developer Tools</NavigationLinkText>
      </NavigationLinkPressable>
    </EnvironmentSwitch>
  );
};

export default DevToolsLink;
