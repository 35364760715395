export default `
<div>
<h3>Let&apos;s Go!</h3>
<p></p>
<p>
In the next step, we&apos;ll connect you with your personally assigned Patient
&nbsp;Care Specialist.
&nbsp;We&apos;ll also start collecting more information about your medical
&nbsp;situation so we can help guide you through the entire process.
</p>
<p>
If you are unsure or change your mind, you will be able to send us a message so
&nbsp;we can help update your selection.
</p>
</div>
`;
