import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import Anchor from 'app/components/Common/Anchor';
import InputRaw from 'app/components/Common/Input';
import SelectRaw from 'app/components/Common/Select';
import { Text, View } from 'app/components/Common/Styled';

export { default as CheckBox } from 'app/components/Common/CheckBox';
export { Text, View } from 'app/components/Common/Styled';

export const CheckboxWrapper = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2}px;
    ${Platform.OS === 'android' &&
    css`
      padding-bottom: ${theme.spacing * 4}px;
    `}
  `}
`;

export const Input = styled(InputRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export const IncorrectInfoWrapper = styled(View)`
  margin-top: ${({ theme }) => theme.spacing}px;
`;

export const InputsWrapper = styled(View)`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
`;

export const InsuranceProviderText = styled(Text).attrs({
  size: 'body1',
})``;

export const Select = styled(SelectRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export const SendMessageLink = styled(Anchor).attrs(({ theme }) => {
  return {
    titleStyle: { fontSize: theme.fontSizes.body1 },
  };
})``;
