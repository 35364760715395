import React from 'react';

import Modal from 'app/components/Common/Modal';
import { Text } from 'app/components/Common/Styled';

const SurgeonConfirmationModal = ({ onClose, onConfirm, ...modalProps }) => {
  return (
    <Modal
      title="Confirm your selection"
      onClose={onClose}
      options={[
        {
          onPress: onClose,
          title: 'Cancel',
          type: 'clear',
        },
        {
          onPress: onConfirm,
          title: 'Confirm',
          type: 'solid',
        },
      ]}
      {...modalProps}
    >
      <Text size="body2">
        Selecting your doctor is a big step forward. If you change your mind,
        send us a message so that we can help update your selection
      </Text>
    </Modal>
  );
};

export default SurgeonConfirmationModal;
