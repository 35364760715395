import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Animated, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';

import { Icon } from 'react-native-elements';
//@ts-ignore
import { useHistory } from '@cross-platform/react-router-native';

import { receiveSetCallUsModalVisibility } from 'app/actions/uiActions';
import { useEpisode, usePlatform } from 'app/hooks';
import IconLink from 'app/components/Common/IconLink';
import { Routes } from 'app/util/routes';
import { routeToFAQ } from 'app/util/routeUtils';
import theme from 'app/util/theme';
import { IconType } from 'types/icon';

import ProgressSection from './ProgressSection';
import {
  BottomSection,
  MainContentsWrapper,
  MenuToggleButton,
  NavWrapper,
  PositionRelativeWrapper,
  TopSpacer,
} from './styles';

const { darkBlue, white } = theme.colors;
export interface ProgressNavProps {
  children: ReactElement;
  open?: boolean;
}

const animConfig = {
  duration: 300,
  useNativeDriver: false, // native driver doesn't support animating width or left position
};

const navPanelWidth = 400;
const navButtonHeight = 120;
const navButtonWidth = 20;
const navButtonOpenPosition = 0;
const navButtonClosedPosition = navButtonWidth;
const navPanelOpenPosition = 0;
const navPanelClosedPosition = -navPanelWidth;
const borderRadiusRounded = 15;
const borderRadiusSquared = 0;

const ProgressNav = ({ children, open = false }: ProgressNavProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(open);
  const { episode } = useEpisode();
  const { isPlatformTwo } = usePlatform();
  const history = useHistory();
  const { width } = useWindowDimensions();

  /**
   * navAnimation controls all animation related to the ProgressNav opening and closing:
   *  - Nav sliding on/off the screen
   *  - Left boundary of the children (aka MainContents)
   *  - MenuToggleButton flipping left/right (in/out) of the ProgressNav and its background and icon colors
   *
   * The 0 / 1 input range is interpolated as open / closed values
   */
  const navAnim = useRef(new Animated.Value(isOpen ? 0 : 1)).current;

  const navPanelPositionInterp = navAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [navPanelOpenPosition, navPanelClosedPosition],
  });

  const mainContentsGrowInterp = navAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [navPanelWidth, 0],
  });

  const navButtonPositionInterp = navAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [navButtonOpenPosition, navButtonClosedPosition],
  });

  const navButtonBackgroundInterp = navAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [darkBlue, 'transparent'],
  });

  const navButtonLeftBorderRadiusInterp = navAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [borderRadiusRounded, borderRadiusSquared],
  });

  const navButtonRightBorderRadiusInterp = navAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [borderRadiusSquared, borderRadiusRounded],
  });

  useEffect(() => {
    Animated.timing(navAnim, {
      ...animConfig,
      toValue: isOpen ? 0 : 1,
    }).start();
  }, [isOpen]);

  if (width < theme.breakpoints.medium) return children;
  if (!isPlatformTwo) return children;
  if (!episode) return children;

  return (
    <PositionRelativeWrapper>
      <NavWrapper
        width={navPanelWidth}
        style={{
          transform: [{ translateX: navPanelPositionInterp }],
        }}
      >
        {/* TopSpacer balances positioning of <BottomSection /> */}
        <TopSpacer />

        <ProgressSection />

        <BottomSection>
          <IconLink
            title="About Us"
            iconName="info"
            iconType={IconType.Material}
            onPress={routeToFAQ}
          />
          <IconLink
            title="Message Us"
            iconName="commenting"
            iconType={IconType.FontAwesome}
            onPress={() => {
              history.push(`/${Routes.Messages}`);
            }}
          />
          <IconLink
            title="Talk to Us"
            iconName="phone"
            iconType={IconType.Material}
            onPress={() => {
              dispatch(receiveSetCallUsModalVisibility(true));
            }}
          />
        </BottomSection>

        {/* TODO:
        - might be something to do with TouchableOpacity
        - check into onLongPress noOp
        - Race condition with animation?
        - Is there some other state variable that is behind (when pressing)?
        */}
        {/* MenuToggleButton Can be placed anywhere directly within NavWrapper */}
        <MenuToggleButton
          onLongPress={() => {}}
          onPressIn={() => {}}
          onPressOut={() => {}}
          onPress={() => setIsOpen(!isOpen)}
          style={{
            backgroundColor: navButtonBackgroundInterp,
            borderBottomLeftRadius: navButtonLeftBorderRadiusInterp,
            borderBottomRightRadius: navButtonRightBorderRadiusInterp,
            borderTopLeftRadius: navButtonLeftBorderRadiusInterp,
            borderTopRightRadius: navButtonRightBorderRadiusInterp,
            transform: [
              { translateX: navButtonPositionInterp },
              { translateY: navButtonHeight / -2 },
            ],
          }}
          width={navButtonWidth}
        >
          <Icon
            color={isOpen ? white : darkBlue}
            name={isOpen ? 'arrow-left' : 'arrow-right'}
            size={20}
          />
        </MenuToggleButton>
      </NavWrapper>

      <MainContentsWrapper
        style={{
          left: mainContentsGrowInterp,
        }}
      >
        {children}
      </MainContentsWrapper>
    </PositionRelativeWrapper>
  );
};

export default ProgressNav;
