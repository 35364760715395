import {
  planTypeOptions,
  Routes,
  stopScreenPageValues,
  TestID,
  theme,
} from 'app/components/CompleteYourProfile/constants';

import {
  dispatchCreateEscalation,
  getPreviousProfileRoute,
  getUserInsurance,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useCallback,
  useDispatch,
  useEpisode,
  useEffect,
  useHistory,
  useNextPageForProfileCompletion,
  useSelector,
  useState,
} from 'app/components/CompleteYourProfile/hooks';

import {
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import {
  InsuranceInfoPageProps,
  State,
} from 'app/components/CompleteYourProfile/types';

import {
  CheckBox,
  CheckboxWrapper,
  IncorrectInfoWrapper,
  Input,
  InputsWrapper,
  InsuranceProviderText,
  Select,
  SendMessageLink,
  Text,
  View,
} from './styles';

export const InsuranceInfoPage = ({
  isSaving = false,
  onChange,
  onGoToStopScreen,
  onNext,
  values,
}: InsuranceInfoPageProps) => {
  const dispatch = useDispatch();
  const goToNextPage = useNextPageForProfileCompletion({
    isWelcomePage: false,
    nextPageKey: Routes.SaveProgress,
  });
  const history = useHistory();
  const { procedure } = useEpisode();

  const insurance = useSelector((state: State.RootState) =>
    getUserInsurance(state.session)
  );

  const membership = { ...values?.insurance?.membership };
  const [memberId, setMemberId] = useState(
    membership?.memberId || insurance?.memberId || ''
  );
  const [groupNumber, setGroupNumber] = useState(
    membership?.groupNumber || insurance?.groupNumber || ''
  );
  const [planType, setPlanType] = useState(
    membership?.planType || insurance?.planType || ''
  );
  const [insuranceType, setInsuranceType] = useState(
    membership?.insuranceType || ''
  );
  const [medicareOrMedicaid, setMedicareOrMedicaid] = useState(
    values?.insurance?.membership?.medicareOrMedicaid || false
  );

  /** Update parent state */
  useEffect(() => {
    onChange({
      membership: {
        ...membership,
        groupNumber,
        insuranceType,
        medicareOrMedicaid,
        memberId,
        planType,
      },
    });
  }, [memberId, groupNumber, planType, insuranceType, medicareOrMedicaid]);

  const createMedicareMedicaidEscalation = () => {
    dispatchCreateEscalation(
      'Patient is covered by Medicare or Medicaid.',
      'coverage_is_medicare_medicaid',
      dispatch
    );
  };

  const createOncologyEscalation = () => {
    dispatchCreateEscalation(
      'This patient has selected an oncology episode.',
      'oncology_episode',
      dispatch
    );
  };

  /**
   * Navigate to the correct next page based on the current profile and episode.
   *
   * 1. If the user indicates that their plan is a Medicare plan, present a stop screen
   * 2. If the user is creating an oncology episode, present a stop screen
   * 3. Otherwise, present the next profile creation screen based on task completion
   */
  const onPressNext = useCallback(async () => {
    await onNext();

    if (medicareOrMedicaid) {
      createMedicareMedicaidEscalation();
      onGoToStopScreen();
    } else if (procedure?.serviceType === 'oncology') {
      createOncologyEscalation();
      onGoToStopScreen({ ...stopScreenPageValues.oncology });
    } else {
      goToNextPage();
    }
  }, [medicareOrMedicaid, procedure?.serviceType, onNext]);

  // Navigate to the previous page.
  const onPressPrevious = () => {
    history.push(getPreviousProfileRoute(Routes.InsuranceInfo));
  };

  return (
    <StepScreen>
      <FormContainer>
        <Header title="Please confirm and complete the following information:" />
        <View testID={TestID.CompleteYourProfile.Insurance}>
          <InsuranceProviderText>
            Provider: {insurance?.name}
          </InsuranceProviderText>
          <InsuranceProviderText>
            Plan Name: {insurance?.planName}
          </InsuranceProviderText>
          <InsuranceProviderText>
            HSA Eligible? {insurance?.hsaFlag ? 'Yes' : 'No'}
          </InsuranceProviderText>
        </View>

        <IncorrectInfoWrapper>
          <Text size="body1">
            <SendMessageLink
              title="Send a message"
              onPress={() => history.push(`/${Routes.Messages}`)}
            />{' '}
            if the information above is incorrect.
          </Text>
        </IncorrectInfoWrapper>

        <InputsWrapper>
          <Input
            input={{
              name: TestID.CompleteYourProfile.MemberID, // passed on to `testID`
              onChange: setMemberId,
              value: memberId,
            }}
            label="Member ID"
            returnKeyType="next"
          />

          <Input
            input={{
              name: TestID.CompleteYourProfile.GroupNumber, // passed on to `testID`
              onChange: setGroupNumber,
              value: groupNumber,
            }}
            label="Group #"
            returnKeyType="next"
          />

          <Select
            input={{
              name: TestID.CompleteYourProfile.PlanType, // passed on to `testID`
              value: planType,
              onChange: setPlanType,
            }}
            label="Plan Type"
            options={planTypeOptions}
            theme={theme}
            unSelectedOption
          />

          <Select
            input={{
              name: TestID.CompleteYourProfile.PrimaryOrSecondary, // passed on to `testID`
              value: insuranceType,
              onChange: setInsuranceType,
            }}
            label="Is your employer-based health insurance primary or secondary for you?"
            options={[
              {
                label: 'Primary',
                value: 'Primary',
              },
              {
                label: 'Secondary',
                value: 'Secondary',
              },
            ]}
            theme={theme}
            unSelectedOption
          />

          <CheckboxWrapper>
            <CheckBox
              input={{
                name: TestID.CompleteYourProfile.MedicareOrMedicaid, // passed on to `testID`
                onChange: setMedicareOrMedicaid,
                value: medicareOrMedicaid,
              }}
              label="My healthcare coverage is through Medicare/Medicaid"
            />
          </CheckboxWrapper>
        </InputsWrapper>
      </FormContainer>
      <ProfileCTAs
        isNextDisabled={
          !memberId || !groupNumber || !planType || !insuranceType
        }
        isSaving={isSaving}
        onNext={onPressNext}
        onPrevious={onPressPrevious}
      />
    </StepScreen>
  );
};

export default InsuranceInfoPage;
