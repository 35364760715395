import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Text, withTheme } from 'react-native-elements';

import PropTypes from 'prop-types';

import theme, { fontFamilyBold, shadows } from 'app/util/theme';

/**
 * Renders a subnavigation with steps and links to
 * show the progress in creating an episode of care.
 *
 * @example
 *  <Stepper
 *    step={1}
 *    steps={['First Step', 'Second Step', 'Third Step']}
 *  />
 */
export const Stepper = ({ bordered, containerStyle, step, steps, theme }) => (
  <View
    style={StyleSheet.flatten([
      styles.container,
      bordered ? styles.containerBordered : {},
      containerStyle,
    ])}
  >
    {steps.map((label, i) => (
      <Fragment key={i}>
        <View style={styles.stepContainer}>
          {step > i + 1 ? (
            <Icon
              size={12}
              name="done"
              containerStyle={[
                styles.step,
                {
                  backgroundColor:
                    step === i + 1 ? theme.colors.primary : theme.colors.white,
                  borderColor:
                    step === i + 1
                      ? theme.colors.primary
                      : theme.colors.dimText,
                },
              ]}
              iconStyle={{ color: theme.colors.dimText }}
            />
          ) : (
            <View
              style={[
                styles.step,
                {
                  backgroundColor:
                    step === i + 1 ? theme.colors.primary : theme.colors.white,
                  borderColor:
                    step === i + 1
                      ? theme.colors.primary
                      : theme.colors.dimText,
                },
              ]}
            >
              <Text
                style={[
                  styles.stepText,
                  {
                    color:
                      step === i + 1
                        ? theme.colors.white
                        : theme.colors.dimText,
                  },
                ]}
              >
                {i + 1}
              </Text>
            </View>
          )}

          {label ? (
            <Text
              style={[
                styles.label,
                {
                  color:
                    step === i + 1
                      ? theme.colors.primary
                      : theme.colors.dimText,
                },
              ]}
            >
              {label}
            </Text>
          ) : null}
        </View>
      </Fragment>
    ))}
  </View>
);

/**
 * @property {number} step The current step.
 * @property {array} steps The list of steps to display.
 * @property {objet} theme A Material UI theme object.
 * @property {boolean} bordered If true, renders a border around the container (defaults to false).
 * @property {object|number} bordered If true, renders a border around the container (defaults to false).
 */
Stepper.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,

  bordered: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

Stepper.defaultProps = {
  bordered: false,
  containerStyle: {},
};

export default withTheme(Stepper);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: theme.spacing * 1.25,
    paddingVertical: theme.spacing * 1.5,
    width: '100%',
  },

  containerBordered: {
    ...shadows.small,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    paddingVertical: theme.spacing * 1.25,
  },

  stepContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  step: {
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.dimText,
    borderRadius: 10,
    height: 20,
    justifyContent: 'center',
    overflow: 'hidden',
    width: 20,
  },

  stepText: {
    color: theme.colors.white,
    fontFamily: fontFamilyBold,
    fontSize: theme.fontSizes.small,
    textAlign: 'center',
    // Offset the border
    bottom: 1,
    position: 'relative',
  },

  label: {
    color: theme.colors.primary,
    fontSize: theme.fontSizes.small,
    marginLeft: theme.spacing / 4,
    marginRight: theme.spacing,
  },
});
