import React, { useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@cross-platform/react-router-native';

import { getUserPhoneNumber } from 'app/selectors/session';
import { ModalOption } from 'app/components/Common/Modal';
import { createEscalation } from 'app/actions/escalationActions';
import { callCarrum } from 'app/util/call';
import { REQUEST_RECEIVED_TEXT } from 'app/util/constants';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import {
  Anchor,
  CallYouText,
  ConfirmationBody,
  ConfirmationHeader,
  DetailsText,
  DetailsTextBold,
  Divider,
  GreenCheckMark,
  Header,
  Modal,
  SubText,
  Text,
} from './styles';

import PhoneInput from 'app/components/Common/PhoneInput';
import TextArea from 'app/components/Common/TextArea';

enum ModalPage {
  CallUsPage = 'call-us-page',
  RequestReceivedPage = 'request-received-page',
}

interface MatchParams {
  carrumId?: string;
}

interface Props {
  onClose: () => void;
  visible: boolean;
}

const GetHelpModal = ({ visible, ...props }: Props) => {
  const dispatch = useDispatch();
  const customPhoneNumber = useState('')[0];
  const [scheduledTime, setScheduledTime] = useState('');
  const [currentModalPage, setCurrentModalPage] = useState(
    ModalPage.CallUsPage
  );
  const [isCreatingEscalation, setIsCreatingEscalation] = useState(false);

  const carrumId = useParams<MatchParams>().carrumId;

  const userPhoneNumber = useSelector((state: State.RootState) =>
    getUserPhoneNumber(state.session)
  );

  const [phoneNumber, setPhoneNumber] = useState<string>(
    customPhoneNumber || userPhoneNumber
  );

  const onClose = () => {
    setCurrentModalPage(ModalPage.CallUsPage);
    props.onClose();
  };

  const sendEscalation = async () => {
    setIsCreatingEscalation(true);

    const success = dispatch(
      // Raise escalation with one of two types, depending on whether the carrumId was
      // available as a path fragment from the url: if it was available, pass that
      // along as the sole property of the eligible patient; otherwise, pass an empty
      // object and the patient will be pulled from the store.
      createEscalation({
        escalationType: !carrumId
          ? 'call_me_provider_search'
          : 'phone_outreach',
        exceptionStatus: 'not_applicable',
        exceptionType: null,
        description: `Patient requested call back\n- number: ${phoneNumber}\n- time: ${
          scheduledTime ? scheduledTime : 'not specified'
        }`,
        eligiblePatient: !carrumId ? {} : { carrumId },
      })
    );
    setIsCreatingEscalation(false);

    if (success) {
      setCurrentModalPage(ModalPage.RequestReceivedPage);
    }
  };

  let modalOptions: ModalOption[] = [];
  switch (currentModalPage) {
    case ModalPage.CallUsPage:
      modalOptions = [
        {
          isLoading: isCreatingEscalation,
          keepCasing: true,
          onPress: sendEscalation,
          testID: TestID.GetHelpModal.OkButton,
          title: 'OK',
          type: 'solid',
        },
      ];

      break;

    case ModalPage.RequestReceivedPage:
      modalOptions = [
        {
          onPress: onClose,
          testID: TestID.GetHelpModal.OkCloseButton,
          title: 'OK',
          type: 'solid',
        },
      ];

      break;
  }

  const renderModalContent = () => {
    switch (currentModalPage) {
      case ModalPage.CallUsPage:
        return (
          <>
            <Header>
              Call{' '}
              {Platform.OS === 'web' ? (
                <Header>1-888-855-7806</Header>
              ) : (
                <Anchor
                  onPress={(e) => callCarrum(e)}
                  testID={TestID.GetHelpModal.CarrumPhoneLink}
                  title="1-888-855-7806"
                />
              )}
            </Header>
            <DetailsText>
              Monday - Friday | 6am - 5pm PST, except major holidays
            </DetailsText>
            <DetailsText>
              For medical emergencies,{' '}
              <DetailsTextBold>please call 911.</DetailsTextBold>
            </DetailsText>
            <Divider />

            <CallYouText>or We can call you</CallYouText>
            <Text>Which number should we call?</Text>
            <PhoneInput
              input={{
                onChange: setPhoneNumber,
                value: phoneNumber?.toString() || '',
                name: TestID.GetHelpModal.PhoneInput,
              }}
              keyboardType="number-pad"
              returnKeyType="done"
            />

            <Text>When should we call?</Text>
            <SubText>
              We will try to call you at the requested time in the next 24
              hours.
            </SubText>
            <TextArea
              input={{
                onChange: setScheduledTime,
                value: scheduledTime,
                name: TestID.GetHelpModal.TextArea,
              }}
            />
          </>
        );

      default:
        return (
          <>
            <GreenCheckMark />
            <ConfirmationHeader>{REQUEST_RECEIVED_TEXT}</ConfirmationHeader>
            <ConfirmationBody>
              We'll call you within 1 business day. We respond Monday-Friday
              between 6am-5pm PST, except major holidays.
            </ConfirmationBody>
          </>
        );
    }
  };

  return (
    <Modal
      isVisible={visible}
      onClose={onClose}
      options={modalOptions}
      size="small"
    >
      {renderModalContent()}
    </Modal>
  );
};

export default GetHelpModal;
