export enum Routes {
  Address = 'address',
  AlreadyScheduled = 'already-scheduled',
  CallUs = 'call-us',
  CarrumID = 'carrum-id',
  ChronicPainCheck = 'chronic-pain-check',
  Complete = 'complete',
  Confirmation = 'confirmation',
  Dashboard = 'dashboard',
  EvaluateYourCase = 'evaluate-your-case',
  Forms = 'forms',
  GetSurgery = 'get-surgery',
  Home = '/',
  InsuranceInfo = 'insurance-info',
  InvalidActivation = 'invalid-activation',
  LearnMore = 'learn-more',
  Location = 'location',
  Login = 'login',
  Messages = 'messages',
  MySettings = 'my-settings',
  NotAvailable = 'not-available',
  Oncology = 'oncology',
  OtherProcedure = 'other-procedure',
  Overview = 'overview',
  PasswordReset = 'password-reset',
  PatientCheck = 'patient-check',
  ProcedureConfirmation = 'procedures/:id/confirmation',
  Procedures = 'procedures',
  Profile = 'profile',
  ProfileCreate = 'complete-your-profile',
  Recommendation = 'recommendation',
  Recover = 'recover',
  Register = 'register',
  SaveProgress = 'save-progress',
  Search = 'search',
  SelectSurgeon = 'select-surgeon',
  SelectYourDoctor = 'select-your-doctor',
  SmokingStatus = 'smoking-status',
  TellUsMore = 'tell-us-more',
  Travel = 'travel',
}

// NOTE: the order is significant
export const profileStepRoutes = [
  Routes.PatientCheck,
  Routes.Recommendation,
  Routes.AlreadyScheduled,
  Routes.TellUsMore,
  Routes.SmokingStatus,
  Routes.ChronicPainCheck,
  Routes.Address,
  Routes.Oncology,
  Routes.InsuranceInfo,
];

type ProtectedRoutes = {
  [key in Routes]: boolean;
};

export const protectedRoutes: ProtectedRoutes = {
  [Routes.CarrumID]: true,
  [Routes.Complete]: true,
  [Routes.ProfileCreate]: true,
  [Routes.Confirmation]: true,
  [Routes.Dashboard]: true,
  [Routes.EvaluateYourCase]: true,
  [Routes.Forms]: true,
  [Routes.GetSurgery]: true,
  [Routes.CallUs]: false,
  [Routes.Home]: false,
  [Routes.InvalidActivation]: false,
  [Routes.LearnMore]: false,
  [Routes.Location]: true,
  [Routes.Login]: false,
  [Routes.Messages]: true,
  [Routes.MySettings]: true,
  [Routes.NotAvailable]: false,
  [Routes.OtherProcedure]: true,
  [Routes.Overview]: true,
  [Routes.PasswordReset]: false,
  [Routes.ProcedureConfirmation]: true,
  [Routes.Procedures]: true,
  [Routes.Profile]: true,
  [Routes.Recover]: true,
  [Routes.Register]: false,
  [Routes.Search]: true,
  [Routes.SelectSurgeon]: true,
  [Routes.SelectYourDoctor]: true,
  [Routes.Travel]: true,
  [Routes.PatientCheck]: true,
  [Routes.Recommendation]: true,
  [Routes.AlreadyScheduled]: true,
  [Routes.TellUsMore]: true,
  [Routes.Address]: true,
  [Routes.InsuranceInfo]: true,
  [Routes.SmokingStatus]: true,
  [Routes.ChronicPainCheck]: true,
  [Routes.Oncology]: true,
  [Routes.SaveProgress]: true,
};
