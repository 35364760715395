import { TouchableOpacity } from 'react-native';

import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export { default as ProfileAvatar } from '../ProfileAvatar';

const OneLineOfText = styled(Text).attrs({
  numberOfLines: 1,
})`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

export const ProfileEmail = styled(OneLineOfText)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small}px;
  `}
`;

export const ProfileIdentity = styled(View)`
  ${({ theme }) => css`
    flex: 1;
    padding-left: ${theme.spacing * 0.75}px;
  `}
`;

export const ProfileInfoWrapper = styled(TouchableOpacity)`
  ${({ theme }) => css`
    align-items: center;
    border-bottom-color: ${theme.colors.lightBlue};
    border-bottom-width: 1px;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: ${theme.spacing * 0.5}px;
    padding-bottom: ${theme.spacing * 1.25}px;
    padding-left: ${theme.spacing * 0.5}px;
    padding-right: ${theme.spacing * 0.5}px;
    padding-top: ${theme.spacing * 0.5}px;
  `}
`;

export const ProfileName = styled(OneLineOfText)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.body2}px;
  `}
`;
