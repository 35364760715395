import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Card, Text } from 'react-native-elements';

import AnchorWithIcon from 'app/components/Common/AnchorWithIcon';
import MemberIDForm from 'app/components/Common/MemberIDForm';
import theme from 'app/util/theme';

const MemberIDCard = ({ disabled, error, onReset, onSubmit }) => (
  <Card>
    <Card.Title>Out-of-Pocket Estimate</Card.Title>
    <Card.Divider />

    <View style={styles.container}>
      {error ? (
        <Fragment>
          <Text style={styles.message}>
            There was a problem calculating your out-of-pocket estimate. Double
            check the member ID for your insurance and try again.
          </Text>

          <AnchorWithIcon
            raised
            icon="refresh"
            title="Recalculate My Savings"
            onPress={onReset}
            containerStyle={styles.button}
            titleStyle={styles.center}
          />
        </Fragment>
      ) : (
        <MemberIDForm disabled={disabled} onSubmit={onSubmit} />
      )}
    </View>
  </Card>
);

MemberIDCard.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
};

MemberIDCard.defaultProps = {
  disabled: false,
  error: false,
};

export default MemberIDCard;

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing * 1.25,
    paddingHorizontal: theme.spacing,
  },

  message: {
    color: theme.colors.primary,
    marginBottom: theme.spacing * 1.25,
    textAlign: 'center',
  },

  button: {
    justifyContent: 'center',
  },
});
