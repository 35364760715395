import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@cross-platform/react-router-native';

import { getUserPhoneNumber } from 'app/selectors/session';
import { ModalOption } from 'app/components/Common/Modal';
import { createEscalation } from 'app/actions/escalationActions';
import { REQUEST_RECEIVED_TEXT } from 'app/util/constants';
import { State } from 'types/state';

import {
  ConfirmationBody,
  ConfirmationHeader,
  Divider,
  GreenCheckMark,
  Modal,
  SubText,
  Text,
} from './styles';

import PhoneInput from 'app/components/Common/PhoneInput';
import TextArea from 'app/components/Common/TextArea';

enum ModalPage {
  ContactMePage = 'contact-me-page',
  RequestReceivedPage = 'request-received-page',
}

interface MatchParams {
  carrumId?: string;
}

interface Props {
  onClose: () => void;
  visible: boolean;
}

const ContactMeModal = ({ visible, ...props }: Props) => {
  const dispatch = useDispatch();
  const [customPhoneNumber, setCustomPhoneNumber] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [currentModalPage, setCurrentModalPage] = useState(
    ModalPage.ContactMePage
  );
  const [isCreatingEscalation, setIsCreatingEscalation] = useState(false);

  const carrumId = useParams<MatchParams>().carrumId;

  const userPhoneNumber = useSelector((state: State.RootState) =>
    getUserPhoneNumber(state.session)
  );

  const [phoneNumber, setPhoneNumber] = useState<string>(
    customPhoneNumber || userPhoneNumber
  );

  const onClose = () => {
    setCurrentModalPage(ModalPage.ContactMePage);
    props.onClose();
  };

  const sendEscalation = async () => {
    setIsCreatingEscalation(true);

    const success = dispatch(
      // Raise escalation with one of two types, depending on whether the carrumId was
      // available as a path fragment from the url: if it was available, pass that
      // along as the sole property of the eligible patient; otherwise, pass an empty
      // object and the patient will be pulled from the store.
      createEscalation({
        escalationType: 'call_me_carrum_local',
        exceptionStatus: 'not_applicable',
        exceptionType: null,
        description: `Patient requested call back\n- number: ${phoneNumber}\n- time: ${
          scheduledTime ? scheduledTime : 'not specified'
        }`,
        eligiblePatient: !carrumId ? {} : { carrumId },
      })
    );
    setIsCreatingEscalation(false);

    if (success) {
      setCurrentModalPage(ModalPage.RequestReceivedPage);
    }
  };

  let modalOptions: ModalOption[] = [];
  switch (currentModalPage) {
    case ModalPage.ContactMePage:
      modalOptions = [
        {
          isLoading: isCreatingEscalation,
          keepCasing: true,
          onPress: sendEscalation,
          title: 'OK',
          type: 'solid',
        },
      ];

      break;

    case ModalPage.RequestReceivedPage:
      modalOptions = [
        {
          onPress: onClose,
          title: 'OK',
          type: 'solid',
        },
      ];

      break;
  }

  const renderModalContent = () => {
    switch (currentModalPage) {
      case ModalPage.ContactMePage:
        return (
          <>
            <GreenCheckMark />
            <ConfirmationHeader>Request received</ConfirmationHeader>
            <Divider />
            <Text>Which number should we call?</Text>
            <PhoneInput
              input={{
                onChange: setPhoneNumber,
                value: phoneNumber?.toString() || '',
              }}
              keyboardType="number-pad"
              returnKeyType="done"
            />

            <Text>When should we call?</Text>
            <SubText>
              We will try to call you at the requested time in the next 1-2
              business days.
            </SubText>
            <TextArea
              input={{
                onChange: setScheduledTime,
                value: scheduledTime,
              }}
            />
          </>
        );

      default:
        return (
          <>
            <GreenCheckMark />
            <ConfirmationHeader>{REQUEST_RECEIVED_TEXT}</ConfirmationHeader>
            <ConfirmationBody>
              We'll call you within 1-2 business days. We respond Monday-Friday
              between 6am-5pm PST, except major holidays.
            </ConfirmationBody>
          </>
        );
    }
  };

  return (
    <Modal
      isVisible={visible}
      onClose={onClose}
      options={modalOptions}
      size="small"
    >
      {renderModalContent()}
    </Modal>
  );
};

export default ContactMeModal;
