import React, { useCallback, useEffect, useState } from 'react';
import { BackHandler, Platform } from 'react-native';

import { getHomeComponent } from './helpers';

import * as Linking from 'expo-linking';
import { useDispatch } from 'react-redux';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from '@cross-platform/react-router-native';
import {
  setShouldRememberDevice,
  syncShouldRememberFromLocalStorage,
} from 'app/actions/uiActions';
import LearnMore from 'app/components/LearnMore';
import ActivationRedirect from 'app/components/Login/ActivationRedirect';
import RegisterError from 'app/components/Register/Error';
import CarrumID from 'app/containers/CarrumIDContainer';
import CompleteYourProfile from 'app/components/CompleteYourProfile';
import Confirmation from 'app/containers/ConfirmationContainer';
import Dashboard from 'app/containers/DashboardContainer';
import Forms from 'app/containers/FormsContainer';
import Location from 'app/containers/LocationContainer';
import MagicLinkRedirect from 'app/components/Login/Magic';
import Messages from 'app/components/Messages';
import ProcedureConfirmationPage from 'app/components/ProcedureConfirmation';
import OtherProcedure from 'app/containers/OtherProcedureContainer';
import Procedures from 'app/containers/ProceduresContainer';
import Profile from 'app/containers/ProfileContainer';
import Register from 'app/containers/RegisterContainer';
import SelectSurgeon from 'app/containers/SelectSurgeonContainer';
import SelectYourDoctor from 'app/components/SelectYourDoctor';
import SurgeonDetail from 'app/containers/SurgeonDetailContainer';
import Travel from 'app/containers/TravelContainer';
import LoginOverlay from 'app/components/App/LoginOverlay';
import Navigation from 'app/components/App/Navigation';
import SidePanel from 'app/components/App/Navigation/SidePanel';
import { Container, RoutesView } from 'app/components/App/styles';
import Toasts from 'app/components/App/Toasts';
import CallUs from 'app/components/CallUs';
import JourneyPhase from 'app/components/JourneyPhase';
import Login from 'app/components/Login';
import MySettings from 'app/components/MySettings';
import NotAvailable from 'app/components/NotAvailable';
import OneClickExpired from 'app/components/Register/SingleClick/Expired';
import OneClickWelcome from 'app/components/OneClickWelcome';
import ProcedureDescriptionPage from 'app/components/ProcedureDescription';
import ProcedureSearchResultsPage from 'app/components/ProcedureSearch/ProcedureSearchResultsPage';
import {
  useAppState,
  useNextPhaseRoute,
  useNotifications,
  usePlatform,
  useUi,
} from 'app/hooks';
import { logCampaignParams } from 'app/util/analytics';
import { isWithinDateRangeOf } from 'app/util/methods';
import { Routes } from 'app/util/routes';

const loginRoutePath = 'login';
const redirect = (path) => () => <Redirect to={path} />; // eslint-disable-line react/display-name

interface AppProps {
  connectToWebSocket: () => void;
  detectSessionOverride: () => void;
  disconnectFromWebSocket: () => void;
  dismissLocalNotification: (params: {
    redirect?: string;
    index?: any;
  }) => void;
  logout: (value?: boolean) => void;
  registerForPushNotifications: () => void;
  showLocalNotification: (data) => void;

  autoLogout: boolean;
  conversationId: string;
  user: {
    profileImage: string;
    profile: object;
    name: string;
    id: string;
    email: string;
  };
  features: any[];
  loggedIn: boolean;
  notifications: any[];
}

/**
 * Top level container that displays the application routes and layout.
 *
 * @param  {boolean}   autoLogout                    whether to log user out
 *                                                   when app is backgrounded
 * @param  {function}  connectToWebSocket            method to open web socket
 *                                                   connection
 * @param  {function}  detectSessionOverride         method to detect session
 *                                                   override
 * @param  {function}  disconnectFromWebSocket       method to close web socket
 *                                                   connection
 * @param  {function}  dismissLocalNotification      method to dismiss a local
 *                                                   notification
 * @param  {boolean}   loggedIn                      whether user is logged in
 * @param  {function}  logout                        method to log user out
 * @param  {array}     notifications                 notifications to display
 * @param  {function}  registerForPushNotifications  method to register for push
 *                                                   notifications
 * @param  {object}    user                          details about user
 */
const App = ({
  autoLogout = true,
  connectToWebSocket,
  detectSessionOverride,
  disconnectFromWebSocket,
  dismissLocalNotification,
  loggedIn = false,
  logout,
  notifications = [],
  registerForPushNotifications,
  user,
}: AppProps) => {
  const { appState } = useAppState();
  const {
    goBack,
    location: { pathname },
    push,
  } = useHistory();

  const dispatch = useDispatch();
  const { nextRoute } = useNextPhaseRoute();
  const { isPlatformTwo } = usePlatform();
  const { shouldRememberDateSet } = useUi();

  const [redirectPath, setRedirectPath] = useState(null);

  // Listen for and handle in-app and native push notifications.
  const { onNotificationPress } = useNotifications();

  /** Listen for back button presses on Android or web browsers. */
  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onBackPress);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    };
  }, []);

  /**
   * Listen for route changes on mobile devices when opening the application
   * from another app (eg., an email application).
   */
  useEffect(() => {
    if (Platform.OS === 'web') return;

    const subscription = Linking.addEventListener('url', navigateToLink);

    return () => {
      subscription?.remove();
    };
  }, []);

  /** Publish a notification if logged in as another user. */
  useEffect(() => {
    detectSessionOverride();
  }, []);

  /** Detect the initial route when loading the app. */
  useEffect(() => {
    loadInitialRoute();
  }, []);

  /** Handle changes from log in to log out state. */
  useEffect(() => {
    loggedIn ? onLogin() : onLogout();
  }, [loggedIn]);

  /**
   * Listen for app state changes.
   *
   * - On mobile, trigger callback when app moves to background or foreground.
   * - On web, trigger callback when browser tab gains or loses focus.
   */
  useEffect(() => {
    if (appState === 'background') onBackgroundApp();
  }, [appState]);

  /** Check the local storage for remember device settings. */
  useEffect(() => {
    if (user) {
      dispatch(syncShouldRememberFromLocalStorage(user.email));
    }
  }, [dispatch, user?.email]);

  /**
   * When logging in, connect to the ActionCable server, register for push
   * notifications, and handle redirects.
   */
  const onLogin = () => {
    connectToWebSocket();
    registerForPushNotifications();

    // reset `rememberMe` value after login, if expired
    if (
      shouldRememberDateSet &&
      !isWithinDateRangeOf(shouldRememberDateSet, 30)
    ) {
      dispatch(setShouldRememberDevice(user?.email, false));
    }
    if (!redirectPath) return;

    push(redirectPath);
    setRedirectPath(null);
  };

  /** When logging out, disconnect from the ActionCable server. */
  const onLogout = () => {
    disconnectFromWebSocket();
  };

  /** When backgrounding the app and `autoLogout` is on, log out of the app. */
  const onBackgroundApp = useCallback(() => {
    if (autoLogout && loggedIn) logout();
  }, [autoLogout, loggedIn]);

  const onBackPress = () => {
    goBack();
    return true;
  };

  /** Load the initial route (if any). */
  const loadInitialRoute = async () => {
    const url = await Linking.getInitialURL();
    await navigateToLink({ url });
  };

  /**
   * Parse the current link and, if valid, call `props.push()` with the path.
   *
   * @param  {string}  url  URL used to launch the app (such as
   *                        carrumhealth://password-reset)
   */
  const navigateToLink = async ({ url }) => {
    const uri = url?.replace(/^([^?]*)carrumhealth\.com/, '');

    if (!uri) return;

    let { path, queryParams } = Linking.parse(uri);

    if (queryParams) await logCampaignParams(queryParams);

    if (!path || path === '/' || path === 'localhost') return;

    const query = Object.keys(queryParams || {})
      .map((key) => {
        const value = encodeURIComponent(queryParams[key]);
        return `${key}=${value}`;
      })
      .join('&');

    path = /^\//.test(path) ? path : `/${path}`;
    path = [path, query].filter((isPresent) => isPresent).join('?');

    setRedirectPath(path);

    await push(path);
  };

  // Don't display the navigation when viewing the login page or sub pages.
  const displayNavigation =
    loggedIn || (pathname && !pathname.includes(loginRoutePath));

  return (
    <Container testID="App">
      <RoutesView topPadding={displayNavigation}>
        <Switch>
          {/**
           * (1) Protected Routes
           * ------------------------------------------------------------------
           *
           * The following section of code contains protected routes that can
           * be accessed by authenticated users, covering several aspects of
           * the patient's journey of care:
           *
           * (1.1) Patient Communication and Support
           * (1.2) Account Settings
           * (1.3) Procedure Selection
           * (1.4) Profile Completion
           * (1.5) Episode Creation
           * (1.6) Provider and Physician Selection
           */}

          {/* (1.1) Patient Communication and Support Routes */}
          <Route
            exact
            path={`/:carrumId/${Routes.CallUs}`}
            component={CallUs}
          />
          <Route exact path={`/${Routes.CarrumID}`} component={CarrumID} />
          <Route path={`/${Routes.Dashboard}`} component={Dashboard} />
          <Route exact path={`/${Routes.Forms}/:id?`} component={Forms} />
          <Route exact path={`/${Routes.Location}`} component={Location} />
          <Route exact path={`/${Routes.Messages}`} component={Messages} />
          <Route path={`/${Routes.NotAvailable}`} component={NotAvailable} />
          <Route path={`/${Routes.Travel}`} component={Travel} />

          {/* (1.2) Account Settings Route */}
          <Route path={`/${Routes.MySettings}`} component={MySettings} />

          {/* (1.3) Procedure Selection Routes */}
          <Route
            path={`/${Routes.Search}/:search`}
            component={ProcedureSearchResultsPage}
          />

          {isPlatformTwo ? (
            <Route
              exact
              path={`/${Routes.Procedures}/:id`}
              component={ProcedureDescriptionPage}
            />
          ) : (
            <Route path={`/${Routes.Procedures}`} component={Procedures} />
          )}

          <Route
            exact
            path={`/${Routes.ProcedureConfirmation}`}
            component={ProcedureConfirmationPage}
          />

          {/* (1.4) Profile Completion Routes */}
          {isPlatformTwo ? (
            <Redirect
              from={`/${Routes.Profile}`}
              to={`/${Routes.ProfileCreate}/${Routes.Overview}`}
            />
          ) : (
            <Route path={`/${Routes.Profile}`} component={Profile} />
          )}

          <Route
            path={`/${Routes.ProfileCreate}`}
            component={CompleteYourProfile}
          />

          {/* (1.5) Episode Creation Routes */}
          <Route
            exact
            path={`/${Routes.OtherProcedure}`}
            component={OtherProcedure}
          />

          {/* (1.6) Provider and Physician Selection Routes */}
          {isPlatformTwo ? (
            <Route
              path={`/${Routes.SelectYourDoctor}`}
              component={SelectYourDoctor}
            />
          ) : (
            <Route
              exact
              path={`/${Routes.SelectSurgeon}`}
              component={SelectSurgeon}
            />
          )}

          {!isPlatformTwo && (
            <Route
              exact
              path={`/${Routes.SelectSurgeon}/:id`}
              component={SurgeonDetail}
            />
          )}

          <Route
            exact
            path={`/${Routes.Confirmation}`}
            component={Confirmation}
          />

          {/**
           * (2) Public Routes and Client Personalization
           * ------------------------------------------------------------------
           *
           * Public routes can be displayed for non-authenticated guest. Each
           * can include a path fragment that maps to a client code to enable a
           * more personalized experience (e.g., "/pear/register").
           *
           * Public routes are included in the following blocks:
           *
           * - (2.1) Registration
           * - (2.2) Password Reset
           * - (2.3) Login
           * - (2.4) "Learn More"
           */}

          {/**
           * (2.1) Registration Routes
           *
           * NOTE: Many erroneous routes were reported as a result of mistakes
           * during marketing campaigns (see TEC-4132). To enable users to
           * register still, the following URL patterns should redirect such
           * users directly to the registration page:
           *
           * - `/invalid/register`
           * - `/register/invalid`
           * - `/register/token/invalid`
           * - `/register/d530f35c-6343-b3eb-8556-164802440b72/invalid`
           * - `/register/invalid/register`
           */}
          <Route
            exact
            path={`/invalid/${Routes.Register}`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/invalid/register`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/:token?/invalid`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/:token/expired`}
            component={loggedIn ? redirect(nextRoute) : OneClickExpired}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}/:token`}
            component={loggedIn ? redirect(nextRoute) : OneClickWelcome}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Register}`}
            component={loggedIn ? redirect(nextRoute) : Register}
          />
          <Route
            path={`/:clientId?/${Routes.InvalidActivation}`}
            component={loggedIn ? redirect(nextRoute) : RegisterError}
          />

          {/* These routes no longer exist so we redirect to login */}
          <Route
            exact
            path={`/:clientId?/${Routes.PasswordReset}/valid`}
            component={redirect(`/${Routes.Login}`)}
          />
          <Route
            path={`/:clientId?/${Routes.PasswordReset}(|/invalid|/expired)`}
            component={redirect(`/${Routes.Login}`)}
          />

          {/** (2.3) Login Routes */}
          <Route
            exact
            path={`/:clientId?/${Routes.Login}/activation`}
            component={loggedIn ? redirect(nextRoute) : ActivationRedirect}
          />
          <Route
            exact
            path={`/:clientId?/${Routes.Login}/magic`}
            component={loggedIn ? redirect(nextRoute) : MagicLinkRedirect}
          />
          <Route
            path={`/:clientId?/${Routes.Login}`}
            component={loggedIn ? redirect(nextRoute) : Login}
          />

          {/** (2.4) "Learn More" Route */}
          <Route
            path={`/:clientId?/${Routes.LearnMore}`}
            component={LearnMore}
          />

          {/**
           * (3) "Catch-all" Routes
           * ------------------------------------------------------------------
           *
           * Attempt to match any other route to a Journey Phase.
           *
           * This component will automatically redirect to the correct route if
           * the one given is not valid or belongs to a future journey phase.
           */}
          <Route
            exact
            path="/:journeyPhase"
            component={loggedIn ? JourneyPhase : Login}
          />
          <Route
            path={Routes.Home}
            component={getHomeComponent({
              loggedIn,
              nextRoute,
              redirect,
              user,
            })}
          />
        </Switch>
      </RoutesView>

      <SidePanel />

      <Toasts
        notifications={notifications}
        onPress={(data) => onNotificationPress({ data })}
        onDismiss={(index) => dismissLocalNotification({ index })}
      />

      <LoginOverlay isVisible={!loggedIn} />

      {displayNavigation && <Navigation />}
    </Container>
  );
};

export default App;
