import React from 'react';

import { TestID } from 'app/util/test-id';

import { AnimatedOverlayProps } from '../../types';
import { AnimatedOverlayContainer, OverlayToggleSwitch } from './styles';

/**
 * Render the animated overlay which enables the user to close the side panel.
 */
const AnimatedOverlay = ({ onClose, style }: AnimatedOverlayProps) => {
  return (
    <AnimatedOverlayContainer
      testID={TestID.Navigation.AnimatedOverlay}
      style={style}
    >
      <OverlayToggleSwitch
        testID={TestID.Navigation.OverlayToggleSwitch}
        onPress={onClose}
      />
    </AnimatedOverlayContainer>
  );
};

export default AnimatedOverlay;
