import { Animated, TouchableOpacity } from 'react-native';

import styled from 'styled-components/native';

export const AnimatedOverlayContainer = styled(Animated.View)`
  background-color: #00000080;
  flex: 1;
`;

export const OverlayToggleSwitch = styled(TouchableOpacity)`
  flex: 1;
`;
