import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { useHistory } from '@cross-platform/react-router-native';
import { useDispatch, useSelector } from 'react-redux';

import { receiveSetCallUsModalVisibility } from 'app/actions/uiActions';
import { SelectYourDoctorRoutes } from 'app/components/SelectYourDoctor/routes';
import { usePlatform, useUi } from 'app/hooks';
import { getIsLoggedIn } from 'app/selectors/session';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';
import { State } from 'types/state';

import { Button, GetHelpModal } from './styles';

const GetHelpButton = () => {
  const dispatch = useDispatch();
  const { isCallUsModalVisible } = useUi();
  const { isPlatformTwo } = usePlatform();

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  const {
    location: { pathname },
  } = useHistory();

  const isDoctorSelectionPage = pathname.includes(SelectYourDoctorRoutes.Root);
  const isNarrow = useWindowDimensions().width < theme.breakpoints.xsmall;
  const isProcedureSearchPage = pathname === '/';

  const shouldShowButton = useMemo(
    () =>
      isLoggedIn &&
      isPlatformTwo &&
      (isDoctorSelectionPage || isProcedureSearchPage || isNarrow),
    [
      isLoggedIn,
      isPlatformTwo,
      isDoctorSelectionPage,
      isProcedureSearchPage,
      isNarrow,
    ]
  );

  return (
    <>
      {shouldShowButton && (
        <Button
          onPress={() => dispatch(receiveSetCallUsModalVisibility(true))}
          testID={TestID.GetHelpModal.GetHelpButton}
        />
      )}
      <GetHelpModal
        onClose={() => dispatch(receiveSetCallUsModalVisibility(false))}
        visible={isCallUsModalVisible}
      />
    </>
  );
};

export default GetHelpButton;
