import React from 'react';
import { useWindowDimensions } from 'react-native';

import { useSelector } from 'react-redux';

import { getIsLoggedIn } from 'app/selectors/session';
import { breakpoints } from 'app/util/breakpoints';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import {
  ContentLinks,
  GuestLinks,
  NavigationLinksContainer,
  ProfileLinks,
} from './styles';

/**
 * Render the navigation links responsively.
 *
 * - for narrow screens, render links as side panel items.
 * - for wide screens, render links as top nav items and/or as profile menu items.
 *
 * Render the navigation links conditionally based on authentiction status.
 *
 * - for authenticated users, render content links
 * - for authenticated users, render profile links to side panel for narrow screens
 * - for authenticated users, render profile links to menu for wide screens
 * - for non-authenticated users, render guest links
 */
const NavigationLinks = () => {
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  return (
    <NavigationLinksContainer testID={TestID.Navigation.NavigationLinks}>
      {isLoggedIn && <ContentLinks />}
      {isLoggedIn && isNarrow && <ProfileLinks layout="column" />}
      {!isLoggedIn && <GuestLinks />}
    </NavigationLinksContainer>
  );
};

export default NavigationLinks;
