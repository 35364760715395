import React, { FC } from 'react';

import theme from 'app/util/theme';

import {
  Avatar,
  AvatarButton,
  CameraIcon,
  CameraIconContainer,
  Container,
  DetailsWrapper,
  Subtitle,
  Title,
} from './styles';

const defaultIcon = {
  color: theme.colors.default,
  name: 'user',
  type: 'font-awesome',
};

interface ProfileHeaderProps {
  onAvatarEdit: () => void;

  email: string;
  name: string;
  profileImage: string;
  progress: number;
}

const ProfileHeader = ({
  email,
  name,
  onAvatarEdit,
  profileImage,
}: ProfileHeaderProps) => (
  <>
    <Container>
      <Title>Profile</Title>
    </Container>

    <DetailsWrapper>
      <AvatarButton onPress={onAvatarEdit}>
        <Avatar
          rounded
          size={100}
          source={profileImage ? { uri: profileImage } : null}
          icon={profileImage ? null : defaultIcon}
        />

        <CameraIconContainer>
          <CameraIcon />
        </CameraIconContainer>
      </AvatarButton>

      <Subtitle>{name}</Subtitle>

      <Subtitle>{email}</Subtitle>
    </DetailsWrapper>
  </>
);

ProfileHeader.defaultProps = {
  profileImage: '',
  progress: 0,
};

export default ProfileHeader;
