import React, { ReactNode } from 'react';
import { Platform } from 'react-native';

import { ENVIRONMENT } from 'app/util/constants';
import { isJestRunning } from 'app/util/devUtils';
import { EnvironmentTypes } from 'types/environments';
import { PlatformTypes } from 'types/platforms';

export interface EnvironmentSwitchProps {
  children?: ReactNode;
  environments?: Array<EnvironmentTypes>;
  platforms?: Array<PlatformTypes>;
}

export const AllEnvironments: EnvironmentTypes[] = [
  EnvironmentTypes.Demo,
  EnvironmentTypes.Development,
  EnvironmentTypes.Staging,
  EnvironmentTypes.Production,
  EnvironmentTypes.Test,
  EnvironmentTypes.UserAcceptanceTesting,
];

export const AllPlatforms: PlatformTypes[] = [
  PlatformTypes.Android,
  PlatformTypes.iOS,
  PlatformTypes.Web,
];

/**
 * Conditionally render content based on the current application environment.
 * Defaults to showing in all environments and platforms.
 *
 * @example
 *
 * <EnvionmentSwitch environments={["development"]}>
 *   <Text>Only visible in development!</Text>
 * </EnvionmentSwitch>
 *
 * <EnvionmentSwitch platforms={["ios"]}>
 *   <Text>Only visible on iOS!</Text>
 * </EnvionmentSwitch>
 */
export const EnvironmentSwitch = ({
  children = null,
  environments = AllEnvironments,
  platforms = AllPlatforms,
}: EnvironmentSwitchProps = {}) => {
  // Interpret the running of jest specs as the "Test" environment.
  const env = isJestRunning ? EnvironmentTypes.Test : ENVIRONMENT;

  if (!environments.includes(env)) return null;
  if (!platforms.includes(Platform.OS)) return null;

  return <>{children}</>;
};

export default EnvironmentSwitch;
