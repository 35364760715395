import React from 'react';
import { useWindowDimensions } from 'react-native';

import { useHistory } from '@cross-platform/react-router-native';
import { useSession } from 'app/hooks';
import { breakpoints } from 'app/util/breakpoints';
import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';

import { extractBranding } from '../helpers';
import {
  CarrumLogoContainer,
  CarrumLogoNarrow,
  CarrumLogoWide,
} from './styles';

/**
 * Render the Carrum Health logo responsively.
 *
 * - Use the narrow version when the screen is narrow and client branding is
 *   present.
 * - Use the wide version otherwise.
 */
const CarrumLogo = () => {
  const history = useHistory();
  const session = useSession();
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  const hasClientLogo = !!extractBranding(session);
  const carrumLogoIsNarrow = isNarrow && hasClientLogo;
  const testKey = `CarrumLogo${carrumLogoIsNarrow ? 'Narrow' : 'Wide'}`;

  return (
    <CarrumLogoContainer
      onPress={() => history.push(Routes.Home)}
      testID={TestID.Navigation[testKey]}
    >
      {carrumLogoIsNarrow ? <CarrumLogoNarrow /> : <CarrumLogoWide />}
    </CarrumLogoContainer>
  );
};

export default CarrumLogo;
