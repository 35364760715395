import { KeyboardAvoidingView, Platform, ViewProps } from 'react-native';

import styled, { css } from 'styled-components/native';

import IconLinkRaw from 'app/components/Common/IconLink';
import OnboardingRaw from 'app/components/Common/Onboarding';
import SearchBarRaw from 'app/components/Common/SearchBar';
import TrackableButton from 'app/components/Common/TrackableButton';
import { Text, View } from 'app/components/Common/Styled';
import {
  DotIndicator as DotIndicatorRaw,
  RedVerticalLogo as RedVerticalLogoRaw,
} from 'app/components/Common/svg-components';
import { LetUsKnow as LetUsKnowRaw } from 'app/components/LetUsKnow';
import { IconType } from 'types/icon';

import { default as ProcedureSearchPageWrapperRaw } from '../ProcedureSearchPageWrapper';

interface SvgWrapperProps extends ViewProps {
  side: 'left' | 'right';
}

const IconEqualFlexWrapper = styled(View)`
  flex: 1 0 auto;
`;

const IconLink = styled(IconLinkRaw).attrs(({ iconType, theme }) => {
  return {
    color: theme.colors.primary,
    fontSize: theme.fontSize,
    iconType: IconType[iconType],
    size: 26,
    containerStyle: {
      flex: 1,
      flexBasis: 'auto',
    },
  };
})``;

export const AboutIcon = styled(IconLink)`
  flex: 1 0 auto;
`;

export const AboutIconWrapper = styled(IconEqualFlexWrapper)`
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing}px;
  flex: 1 0 auto;
`;

export const ChatIcon = styled(IconLink)`
  flex: 1 0 auto;
`;

export const ChatIconWrapper = styled(IconEqualFlexWrapper)`
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing}px;
  margin-right: ${({ theme }) => theme.spacing}px;
  flex: 1 0 auto;
`;

export const Container = styled(View)`
  background-color: ${({ theme }) => theme.colors.sand};
  flex: 1 0 auto;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing * 2}px;
`;

export const CtaContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const DotIndicator = styled(DotIndicatorRaw)`
  align-self: center;
  margin: ${({ theme }) => theme.spacing}px;
`;

export const Footer = styled(View)``;

export const FooterWrapper = styled(View)``;

export const LetUsKnow = LetUsKnowRaw;

export const LetUsKnowButton = styled(TrackableButton).attrs(({ theme }) => {
  return {
    buttonStyle: {
      paddingTop: theme.spacing,
    },
    containerStyle: {
      marginBottom: theme.spacing * 2,
    },
    titleStyle: {
      fontSize: theme.fontSizes.small,
    },
  };
})``;

export const LogoWrapper = styled(View)`
  align-items: center;
  flex: 0 1 auto;
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const NoResultsText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.body3}px;
  padding-top: ${({ theme }) => theme.spacing}px;
  text-align: justify;
`;

export const NotWhatYoureLookingForText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.body3}px;
  text-align: center;
`;

export const Onboarding = OnboardingRaw;

export const PhoneIcon = styled(IconLink)`
  flex: 1 0 auto;
`;

export const PhoneIconWrapper = styled(IconEqualFlexWrapper)`
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing}px;
  flex: 1 0 auto;
`;

export const ProcedureSearchPageWrapper = styled(
  ProcedureSearchPageWrapperRaw
).attrs(({ theme }) => {
  return {
    containerStyle: {
      padding: theme.spacing,
    },
  };
})``;

export const RedVerticalLogo = styled(RedVerticalLogoRaw).attrs({
  height: 72,
  width: 120,
})``;

export const SearchBar = styled(SearchBarRaw).attrs(({ theme }) => {
  return {
    blurOnSubmit: true,
    cancelButtonProps: {
      buttonStyle: { display: 'none' },
      disabled: true,
    },
    containerStyle: {
      marginTop: theme.spacing * 3,
      borderRadius: theme.spacing / 4,
    },
    inputContainerStyle: {
      backgroundColor: theme.colors.white,
    },
    placeholder: 'Type condition or procedure here...',
    placeholderTextColor: theme.colors.placeholderText,
    // @ts-ignore (platform does not want to accept 'windows' as part of PlatformOSType)
    platform: Platform.OS.replace('web', 'default'),
    /** Prevent the clear icon from affecting the component layout. */
    rightIconContainerStyle: {
      position: 'absolute',
      right: 0,
    },
    searchIcon: { color: theme.colors.bodyText, name: 'search' },
  };
})``;

export { default as SearchSuggestions } from 'app/components/ProcedureSearch/ProcedureSearchPage/SearchSuggestions';

export { default as SubCategoryButtons } from 'app/components/ProcedureSearch/ProcedureSearchPage/SubCategoryButtons';

export const SvgWrapper = styled(View)<SvgWrapperProps>`
  align-items: ${({ side = 'left' }) =>
    side === 'left' ? 'flex-start' : 'flex-end'};
  flex: 1 1 auto;
  justify-content: flex-end;
`;

export const Title = styled(Text).attrs({
  align: 'center',
  size: 'h1',
})`
  ${({ theme }) => css`
    flex: 0 1 auto;
    margin-bottom: ${theme.spacing * 2}px;
    margin-top: ${theme.spacing}px;
  `}
`;

export const TopSection = styled(KeyboardAvoidingView).attrs(({ theme }) => ({
  keyboardVerticalOffset: theme.spacing * -2,
  behavior: Platform.select({
    android: 'position',
  }),
}))`
  flex: 1 0 auto;

  /* Allow <SearchResultsPositionWrapper /> to cover contents rendered after */
  z-index: ${({ theme }) => theme.zIndexes.base};
`;
