import { useEffect, useState } from 'react';

import { useHistory, useParams } from '@cross-platform/react-router-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  createEpisodeFromState,
  fetchOfferedProcedure,
} from 'app/actions/episodeActions';

import {
  NavigationConfig,
  NavigationMode,
} from 'app/components/Common/StickyNavFooter/types';

import { useEpisode } from 'app/hooks';
import { getOfferedProcedure } from 'app/selectors/episode';
import { getIsLoggedIn } from 'app/selectors/session';
import { Routes } from 'app/util/routes';
import { routeTo } from 'app/util/routeUtils';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import {
  ColloquialProcedureName,
  ProcedureDescriptionContainer,
  DescriptionHeader,
  DescriptionWrapper,
  DotIndicator,
  ProcedureDetailsHtml,
  ProcedureLabel,
  ProcedureName,
  StickyNavFooter,
  TopBackLink,
  TopLinkWrapper,
} from './styles';

import { MatchParams } from '../ProcedureConfirmation/types';
import {
  getProcedureColloquialName,
  getProcedureName,
} from '../ProcedureConfirmation/helpers';

export const ProcedureDescription = () => {
  const { id } = useParams<MatchParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const episodeState = useEpisode();

  const episodeIsAlreadyCreated = episodeState.episode;
  const navigationConfig: NavigationConfig[] = [];

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const procedure = useSelector((state: State.RootState) =>
    getOfferedProcedure(state, id)
  );
  const employer = useSelector(
    (state: State.RootState) => state.session?.user?.employer
  );

  const [isCreating, setIsCreating] = useState(false);
  const [procedureIsLoading, setProcedureIsLoading] = useState(
    procedure?.key !== id
  );

  // Find external partnerships for employer based on the procedure category.
  const nonSurgicalPartnership = employer.partnerships.find(
    ({ procedureCategory }) => procedureCategory === procedure?.category
  );

  /**
   * Show an additional CTA if there is a matching partnership with a
   * CTA defined and an episode has not already been created.
   */
  const showNonSurgicalOption =
    !episodeIsAlreadyCreated && !!nonSurgicalPartnership?.url?.length;

  // Refresh current episode on mount
  useEffect(() => {
    episodeState.refresh();
  }, []);

  // Fetch the specified procedure for logged in users (if necessary).
  useEffect(
    function fetchSpecifiedProcedure() {
      if (isLoggedIn && id && procedure?.key !== id) {
        dispatch(fetchOfferedProcedure(id));
      }
    },
    [isLoggedIn]
  );

  // Toggle the page loading state off after the procedure has been fetched.
  useEffect(
    function toggleLoadingState() {
      if (procedure) setProcedureIsLoading(false);
    },
    [procedure]
  );

  const createEpisodeAndProceed = () => {
    setIsCreating(true);
    dispatch(createEpisodeFromState({ procedure }));
    history.push(`/${Routes.Procedures}/${id}/confirmation`);
  };

  const goToNonSurgicalOption = () => {
    routeTo(nonSurgicalPartnership.url);
  };

  if (showNonSurgicalOption) {
    navigationConfig.push({
      disabled: isCreating,
      onPress: goToNonSurgicalOption,
      testID: TestID.ProcedureDescription.NonSurgicalButton,
      text: 'Explore Non-Surgical Options',
    });
  }

  navigationConfig.push({
    disabled: isCreating,
    onPress: episodeIsAlreadyCreated
      ? () => history.push('/')
      : createEpisodeAndProceed,
    testID: TestID.ProcedureDescription.NavFooterNextButton,
    text: episodeIsAlreadyCreated ? 'Continue' : "I'm Ready to Receive Care",
  });

  const htmlContent = procedure?.content?.details ?? '';

  return (
    <>
      <ProcedureDescriptionContainer testID={TestID.ProcedureDescription.Page}>
        <TopLinkWrapper>
          <TopBackLink testID={TestID.ProcedureDescription.BackLink} />
        </TopLinkWrapper>

        {procedureIsLoading && (
          <DotIndicator testID={TestID.ProcedureDescription.LoadingIndicator} />
        )}

        {!!htmlContent && (
          <DescriptionWrapper>
            <DescriptionHeader>
              <ProcedureLabel
                testID={TestID.ProcedureDescription.DescriptionHeaderText}
              >
                You selected:
              </ProcedureLabel>
              <ProcedureName>{getProcedureName(procedure)}</ProcedureName>
              <ColloquialProcedureName>
                {getProcedureColloquialName(procedure)}
              </ColloquialProcedureName>
            </DescriptionHeader>

            <ProcedureDetailsHtml html={htmlContent} />
          </DescriptionWrapper>
        )}
      </ProcedureDescriptionContainer>

      {!episodeState.loading && (
        <StickyNavFooter
          navigationConfig={navigationConfig}
          navigationMode={
            showNonSurgicalOption
              ? NavigationMode.DoubleButton
              : NavigationMode.Centered
          }
          testID={TestID.ProcedureDescription.NavFooter}
        />
      )}
    </>
  );
};

export default ProcedureDescription;
