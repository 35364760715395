import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.colors.sand};
`;

interface RoutesViewProps {
  topPadding: boolean;
}

/**
 * Create a vertical offset to avoid the fixed position header at
 * the top of the application, and offset the amount in iOS to
 * ensure that everything lines up properly.
 */
const navigationOffset = ({ navigationHeight, spacing }: any) =>
  Platform.select({
    default: navigationHeight,
    ios: navigationHeight - spacing * 0.5,
  });

export const RoutesView = styled(SafeAreaView).attrs({})<RoutesViewProps>`
  flex: 1 1 auto;
  padding-top: ${({ theme, topPadding }) =>
    topPadding ? navigationOffset(theme) : 0}px;
`;
