// NOTE: This file is necessary as a workaround for a bug. When a styled RNE
// Avatar is exported from a `.ts` file, it does not render correctly. It must
// be defined in a `.js` file and routed through a `.ts` file.

import styled from 'styled-components';

import { theme } from './constants';

import { Avatar } from 'react-native-elements';

export const RoundedAvatar = styled(Avatar).attrs({
  rounded: true,
})``;

export const NotificationGroupAvatar = styled(RoundedAvatar).attrs(
  ({ iconName }) => ({
    // NOTE: for some reason, theme does not work properly in this context
    //       (i.e., `{ iconName, Theme }) and so has been imported directly
    //       above.
    overlayContainerStyle: { backgroundColor: theme.colors.lightOrange },
    icon: {
      name: iconName,
      type: 'font-awesome',
      color: theme.colors.badge,
    },
  })
)``;
