import React from 'react';
import PropTypes from 'prop-types';

import { Container, Details, Icon, Paragraph, Title } from './styles';

const propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,

  iconType: PropTypes.string,
  items: PropTypes.array,
};

const SurgeonCardDetail = ({
  icon,
  iconType = 'font-awesome',
  items,
  title,
}) => {
  if (items.length <= 0) return null;

  return (
    <Container>
      <Icon
        containerStyle={{
          marginTop: 0,
        }}
        type={iconType}
        name={icon}
      />

      <Details>
        <Title>{title}</Title>

        {items.map((text) => (
          <Paragraph key={text}>{text}</Paragraph>
        ))}
      </Details>
    </Container>
  );
};

SurgeonCardDetail.propTypes = propTypes;
export default SurgeonCardDetail;
