import { Platform, View } from 'react-native';

import styled, { css } from 'styled-components/native';

export { default as ProfileInfo } from '../ProfileInfo';
export { default as ProfileLinks } from '../NavigationLinks/ProfileLinks';
export { default as ProfileMenuToggle } from './ProfileMenuToggle';

const fixedPosition = Platform.select({ default: 'absolute', web: 'fixed' });

export const ProfileMenuContainer = styled(View)``;

export const ProfileMenuPanel = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacing * 0.5}px;
    box-shadow: 0px 0px ${theme.spacing * 0.5}px ${theme.colors.black}40;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing * 0.75}px;
    position: ${fixedPosition};
    right: ${theme.spacing * 1.25}px;
    top: ${theme.spacing * 4.25}px;
    width: ${theme.spacing * 17.5}px;
  `}
`;
