import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import { Integrations } from '@sentry/tracing';

import { ENVIRONMENT, SENTRY_DSN } from 'app/util/constants';

// Use null check to prevent errors due to missing import in web browsers.
const RoutingInstrumentation =
  Sentry.Native?.RoutingInstrumentation ?? (() => {});

export const routingInstrumentation = new RoutingInstrumentation();

const initializeSentry = () => {
  try {
    const integrations = [];
    const options = {
      tracingOrigins: ['localhost', 'carrumhealth.com', /^\//],
    };

    if (Platform.OS === 'web') {
      const tracing = new Integrations.BrowserTracing(options);
      integrations.push(tracing);
    } else {
      const tracing = new Sentry.Native.ReactNativeTracing({
        ...options,
        routingInstrumentation,
      });
      integrations.push(tracing);
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      enableAutoSessionTracking: Platform.OS !== 'web',
      enableInExpoDevelopment: ENVIRONMENT === 'development',
      debug: ENVIRONMENT === 'development',
      integrations,
      // Only trace 20% of transactions in production
      tracesSampleRate: ENVIRONMENT === 'development' ? 1.0 : 0.2,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to initialize Sentry', error);
  }
};

// Log errors using Sentry in Production
if (ENVIRONMENT !== 'development') initializeSentry();
