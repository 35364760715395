import styled from 'styled-components/native';

import { Platform } from 'react-native';
import { Icon as RNEIcon } from 'react-native-elements';

import Text from 'app/components/Common/Styled/Text';

export const Container = styled.View`
  align-items: center;
  background-color: #fff;
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  flex: 1 0 auto;
  flex-direction: row;
  padding-horizontal: ${({ theme }) => theme.spacing / 2}px;
  padding-vertical: ${({ theme }) => theme.spacing}px;
  position: ${Platform.select({ default: 'absolute', web: 'fixed' })};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndexes.titleBar};
`;

export const Icon = styled(RNEIcon).attrs({
  name: Platform.select({
    default: 'arrow-back',
    ios: 'chevron-left',
  }),
})`
  margin-right: ${({ theme }) => theme.spacing}px;
  width: ${({ theme }) => theme.spacing * 2}px;
`;

export const Subtitle = styled(Text).attrs({ size: 'small' })`
  margin-left: ${({ theme }) => theme.spacing / 4}px;
`;

export const Title = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing / 4}px;
`;
