import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import VisibilitySensor from 'app/components/Common/VisibilitySensor';
import { useSession } from 'app/hooks';
import { GOOGLE_API_KEY } from 'app/util/constants';
import { TestID } from 'app/util/test-id';

import { Text } from './styles';
import { getTravelDuration, formatDurationString } from './helpers';

interface TravelDurationProps {
  latitude: number;
  longitude: number;
}

const TravelDuration = ({ latitude, longitude }: TravelDurationProps) => {
  const {
    user: { location },
  } = useSession();

  const [durationString, setDurationString] = useState('Calculating...');
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    return () => {
      setDurationString(undefined);
    };
  }, []);

  useEffect(() => {
    if (!isInViewport) return;

    getTravelDuration(location, {
      lat: latitude,
      lng: longitude,
    }).then((res) => setDurationString(res));
  }, [location, latitude, longitude, isInViewport]);

  return (
    <>
      <VisibilitySensor onChange={setIsInViewport}>
        <Text testID={TestID.SelectYourDoctor.TravelDuration}>
          {durationString}
        </Text>
      </VisibilitySensor>
    </>
  );
};

export default TravelDuration;
