import { Platform } from 'react-native';

import { ENVIRONMENT } from 'app/util/constants';
import { EnvironmentTypes } from 'types/environments';
import { PlatformTypes } from 'types/platforms';

// Support debugging in staging (only supported on mobile)
if (
  ENVIRONMENT === EnvironmentTypes.Staging &&
  Platform.OS !== PlatformTypes.Web
) {
  try {
    const analytics = require('@react-native-firebase/analytics').default;
    analytics()?.setDebugModeEnabled(true);
  } catch (error) {
    // Do nothing on failure
  }
}
