import {
  breakpoints,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import { useWindowDimensions } from 'app/components/CompleteYourProfile/hooks';

import {
  AlignmentWrapper,
  CarrumGoldExperienceBenefits,
  ContinueButton,
  ContinueButtonWrapper,
  Fade,
  FooterText,
  FooterTextWrapper,
  NavigationMode,
  StickyNavFooter,
  UpperContentWrapper,
  Wrapper,
} from 'app/components/CompleteYourProfile/styles';

import { BenefitsOverviewProps } from 'app/components/CompleteYourProfile/types';

const BenefitsOverview = ({
  allowModal = false,
  description,
  isSaving,
  mode,
  onContinue,
  showDetailsFooter = false,
  title,
}: BenefitsOverviewProps) => {
  const isNarrow = useWindowDimensions().width <= breakpoints.xsmall;

  return (
    <>
      <Wrapper testID={TestID.CompleteYourProfile.BenefitsOverview}>
        <AlignmentWrapper>
          <Fade in delay={300}>
            <UpperContentWrapper>
              {!!title && title}

              {!!description && description}
            </UpperContentWrapper>
          </Fade>

          <Fade in delay={400}>
            <CarrumGoldExperienceBenefits disabled={!allowModal} mode={mode} />
          </Fade>

          {showDetailsFooter && (
            <Fade in delay={500}>
              <FooterTextWrapper>
                <FooterText>Click each to see the details.</FooterText>
              </FooterTextWrapper>
            </Fade>
          )}

          {!isNarrow && (
            <Fade in delay={600}>
              <ContinueButtonWrapper>
                <ContinueButton
                  loading={isSaving}
                  onPress={onContinue}
                  testID={TestID.CompleteYourProfile.NextButton}
                  title={isSaving ? 'Saving' : 'Continue'}
                />
              </ContinueButtonWrapper>
            </Fade>
          )}
        </AlignmentWrapper>
      </Wrapper>

      <StickyNavFooter
        navigationConfig={[
          {
            disabled: isSaving,
            onPress: onContinue,
            testID: TestID.CompleteYourProfile.NextButtonMobile,
            text: isSaving ? 'Saving' : 'Continue',
          },
        ]}
        navigationMode={NavigationMode.Continue}
        testID={TestID.CompleteYourProfile.NavFooter}
      />
    </>
  );
};

export default BenefitsOverview;
