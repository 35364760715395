import { Button } from 'react-native-elements';

import styled from 'styled-components/native';

export const ButtonRow = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
  flex-direction: row;
  flex: 1 0 auto;
`;

export const PlayButton = styled(Button)`
  width: 50px;
  margin-right: ${({ theme }) => theme.spacing}px;
`;

export const DeleteButton = styled(Button)`
  width: 50px;
`;

export const RecordButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing}px;
`;
