import React from 'react';
import PropTypes from 'prop-types';

import { Platform, StyleSheet, View } from 'react-native';
import MapView, { Marker } from '@cross-platform/react-native-maps';

const formatCoords = ({ lat, lng, latitude, longitude }, offset) => ({
  lat: (lat || parseFloat(latitude)) + (offset && offset.y ? offset.y : 0),
  lng: (lng || parseFloat(longitude)) + (offset && offset.x ? offset.x : 0),
  latitude: (lat || parseFloat(latitude)) + (offset && offset.y ? offset.y : 0),
  longitude:
    (lng || parseFloat(longitude)) + (offset && offset.x ? offset.x : 0),
  latitudeDelta: 12,
  longitudeDelta: 12,
});

const getMapCenter = (hospitals, offset) => {
  if (hospitals.length === 1) return formatCoords(hospitals[0], offset);

  let lat = 0;
  let lng = 0;

  hospitals.forEach((hospital) => {
    const marker = formatCoords(hospital);
    lat += marker.lat;
    lng += marker.lng;
  });

  lat /= hospitals.length;
  lng /= hospitals.length;

  return formatCoords({ lat, lng }, offset);
};

/**
 * Displays a background map view with hospital markers.
 * The map center can be offset to accomodate various
 * device sizes.
 */
const SurgeonMap = ({ hospitals, offset }) => (
  <View style={styles.container}>
    <MapView
      style={styles.map}
      region={hospitals.length > 0 ? getMapCenter(hospitals, offset) : null}
      zoom={6}
    >
      {hospitals.map((hospital) =>
        hospital && hospital.latitude && hospital.longitude ? (
          <Marker
            key={hospital.id}
            coordinate={formatCoords(hospital)}
            position={formatCoords(hospital)}
          />
        ) : null
      )}
    </MapView>
  </View>
);

SurgeonMap.propTypes = {
  hospitals: PropTypes.array.isRequired,
  offset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

SurgeonMap.defaultProps = {
  offset: {},
};

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
  },

  map: {
    height: '100%',
    width: '100%',
  },
});

export default SurgeonMap;
