import React from 'react';
import { View } from 'react-native';
import { useHistory } from '@cross-platform/react-router-native';

import CheckBox from 'app/components/Common/CheckBox';
import Input from 'app/components/Common/Input';
import Select from 'app/components/Common/Select';
import SectionHeader from 'app/components/Profile/SectionHeader';
import { planTypeOptions } from 'app/constants/insurance';

import {
  CheckboxFieldView,
  Field,
  MessageAnchorText,
  StaticFieldsView,
  Text,
  TipText,
} from './styles';

interface InsuranceFormProps {
  dependent: boolean;
  formErrors: any;
  isHsaEligible: boolean;
  planName: string;
  providerName: string;
  showBackLink?: boolean;
  showTitle?: boolean;
}

const defaultProps = {
  formErrors: {},
  isHsaEligible: false,
  showBackLink: false,
  showTitle: false,
};

const InsuranceForm = ({
  dependent,
  formErrors,
  isHsaEligible,
  planName,
  providerName,
  showBackLink,
  showTitle,
}: InsuranceFormProps) => {
  const history = useHistory();
  return (
    <View testID="InsuranceForm">
      <SectionHeader
        showBackLink={showBackLink}
        title="Insurance"
        showTitle={showTitle}
      />

      <StaticFieldsView>
        <Text>Provider: {providerName || 'Unknown'}</Text>

        <Text>Plan Name: {planName || 'Unknown'}</Text>

        <Text>HSA Eligible? {isHsaEligible ? 'Yes' : 'No'}</Text>

        <TipText>
          <MessageAnchorText
            onPress={() => {
              history.replace('/messages');
            }}
          >
            Send a message
          </MessageAnchorText>
          &nbsp;to your Patient Care Specialist if the information above is
          incorrect.
        </TipText>
      </StaticFieldsView>

      <Field
        error={formErrors['profile.insurance.memberId']}
        name="profile.insurance.memberId"
        label="Member ID"
        component={Input}
      />

      <Field
        error={formErrors['profile.insurance.groupNumber']}
        name="profile.insurance.groupNumber"
        label="Group #"
        component={Input}
      />

      <Field
        error={formErrors['profile.insurance.planType']}
        name="profile.insurance.planType"
        label="Plan Type"
        options={planTypeOptions}
        component={Select}
        unSelectedOption
      />

      <CheckboxFieldView>
        <Field
          name="profile.insurance.medicareOrMedicaid"
          label="My healthcare coverage is through Medicare/Medicaid."
          component={CheckBox}
        />
      </CheckboxFieldView>

      <CheckboxFieldView>
        <Field
          name="profile.dependent"
          label="I am a dependent on this plan (not the primary subscriber)"
          component={CheckBox}
        />
      </CheckboxFieldView>

      {dependent && (
        <Field
          error={formErrors['profile.subscriberFullSsn']}
          name="profile.subscriberFullSsn"
          label="SSN of the Primary Subscriber"
          component={Input}
        />
      )}
    </View>
  );
};
InsuranceForm.defaultProps = defaultProps;

export default InsuranceForm;
