import styled from 'styled-components/native';
import { Field as FieldRaw } from 'redux-form';

export const Field = styled(FieldRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;
