import { logError } from 'app/util/methods';

export const RECEIVE_CORE_LOGIN = 'RECEIVE_CORE_LOGIN';
export const RECEIVE_SESSION_ERROR = 'RECEIVE_SESSION_ERROR';
export const RECEIVE_LOADING = 'RECEIVE_LOADING';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const RECEIVE_USED_MAGIC_LINK = 'RECEIVE_USED_MAGIC_LINK';

export const receiveCoreLogin = (token, override = false) => {
  return {
    type: RECEIVE_CORE_LOGIN,
    token,
    override,
  };
};

export const receiveUsedMagicLink = (usedMagicLink = false) => {
  return {
    type: RECEIVE_USED_MAGIC_LINK,
    usedMagicLink,
  };
};

export const receiveLoading = (loading) => {
  return {
    type: RECEIVE_LOADING,
    loading,
  };
};

export const receiveLogout = (hard) => {
  return {
    type: RECEIVE_LOGOUT,
    hard,
  };
};

export const receiveSessionError = (error, log = false) => {
  if (error?.response?.data?.message) {
    error = error.response.data.message;
  } else if (error?.message) {
    error = error.message;
  }

  if (error && log) logError(error);

  return {
    type: RECEIVE_SESSION_ERROR,
    error,
  };
};
