import styled from 'styled-components/native';

import { TouchableOpacity } from 'react-native';
import { Card as RNECard, Icon } from 'react-native-elements';

import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

export const Card = styled(RNECard).attrs({
  containerStyle: {
    marginHorizontal: 0,
    maxWidth: theme.breakpoints.xsmall,
    overflow: 'hidden',
  },
  wrapperStyle: {
    overflow: 'hidden',
  },
})``;

export const CardContent = styled.View`
  padding: ${({ theme }) => theme.spacing / 2}px;
`;

export const PlayIcon = styled(Icon).attrs({
  color: '#eee',
  name: 'play-circle-outline',
  size: 80,
})`
  margin-top: ${({ theme }) => theme.spacing * 3}px;
`;

export const PlayIconContainer = styled(TouchableOpacity)`
  position: absolute;
  left: -10px;
  right: -10px;
  top: -200px;
  bottom: 44px;
  align-items: center;
  justify-content: center;
`;

export const VideoTitle = styled(Text).attrs({ bold: true })``;
