import {
  EVENTS,
  initialFormValues,
  messagesPerPage,
  redrawTimeout,
  TestID,
} from './constants';

import {
  chime,
  connectToWebSocket,
  fetchMoreMessages,
  getConversations,
  getConversation,
  getMessageGroups,
  getMessagesData,
  getParticipants,
  getShowLoadMoreButton,
  hasNewMessage,
  logEvent,
  markUnreadMessages,
  scrollToBottom,
  setMessagesOpen,
} from './helpers';

import {
  useDispatch,
  useEffect,
  useMessage,
  usePreviousValue,
  useRef,
  useSession,
  useState,
} from './hooks';

import {
  Container,
  ListContainer,
  MessageActions,
  MessageAttachments,
  MessageListEmpty,
  MessageLoadMore,
  MessageGroup,
  MessagesHeader,
  MessagesList,
  NotificationGroup,
  Platform,
} from './styles';

const Messages = ({}) => {
  let refreshTimeout;
  let unreadTimeout;

  const componentHasMounted = useRef(false);
  const dispatch = useDispatch();
  const message = useMessage();
  const scrollView = useRef();
  const session = useSession();

  const isConnected = Boolean(
    session.coreToken && !session.loading && message.connected
  );
  const conversation = getConversation(message, session);
  const currentUserId = session.user.id;
  const previousConversation = usePreviousValue(conversation);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [isLoading, setIsLoading] = useState(!conversation);
  const [refreshing, setRefreshing] = useState(false);

  const participants = getParticipants(conversation);
  const messageGroups = getMessageGroups(conversation);
  const messagesData = getMessagesData(messageGroups);

  /**
   * For the initial mount:
   *
   * 1. open up a web socket connection
   * 2. scroll the UI down to show recent messages if a conversation exists
   * 3. log `view_messages` event
   */
  useEffect(() => {
    connectToWebSocket(dispatch);
    if (conversation) scrollToBottom(false, scrollView.current);
    logEvent(EVENTS.messaging.view);
  }, []);

  /** Fetch conversation data when a connection is first established. */
  useEffect(() => {
    if (isConnected) loadConversations();
  }, [isConnected]);

  /**
   * When a conversation is first available, scroll down to show messages.
   * Ensure that there was no previous conversation to prevent scrolling
   * when users click the "Load Previous Messages" button.
   */
  useEffect(() => {
    !previousConversation &&
      conversation &&
      scrollToBottom(false, scrollView.current);
  }, [conversation, previousConversation]);

  /**
   * When both the connection and conversation have been established:
   *
   * 1. mark any new message(s) which the user has not yet seen as unread
   * 2. sound a chime to indicate that a new message exists
   * 3. scroll through the reverse-infinite-scrolling list to show any new
   *    messages
   */
  useEffect(() => {
    if (componentHasMounted.current) {
      if (refreshing) return;

      if (hasNewMessage(currentUserId, conversation, previousConversation)) {
        chime();
        markUnreadMessages(dispatch, conversation.id, 1000, unreadTimeout);
        scrollToBottom(true, scrollView.current);
      }
    } else {
      componentHasMounted.current = true;
    }
  }, [conversation, previousConversation, currentUserId]);

  /** On dismount, clear any timeouts and close the "Messages" tab. */
  useEffect(() => {
    setMessagesOpen(dispatch, true);

    return () => {
      refreshTimeout && clearTimeout(refreshTimeout);
      unreadTimeout && clearTimeout(unreadTimeout);

      setMessagesOpen(dispatch, false);
    };
  }, [refreshTimeout, unreadTimeout]);

  const loadConversations = () =>
    getConversations({
      conversation,
      dispatch,
      redrawTimeout,
      scrollView,
      setIsLoading,
    });

  const loadMoreMessages = () =>
    fetchMoreMessages(
      dispatch,
      conversation,
      refreshing,
      setRefreshing,
      refreshTimeout
    );

  /** Show the "Load More" button if there's at least one page of messages. */
  const showLoadMoreButton = getShowLoadMoreButton(
    conversation,
    messagesPerPage
  );

  /** Render a message or notification.  */
  const renderMessageOrNotificationGroup = ({ item }) => {
    return item.notification ? (
      <NotificationGroup {...item} />
    ) : (
      <MessageGroup
        patientIsAuthor={parseInt(item.author.id) === parseInt(currentUserId)}
        {...item}
      />
    );
  };

  /**
   * Get conversations if no conversation exists and the web socket connection
   * is established. If a conversation exists, mark any unread messages as bold
   * for a short time as an affordance for the user.
   */
  if (conversation) {
    markUnreadMessages(dispatch, conversation.id, 10000, unreadTimeout);
  } else if (isConnected) {
    loadConversations();
  }

  return (
    <Container testID={TestID.Messages.Page}>
      <MessagesHeader isRendered={conversation} participants={participants} />

      <ListContainer
      // @ts-ignore TODO: fix "no overload matches" TS error
      >
        <MessagesList
          // @ts-ignore TODO: fix "no overload matches" TS error
          data={messagesData}
          enableOnAndroid
          inverted={Object.keys(messageGroups).length > 0}
          ListEmptyComponent={<MessageListEmpty isLoading={isLoading} />}
          ListFooterComponent={
            showLoadMoreButton && (
              <MessageLoadMore
                loading={refreshing}
                onPress={loadMoreMessages}
              />
            )
          }
          onEndReached={Platform.OS !== 'web' ? fetchMoreMessages : null}
          onEndReachedThreshold={0.2}
          ref={scrollView}
          renderItem={renderMessageOrNotificationGroup}
        />

        <MessageAttachments
          attachments={formValues.attachments}
          formValues={formValues}
          isRendered={conversation}
          setFormValues={setFormValues}
        />

        <MessageActions
          conversation={conversation}
          currentUserId={currentUserId}
          formValues={formValues}
          isRendered={conversation}
          scrollView={scrollView}
          setFormValues={setFormValues}
        />
      </ListContainer>
    </Container>
  );
};

export default Messages;
