import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-native-elements';

/**
 * Renders an icon for a given file based on the extension.
 */
const FileIcon = ({ file, onLongPress, onPress, containerStyle }) => {
  let name = 'file';

  if (/^data:application\/pdf|(\.|)pdf$/i.test(file)) {
    name = 'file-pdf-box';
  } else if (/(\.|)txt$/i.test(file)) {
    name = 'file-document';
  } else if (/(\.|)(doc|docx)$/i.test(file)) {
    name = 'file-word';
  } else if (/(\.|)(xlsx|xls|csv)$/i.test(file)) {
    name = 'file-excel';
  } else if (/(\.|)(mp4|mpeg|m4a|mov|ogg)$/i.test(file)) {
    name = 'file-video';
  }

  return (
    <Icon
      type="material-community"
      size={80}
      name={name}
      containerStyle={containerStyle}
      onPress={onPress}
      onLongPress={onLongPress}
    />
  );
};

FileIcon.propTypes = {
  file: PropTypes.string.isRequired,
  containerStyle: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
  onLongPress: PropTypes.func,
  onPress: PropTypes.func,
};

export default FileIcon;
