import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { CheckBox as CheckBoxNative } from 'react-native-elements';

import FormError from '../FormError';

/**
 * A checkbox component wrapper for interacting
 * with redux-form.
 *
 * @example
 * import { Field } from 'redux-form';
 * import CheckBox from 'app/components/Common/CheckBox';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myBooleanField"
 *      component={CheckBox}
 *    />
 *   </View>
 * );
 */
const CheckBox = ({
  containerStyle,
  disabled,
  error,
  label,
  input: { onBlur, onChange, value, ...inputProps },
}: {
  containerStyle?: any;
  disabled?: boolean;
  error?: string;
  label: string | ReactNode;
  input: {
    onBlur?: () => void;
    onChange: (value: boolean) => void;
    value: any;
    name?: string;
  };
}) => (
  <View
    style={[
      disabled ? styles.containerDisabled : null,
      StyleSheet.flatten(containerStyle),
    ]}
    testID={inputProps.name}
  >
    <CheckBoxNative
      // React claims that it only supports a string value for `title`,
      // but we pass in a component title sometimes and it works fine.
      // @ts-ignore
      title={label}
      checked={Boolean(value)}
      checkedColor={disabled ? '#545454' : '#A53723'}
      containerStyle={{
        borderWidth: 0,
      }}
      onPress={() => {
        if (disabled) return;

        onChange(!value);

        if (onBlur) setTimeout(onBlur);
      }}
      onIconPress={() => {
        if (disabled) return;
        onChange(!value);

        if (onBlur) {
          setTimeout(onBlur);
        }
      }}
      {...inputProps}
    />
    {error ? <FormError>{error}</FormError> : null}
  </View>
);

export default CheckBox;

/**
 * @property {boolean} disabled True if the checkbox is disabled.
 * @property {string} error An error to display beneath to the checkbox.
 * @property {string} label A label to display next to the checkbox.
 * @property {object} input An object with props passed down from redux-form.
 * @property {function} input.onChange A function to call when the value changes.
 * @property {boolean} input.value The current value, truthy if the checkbox should be checked.
 */
CheckBox.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,

    onBlur: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
};

const styles = StyleSheet.create({
  containerDisabled: {
    opacity: 0.3,
  },
});
