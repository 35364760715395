import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';

export { default as AnimatedOverlay } from './AnimatedOverlay';
export { default as AnimatedPanel } from './AnimatedPanel';
export { default as DevToolsLink } from './DevToolsLink';
export { default as NavigationLinks } from '../NavigationLinks';
export { default as ProfileInfo } from '../ProfileInfo';
import { SidePanelContainerProps } from '../types';

export const CurrentEnvironment = styled(Text)``;

const fixedPosition = Platform.select({
  default: 'absolute',
  web: 'fixed',
});

export const SidePanelContainer = styled(View).attrs<SidePanelContainerProps>(
  ({ isOpen }) => {
    return { pointerEvents: isOpen ? 'auto' : 'none' };
  }
)<SidePanelContainerProps>`
  ${({ theme }) => css`
    height: 100%;
    position: ${fixedPosition};
    width: 100%;
    z-index: ${theme.zIndexes.overlay};
  `}
`;
