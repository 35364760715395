import React, { FC, Fragment } from 'react';
import { View } from 'react-native';

import CheckBox from 'app/components/Common/CheckBox';
import GooglePlacesInput from 'app/components/Common/GooglePlacesInput';
import Input from 'app/components/Common/Input';
import SectionHeader from 'app/components/Profile/SectionHeader';

import {
  CheckBoxView,
  Field,
  MapView,
  Marker,
  Row,
  SmallInputContainer,
} from './styles';

interface AddressFormProps {
  formErrors: any;
  location: any;
  mailingAddressSame: boolean;
  showBackLink?: boolean;
  showTitle?: boolean;
}

const formatCoords = ({ lat, lng }) => ({
  lat,
  lng,
  latitude: lat,
  longitude: lng,
  latitudeDelta: 0.0922,
  longitudeDelta: 0.0421,
});

const AddressForm = ({
  formErrors,
  location,
  mailingAddressSame,
  showBackLink,
  showTitle,
}: AddressFormProps) => (
  <View testID="AddressForm">
    <SectionHeader
      showBackLink={showBackLink}
      title="Address"
      showTitle={showTitle}
    />

    <Field
      name="location"
      label="Physical Address"
      component={GooglePlacesInput}
    />

    {typeof location?.lat === 'number' && typeof location?.lng === 'number' ? (
      <MapView region={formatCoords(location)} zoom={8}>
        <Marker coordinate={formatCoords(location)} position={location} />
      </MapView>
    ) : null}

    <CheckBoxView>
      <Field
        error={formErrors.mailingAddressSame}
        name="mailingAddressSame"
        label="My physical and mailing addresses are the same."
        component={CheckBox}
      />
    </CheckBoxView>

    {!mailingAddressSame && (
      <Fragment>
        <Row>
          <SmallInputContainer>
            <Field
              name="profile.mailingAddress.street"
              label="Mailing Address"
              component={Input}
            />
          </SmallInputContainer>
        </Row>
        <Row>
          <SmallInputContainer mr>
            <Field
              name="profile.mailingAddress.unit"
              label="Unit"
              component={Input}
            />
          </SmallInputContainer>
          <SmallInputContainer>
            <Field
              name="profile.mailingAddress.city"
              label="City"
              component={Input}
            />
          </SmallInputContainer>
        </Row>

        <Row>
          <SmallInputContainer mr>
            <Field
              name="profile.mailingAddress.state"
              label="State"
              component={Input}
            />
          </SmallInputContainer>
          <SmallInputContainer>
            <Field
              name="profile.mailingAddress.postalCode"
              label="Zip"
              component={Input}
            />
          </SmallInputContainer>
        </Row>
      </Fragment>
    )}
  </View>
);

AddressForm.defaultProps = {
  formErrors: {},
  location: {},
  mailingAddressSame: false,
  showBackLink: false,
  showTitle: false,
};

export default AddressForm;
