import { Platform } from 'react-native';

import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {
  fetchCurrentEpisode,
  fetchProductOfferings,
} from 'app/actions/episodeActions';
import {
  setAutoLogout,
  setShouldRememberDevice,
  showLocalNotification,
  syncShouldRememberFromLocalStorage,
} from 'app/actions/uiActions';
import {
  getUserEmployer,
  getUserInsurer,
  getUserLocation,
  receiveCurrentUser,
  updateUserDetails,
} from 'app/actions/userActions';
import {
  logEvent,
  setUserId,
  setUserProperty,
  EVENTS,
} from 'app/util/analytics';
import { CORE_API_URL, CARRUM_DIGEST } from 'app/util/constants';
import { push } from 'app/util/history';
import { generateEligibilityDigest } from 'app/util/methods';
import { unformatObject } from 'app/util/reducerUtils';
import { Routes } from 'app/util/routes';

import {
  receiveCoreLogin,
  receiveLoading,
  receiveLogout,
  receiveSessionError,
  RECEIVE_SESSION_ERROR,
  receiveUsedMagicLink,
} from './action-types';

export * from './action-types';
export * from './verifySingleClickRegistration';

const isWebPlatform = Platform.OS === 'web';

export const emailValidationError =
  'It looks like there might be something wrong with your email address. Please double-check the spelling.';

const expiredSessionError = 'Your session has expired, please log in again.';

const invalidUserError = `The information you provided does not match our \
records. Please ensure your information matches the information on your medical insurance ID \
card and that you have a valid plan (HMO and Medicare primary plans are currently not \
accepted).`;

const patientNotFoundError = `Sorry, the information you provided does not match our records. \n\n• Try entering the last 4 digits of your employee identification number (sometimes called EEIN, EID) \n• If you are a dependent, try the last 4 digits of the primary subscriber's Social Security Number.\n\n`;

const patientNotEligibleError = `We found you in our system, but our records indicate \
that with your current health plan and employment status you are not eligible for the \
Carrum Health benefit. Please contact your Benefits Administrator for further information.`;

/**
 * Make a call to the core service with the activation token and email and
 * check its status. The API response returns the correct route to redirect
 * to.
 *
 * @param   {object}   params  a params object with email and token fields.
 * @return  {promise}          a promise that resolves with a response object
 *                             containing a path field.
 */
export function validateActivation(params) {
  Object.keys(params).forEach((key) => {
    params[key] = encodeURIComponent(params[key]);
  });

  const { token, email } = params;
  return axios.get(
    `${CORE_API_URL}/users/${token}/activate_account?email=${email}`
  );
}

/**
 * Check for a `carrumoverride` cookie to allow admins temporary login access
 * on behalf of another user.
 *
 * @return  {promise}  A promise that resolves after checking the cookie.
 */
export const detectSessionOverride = () => {
  return async (dispatch) => {
    if (!isWebPlatform) return;

    // eslint-disable-next-line no-undef
    const override = window.document.cookie.match(/carrumoverride=([^;]+)/);
    const token = override && override[1];

    if (!token) return;

    await dispatch(receiveLoading(true));
    await dispatch(receiveCoreLogin(token, true));
    await dispatch(fetchCurrentUser());
    await dispatch(receiveLoading(false));

    await dispatch(
      showLocalNotification({
        body: 'You are logged in on behalf of another user. This temporary override will automatically expire after 15 minutes.',
        title: 'Session Override',
        id: Math.random(),
        icon: 'key',
      })
    );
  };
};

/**
 * Log the user into the Core Service and then fetch their user data.
 *
 * @param  {object}  auth  an object with an email/password to login with.
 */
export function loginCoreUser(auth, isMagicLink = false) {
  return async (dispatch, getState) => {
    try {
      // If changing users, reset the app state before logging in.
      const { user } = getState().session;

      if (user.email && user.email !== auth.email) dispatch(logout(true));

      dispatch(receiveLoading(true));

      const response = await axios.post(`${CORE_API_URL}/login`, { auth });

      await dispatch(receiveCoreLogin(response.data.jwt));
      await dispatch(receiveUsedMagicLink(isMagicLink));
      await dispatch(fetchCurrentUser());

      /**
       * If the user is opening the app on a mobile device and we have not
       * previously recorded the installation of the mobile app, update the
       * `hasMobileApp` property to `true`.
       */
      if (Platform.OS === 'mobile' && !user.hasMobileApp) {
        await dispatch(updateUserDetails({ hasMobileApp: true }));
      }

      await dispatch(receiveLoading(false));
      await dispatch(trackRememberMe(auth.shouldRememberDevice, auth.email));
    } catch (error) {
      dispatch(loginError(error));
    }
  };
}

/**
 * Dispatch a login error using a given error object.
 *
 * @param   {object|string}  error  An error message or object to display.
 * @return  {promise}               A promise that resolves after clearing the
 *                                  session.
 */
function loginError(error) {
  return (dispatch) => {
    let errorMessage = error;

    if (error.response && error.response.status === 422) {
      errorMessage =
        'Your account has not been activated. Please check your email to activate your account.';
    } else if (error.response && error.response.status === 404) {
      errorMessage = 'Incorrect email or password.';
    } else if (error.message) {
      errorMessage = error.message;
    }

    return dispatch(invalidateSession(errorMessage));
  };
}

/**
 * Create a new user in the Core Service.
 *
 * @param   {object}   params  details for the new user
 *
 * @return  {promise}          promise that resolves when request completes
 */
export function createCoreUser(params) {
  return async (dispatch) => {
    const eligibilityDigest = generateEligibilityDigest(params, CARRUM_DIGEST);

    const user = unformatObject({
      ...params,
      eligibilityDigest,
      mobileRegistration: true,
      source: 'mobile-app',
    });

    dispatch(receiveLoading(true));

    try {
      await axios.post(`${CORE_API_URL}/users`, { user });

      logEvent(EVENTS.account.register);
      dispatch(receiveLoading(false));
      dispatch(receiveSessionError(null));

      return true;
    } catch (error) {
      dispatch(receiveLoading(false));

      if (
        error.response?.data &&
        /\bValidation\sfailed:\s(.+)/.test(error.response.data.message)
      ) {
        // return validation error message substring from response
        // or use custom message for email validation errors
        let message = error.response.data.message.match(
          /\bValidation\sfailed:\s(.+)/
        )[1];
        if (message.startsWith('Email')) message = emailValidationError;

        dispatch(receiveSessionError(message));
      } else if (error.response?.status === 404) {
        dispatch(receiveSessionError(invalidUserError));
      } else if (
        error.response?.status === 422 &&
        error.response?.data?.message
      ) {
        dispatch(receiveSessionError(error.response.data.message));
      } else {
        dispatch(receiveSessionError(error, true));
      }

      return false;
    }
  };
}

/**
 * Fetch the user's data from the core service and then get the user's
 * coordinates.
 *
 * @return  {promise}  a promise that resolves when the data is fetched.
 */
export function fetchCurrentUser() {
  return async (dispatch, getState) => {
    const {
      session: { coreToken },
    } = getState();

    if (!coreToken) return;

    const { sub, features } = jwtDecode(coreToken);

    const response = await axios.get(`${CORE_API_URL}/users/${sub}`, {
      headers: { Authorization: `Bearer ${coreToken}` },
    });

    if (
      !response ||
      !response.data ||
      !response.data.data ||
      !response.data.data.attributes['eligible-patient']
    ) {
      throw new Error('Incorrect email or password.');
    }

    await dispatch(receiveCurrentUser(response.data.data, features));
    await dispatch(fetchUserDetails());
    await dispatch(trackLogin());
  };
}

/**
 * Fetch additional details about the current user.
 */
function fetchUserDetails() {
  return async (dispatch) => {
    await Promise.all([
      dispatch(getUserLocation()),
      dispatch(getUserEmployer()),
      dispatch(getUserInsurer()),
    ]);

    await dispatch(fetchProductOfferings());

    await dispatch(fetchCurrentEpisode());
  };
}

/**
 * Use the employer code and eligibility digest to track user properties in
 * Firebase analytics.
 */
function trackLogin() {
  return (_, getState) => {
    const {
      session: {
        user: {
          eligiblePatient: { eligibilityDigest },
          employer: { code },
        },
      },
    } = getState();

    setUserId(eligibilityDigest);
    setUserProperty('employer', code.toLowerCase());
    // Log the event *last* so the user properties are included in the event.
    logEvent(EVENTS.session.login);
  };
}

/**
 * Log an event in Firebase analytics and clear the user properties from the
 * session.
 */
function trackLogout() {
  // Log the event *first* so the user properties are included in the event.
  logEvent(EVENTS.session.logout);
  setUserId(null);
  setUserProperty('employer', null);
}

/**
 * Refresh and store the localStorage data to indicate if the user would like
 * the app to remember their login for this device.
 *
 * @param  {boolean}  shouldRemember  whether to remember login for device.
 * @param  {string}   userEmail       the user's email address.
 */
export function trackRememberMe(shouldRemember, userEmail) {
  return async (dispatch) => {
    if (!userEmail) return;

    await dispatch(syncShouldRememberFromLocalStorage(userEmail));

    dispatch(
      setRememberDevice(shouldRemember ? userEmail : undefined, shouldRemember)
    );
  };
}

/**
 * Clear the session in the reducer and route to /.
 *
 * @param   {boolean}  hard  if true, signifies a hard logout with a redirect.
 * @return  {promise}        a promise that resolves when the session is
 *                           cleared.
 */
export function logout(hard = false, redirectRoute = `/${Routes.Login}`) {
  return async (dispatch) => {
    dispatch(receiveLogout(hard));

    if (!hard) return;

    push(redirectRoute);

    trackLogout();
  };
}

/**
 * Verify that the patient exists in the database. Make a GET request to the
 * eligible_patients#confirmed route in the core service.
 *
 * @param   {object}   params  params for verifying a patient's eligibility.
 * @return  {promise}          a promise that resolves after the patient's
 *                             eligibility is determined.
 */
export const verifyEligiblePatient = (params) => {
  return async (dispatch) => {
    try {
      dispatch(receiveSessionError(null));
      dispatch(receiveLoading(true));

      const eligibilityDigest = generateEligibilityDigest(
        params,
        CARRUM_DIGEST
      );

      await axios.get(
        `${CORE_API_URL}/eligible_patients/${eligibilityDigest}/confirmed`
      );

      // Tag Google Analytics events for user after they start registration
      setUserId(eligibilityDigest);
      dispatch(receiveLoading(false));

      return true;
    } catch (error) {
      dispatch(receiveEligibilityError(error));
      dispatch(receiveLoading(false));

      return false;
    }
  };
};

/**
 * Clear the current session and display a message indicating that the session
 * has expired.
 *
 * @param   {object|string}  error  an error message or object to display.
 * @return  {promise}               a promise that resolves after clearing the
 *                                  session.
 */
export function invalidateSession(error = expiredSessionError) {
  return async (dispatch) => {
    await dispatch(logout());

    dispatch(receiveLoading(false));
    dispatch(receiveSessionError(error));

    if (error === expiredSessionError) logEvent(EVENTS.session.expired);
  };
}

/**
 * Dispatch eligibility error action.
 *
 * @param  {object|string}  error  An error object or string
 */
export const receiveEligibilityError = (error) => {
  const getErrorMessage = (errorResponse) => {
    let result;

    const addendum = isWebPlatform ? `.` : ` (or click the button below).`;

    switch (true) {
      case errorResponse?.status === 404:
        result = patientNotFoundError;
        break;
      case errorResponse?.status === 422:
        result = patientNotEligibleError;
        break;
      case Boolean(errorResponse?.data?.message):
        result = errorResponse?.data?.message;
        break;
    }

    return `${result} If you need help, call 1-888-855-7806${addendum}`;
  };

  return {
    type: RECEIVE_SESSION_ERROR,
    error: getErrorMessage(error?.response),
  };
};

const setRememberDevice = (userEmail, shouldRememberDevice) => (dispatch) => {
  dispatch(setShouldRememberDevice(userEmail, shouldRememberDevice));
  dispatch(setAutoLogout(!shouldRememberDevice));
};
