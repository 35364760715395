import { Animated, Platform, View } from 'react-native';
import { Icon } from 'react-native-elements';
import styled from 'styled-components/native';

import theme, { shadows } from 'app/util/theme';

import { panelOffset } from './animations';

/**
 * The minimum height of the reveal content (slightly smaller on Android).
 */
const teaserContentHeight =
  theme.spacing *
  Platform.select({ android: theme.spacing / 3, default: theme.spacing / 2 });

/**
 * An offset to use for bottom padding that allows enough
 * space for the teaser content with additional top padding.
 */
export const teaserOffset = teaserContentHeight + theme.spacing;

const minHeight = panelOffset + teaserContentHeight;

const borderRadius = Platform.select({
  ios: theme.spacing * 3,
  default: theme.spacing,
});

export const AnimatedBackdrop = styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  bottom: 0;
  flex: 1;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  color: '#fff',
  name: 'close',
  size: theme.spacing * 2,
  containerStyle: { alignItems: 'flex-end' },
}))``;

export const Container = styled(Animated.View).attrs({ ...shadows.medium })`
  align-self: center;
  background-color: ${({ theme }) => theme.colors.defaultLight};
  border-top-left-radius: ${({ theme }) => theme.border.radiusTen}px;
  border-top-right-radius: ${({ theme }) => theme.border.radiusTen}px;
  bottom: 0;
  elevation: 3;
  flex: 1;
  height: ${({ expanded }) => (expanded ? 'auto' : `${minHeight}px`)};
  max-width: ${({ theme }) => theme.breakpoints.small}px;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing * 2}px;
  position: ${Platform.select({ default: 'absolute', web: 'fixed' })};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndexes.overlay};
`;

export const ContentWrapper = styled(Animated.View)`
  flex: 1 0 auto;
  padding-horizontal: ${({ theme }) => theme.spacing}px;
`;

export const Handle = styled.View`
  background-color: ${({ theme }) => theme.colors.default};
  border-radius: ${({ theme }) => theme.spacing / 4}px;
  height: ${({ theme }) => theme.spacing / 4}px;
  width: ${({ theme }) => theme.spacing * 2}px;
`;

export const HandleWrapper = styled(View)`
  align-items: center;
  background-color: transparent;
  /* Only show the draggable handle on mobile devices  */
  display: ${Platform.select({ default: 'flex', web: 'none' })};
  left: 0;
  padding: ${({ theme }) => theme.spacing}px;
  padding-top: ${({ theme }) => theme.spacing * 2}px;
  position: absolute;
  right: 0;
  top: ${({ theme }) => -theme.spacing}px;
`;

export const TeaserContentWrapper = styled(Animated.View)`
  align-items: stretch;
  align-self: center;
  flex: 1 0 auto;
  padding: ${({ theme }) => `${theme.spacing * 2}px ${theme.spacing}px`};
  position: absolute;
  top: 0px;
  width: 100%;
`;
