import { flatten } from 'lodash';
import { Routes } from 'app/util/routes';
import useEpisode from 'app/hooks/useEpisode';
import { useHistory } from '@cross-platform/react-router-native';

/**
 * Return a function to navigate to the next profile completion page based on
 * input parameters and the completion status of related tasks (for account
 * creation and profile completion).
 *
 * @param  {boolean}   isWelcomePage  whether the hook is called from the
 *                                    welcome page
 * @param  {string}    nextPageKey    the default next page to navigate to
 *                                    when the user's account has been
 *                                    created and their profile completed
 *
 * @return  {function}                function to navigate to the proper route
 */
const useNextPageForProfileCompletion = ({
  isWelcomePage = true,
  nextPageKey,
}) => {
  const history = useHistory();
  const { steps } = useEpisode();

  /** Redirect user to a specific route related to profile completion. */
  const goToProfileCreateRoute = (routeKey) => {
    history.push(`/${Routes.ProfileCreate}/${routeKey}`);
  };

  /**
   * Determine whether a specific task can be considered complete because (1)
   * it does not exist or (2) the user has completed it.
   *
   * @param   {string}   taskName  name used to identify a specific task
   * @param   {array}    task      array of task objects to search within
   *
   * @return  {boolean}            whether the task is complete
   */
  const isTaskComplete = (taskName, tasks) => {
    const taskIsComplete = !!tasks.find((task) => {
      return task.meta.event == taskName && task.complete === true;
    });

    const taskExists = tasks.map((task) => task.meta?.event).includes(taskName);

    return taskIsComplete || !taskExists;
  };

  const tasks = flatten(steps.map(({ tasks }) => tasks));

  const isProfileComplete = isTaskComplete('complete_profile', tasks);
  const isPasswordCreated = isTaskComplete('create_account', tasks);

  let returnFunction;

  if (isProfileComplete && isPasswordCreated) {
    returnFunction = () => goToProfileCreateRoute(Routes.Complete);
  } else if (isProfileComplete && !isPasswordCreated) {
    returnFunction = () => goToProfileCreateRoute(Routes.SaveProgress);
  } else if (!isProfileComplete && isPasswordCreated && !isWelcomePage) {
    returnFunction = () => goToProfileCreateRoute(Routes.Complete);
  } else {
    returnFunction = () => goToProfileCreateRoute(nextPageKey);
  }

  return returnFunction;
};

export default useNextPageForProfileCompletion;
