import { useState } from 'react';
import { Animated, PanResponder } from 'react-native';

/**
 * Include functionality to drag and drop elements.
 *
 * Apply the `pan.x` and `pan.y` properties as transforms on the element
 * you want to move, and expand the `panResponder.panHandlers` property on
 * the element you want to respond to gestures.
 *
 * @example
 * const { pan, panResponder } = usePanHandle();
 *
 * return (
 *   <Animated.View
 *     style={{
 *       transform: [{ translateX: pan.x }, { translateX: pan.y }]
 *     }}
 *   />
 *     <View {...panResponder.panHandlers}>
 *       <Text>Grab Me!</Text>
 *     </View>
 *   </Animated.View>
 * );
 */
const usePanHandle = ({
  initialBoundary = {},
  initialOffset = { x: 0, y: 0 },
  onStartShouldSetPanResponder = () => true,
  onStartShouldSetPanResponderCapture = () => true,
  onMoveShouldSetPanResponder = () => true,
  onMoveShouldSetPanResponderCapture = () => true,
  onShouldBlockNativeResponder = () => false,
  onPanResponderMove = () => {},
  onPanResponderRelease = () => {},
} = {}) => {
  const [boundary, setBoundary] = useState(initialBoundary);
  const [pan] = useState(new Animated.ValueXY(initialOffset));

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder,
    onStartShouldSetPanResponderCapture,
    onMoveShouldSetPanResponder,
    onMoveShouldSetPanResponderCapture,
    onShouldBlockNativeResponder,
    onPanResponderRelease,

    onPanResponderMove: makeOnPanResponderMove({
      boundary,
      pan,
      onPanResponderMove,
    }),
  });

  return {
    boundary,
    pan,
    panResponder,
    setBoundary,
  };
};

export default usePanHandle;

/**
 * Returns true if a given coordinate is beyond a given boundary.
 *
 * @param {number} coordinate A number representing an X or Y coordinate to check.
 * @param {number|null} boundary A number representing an X or Y coordinate representing a boundary.
 * @param {boolean} positive If true, will check if the coodinate is greated than the boundary.
 */
export const isBeyondBoundary = (coordinate, boundary, positive = true) => {
  if (!boundary) return false;

  if (positive) return coordinate > boundary;

  return coordinate < boundary;
};

/**
 * A factory function that defines a handler for gestures on a PanResponder.
 *
 * @param {object} options An object with options for the function to build.
 * @return {function} A function that handles gestures on a PanResponder.
 */
export const makeOnPanResponderMove =
  ({ boundary, pan, onPanResponderMove }) =>
  /**
   * This handler will update an Animated.ValueXY called `pan` with an offset
   * based on the movement of a user.  This offset can be limited by a `boundary`
   * object that can describe a minimum or maximum value of movement in a given
   * direction.
   *
   * @param {object} event A native animation event.
   * @param {object} gesture An object that describes the position of a pan event.
   */
  (event, gesture) => {
    const offset = {};

    /**
     * Gesture positions are based on the top left corner having coordinate (0, 0).
     *
     * When swiping left, dx is negative.
     * When swiping right, dx is positive.
     * When swiping up, dy is negative.
     * When swiping down, dy is positive.
     */
    const { dx, dy } = gesture;

    // Boundary check for X axis (horizontal movement).
    if (isBeyondBoundary(dx, boundary?.left)) {
      offset.x = boundary.left;
    } else if (isBeyondBoundary(dx, boundary?.right, false)) {
      offset.x = boundary.right;
    } else {
      offset.x = dx;
    }

    // Boundary check for Y axis (vertical movement).
    if (isBeyondBoundary(dy, boundary?.top, false)) {
      offset.y = boundary.top;
    } else if (isBeyondBoundary(dy, boundary?.bottom)) {
      offset.y = boundary.bottom;
    } else {
      offset.y = dy;
    }

    // Animate the pan to the calculated offset.
    pan.setOffset(offset);

    // Run any additional callback.
    onPanResponderMove?.(event, gesture);
  };
