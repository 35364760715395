import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import { Redirect } from '@cross-platform/react-router-native';

import SelectSurgeon from 'app/components/SelectSurgeon';
import {
  getPossiblePhysicians,
  updateCurrentEpisode,
} from 'app/actions/episodeActions';
import { usePlatform } from 'app/hooks';
import { updateCurrentPatient } from 'app/actions/userActions';
import { extractFormValues } from 'app/util/methods';
import { Routes } from 'app/util/routes';

const sortOptions = [
  { label: 'Distance', value: 'distance' },
  { label: 'Hospital Name', value: 'hospitals.name' },
  { label: 'Surgeon Last Name', value: 'physicians.name' },
];

const additionalSortOptions = [
  { label: 'Default', value: 'distance,physicians.availability_in_weeks' },
  { label: 'Soonest Available', value: 'physicians.availability_in_weeks' },
];

const getNextPage = ({
  possiblePhysicians,
  possiblePhysiciansPage,
  possiblePhysiciansTotal,
}) =>
  possiblePhysicians.length < possiblePhysiciansTotal
    ? possiblePhysiciansPage + 1
    : null;

const getSortOptions = ({ features }) =>
  features.indexOf('surgeon-availability') === -1
    ? sortOptions
    : [...additionalSortOptions, ...sortOptions];

const mapStateToProps = ({ episode, form, session }) => ({
  formValues: extractFormValues(form, 'episode'),
  hasEpisode: Boolean(episode && episode.episode),
  isPlatformTwo: session?.user?.employer?.contract?.platformTwo,
  nextPage: getNextPage(episode),
  physicians: episode.possiblePhysicians,
  sortOptions: getSortOptions(session),
});

const mapDispatchToProps = (dispatch) => ({
  initializeSortValue: (value) =>
    dispatch(change('episode', 'surgeonSortBy', value)),
  getPossiblePhysicians: (procedureId, sortBy, page) =>
    dispatch(getPossiblePhysicians(procedureId, sortBy, page)),
  updateEpisodeLocation: (location) =>
    dispatch(updateCurrentEpisode({ location })),
  // TODO: Update profile instead of patient record.
  updateCurrentPatient: (patient) => dispatch(updateCurrentPatient(patient)),
  clearPhysician: () =>
    Promise.all([
      dispatch(change('episode', 'physician', null)),
      dispatch(change('episode', 'provider', null)),
    ]),
  skipPhysician: () =>
    dispatch(
      updateCurrentEpisode({
        physician: null,
        provider: null,
      })
    ),
});

// If the patient is on Platform 2.0, redirect them from the `/select-surgeon` route
// to the `/select-your-doctor` route.
const RedirectWrapper = () => {
  const { isPlatformTwo } = usePlatform();

  if (isPlatformTwo) {
    return <Redirect to={Routes.SelectYourDoctor} />;
  }

  return <SelectSurgeonContainer />;
};

const SelectSurgeonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSurgeon);

export default reduxForm({
  form: 'episode',
  destroyOnUnmount: false,
  keepValues: true,
  updateUnregisteredFields: true,
})(RedirectWrapper);
