import styled, { css } from 'styled-components/native';

import { MINIMUM_PASSWORD_LENGTH } from 'app/components/CompleteYourProfile/constants';
import { Text } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';

const BodyText = styled(Text).attrs({
  size: 'body1',
})`
  margin-top: ${({ theme }) => theme.spacing}px;
`;

const ContentWrapper = styled(View)`
  padding: ${({ theme }) => theme.spacing}px;
`;

const SubmitButton = styled(TrackableButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white}
    font-size: ${theme.rawFontSizes.medium}px;
    width: ${theme.spacing * 10}px;
  `}
`;

const SubmitButtonWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing * 2.5}px;
    padding-bottom: ${theme.spacing / 2}px;
  `}
`;

const TitleText = styled(Text).attrs({
  align: 'center',
  medium: true,
  size: 'h1',
})``;

export { default as AccountSettingsForm } from 'app/components/Common/AccountSettingsForm';

export const AccountSettingsHeader = () => (
  <ContentWrapper>
    <TitleText>Save your progress</TitleText>
    <BodyText>
      You are currently exploring this benefit as a Guest. Create an account to
      save your progress.
    </BodyText>
    <BodyText>
      Your password must be at least {MINIMUM_PASSWORD_LENGTH} characters long
      and include at least 1 upper case letter and 1 number.
    </BodyText>
  </ContentWrapper>
);

export const AccountSettingsWrapper = styled(View)`
  ${({ theme }) => css`
    max-width: ${theme.breakpoints.xsmall}px;
    width: 100%;
    flex: 1 0 auto;
    height: 100%;
    margin: 0 auto;
  `}
`;

export const ContinueButton = ({ disabled, onPress, title }) => (
  <SubmitButtonWrapper>
    <SubmitButton disabled={disabled} onPress={onPress} title={title} />
  </SubmitButtonWrapper>
);

export { FormContainer as FormWrapper } from 'app/components/CompleteYourProfile/styles';

export const InputWrapper = styled(View)`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
`;
