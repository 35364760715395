import React from 'react';
import PropTypes from 'prop-types';

import { Platform, StyleSheet, View } from 'react-native';
import { Button, Card, Icon, Text } from 'react-native-elements';
import { Field } from 'redux-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import Input from 'app/components/Common/Input';

import { DotIndicator } from 'app/components/Common/svg-components';
import { CostEstimatePropTypes } from 'app/util/propTypes';
import theme from 'app/util/theme';

const formatNumber = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const ProcedureSavings = ({
  costEstimate,
  costEstimateLoading,
  memberIdError,
  memberIdRequired,
  onEstimatePress,
  onKeyboardDidHide,
  onKeyboardDidShow,
}) => {
  let cost = 0;
  let savings = 0;
  if (costEstimate && Object.keys(costEstimate).length > 0) {
    cost =
      costEstimate.carrumDeductible +
      costEstimate.carrumCoinsuranceAmount +
      costEstimate.carrumCopay;
    savings =
      costEstimate.nonCarrumDeductible +
      costEstimate.nonCarrumCoinsuranceAmount +
      costEstimate.nonCarrumCopay -
      cost;
  }

  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      contentContainerStyle={styles.container}
      onKeyboardDidHide={onKeyboardDidHide}
      onKeyboardDidShow={onKeyboardDidShow}
      extraScrollHeight={Platform.select({
        ios: 40,
        android: 80,
      })}
    >
      {costEstimateLoading ? (
        <View>
          <Icon size={106} name="hourglass-empty" />
          <Text h2 h2Style={styles.emptyContentTitle}>
            Estimating Your Cost
          </Text>

          <Text
            style={{ textAlign: 'center', marginBottom: theme.spacing * 2.5 }}
          >
            Please wait while we estimate your out-of-pocket costs (if any) for
            this procedure.
          </Text>

          <DotIndicator style={{ alignSelf: 'center' }} />
        </View>
      ) : costEstimate && Object.keys(costEstimate).length > 0 ? (
        <View>
          <Icon
            color={theme.colors.success}
            size={106}
            name="money-off"
            style={{ marginBottom: theme.spacing * 2.5 }}
          />

          {savings !== 0 && (
            <Text style={{ textAlign: 'center' }}>
              You&apos;re saving &nbsp;
              <Text style={{ color: theme.colors.success }}>
                ${formatNumber(savings)}
              </Text>
              &nbsp; compared to your normal insurance costs.
            </Text>
          )}

          <Card containerStyle={{ marginHorizontal: 0 }}>
            <Card.Title>Your Out-of-Pocket Estimate</Card.Title>

            <View style={{ paddingHorizontal: theme.spacing }}>
              <View style={styles.metric}>
                <Text>Coinsurance:</Text>
                <Text style={{ flex: 1, textAlign: 'right' }}>
                  ${formatNumber(costEstimate.carrumCoinsuranceAmount)}
                </Text>
              </View>
              <View style={styles.metric}>
                <Text>Copay:</Text>
                <Text style={{ flex: 1, textAlign: 'right' }}>
                  ${formatNumber(costEstimate.carrumCopay || 0)}
                </Text>
              </View>

              <View style={styles.metric}>
                <Text>Deductible:</Text>

                <Text style={{ flex: 1, textAlign: 'right' }}>
                  ${formatNumber(costEstimate.carrumCoinsuranceAmount)}
                </Text>
              </View>

              <View style={styles.metric}>
                <Text>Travel:</Text>

                <Text style={{ flex: 1, textAlign: 'right' }}>$0</Text>
              </View>

              <View
                style={{
                  borderTopColor: '#ccc',
                  borderTopWidth: 1,
                  marginVertical: theme.spacing / 4,
                }}
              />

              <View style={styles.metric}>
                <Text h4>Total</Text>

                <Text h4 h4Style={{ flex: 1, textAlign: 'right' }}>
                  ${formatNumber(cost)}
                </Text>
              </View>
            </View>
          </Card>
        </View>
      ) : (
        <View>
          <Icon size={106} name="error-outline" />

          <Text h2 h2Style={styles.emptyContentTitle}>
            Estimate Error
          </Text>

          {memberIdError ? (
            <Text
              style={{ textAlign: 'center', marginBottom: theme.spacing * 2.5 }}
            >
              It looks like there was an issue with your insurance member ID.
              Confirm your member ID below and try again.
            </Text>
          ) : memberIdRequired ? (
            <Text
              style={{ textAlign: 'center', marginBottom: theme.spacing * 2.5 }}
            >
              It looks like your insurance member ID is required to get an
              estimate. Confirm your member ID below to get started.
            </Text>
          ) : (
            <Text
              style={{ textAlign: 'center', marginBottom: theme.spacing * 2.5 }}
            >
              There was a problem calculating your out-of-pocket estimate.
              We&apos;re working on fixing this right now. In the meantime, you
              can still schedule your surgery, and a Patient Care Specialist
              will provide more details.
            </Text>
          )}

          {(memberIdError || memberIdRequired) && (
            <View style={styles.actions}>
              <Field
                name="memberId"
                label="Member ID"
                component={Input}
                containerStyle={styles.input}
                onSubmitEditing={onEstimatePress}
              />

              <Button
                type="clear"
                title="Try Again"
                onPress={onEstimatePress}
              />
            </View>
          )}
        </View>
      )}
    </KeyboardAwareScrollView>
  );
};

ProcedureSavings.propTypes = {
  onEstimatePress: PropTypes.func.isRequired,
  onKeyboardDidHide: PropTypes.func.isRequired,
  onKeyboardDidShow: PropTypes.func.isRequired,

  costEstimate: PropTypes.shape(CostEstimatePropTypes),
  costEstimateLoading: PropTypes.bool,
  memberIdError: PropTypes.bool,
  memberIdRequired: PropTypes.bool,
};

ProcedureSavings.defaultProps = {
  costEstimate: null,
  costEstimateLoading: false,
  memberIdError: false,
  memberIdRequired: false,
};

export default ProcedureSavings;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: theme.breakpoints.small,
    paddingTop: theme.spacing * 2.5,
    paddingBottom: theme.spacing * 12,
    paddingHorizontal: theme.spacing * 1.25,
  },

  emptyContentTitle: {
    marginBottom: theme.spacing / 2,
    marginTop: theme.spacing,
    textAlign: 'center',
  },

  emptyContent: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing * 1.25,
    marginBottom: theme.spacing,
  },

  metric: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing / 2,
  },

  label: {
    flex: 1,
  },

  actions: {
    marginTop: theme.spacing * 1.25,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  input: {
    flex: 1,
  },
});
