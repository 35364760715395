import React, { FC } from 'react';
import { View } from 'react-native';

import BmiStatus from 'app/components/Common/BmiStatus';
import Input from 'app/components/Common/Input';
import Select from 'app/components/Common/Select';
import SectionHeader from 'app/components/Profile/SectionHeader';

import { BMIContainer, Field, Row, SmallInputContainer } from './styles';

const ethnicityOptions = [
  { label: 'White/Non-Hispanic', value: 'white_non_hispanic' },
  { label: 'Hispanic', value: 'hispanic' },
  { label: 'African American', value: 'african_american' },
  { label: 'Asian', value: 'Asian' },
  {
    label: 'Native Hawaiian/Pacific Islander',
    value: 'native_hawaiian_pacific_islander',
  },
  {
    label: 'Native American/Eskimo/Aleut',
    value: 'native_american_eskimo_aleut',
  },
  { label: 'Not Listed', value: 'not_listed' },
  { label: 'Decline to Answer', value: 'decline' },
];

const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Other', value: 'O' },
  { label: 'Decline to Answer', value: 'decline' },
];

const martialStatusOptions = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Domestic Partnership', value: 'domestic_partnership' },
  { label: 'Widowed', value: 'widowed' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Separated', value: 'separated' },
  { label: 'Decline to Answer', value: 'decline' },
];

interface MakeupFormProps {
  formErrors: any;
  bmi: string;
  showBackLink?: boolean;
  showTitle?: boolean;
}

const MakeupForm = ({
  bmi,
  formErrors,
  showBackLink,
  showTitle,
}: MakeupFormProps) => (
  <View testID="MakeupForm">
    <SectionHeader
      showBackLink={showBackLink}
      title="Physical Makeup"
      showTitle={showTitle}
    />

    <Row>
      <SmallInputContainer mr>
        <Field
          error={formErrors['profile.heightFeet']}
          name="profile.heightFeet"
          label="Height (feet)"
          type="numeric"
          maxLength={1}
          component={Input}
        />
      </SmallInputContainer>
      <SmallInputContainer>
        <Field
          error={formErrors['profile.heightInches']}
          name="profile.heightInches"
          label="Height (inches)"
          type="numeric"
          maxLength={2}
          component={Input}
        />
      </SmallInputContainer>
    </Row>

    <Field
      error={formErrors['profile.weight']}
      name="profile.weight"
      label="Weight (pounds)"
      maxLength={3}
      component={Input}
    />

    {bmi ? (
      <BMIContainer>
        <BmiStatus bmi={bmi} />
      </BMIContainer>
    ) : null}

    <Field
      error={formErrors['profile.gender']}
      unSelectedOption
      name="profile.gender"
      label="Gender"
      options={genderOptions}
      component={Select}
    />

    <Field
      error={formErrors['profile.ethnicity']}
      unSelectedOption
      name="profile.ethnicity"
      label="Ethnicity"
      options={ethnicityOptions}
      component={Select}
    />

    <Field
      error={formErrors['profile.maritalStatus']}
      unSelectedOption
      name="profile.maritalStatus"
      label="Marital Status"
      component={Select}
      options={martialStatusOptions}
    />

    <Field
      error={formErrors['profile.religion']}
      name="profile.religion"
      label="Religion"
      component={Input}
    />
  </View>
);

MakeupForm.defaultProps = {
  formErrors: {},
  bmi: '',
  showBackLink: false,
  showTitle: false,
};

export default MakeupForm;
