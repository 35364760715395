import { Animated, Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { default as NavigationLinks } from '../../NavigationLinks';
export { default as ProfileInfo } from '../../ProfileInfo';
import { AnimatedPanelContainerProps } from '../../types';
export { default as CurrentEnvironment } from '../CurrentEnvironment';
export { default as DevToolsLink } from '../DevToolsLink';

export const AnimatedPanelContainer = styled(
  Animated.View
)<AnimatedPanelContainerProps>`
  ${({ isOpen = false, theme }) => css`
    background-color: white;
    bottom: 0;
    ${isOpen &&
    css`
      box-shadow: 2px 0px 8px ${theme.colors.black}80;
    `}
    position: ${Platform.select({
      default: 'absolute',
      web: 'fixed',
    })};
    right: 0;
    top: 0;
  `}
`;

export const PanelContent = styled(View)`
  ${({ theme }) => css`
    flex: 1;
    padding: ${theme.spacing * 1.25}px;
    padding-top: ${Platform.select({
      default: theme.spacing * 2.5,
      web: theme.spacing * 1.25,
    })}px;
    width: ${theme.spacing * 17.5}px;
  `}
`;
