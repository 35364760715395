import React from 'react';

import { routeTo } from 'app/util/routeUtils';
import { TestID } from 'app/util/test-id';

import { AnimatedPanelProps } from '../../types';
import {
  AnimatedPanelContainer,
  CurrentEnvironment,
  DevToolsLink,
  PanelContent,
  ProfileInfo,
  NavigationLinks,
} from './styles';

/**
 * Render the animated panel which may contain profile information for the user
 * and navigation links. The content displayed varies based on conditions such
 * as screen size, the user's authentication status, and details about the
 * user's journey of care.
 */
const AnimatedPanel = ({ onClose, style }: AnimatedPanelProps) => {
  const closeAndNavigateTo = (path: string) => () => {
    onClose();
    routeTo(path);
  };

  return (
    <AnimatedPanelContainer
      testID={TestID.Navigation.AnimatedPanel}
      style={style}
    >
      <PanelContent>
        <ProfileInfo onPress={closeAndNavigateTo('/profile')} />
        <NavigationLinks onClose={onClose} />
        <DevToolsLink />
        <CurrentEnvironment />
      </PanelContent>
    </AnimatedPanelContainer>
  );
};

export default AnimatedPanel;
