interface Location {
  lat: number;
  lng: number;
}

const travelDurations = {};
const travelDurationPromises = {};

/**
 * Get a string representing a time comparison between now and a prior time. The difference is rounded up by the unit: mins, hours, days.
 *
 * @param {number} durationInSeconds Duration provided by Google in seconds.
 * @returns A string escalating from mins to hours to days depending on the length of time since an event.
 */
export const formatDurationString = (durationInSeconds) => {
  const minuteDivisor = 60;
  const hourDivisor = 60 * 60;

  const ageInMinutes = Math.floor(durationInSeconds / minuteDivisor);
  if (ageInMinutes < 60) return `${ageInMinutes} min`;

  const ageInHours = Math.floor(durationInSeconds / hourDivisor);
  const extraMinutes = ageInMinutes % 60;

  return `${ageInHours} hr${` and ${extraMinutes && extraMinutes} min`}`;
};

/**
 * Generates a string representing a time comparison between now and a prior time.
 * The difference is rounded up by the unit: mins, hours, days.
 *
 * NOTE: The result is cached locally, allowing mulitple consumers to get the
 *       travel duration for the same location without making extra API calls.
 *
 * @param {object} userLocation User's Profile address
 * @param {object} destination Location of the destination facility
 * @returns A Promise with a string escalating from mins to hours to days depending on the length of time since an event.
 */
export const getTravelDuration = (
  userLocation: Location,
  destination: Location
): Promise<string> => {
  const travelDurationkey = formatTravelDurationkey(userLocation, destination);

  if (travelDurations[travelDurationkey])
    return Promise.resolve(travelDurations[travelDurationkey]);

  if (travelDurationPromises[travelDurationkey])
    return travelDurationPromises[travelDurationkey];

  return (travelDurationPromises[travelDurationkey] = fetchTravelDuration(
    userLocation,
    destination
  ));
};

const fetchTravelDuration = (
  userLocation: Location,
  destination: Location
): Promise<string> => {
  // TODO: investigate including the global `google` var on mobile
  const directionsService = new google.maps.DirectionsService();
  const travelDurationkey = formatTravelDurationkey(userLocation, destination);

  return directionsService
    .route({
      origin: { lat: userLocation.lat, lng: userLocation.lng },
      destination: {
        lat: destination.lat,
        lng: destination.lng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((resonse) => {
      const result = `${formatDurationString(
        resonse.routes[0].legs[0].duration.value
      )} drive`;

      return (travelDurations[travelDurationkey] = result);
    })
    .catch((err) => {
      console.error({ err });
      return 'Error';
    });
};

const onTravelDurationFetched = (resonse) => {
  return (travelDurations[travelDurationkey] = `${formatDurationString(
    resonse.routes[0].legs[0].duration.value
  )} drive`);
};

const formatTravelDurationkey = (
  userLocation: Location,
  destination: Location
): string => {
  return `${userLocation.lat},${userLocation.lng}:${destination.lat},${destination.lng}`;
};
