import {
  useEffect,
  useMemo,
  useSession,
  useState,
} from 'app/components/CompleteYourProfile/hooks';

import { ProgressProps } from 'app/components/CompleteYourProfile/types';

import {
  LinearProgress,
  ProgressText,
  ProgressTextWrapper,
  ProgressWrapper,
} from './styles';

const Progress = ({ currentStep, totalSteps }: ProgressProps) => {
  const { user } = useSession();
  const isComplete = user?.profile?.currentStep === 0;
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const stepToRender = useMemo(() => {
    if (isFirstRender) {
      return currentStep;
    } else {
      // ignore the `0` currentStep when profile is completed
      return isComplete ? totalSteps : currentStep;
    }
  }, [currentStep, totalSteps]);

  return (
    <>
      <ProgressWrapper>
        {/** There is an issue in react-native-elements, where some properties on <LinearProgress /> are not properly initialized during initial render */}
        <LinearProgress value={stepToRender / totalSteps} />
        <ProgressTextWrapper>
          <ProgressText>
            {stepToRender} / {totalSteps}
          </ProgressText>
        </ProgressTextWrapper>
      </ProgressWrapper>
    </>
  );
};

export default Progress;
