import React, { FC } from 'react';
import { Platform, View } from 'react-native';

import CheckBox from 'app/components/Common/CheckBox';
import Input from 'app/components/Common/Input';
import PhoneInput from 'app/components/Common/PhoneInput';
import Select from 'app/components/Common/Select';
import SectionHeader from 'app/components/Profile/SectionHeader';

import SpokenNameFieldMobile from './SpokenNameFieldMobile';
import { AssociatedFieldLabel, AssociatedFieldView, Field } from './styles';

const languageOptions = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Not Listed', value: 'not_listed' },
  { label: 'Decline to Answer', value: 'decline' },
];

interface SettingsFormProps {
  formErrors: any;
  createUpload: any;
  destroyUpload: any;
  setUploadUri: any;
  setAutoLogout: any;

  showInterpreterRequiredField: boolean;
  showBackLink?: boolean;
  showTitle?: boolean;
  spokenNameUrl: string;
}

const defaultProps = {
  formErrors: {},
  showInterpreterRequiredField: false,
  showBackLink: false,
  showTitle: false,
  spokenNameUrl: '',
};

const SettingsForm = ({
  formErrors,
  createUpload,
  destroyUpload,
  showInterpreterRequiredField,
  showBackLink,
  showTitle,
  setUploadUri,
  spokenNameUrl,
  setAutoLogout,
}: SettingsFormProps) => (
  <View testID="SettingsForm">
    <SectionHeader
      showBackLink={showBackLink}
      title="Settings"
      showTitle={showTitle}
    />
    <Field
      error={formErrors.email}
      name="email"
      label="Email"
      keyboardType="email-address"
      component={Input}
    />

    <Field
      error={formErrors['profile.phoneNumber']}
      name="profile.phoneNumber"
      label="Phone Number"
      component={PhoneInput}
    />

    <AssociatedFieldView>
      <Field
        name="profile.voicemailsAllowed"
        label={
          <AssociatedFieldLabel>
            I give Carrum Health permission to leave voicemails containing
            patient health information at this number.
          </AssociatedFieldLabel>
        }
        component={CheckBox}
      />
    </AssociatedFieldView>

    <Field
      error={formErrors['profile.preferredName']}
      name="profile.preferredName"
      label="Preferred Name"
      component={Input}
    />

    {Platform.OS !== 'web' && (
      <SpokenNameFieldMobile
        setAutoLogout={setAutoLogout}
        createUpload={createUpload}
        destroyUpload={destroyUpload}
        setUploadUri={setUploadUri}
        spokenNameUrl={spokenNameUrl}
      />
    )}

    <Field
      error={formErrors['profile.language']}
      unSelectedOption
      name="profile.language"
      label="Spoken Language"
      component={Select}
      options={languageOptions}
    />

    {showInterpreterRequiredField && (
      <AssociatedFieldView>
        <Field
          name="profile.interpreterRequired"
          label={
            <AssociatedFieldLabel>
              I require an interpreter. / Necesito un interprete de idiomas.
            </AssociatedFieldLabel>
          }
          component={CheckBox}
        />
      </AssociatedFieldView>
    )}
  </View>
);

SettingsForm.defaultProps = defaultProps;

export default SettingsForm;
