import React, { useCallback } from 'react';
import { Button } from 'react-native-elements';
import { useDispatch } from 'react-redux';

import { createEscalation } from 'app/actions/escalationActions';
import { usePlatform } from 'app/hooks';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';

interface ManualTaskButtonProps {
  markTaskComplete: (task: any) => void;
  resetTask: () => void;
  task: any;
}

const ManualTaskButton = ({
  markTaskComplete,
  resetTask,
  task,
}: ManualTaskButtonProps) => {
  const dispatch = useDispatch();
  const { isPlatformTwo } = usePlatform();

  const isEscalation =
    task.taskType === 'manual' && task.escalationType && isPlatformTwo;

  const onEscalationPress = async () => {
    dispatch(
      createEscalation({
        description: `Escalation created by task: ${task.title}`,
        escalationType: task.escalationType,
        escalationStatus: 'active',
        exceptionStatus: 'not_applicable',
        exceptionType: null,
      })
    );
  };

  const completeTask = async () => {
    if (isEscalation) await onEscalationPress();

    markTaskComplete(task);
    resetTask();
  };

  const onPress = useCallback(() => {
    completeTask();
  }, [isEscalation]);

  return (
    <Button
      testID={TestID.Progress.ManualTaskButton}
      raised
      title={task.ctaLabel || 'Confirm'}
      containerStyle={{ margin: theme.spacing * 1.25 }}
      onPress={onPress}
    />
  );
};

export default ManualTaskButton;
