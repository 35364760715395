import {
  AccountSettingsFormState,
  PersonalInformationFormState,
  User,
} from './types';

import {
  isFormat,
  isPhoneNumberFormat,
  isPresent,
  isSsnFormat,
} from 'app/util/validations';

const profileValidations = {
  email: [
    isPresent(),
    isFormat(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
  ],
  'profile.language': [isPresent()],
  'profile.phoneNumber': [isPresent(), isPhoneNumberFormat()],
  'profile.preferredName': [isPresent()],
  'profile.ethnicity': [isPresent()],
  'profile.gender': [isPresent()],
  'profile.heightFeet': [isPresent()],
  'profile.heightInches': [isPresent()],
  'profile.maritalStatus': [isPresent()],
  'profile.weight': [isPresent()],
  location: [isPresent()],
  'profile.insurance.groupNumber': [isPresent()],
  'profile.insurance.memberId': [isPresent()],
  'profile.insurance.planType': [isPresent()],
  'profile.subscriberFullSsn': [isPresent(), isSsnFormat()],
  'profile.emergencyContact.email': [
    isPresent(),
    isFormat(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
  ],
  'profile.emergencyContact.name': [isPresent()],
  'profile.emergencyContact.phone': [isPresent(), isPhoneNumberFormat()],
  'profile.emergencyContact.relationship': [isPresent()],
};

export const getDefaultStep = (savedValues) => {
  const fieldsBySection = getFieldsBySection(savedValues);

  return (
    fieldsBySection.findIndex((section) => section.some((field) => !field)) + 1
  );
};

export const getFieldsBySection = (parentObject) => {
  const { email, profile, location, mailingAddressSame } = parentObject;
  return [
    // required fields in `Settings`
    [email, profile?.phoneNumber, profile?.preferredName, profile?.language],
    // required fields in `Makeup`
    [
      profile?.heightFeet,
      profile?.heightInches,
      profile?.weight,
      profile?.bmi,
      profile?.gender,
      profile?.ethnicity,
      profile?.maritalStatus,
    ],
    // required fields in `Address`
    mailingAddressSame
      ? [
          profile?.address || location?.address,
          profile?.address?.street || location?.address?.street,
          profile?.address?.city || location?.address?.city,
          profile?.address?.state || location?.address?.state,
          profile?.address?.postalCode || location?.address?.postalCode,
        ]
      : [
          profile?.address || location?.address,
          profile?.address?.street || location?.address?.street,
          profile?.address?.city || location?.address?.city,
          profile?.address?.state || location?.address?.state,
          profile?.address?.postalCode || location?.address?.postalCode,
          profile?.mailingAddress,
          profile?.mailingAddress?.street,
          profile?.mailingAddress?.city,
          profile?.mailingAddress?.state,
          profile?.mailingAddress?.postalCode,
        ],
    // required fields in `Insurance`
    [
      profile?.insurance,
      profile?.insurance?.memberId,
      profile?.insurance?.groupNumber,
      profile?.insurance?.planType,
      !profile?.dependent || profile?.subscriberFullSsn,
    ],
    // required fields in `Emergency Contact`
    [
      profile?.emergencyContact,
      profile?.emergencyContact?.name,
      profile?.emergencyContact?.email,
      profile?.emergencyContact?.phone,
      profile?.emergencyContact?.relationship,
    ],
  ];
};

/**
 * Returns an object with properties of a `AccountSettingsFormState`
 * destructured from the current user object in the sesssion.
 */
export const getAccountSettingsFormValuesFromUser = (user: User) => {
  const { email } = user;

  const formValues: AccountSettingsFormState = { email };

  Object.keys(formValues).forEach((field) => {
    if (typeof formValues[field] === 'undefined') delete formValues[field];
  });

  return formValues;
};

/**
 * Returns an object with properties of a `PersonalInformationFormState`
 * destructured from the current user object in the sesssion.
 */
export const getPersonalInformationFormValuesFromUser = (user: User) => {
  const {
    eligiblePatient: { phoneNumber: patientPhoneNumber },
    profile: {
      interpreterRequired,
      language,
      preferredName,
      voicemailsAllowed,
      phoneNumber: profilePhoneNumber,
    },
  } = user;

  const formValues: PersonalInformationFormState = {
    interpreterRequired,
    language,
    phoneNumber: profilePhoneNumber || patientPhoneNumber,
    preferredName,
    voicemailsAllowed,
  };

  Object.keys(formValues).forEach((field) => {
    if (typeof formValues[field] === 'undefined') delete formValues[field];
  });

  return formValues;
};

export { getProfileProgress } from 'app/util/profileUtils';

export const getUserName = (user) =>
  user.profile?.preferredName || `${user.firstName} ${user.lastName}`;

export const getValueForKeyString = (key, object) => {
  const splitKey = key.split('.');
  if (!object) return;
  if (splitKey.length === 1) {
    return object[key];
  } else {
    const child = object[splitKey[0]];
    return getValueForKeyString(splitKey.slice(1).join('.'), child);
  }
};

export { isEmpty } from 'app/util/methods';

export const isStepComplete = (formValues, currentStep) => {
  const fieldsBySection = getFieldsBySection(formValues);
  return fieldsBySection[currentStep - 1]?.every((field) => !!field);
};

export { launchCamera, launchImageLibrary } from 'app/actions/uiActions';

export { logEvent } from 'app/util/analytics';

export { showLocalNotification } from 'app/actions/uiActions';

export { updateUserDetails } from 'app/actions/userActions';

export const validateFormValue = (field, values, setState, formErrors) => {
  setState({ formErrors: {} });

  if (field === 'location') field = 'profile.address';

  const value = getValueForKeyString(field, values);

  [].concat(profileValidations[field]).some((validation) => {
    let error;

    if (field === 'mailingAddressSame') {
      error =
        !values.profile.mailingAddressSame &&
        (!values.profile.mailingAddress.city ||
          !values.profile.mailingAddress.postalCode ||
          !values.profile.mailingAddress.state ||
          !values.profile.mailingAddress.street)
          ? 'provide complete mailing address'
          : undefined;
    } else {
      error = validation?.(value, values);
    }

    if (error) {
      formErrors[field] = error;
    } else if (!error) {
      delete formErrors[field];
    }

    setState({ formErrors });

    return Boolean(error);
  });
};
