import axios from 'axios';

import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';
import { pageNumberFromUrl } from 'app/util/methods';
import { parseUserLocation } from 'app/util/userUtils';
import {
  receiveEpisodeLoading,
  receivePossibleHospitals,
} from './action-types';

/**
 * Fetches possible hospitals for the procedure id.
 *
 * @param {integer} procedureId The ID of the offered procedure
 * @param {string} sort A key to sort hospitals by
 * @param {integer} page The page of surgeons to fetch
 * @return {promise} A promise that resolves with the hospitals that perform the procedure.
 */
export default (procedureId, sort, page = 1) => {
  return async (dispatch, getState) => {
    await dispatch(receiveEpisodeLoading(true));

    try {
      const { coreToken, user } = getState().session;
      const { latitude, longitude } = parseUserLocation(user);

      const params = {
        sort,
        status: 'active',
        include: 'physicians,product_offering',
        'page[size]': 30,
        'page[number]': page,
      };

      /**
       * If both longitude and latitude are known, include them as filter params
       * for this API request.  If not, prevent sorting by distance.
       */
      if (latitude && longitude) {
        params['location[latitude]'] = latitude;
        params['location[longitude]'] = longitude;
      } else {
        params.sort = params.sort.replace('distance', 'physicians.id');
      }

      const response = await axios.get(
        `${CORE_API_URL}/offered_procedures/${procedureId}/hospitals`,
        {
          params,
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      dispatch(
        receivePossibleHospitals(
          response.data.data,
          parseInt(response.headers.total || 0),
          pageNumberFromUrl(response.data.links.self),
          pageNumberFromUrl(response.data.links.next)
        )
      );
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
