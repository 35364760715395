import React, { Component } from 'react';
import { Platform, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Route, Switch } from '@cross-platform/react-router-native';

import FloatingActionButton from 'app/components/Common/FloatingActionButton';
import Modal, { ModalOptionType } from 'app/components/Common/Modal';
import { EVENTS, logEvent } from 'app/util/analytics';
import { isEmpty } from 'app/util/methods';
import { calculateBmi, getProfileProgress } from 'app/util/profileUtils';
import theme from 'app/util/theme';

import AddressForm from './AddressForm';
import EmergencyContactForm from './EmergencyContactForm';
import { validateFormValue } from './helpers';
import InsuranceForm from './InsuranceForm';
import MakeupForm from './MakeupForm';
import ProfileHeader from './ProfileHeader';
import ProfileSummary from './ProfileSummary';
import SettingsForm from './SettingsForm';
import {
  BackgroundContainer,
  FABContainer,
  FormContainer,
  SummaryContainer,
  SummaryInnerContainer,
} from './styles';
import Wizard from './Wizard';

interface ClassProps {
  loadingTimeout: ReturnType<typeof setTimeout>;
  savedTimeout: ReturnType<typeof setTimeout>;
  scrollView: KeyboardAwareScrollView;
}

interface PassedProps {
  change: (...args: any[]) => any;
  createUpload: (...args: any[]) => any;
  destroyUpload: (...args: any[]) => any;
  formValues: any;
  registeredFields: any;
  initialize: (...args: any[]) => any;
  launchCamera: (...args: any[]) => any;
  launchImageLibrary: (...args: any[]) => any;
  pathname: string;
  push: (...args: any[]) => any;
  updateUserDetails: (...args: any[]) => any;
  showLocalNotification: (...args: any[]) => any;
  setAutoLogout: (...args: any[]) => any;

  episode: any;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    profileImage: string;
    profile: any;
    eligiblePatient: any;
  };

  isPlatformTwo: boolean;
}

interface State {
  editing: boolean;
  error: boolean;
  formErrors: any;
  loading: boolean;
  saved: boolean;
  selectedIndex: number;
  showAvatarOverlay: boolean;
  transitioning: boolean;
}

class Profile extends Component<PassedProps, State> implements ClassProps {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      error: false,
      formErrors: {},
      loading: false,
      saved: false,
      selectedIndex: 0,
      showAvatarOverlay: false,
      transitioning: false,
    };

    if (!isEmpty(props.user)) props.initialize(props.user);
  }
  loadingTimeout = undefined;
  savedTimeout = undefined;
  scrollView;

  componentDidUpdate = (prevProps) => {
    if (isEmpty(this.props.user)) return;

    if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.props.initialize(this.props.user);
    }

    if (!prevProps.formValues.profile) return;

    if (this.shouldCalculateBmi(prevProps)) {
      const { bmi, heightFeet, heightInches, weight } =
        this.props.formValues.profile ?? {};

      if (bmi && (!heightFeet || !heightInches || !weight)) {
        return this.props.change('profile.bmi', '');
      }

      this.props.change(
        'profile.bmi',
        calculateBmi(heightFeet, heightInches, weight)
      );
    }

    if (
      prevProps.formValues.mailingAddressSame !==
      this.props.formValues.mailingAddressSame
    ) {
      const address = this.props.formValues.mailingAddressSame
        ? this.props.formValues.location.address
        : {
            street: '',
            unit: '',
            city: '',
            state: '',
            postalCode: '',
          };

      this.props.change('profile.mailingAddress', address);
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.loadingTimeout);
    clearTimeout(this.savedTimeout);
  };

  scrollToTop = () => {
    // eslint-disable-next-line no-undef
    if (window?.scrollTo && document?.body) {
      // eslint-disable-next-line no-undef
      return window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (this.scrollView) this.scrollView.scrollToPosition(0, 0, true);
  };

  /**
   * Retuns true if the height or weight fields changed.
   *
   * @param {object} prevProps An object with props from the previous state.
   * @return {boolean} True if the height or weight fields changed.
   */
  shouldCalculateBmi = (prevProps) => {
    const { bmi, heightFeet, heightInches, weight } =
      this.props.formValues.profile ?? {};

    if (bmi && (!heightFeet || !heightInches || !weight)) return true;
    if (!bmi && heightFeet && heightInches && weight) return true;

    return (
      heightFeet !== prevProps.formValues.profile.heightFeet ||
      heightInches !== prevProps.formValues.profile.heightInches ||
      weight !== prevProps.formValues.profile.weight
    );
  };

  /**
   * Shows an Alert with options for editing the
   * user's profile image.
   */
  onAvatarEdit = () => {
    this.setState({ showAvatarOverlay: true });
  };

  /**
   * Launches the camera roll to allow users to
   * send photos already on their device.
   */
  onAlbumPress = async () => {
    const { cancelled, uri } = await this.props.launchImageLibrary({
      allowsEditing: true,
      aspect: [1, 1],
      mediaTypes: 'Images',
    });

    if (uri && !cancelled) this.onChangeProfileImage(uri);
  };

  /**
   * Launches the camera app to allow users to
   * take and send photos using their device.
   */
  onCameraPress = async () => {
    const { cancelled, uri } = await this.props.launchCamera({
      allowsEditing: true,
      aspect: [1, 1],
      mediaTypes: 'Images',
    });

    if (uri && !cancelled) this.onChangeProfileImage(uri);
  };

  /**
   * Clears the stored profile image.
   */
  onClearProfileImage = () => {
    this.onChangeProfileImage('');
  };

  /**
   * If in the edit state, persist the change
   */
  onChangeProfileImage = (profileImage) => {
    if (this.state.editing) {
      this.props.change('profileImage', profileImage);
    } else {
      this.props.updateUserDetails({ profileImage });
    }

    this.setState({ showAvatarOverlay: false });
  };

  onEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  /**
   * Determine the appropriate GA event key based on step change
   * @param {*} prevStep previous step in profile flow
   * @param {*} nextStep new step in profile flow
   */
  getSaveEventKey = (prevStep, nextStep) => {
    if (prevStep === 0) {
      return EVENTS.profile.update;
    } else if (nextStep > 0 && nextStep < prevStep) {
      return EVENTS.profile.previous;
    } else if (nextStep === 0) {
      return EVENTS.profile.complete;
    } else if (!prevStep || nextStep > prevStep) {
      return EVENTS.profile.save;
    }
  };

  /**
   * Calls `props.updateUserDetails()` with attributes for the User record.
   *
   * @return {promise} A promise that resolves after making the requests.
   */
  onSubmit = async (nextStep: number, navigate = false, notify = false) => {
    const prevStep = this.props.user.profile.currentStep;
    const isComplete = nextStep === 0;

    this.setState({ loading: true });

    if (nextStep >= prevStep) {
      await Object.keys(this.props.registeredFields).forEach((field) =>
        validateFormValue(
          field,
          this.props.formValues,
          this.setState.bind(this),
          this.state.formErrors
        )
      );
    }

    if ((await !isEmpty(this.state.formErrors)) && (notify || prevStep === 0)) {
      this.setState({ loading: false });
      return;
    }

    const { email, location, mailingAddressSame, profileImage, profile } =
      this.props.formValues;
    const params = {
      email,
      profileImage,
      profile: { ...profile, currentStep: nextStep },
    };

    if (
      location &&
      location.address &&
      Object.keys(location.address).length > 0
    ) {
      params.profile.address = location.address;
      params.profile.location = {
        latitude: location.lat,
        longitude: location.lng,
      };
      if (mailingAddressSame) params.profile.mailingAddress = location.address;
    }

    // clear subscriber SSN if the user is the subscriber
    if (!params.profile?.dependent) {
      params.profile.subscriberFullSsn = '';
    }

    // ensure `partnerId` is sync'd with eligibility data
    if (!params.profile?.insurance?.partnerId) {
      params.profile.insurance = {
        ...params.profile.insurance,
        partnerId: this.props.user.eligiblePatient.insurance.partnerId,
      };
    }

    // reset interpreter flag if user speaks English
    if (params.profile?.language?.toLowerCase() === 'english') {
      params.profile.interpreterRequired = false;
    }

    const isSuccess = await this.props.updateUserDetails(params);

    const saveEventKey = this.getSaveEventKey(prevStep, nextStep);

    this.loadingTimeout = setTimeout(() => {
      this.setState({
        loading: false,
        editing: !isSuccess,
        error: !isSuccess,
        saved: isSuccess,
      });

      if (isSuccess) {
        // Log appropriate event to Google Analytics
        if (saveEventKey) logEvent(saveEventKey);

        if (notify) {
          this.props.showLocalNotification({
            id: isComplete ? 'profile-complete' : `profile-saved-${prevStep}`,
            icon: 'check',
            title: isComplete ? 'Profile Completed!' : 'Changes saved.',
            dismissIn: isComplete ? 5000 : 2000,
            status: isComplete ? 'success' : 'info',
          });
        }

        this.scrollToTop();
      }

      this.setState({ formErrors: {} });
    }, 400);

    this.savedTimeout = setTimeout(() => {
      this.setState({ error: false, saved: false });
      if (navigate && isSuccess) this.props.push('/profile');
    }, 1400);
  };

  render() {
    if (isEmpty(this.props.user)) return <View />;

    const progress =
      !this.state.editing && isEmpty(this.props.user.profile)
        ? 0
        : getProfileProgress(this.props.formValues);

    const hasAvatar = Boolean(this.props.formValues.profileImage);

    const isEditing = this.props.pathname
      .split('/')
      .some((substring) =>
        [
          'settings',
          'makeup',
          'address',
          'insurance',
          'emergency-contact',
        ].includes(substring.toLowerCase())
      );

    const { currentStep } = this.props.user.profile;

    return (
      // @ts-ignore TODO: fix TS warning
      <>
        {(typeof currentStep === 'undefined' || currentStep >= 1) && (
          <BackgroundContainer testID="Profile">
            <Wizard
              profile={this.props.user.profile}
              setAutoLogout={this.props.setAutoLogout}
              createUpload={this.props.createUpload}
              destroyUpload={this.props.destroyUpload}
              currentStep={currentStep ?? 1}
              formValues={this.props.formValues}
              formErrors={this.state.formErrors}
              notify={this.props.showLocalNotification}
              onSave={this.onSubmit.bind(this)}
              setUploadUri={(newUri) =>
                this.props.change('profile.spokenNameUrl', newUri)
              }
              spokenNameUrl={this.props.formValues?.profile?.spokenNameUrl}
            />
          </BackgroundContainer>
        )}

        {currentStep === 0 && (
          <SummaryContainer isEditing={isEditing} testID="Profile">
            <SummaryInnerContainer
              enableOnAndroid
              extraHeight={48}
              keyboardShouldPersistTaps="handled"
              ref={(scrollView) => (this.scrollView = scrollView)}
            >
              {this.props.pathname !== '/profile/complete' &&
                !this.props.isPlatformTwo && (
                  <ProfileHeader
                    name={
                      this.props.user.profile?.preferredName ||
                      `${this.props.user.firstName} ${this.props.user.lastName}`
                    }
                    email={this.props.user.email}
                    onAvatarEdit={this.onAvatarEdit}
                    profileImage={this.props.formValues.profileImage}
                    progress={progress}
                  />
                )}

              <Switch>
                <BackgroundContainer>
                  <FormContainer>
                    <Route
                      exact
                      path="/profile"
                      render={() => (
                        <ProfileSummary
                          {...(this.props.user.profile || {})}
                          episode={this.props.episode}
                        />
                      )}
                    />

                    <Route
                      path="/profile/settings"
                      render={() => (
                        <SettingsForm
                          formErrors={this.state.formErrors}
                          setAutoLogout={this.props.setAutoLogout}
                          createUpload={this.props.createUpload}
                          destroyUpload={this.props.destroyUpload}
                          setUploadUri={(newUri) =>
                            this.props.change('profile.spokenNameUrl', newUri)
                          }
                          spokenNameUrl={
                            this.props.formValues?.profile?.spokenNameUrl
                          }
                          showInterpreterRequiredField={
                            this.props.formValues?.profile?.language?.toLowerCase() !==
                            'english'
                          }
                          showBackLink
                          showTitle
                        />
                      )}
                    />
                    <Route
                      path="/profile/makeup"
                      render={() => (
                        <MakeupForm
                          bmi={this.props.formValues.profile?.bmi}
                          formErrors={this.state.formErrors}
                          showBackLink
                          showTitle
                        />
                      )}
                    />
                    <Route
                      path="/profile/address"
                      render={() => (
                        <AddressForm
                          formErrors={this.state.formErrors}
                          location={this.props.formValues.location}
                          mailingAddressSame={
                            this.props.formValues.mailingAddressSame
                          }
                          showBackLink
                          showTitle
                        />
                      )}
                    />
                    <Route
                      path="/profile/insurance"
                      render={() => (
                        <InsuranceForm
                          formErrors={this.state.formErrors}
                          showBackLink
                          showTitle
                          dependent={Boolean(
                            this.props.formValues.profile?.dependent
                          )}
                          providerName={
                            this.props.user.profile?.insurance?.name
                          }
                          planName={
                            this.props.user.profile?.insurance?.planName
                          }
                          isHsaEligible={Boolean(
                            this.props.user.profile?.insurance?.hsaFlag
                          )}
                        />
                      )}
                    />
                    <Route
                      path="/profile/emergency-contact"
                      render={() => (
                        <EmergencyContactForm
                          formErrors={this.state.formErrors}
                          showBackLink
                          showTitle
                        />
                      )}
                    />
                  </FormContainer>
                </BackgroundContainer>
              </Switch>
            </SummaryInnerContainer>

            {isEditing && (
              <>
                <FABContainer>
                  <FloatingActionButton
                    disabled
                    show={!this.state.transitioning && this.state.saved}
                    color={theme.colors.success}
                    icon="check"
                    reverseColor="#fff"
                    sound="success"
                  />
                </FABContainer>
                <FABContainer>
                  <FloatingActionButton
                    show={
                      !this.state.transitioning &&
                      !this.state.error &&
                      !this.state.saved
                    }
                    disabled={this.state.loading}
                    onPress={() => this.onSubmit(0, true)}
                    color={theme.colors.primary}
                    loading={this.state.loading}
                    icon="save"
                    reverseColor="#fff"
                  />
                </FABContainer>
              </>
            )}

            {/* @ts-ignore TODO: address this vvv TS warning */}
            <Modal
              isVisible={this.state.showAvatarOverlay}
              title={hasAvatar ? 'Change Profile Image' : 'Set Profile Image'}
              subtitle={
                hasAvatar
                  ? 'Change your saved profile image.'
                  : 'Set a profile image for your account.'
              }
              onClose={() => this.setState({ showAvatarOverlay: false })}
              options={[
                Platform.OS !== 'web' && {
                  title: 'Take photo',
                  onPress: this.onCameraPress,
                  type: 'solid' as ModalOptionType,
                },
                {
                  title: 'Pick photo',
                  onPress: this.onAlbumPress,
                  type: 'solid' as ModalOptionType,
                },
                hasAvatar && {
                  title: 'Clear photo',
                  onPress: this.onClearProfileImage,
                  type: 'outline' as ModalOptionType,
                },
              ].filter((isPresent) => isPresent)}
            />
          </SummaryContainer>
        )}
      </>
    );
  }
}

export default Profile;
