import {
  expiredAuthLinkConfig,
  TestID,
} from 'app/components/Register/constants';

import { getSavedSingleClickRegistrations } from 'app/components/Register/helpers';

import {
  useCallback,
  useEffect,
  useHistory,
  useState,
} from 'app/components/Register/hooks';

import { ExpiredAuthLink, Routes } from 'app/components/Register/styles';

/**
 * Display possible pivots for user after they use an expired single-click
 * registration link.
 */
const SingleClickRegistrationExpired = () => {
  const history = useHistory();

  const [guestEmail, setGuestEmail] = useState('');

  /**
   * Pull the most recent single-click registrant email address from local
   * storage when this component mounts.
   *
   * - If one or more addresses are found, use the most recent as the magic
   *   link recipient. (NOTE: There is an edge-case scenario in which the email
   *   will be misdirected when multiple guests use the same browser and
   *   device.)
   * - If no address is found, it is not possible to send a magic link
   *   directly. Redirect the user to the Login page instead so that they
   *   can provide the email address and request a magic link.
   */
  useEffect(() => {
    handleGuestEmailFromLocalStorage();
  });

  const handleGuestEmailFromLocalStorage = useCallback(async () => {
    const registrantEmails = await getSavedSingleClickRegistrations();

    if (registrantEmails.length === 0) history.push(`/${Routes.Login}`);

    const lastItemIndex = registrantEmails.length - 1;

    if (!registrantEmails[lastItemIndex]) return;

    setGuestEmail(registrantEmails[lastItemIndex]);
  }, [setGuestEmail]);

  return (
    <ExpiredAuthLink
      guestEmail={guestEmail}
      guidanceText={expiredAuthLinkConfig.guidanceText}
      headerText={expiredAuthLinkConfig.headerText}
      history={history}
      linkText={expiredAuthLinkConfig.linkText}
      testId={TestID.Register.SingleClickRegistrationExpired}
    />
  );
};

export default SingleClickRegistrationExpired;
