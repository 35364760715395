import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { Redirect, withRouter } from '@cross-platform/react-router-native';
import MapView, { Marker } from '@cross-platform/react-native-maps';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Field } from 'redux-form';

import GooglePlacesInput from 'app/components/Common/GooglePlacesInput';
import Stepper from 'app/components/Common/Stepper';

import {
  InquiryPropTypes,
  HistoryPropTypes,
  SessionPropTypes,
} from 'app/util/propTypes';
import { Routes } from 'app/util/routes';
import theme from 'app/util/theme';

const isValid = ({ episode }) =>
  episode && episode.values && episode.values.location;

export const formatCoords = ({ lat, lng }) => ({
  lat,
  lng,
  latitude: lat,
  longitude: lng,
  latitudeDelta: 0.0922,
  longitudeDelta: 0.0421,
});

export class Location extends Component {
  static propTypes = {
    createInquiryFromState: PropTypes.func.isRequired,
    episode: PropTypes.shape({
      inquiry: InquiryPropTypes,
    }),
    form: PropTypes.shape({
      episode: PropTypes.shape({
        values: InquiryPropTypes,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape(HistoryPropTypes).isRequired,
    session: PropTypes.shape(SessionPropTypes).isRequired,
    updateInquiry: PropTypes.func.isRequired,
  };

  onSubmit = async () => {
    const { location } = this.props.form.episode.values;

    const promise = this.props.episode.inquiry
      ? this.props.updateInquiry(this.props.episode.inquiry.id, { location })
      : this.props.createInquiryFromState();
    const isSuccess = await promise;

    if (isSuccess) this.props.history.push(`/${Routes.Confirmation}`);
  };

  render() {
    if (
      !this.props.form ||
      !this.props.form.episode ||
      !this.props.form.episode.values
    ) {
      return <Redirect to={Routes.Home} />;
    }

    const { location } = this.props.form.episode.values;

    return (
      <KeyboardAwareScrollView
        enableOnAndroid
        keyboardShouldPersistTaps="always"
      >
        <Stepper bordered step={2} steps={['', '', '']} />

        <View style={locationStyles.container}>
          <Text h1>Confirm Your Location</Text>

          <Text style={StyleSheet.flatten([locationStyles.message])}>
            Please confirm your current address to ensure the most relevant
            providers in your region.
          </Text>

          <View style={locationStyles.mapWrapper}>
            <View>
              <Field
                name="location"
                label="Your address"
                component={GooglePlacesInput}
              />
            </View>

            <MapView style={locationStyles.map} region={formatCoords(location)}>
              <Marker
                coordinate={formatCoords(location)}
                position={formatCoords(location)}
              />
            </MapView>
          </View>

          <Button
            raised
            title="Continue"
            containerStyle={locationStyles.button}
            disabled={!isValid(this.props.form)}
            onPress={this.onSubmit}
          />

          <Text style={StyleSheet.flatten([locationStyles.helpText])}>
            Need help? Call us at 1-888-855-7806
          </Text>
        </View>
      </KeyboardAwareScrollView>
    );
  }
}

export const locationStyles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: theme.breakpoints.small,
    paddingHorizontal: theme.spacing * 1.25,
    paddingBottom: theme.spacing * 1.25,
  },

  message: {
    textAlign: 'center',
    marginBottom: theme.spacing * 2.5,
  },

  helpText: {
    marginVertical: theme.spacing * 2.5,
    textAlign: 'center',
  },

  mapWrapper: {
    flexShrink: 1,
  },

  map: {
    width: '100%',
    height: 180,
    marginTop: theme.spacing * 1.25,
    marginBottom: theme.spacing * 1.25,
  },
});

export default withRouter(Location);
