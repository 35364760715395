import {
  defaultFormState,
  EVENTS,
  TestID,
} from 'app/components/Login/constants';

import {
  callCarrum,
  getItemAsync,
  isEmpty,
  isValid,
  isWithinDateRangeOf,
  logEvent,
  loginCoreUser,
  receiveSessionError,
  storeCredentials,
} from 'app/components/Login/helpers';

import {
  useAppState,
  useDispatch,
  useEffect,
  useHistory,
  useRouteMatch,
  useSession,
  useState,
} from 'app/components/Login/hooks';

import {
  Anchor,
  Biometrics,
  CenteredScrollScreen,
  Email,
  HelpText,
  KeyboardAvoidingView,
  Linking,
  LogoContainer,
  MagicLinkConfirmation,
  MagicLinkRequest,
  Password,
  RedVerticalLogo,
  Route,
  Routes,
  Switch,
  Text,
  TitleText,
  TopBackLink,
  TopBackLinkWrapper,
} from 'app/components/Login/styles';

import { LoginStep } from 'app/components/Login/types';

export const Login = () => {
  let email;
  let resent;

  const { appState } = useAppState();
  const dispatch = useDispatch();
  const { location } = useHistory();
  const match = useRouteMatch();
  const session = useSession();
  const { queryParams } = Linking.parse(location?.search || '?');

  if (!!queryParams) ({ email, resent } = queryParams);
  // ^ NOTE: app/components/App/spec.tsx test fails without this conditional check

  const emailQueryParamExists = !!email;
  const magicLinkWasResent = resent === 'true';

  const error = session.error;
  const initialLoginStep = magicLinkWasResent ? 'magic-link-sent' : 'email';
  const loading = session.loading;
  const softLogout = Boolean(
    !session.error && !session.loading && session.user?.id
  );

  const [loginStep, setLoginStep] = useState<LoginStep>(initialLoginStep);
  const [formValues, setFormValues] = useState(defaultFormState);

  useEffect(() => {
    // Sync form values with localStore selections on mount
    (async () => {
      const result = (await getItemAsync('localRememberDeviceSetting')) || '{}';
      const { userEmail, shouldRememberDateSet, shouldRememberDevice } =
        JSON.parse(result);
      const isLocalRememberMeValueValid = isWithinDateRangeOf(
        new Date(shouldRememberDateSet),
        30
      );

      if (isLocalRememberMeValueValid) {
        setFormValues({
          ...formValues,
          email: userEmail || session?.user?.email,
          shouldRememberDevice,
        });
      }

      // override email in state when magic link was resent
      if (emailQueryParamExists && magicLinkWasResent) {
        setFormValues({
          ...formValues,
          email: email.toString(),
        });
      }
    })();
  }, []);

  /** Clear any session errors and log the path on component mount. */
  useEffect(() => {
    dispatch(receiveSessionError(null));

    if (location.pathname === '/login/activated') {
      logEvent(EVENTS.account.activate);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Proceed to `Password` screen if user was auto-logged out and email is
   * still in Redux.
   */
  useEffect(() => {
    if (session?.user?.email) {
      setFormValues({ ...formValues, email: session.user.email });
      setLoginStep('password');
    }
  }, [session.user.email]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Return to the email form and reset the password and error state. */
  const goToEmailForm = () => {
    setFormValues({ ...formValues, password: defaultFormState.password });
    setLoginStep('email');
    dispatch(receiveSessionError(null));
  };

  /** Log the user in and securely store the credentials if successful. */
  const logUserIn = async () => {
    if (loading || !isValid(formValues)) return;

    await dispatch(loginCoreUser(formValues));

    if (!error) storeCredentials(formValues);
  };

  const hideBackButton =
    (loginStep === 'password' && !isEmpty(session?.user)) ||
    loginStep === 'email';

  /** Update a specific property of the form values object held in state. */
  const updateFormValues = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <CenteredScrollScreen testID={TestID.Login.Page}>
      <KeyboardAvoidingView behavior="position">
        {!hideBackButton && (
          <TopBackLinkWrapper>
            <TopBackLink
              onPress={goToEmailForm}
              testID={TestID.Login.TopBackLink}
            />
          </TopBackLinkWrapper>
        )}

        {loginStep === 'email' && (
          <LogoContainer>
            <RedVerticalLogo height={120} width={200} />
          </LogoContainer>
        )}

        <Switch>
          <Route
            exact
            path={`${match.url}/activated`}
            render={() => <Text h1>Account Activated</Text>}
          />
          <Route
            exact
            path={`${match.url}/previous`}
            render={() => <Text h1>Already Activated</Text>}
          />

          {(loginStep === 'email' ||
            loginStep === 'password' ||
            loginStep === 'magic-link') && <TitleText>Login</TitleText>}

          {loginStep === 'magic-link-sent' && (
            <TitleText>Check your email</TitleText>
          )}
        </Switch>

        {loginStep === 'email' && (
          <Email
            error={error}
            formValues={formValues}
            loading={loading}
            onChange={updateFormValues}
            setLoginStep={setLoginStep}
            softLogout={softLogout}
          />
        )}
        {loginStep === 'password' && (
          <Password
            disabled={!formValues.password}
            error={error}
            formValues={formValues}
            loading={loading}
            onChange={updateFormValues}
            onSubmit={logUserIn}
            setLoginStep={setLoginStep}
            softLogout={softLogout}
          />
        )}
        {loginStep === 'magic-link' && (
          <MagicLinkRequest
            formValues={formValues}
            isResendRequest={magicLinkWasResent}
            loading={loading}
            setLoginStep={setLoginStep}
          />
        )}
        {loginStep === 'magic-link-sent' && (
          <MagicLinkConfirmation
            formValues={formValues}
            loading={loading}
            setLoginStep={setLoginStep}
          />
        )}
      </KeyboardAvoidingView>

      <Biometrics
        appState={appState}
        error={error}
        onSubmit={(params) => dispatch(loginCoreUser(params))}
      />

      {loginStep === 'magic-link-sent' ? (
        <>
          <HelpText>Need Help&nbsp;?</HelpText>
          <HelpText>
            <Anchor to={`/${Routes.LearnMore}`} title="See FAQ" />
            &nbsp; or call us at&nbsp;
            {/* @ts-ignore */}
            <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />
          </HelpText>
        </>
      ) : (
        <>
          <HelpText>
            Don&apos;t have an account?&nbsp;
            <Anchor to={`/${Routes.Register}`} title="Register" />
          </HelpText>
          <HelpText>
            Have questions?&nbsp;
            <Anchor to={`/${Routes.LearnMore}`} title="See FAQ" />
          </HelpText>
          <HelpText>
            or call us at&nbsp;
            {/* @ts-ignore */}
            <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />
          </HelpText>
        </>
      )}
    </CenteredScrollScreen>
  );
};

export default Login;
