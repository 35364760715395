import { Button as ButtonRaw, ButtonProps, Icon } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

export { default as GetHelpModal } from '../GetHelpModal';

export const Button = styled(ButtonRaw).attrs<ButtonProps>(({ theme }) => {
  const isSmallScreen = theme.windowDimensions.width < theme.breakpoints.large;
  return {
    buttonStyle: { padding: isSmallScreen ? 0 : theme.spacing / 2 },
    icon: <Icon name="call" color={theme.colors.primary} />,
    title: isSmallScreen ? '' : 'Need help? Talk to us',
    titleStyle: {
      marginLeft: isSmallScreen ? 0 : theme.spacing / 2,
    },
    type: isSmallScreen ? 'clear' : 'outline',
  };
})<ButtonProps>`
  ${({ theme }) => css`
    margin-right: ${theme.spacing / 2}px;
  `}
`;
