import { Routes, TestID } from 'app/components/CompleteYourProfile/constants';

import {
  getNextProfileRoute,
  getPreviousProfileRoute,
} from 'app/components/CompleteYourProfile/helpers';

import { useHistory } from 'app/components/CompleteYourProfile/hooks';

import {
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import { OncologyPageProps } from 'app/components/CompleteYourProfile/types';

import {
  OncologyWrapper,
  Select,
  TextField,
} from 'app/components/CompleteYourProfile/Questionnaire/Oncology/styles';

export const OncologyPage = ({
  isSaving,
  onChange,
  onNext,
  values,
}: OncologyPageProps) => {
  const history = useHistory();

  const {
    hasConfirmedDiagnosisForOncology,
    isUnderActiveTreatmentForOncology,
    howToHelpWithDiagnosisForOncology,
  } = values;

  return (
    <StepScreen>
      <FormContainer>
        <Header title="Tell us about your journey with cancer" />
        <OncologyWrapper testID={TestID.CompleteYourProfile.Oncology}>
          <Select
            label="Has your diagnosis been confirmed?"
            input={{
              name: TestID.CompleteYourProfile.HasConfirmedDiagnosis, // passed on to `testID`
              value: hasConfirmedDiagnosisForOncology?.toString(),
              onChange: (value) =>
                onChange({
                  hasConfirmedDiagnosisForOncology: value === 'true',
                }),
            }}
            options={[
              { value: 'false', label: 'No' },
              { value: 'true', label: 'Yes' },
            ]}
            unSelectedOption
          />
          <Select
            label="Are you under active treatment?"
            input={{
              name: TestID.CompleteYourProfile.IsUnderActiveTreatment, // passed on to `testID`
              value: isUnderActiveTreatmentForOncology?.toString(),
              onChange: (value) =>
                onChange({
                  isUnderActiveTreatmentForOncology: value === 'true',
                }),
            }}
            options={[
              { value: 'false', label: 'No' },
              { value: 'true', label: 'Yes' },
            ]}
            unSelectedOption
          />
          <TextField
            label="How can we help with your diagnosis?"
            input={{
              name: TestID.CompleteYourProfile.HowToHelpWithDiagnosis, // passed on to `testID`
              value: howToHelpWithDiagnosisForOncology,
              onChange: (value) =>
                onChange({ howToHelpWithDiagnosisForOncology: value }),
            }}
          />
        </OncologyWrapper>
      </FormContainer>
      <ProfileCTAs
        isNextDisabled={
          typeof hasConfirmedDiagnosisForOncology === 'undefined' ||
          typeof isUnderActiveTreatmentForOncology === 'undefined'
        }
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() => {
            history.push(getNextProfileRoute(Routes.Oncology));
          });
        }}
        onPrevious={() =>
          history.push(getPreviousProfileRoute(Routes.Oncology))
        }
      />
    </StepScreen>
  );
};

export default OncologyPage;
