import { ENVIRONMENT } from 'app/util/constants';

import { nonProdEnvironments } from '../../constants';
import {
  CurrentEnvironmentBoldText,
  CurrentEnvironmentContainer,
  CurrentEnvironmentText,
  EnvironmentSwitch,
} from './styles';

const CurrentEnvironment = () => (
  <EnvironmentSwitch environments={nonProdEnvironments}>
    <CurrentEnvironmentContainer>
      <CurrentEnvironmentText>
        Running app in&nbsp;
        <CurrentEnvironmentBoldText>{ENVIRONMENT}</CurrentEnvironmentBoldText>
      </CurrentEnvironmentText>
    </CurrentEnvironmentContainer>
  </EnvironmentSwitch>
);

export default CurrentEnvironment;
