import { protectedRoutes } from 'app/util/routes';

const addRootDir = (path: string) => {
  if (path === '/') {
    return path;
  }

  return `/${path}`;
};

const protectedRoutesArr = Object.entries(protectedRoutes)
  .filter(([__, isProtectedRoute]) => isProtectedRoute)
  .map(([route]) => addRootDir(route));

export const isProtected = (path: string) => {
  return protectedRoutesArr.some((route) => path?.startsWith(route));
};
