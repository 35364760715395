import { cloneDeep, isEmpty, merge } from 'lodash';

import { formatDateDisplayToRails, toDateTimeString } from 'app/util/methods';
import { Insurance } from 'types/insurance';
import { Location } from 'types/location';
import { Session } from 'types/session';

/**
 * Returns true if the "bypass SSN" setting is turned on for registration. Otherwise, returns false.
 */
export function getBypassSsnRegistration(
  sessionState: Session.SessionState
): boolean {
  return !!sessionState?.user?.employer?.contract?.bypassSsnRegistration;
}

/**
 * Returns true if user is on Platform 1
 */
export function getIsPlatformOne(sessionState: Session.SessionState): boolean {
  return !getIsPlatformTwo(sessionState);
}

/**
 * Returns true if user is on Platform 2
 */
export function getIsPlatformTwo(sessionState: Session.SessionState): boolean {
  // Return early if no contract object is defined.
  if (!sessionState?.user?.employer?.contract) return false;

  const { platformTwo, platformTwoGoLiveDate } =
    sessionState.user.employer.contract;

  /**
   * In some JavaScript environments, the date needs to be parsed from either
   * "MM/DD/YYYY" or "YYYY-MM-DD" format.
   *
   * Attempt to parse the date with the "MM/DD/YYYY" format (default), and if
   * that returns a falsy value (eg., NaN), convert to "YYYY-MM-DD" format and
   * try again.
   */
  const formattedGoLiveDate =
    Date.parse(toDateTimeString(platformTwoGoLiveDate)) ||
    Date.parse(
      toDateTimeString(formatDateDisplayToRails(platformTwoGoLiveDate))
    );

  // Check to see that the `platformTwo` boolean is true and that `platformTwoGoLiveDate`
  // is defined and not in the past.
  return (
    !!platformTwo &&
    !!platformTwoGoLiveDate &&
    formattedGoLiveDate <= Date.now()
  );
}

/**
 * Returns true if logged in
 */
export function getIsLoggedIn(sessionState: Session.SessionState): boolean {
  return Boolean(
    sessionState?.coreToken &&
      !sessionState?.loading &&
      !isEmpty(sessionState.user)
  );
}

/**
 * Returns the user's location, or a default template
 */
export function getUserLocation(sessionState: Session.SessionState): Location {
  return (
    sessionState?.user?.location || {
      address: {
        city: '',
        postalCode: '',
        state: '',
        street: '',
        unit: '',
      },
      lat: 0,
      lng: 0,
    }
  );
}

export function getUserMailingAddress(
  sessionState: Session.SessionState
): Location {
  return {
    address: sessionState?.user?.profile?.mailingAddress,
    lat: 0,
    lng: 0,
  };
}

export function getUserPhoneNumber(sessionState: Session.SessionState): string {
  return (
    sessionState?.user?.profile?.phoneNumber ||
    sessionState?.user?.eligiblePatient?.phoneNumber ||
    ''
  );
}

export function getUserInsurer(
  sessionState: Session.SessionState
): Insurance.Insurer {
  return sessionState?.user?.insurer;
}

export function getUserInsurance(
  sessionState: Session.SessionState
): Insurance.Insurance {
  return merge(
    cloneDeep(sessionState?.user?.eligiblePatient?.insurance),
    sessionState?.user?.profile?.insurance
  );
}

export function getUserName(sessionState: Session.SessionState): string {
  return sessionState?.user?.name;
}

export function getUserProfile(sessionState: Session.SessionState) {
  return sessionState?.user?.profile;
}

export const getIsProfileCompleted = (sessionState: Session.SessionState) => {
  return sessionState?.user?.profile?.currentStep === 0;
};

export const getIsUserDependent = (sessionState: Session.SessionState) => {
  return sessionState?.user?.eligiblePatient?.dependent === true;
};
