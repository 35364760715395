import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';

import { useParams } from '@cross-platform/react-router-native';
import StatusScreen from 'app/components/SelectYourDoctor/Details/StatusScreen';
import { useEpisode, usePossibleHospitals, useSession } from 'app/hooks';
import { getFacilityById } from 'app/selectors/episode';

import {
  AddressIcon,
  BenefitCircleCare,
  BenefitCircleNoCare,
  BenefitCircleNoSavings,
  BenefitCircleQuality,
  BenefitCircleSavings,
  BenefitItem,
  BenefitRow,
  BenefitText,
  ExperienceLabel,
  FacilityDetailsWrapper,
  HtmlView,
  Logo,
  MainImage,
  NameHeader,
  Row,
  Text,
  TravelDuration,
  TopImageSection,
} from './styles';
import { isSilverHospital } from 'app/components/SelectYourDoctor/helpers';
import SurgeonVideo from 'app/components/SurgeonDetail/SurgeonVideo';

interface MatchParams {
  id: string;
}

const FacilityDetails = () => {
  const episodeState = useEpisode();
  const match = useParams<MatchParams>();

  const { user } = useSession();

  const {
    refresh: refreshPossibleHospitals,
    loading,
    data: { records: possibleHospitals },
  } = usePossibleHospitals({ fetch: true });

  // Fetch doctors when mounting if none are loaded.
  useEffect(() => {
    if (isEmpty(possibleHospitals)) refreshPossibleHospitals();
  }, []);

  const facility = getFacilityById(episodeState, Number(match.id));
  const isFacilitySilver = isSilverHospital(facility);

  if (loading || !facility) {
    return <StatusScreen error={!facility} loading={loading} />;
  }

  return (
    <FacilityDetailsWrapper facilityId={facility?.id}>
      {facility.images?.logo && (
        <TopImageSection>
          <Logo source={{ uri: facility.images?.logo }} />
        </TopImageSection>
      )}
      <NameHeader>{facility.name}</NameHeader>
      <Row>
        <AddressIcon />
        <Text>
          {facility.address.street}, {facility.address.city},{' '}
          {facility.address.state} {facility.address.postalCode}
        </Text>
      </Row>
      {user.location && ( // ensure user's location is available
        <Row>
          <Text>
            Distance:{' '}
            <TravelDuration
              latitude={parseFloat(facility.latitude)}
              longitude={parseFloat(facility.longitude)}
            />
          </Text>
        </Row>
      )}
      <Row>
        <ExperienceLabel isSilver={isFacilitySilver} />
      </Row>
      {facility.content?.description && (
        <Row>
          <HtmlView html={facility.content?.description} />
        </Row>
      )}
      {facility.images?.mainImage && (
        <Row>
          <MainImage source={{ uri: facility.images.mainImage }} />
        </Row>
      )}
      <BenefitRow>
        <BenefitItem>
          <BenefitCircleQuality />
          <BenefitText>Quality&#013;advantages</BenefitText>
        </BenefitItem>
        <BenefitItem>
          {isFacilitySilver ? <BenefitCircleNoCare /> : <BenefitCircleCare />}
          <BenefitText isDisabled={isFacilitySilver}>
            Dedicated care&#013;specialists
          </BenefitText>
        </BenefitItem>
        <BenefitItem>
          {isFacilitySilver ? (
            <BenefitCircleNoSavings />
          ) : (
            <BenefitCircleSavings />
          )}
          <BenefitText isDisabled={isFacilitySilver}>
            Lower&#013;cost
          </BenefitText>
        </BenefitItem>
      </BenefitRow>
      {facility.videos?.map((video) => (
        <SurgeonVideo {...video} key={video.url} />
      ))}
    </FacilityDetailsWrapper>
  );
};

export default FacilityDetails;
