import styled, { css } from 'styled-components/native';

import { CenteredScrollScreen } from 'app/components/Common/Layout';
import HtmlView from 'app/components/Common/HtmlView';
import { View, Text } from 'app/components/Common/Styled';

export { DotIndicator } from 'app/components/Common/svg-components';
export { StickyNavFooter } from 'app/components/Common/StickyNavFooter';
export { TopBackLink } from 'app/components/Common/TopBackLink';

const ProcedureDescriptionTitleText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    text-align: center;
  `}
`;

export const ColloquialProcedureName = styled(ProcedureDescriptionTitleText)`
  ${({ theme }) => css`
    font-size: ${theme.rawFontSizes.medium}px;
    font-family: ${theme.fontFamilyBold};
    margin-bottom: ${theme.spacing * 1.5}px;
  `}
`;

export const DescriptionHeader = styled(View)`
  ${({ theme }) => css`
    align-items: stretch;
    background-color: #7b98a3;
    flex: 1 0 auto;
    margin-top: ${theme.spacing / 4}px;
    padding-left: ${theme.spacing * 2.5}px;
    padding-right: ${theme.spacing * 2.5}px;

    ${!theme.isNarrow &&
    css`
      border-radius: ${theme.spacing / 2}px;
      margin-bottom: ${theme.spacing / 2.5}px;
    `}
  `}
`;

export const DescriptionWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: stretch;
    flex: 1 0 auto;
    flex-direction: column;
    padding-bottom: ${theme.spacing * 5}px;
  `}
`;

export const ProcedureDescriptionContainer = styled(CenteredScrollScreen).attrs(
  {
    maxSize: 'small',
  }
)`
  margin-top: ${({ theme }) => theme.spacing * 4}px;
`;

export const ProcedureDetailsHtml = styled(HtmlView).attrs(({ theme }) => ({
  tagsStyles: theme.isNarrow ? { p: { padding: theme.spacing } } : {},
}))``;

export const ProcedureLabel = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    margin-top: ${theme.spacing * 1.5}px;
    text-align: center;
  `}
`;

export const ProcedureName = styled(ProcedureDescriptionTitleText)`
  ${({ theme }) => css`
    font-size: ${theme.rawFontSizes.xxxlarge}px;
    font-family: ${theme.fontFamilyBold};
    margin-bottom: ${theme.spacing / 4}px;
    margin-top: ${theme.spacing / 2}px;
  `}
`;

export const TopLinkWrapper = styled(View)`
  ${({ theme }) => css`
    padding: ${theme.isNarrow ? theme.spacing / 2 : 0}px;
    padding-top: ${theme.isNarrow ? theme.spacing : 0}px;
  `}
`;
