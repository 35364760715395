import React from 'react';

import InputMask from 'react-input-mask';

import Input from 'app/components/Common/Input';

export * from './utils';

/**
 * An input component with a phone number mask for web.
 */
const PhoneInput = ({ input: { onChange, ...inputProps }, ...props }) => {
  const isChromeIOS = navigator.userAgent?.toLowerCase().indexOf('crios') > -1;

  return (
    <InputMask
      // `react-input-mask` breaks on autofill in iOS + Chrome
      mask={isChromeIOS ? null : '(999) 999 9999'}
      onChange={(event) => onChange(event.target.value)}
      value={inputProps.value}
    >
      {() => (
        <Input
          {...props}
          label={props.label}
          input={inputProps}
          keyboardType="phone-pad"
          style={{ border: 'none' }}
          // Expose test ID using input name to match redux-form components.
          data-testid={inputProps.name}
          // Support `onSubmitEditing()` interaction to match other inputs.
          onKeyPress={({ key }) => {
            if (key === 'Enter') props.onSubmitEditing?.();
          }}
        />
      )}
    </InputMask>
  );
};

PhoneInput.propTypes = {
  ...Input.propTypes,
};

PhoneInput.defaultProps = {
  ...Input.defaultProps,
};

export default PhoneInput;
