import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-native-elements';

import TeaserPanel from 'app/components/Common/TeaserPanel';

import {
  CheckIcon,
  ContinueButton,
  FABError,
  FABSuccess,
  HtmlView,
} from './styles';

/**
 * @property {string} confirmation An HTML string to render as confirmation content (required).
 * @property {function} onHide A function to call when the panel is dismissed (required).
 * @property {function} onShow A function to call when the panel is revealed (required).
 * @property {function} onSubmit A function to call when submitting the confirmation (required).
 * @property {boolean} error If true, renders an error indicator (defaults to false).
 * @property {boolean} isVisible If true, reveals the panel content (defaults to false).
 * @property {boolean} loading If true, renders a loading indicator (defaults to false).
 * @property {boolean} succes If true, renders a success indicator (defaults to false).
 */
const propTypes = {
  confirmation: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onShow: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

  error: PropTypes.bool,
  isVisible: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

const defaultProps = {
  error: false,
  isVisible: false,
  loading: false,
  success: false,
};

const ProcedureConfirmationPanel = ({
  confirmation,
  error,
  isVisible,
  loading,
  onHide,
  onShow,
  onSubmit,
  success,
}) => {
  const TeaserComponent = () => (
    <Button
      onPress={onShow}
      raised
      testID="ProcedureDetails--continue-button"
      title="Continue"
    />
  );

  return (
    <TeaserPanel
      isVisible={isVisible}
      onHide={onHide}
      onShow={onShow}
      TeaserComponent={TeaserComponent}
    >
      <HtmlView html={confirmation} />

      <ContinueButton
        // Hide this button inline when the success/error icons are shown
        containerStyle={{ opacity: error || success ? 0.0 : 1.0 }}
        icon={<CheckIcon />}
        loading={loading}
        onPress={onSubmit}
        testID="ProcedureDetails--confirmation-button"
        title="Continue"
      />
      <FABSuccess show={success} />
      <FABError show={error} />
    </TeaserPanel>
  );
};

export default ProcedureConfirmationPanel;

ProcedureConfirmationPanel.propTypes = propTypes;
ProcedureConfirmationPanel.defaultProps = defaultProps;
