import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-elements';
import { Redirect } from '@cross-platform/react-router-native';

import theme from 'app/util/theme';
import { isEmpty } from 'app/util/methods';

import FloatingActionButton from 'app/components/Common/FloatingActionButton';
import Stepper from 'app/components/Common/Stepper';

import EpisodeConfirmation from './EpisodeConfirmation';
import InquiryConfirmation from './InquiryConfirmation';
import { Routes } from 'app/util/routes';

export default class Confirmation extends Component {
  static propTypes = {
    sessionLoading: PropTypes.bool,
    estimateLoading: PropTypes.bool,
    getCostEstimate: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    createEpisode: PropTypes.func.isRequired,
    updateEpisode: PropTypes.func.isRequired,
    updateInsurance: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,

    oopEstimate: PropTypes.object,
    distance: PropTypes.string,
    episode: PropTypes.object,
    inquiry: PropTypes.object,
    hsaFlag: PropTypes.bool,
    deductibleType: PropTypes.string,
    memberIdError: PropTypes.bool,
    memberIdRequired: PropTypes.bool,
  };

  static defaultProps = {
    memberIdError: false,
    memberIdRequired: false,
  };

  state = {
    error: false,
    loading: false,
    saved: false,
  };

  fetchSubmissionTimeout = 0;

  componentDidMount = () => {
    if (!this.props.episode) return;
    if (!isEmpty(this.props.episode.oopEstimate) || this.props.memberIdRequired)
      return;

    this.refreshCostEstimate();
  };

  componentWillUnmount = () => {
    clearTimeout(this.fetchSubmissionTimeout);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.error !== this.state.error) return true;
    if (nextState.loading !== this.state.loading) return true;
    if (nextState.saved !== this.state.saved) return true;
    if (nextProps.memberIdError !== this.props.memberIdError) return true;
    if (nextProps.estimateLoading !== this.props.estimateLoading) return true;
    if (nextProps.sessionLoading !== this.props.sessionLoading) return true;
    if (nextProps.memberIdRequired !== this.props.memberIdRequired) return true;
    if (
      JSON.stringify(nextProps.oopEstimate) !==
      JSON.stringify(this.props.oopEstimate)
    )
      return true;
    if (
      JSON.stringify(nextProps.distance) !== JSON.stringify(this.props.distance)
    )
      return true;
    if (
      JSON.stringify(nextProps.formValues) !==
      JSON.stringify(this.props.formValues)
    )
      return true;
    if (nextProps.episode && !this.props.episode) return true;
    if (nextProps.inquiry && !this.props.inquiry) return true;

    return false;
  }

  refreshCostEstimate = () => {
    this.props.getCostEstimate(this.props.episode.procedureKey).then(() => {
      if (!this.props.episode.id) return;
      if (isEmpty(this.props.oopEstimate)) return;

      this.updateCostEstimate();
    });
  };

  /**
   * Clears the stored member ID for
   * the current patient.
   */
  clearMemberId = () => {
    return this.props.updateInsurance({ memberId: '' });
  };

  createEpisode = async () => {
    this.setState({ loading: true });

    const isSuccess = await this.props.createEpisode();

    this.loadingTimeout = setTimeout(
      () =>
        this.setState({ error: !isSuccess, saved: isSuccess, loading: false }),
      480
    );

    if (isSuccess) {
      this.savedTimeout = setTimeout(this.goDoDashboard, 1480);
    } else {
      this.savedTimeout = setTimeout(
        () => this.setState({ error: false }),
        1480
      );
    }
  };

  goDoDashboard = () => {
    this.props.push('/dashboard');
  };

  restart = () => {
    this.props.push('/');
  };

  /**
   * Updates the stored member ID for
   * the current patient.
   */
  submitMemberId = () => {
    const { memberId } = this.props.formValues;
    return this.props
      .updateInsurance({ memberId })
      .then(this.refreshCostEstimate);
  };

  /**
   * Updates the `oopEstimate` for the current episode.
   */
  updateCostEstimate = async () => {
    await this.props.updateEpisode({ oopEstimate: this.props.oopEstimate });
  };

  render() {
    if (this.props.sessionLoading) return null;
    if (
      !this.props.inquiry &&
      (!this.props.episode ||
        (!this.props.episode.procedure && !this.props.episode.procedureKey))
    )
      return <Redirect to={Routes.Home} />;

    return (
      <View style={styles.container} testID="Confirmation">
        {(!this.props.episode || !this.props.episode.confirmed) && (
          <Stepper bordered step={4} steps={['', '', '']} />
        )}

        <ScrollView enableOnAndroid contentContainerStyle={styles.scrollView}>
          <Text h1>Your Selections</Text>

          {this.props.episode ? (
            <EpisodeConfirmation
              loading={this.props.estimateLoading}
              distance={this.props.distance}
              oopEstimate={this.props.oopEstimate}
              procedure={this.props.episode.procedure}
              location={this.props.episode.location}
              physician={this.props.episode.physician}
              provider={this.props.episode.provider}
              confirmed={this.props.episode.confirmed}
              hsaFlag={this.props.hsaFlag}
              deductibleType={this.props.deductibleType}
              memberIdError={this.props.memberIdError}
              memberIdDisabled={!this.props.formValues.memberId}
              memberIdRequired={this.props.memberIdRequired}
              onConfirm={this.props.episode.id ? null : this.createEpisode}
              onRestart={this.props.episode.id ? null : this.restart}
              onClearMemberId={this.clearMemberId}
              onSubmitMemberId={this.submitMemberId}
            />
          ) : (
            this.props.inquiry && (
              <InquiryConfirmation
                description={this.props.inquiry.description}
              />
            )
          )}
        </ScrollView>

        <FloatingActionButton
          label="Confirm and Continue"
          icon="check"
          reverseColor="#fff"
          loading={this.state.loading}
          disabled={this.state.loading}
          show={
            this.props.episode &&
            !this.props.episode.id &&
            !this.state.saved &&
            !this.state.error
          }
          color={theme.colors.primary}
          onPress={this.createEpisode}
          containerStyle={styles.fab}
        />
        <FloatingActionButton
          disabled
          icon="check"
          reverseColor="#fff"
          sound="success"
          show={!this.state.loading && this.state.saved}
          color={theme.colors.success}
          containerStyle={styles.fab}
        />
        <FloatingActionButton
          disabled
          shake
          icon="close"
          reverseColor="#fff"
          show={!this.state.loading && this.state.error}
          color={theme.colors.error}
          containerStyle={styles.fab}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  scrollView: {
    paddingBottom: theme.spacing * 6,
  },

  fab: {
    right: 20,
    bottom: 20,
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
  },
});
