import React from 'react';
import PropTypes from 'prop-types';

import { Platform, StyleSheet, View } from 'react-native';
import { ThemeProvider } from 'react-native-elements';

import SearchBar from 'app/components/Common/SearchBar';
import theme, { shadows, zIndexes } from 'app/util/theme';
import { TestID } from 'app/util/test-id';

const ProcedureSearchBar = ({
  inputRef,
  onCancel,
  onChangeText,
  onClear,
  value,
}) => (
  <View style={styles.container}>
    <ThemeProvider theme={themeOverride}>
      <SearchBar
        testID={TestID.ProcedureSearch.SearchInput}
        blurOnSubmit
        placeholder="Search procedures"
        placeholderTextColor={theme.colors.placeholderText}
        platform={Platform.OS.replace('web', 'default')}
        onChangeText={onChangeText}
        /**
         * Pass a no-op for Android to prevent unintended back navigation.
         */
        onClear={Platform.OS !== 'android' ? onClear : noOp}
        onCancel={Platform.OS !== 'android' ? onCancel : noOp}
        value={value}
        ref={inputRef}
        containerStyle={{
          backgroundColor: Platform.select({
            default: '#fff',
            // TODO: Related to https://carrumhealth.atlassian.net/browse/TEC-1361
            // ios: 'transparent',
          }),
        }}
      />
    </ThemeProvider>
  </View>
);

ProcedureSearchBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,

  inputRef: PropTypes.any,
  value: PropTypes.string,
};

ProcedureSearchBar.defaultProps = {
  inputRef: null,
  value: '',
};

export default ProcedureSearchBar;

const noOp = () => {};

const styles = StyleSheet.create({
  container: {
    left: 0,
    right: 0,
    zIndex: zIndexes.base,
    borderBottomColor: '#eee',

    backgroundColor: Platform.select({
      default: 'transparent',
      web: '#fff',
    }),
    borderBottomWidth: Platform.select({ ios: 1 }),
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
    top: Platform.select({
      default: 0,
      web: 48,
    }),

    ...Platform.select({
      android: {
        ...shadows.small,
        elevation: 3,
      },
      web: {
        ...shadows.small,
      },
    }),
  },

  blurView: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
  },
});

// Remove container styles from Input
const themeOverride = {
  ...theme,
  Input: {
    ...theme.Input,
    errorStyle: { margin: 0 },
    containerStyle: {},
  },
};
