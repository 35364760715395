import { callCarrum, sendMagicLink } from './helpers';

import { useDispatch } from './hooks';

import {
  Anchor,
  Button,
  ButtonContainer,
  CenteredScrollScreen,
  Header,
  HelpText,
  ImageContainer,
  MagicLinkSvg,
  ParagraphText as GuidanceText,
  Routes,
  TitleText,
} from './styles';

import { ExpiredAuthLinkProps } from './types';

/**
 * Render a page of useful pivots when a guest has been redirectedby the Core
 * Service due to an expired authentication link. The expired link may be for
 * two scenarious: a single-click registration link that has been used
 * already or a magic link that has been used or that the user waited too long
 * to click.
 *
 * @param  {string}   guestEmail    email address for guest pulled from local
 *                                  storage (for SCR links) or the query
 *                                  parameters (for magic links)
 * @param  {string}   guidanceText  text to guide the user
 * @param  {string}   headerText    text for the page header
 * @param  {object}   history       object returned by useHistory hook
 * @param  {string}   linkText      text for the CTA button that sends a new
 *                                  magic link
 * @param  {boolean}  loading       whether the context page is still loading
 * @param  {string}   testId        unique identifier to use for testing
 */
export const ExpiredAuthLink = ({
  guestEmail,
  guidanceText,
  headerText,
  history = null,
  linkText,
  loading = false,
  testId,
}: ExpiredAuthLinkProps) => {
  const dispatch = useDispatch();

  return (
    <CenteredScrollScreen testID={testId}>
      <TitleText>{headerText}</TitleText>
      <Header>{guestEmail}</Header>
      <ImageContainer>
        <MagicLinkSvg />
      </ImageContainer>
      <GuidanceText>{guidanceText}</GuidanceText>
      <ButtonContainer>
        <Button
          disabled={!guestEmail.includes('@')}
          loading={loading}
          onPress={() =>
            sendMagicLink({
              email: guestEmail,
              dispatch,
              history,
              resend: true,
            })
          }
          title={linkText}
        />
      </ButtonContainer>

      <HelpText>
        Prefer password?&nbsp;
        <Anchor to={`/${Routes.Login}`} title="Return to Login" />
      </HelpText>
      <HelpText>
        Have questions?&nbsp;
        <Anchor to={`/${Routes.LearnMore}`} title="See FAQ" />
      </HelpText>
      <HelpText>
        Need help? Call us at&nbsp;
        {/* @ts-ignore re: missing `to` parameter */}
        <Anchor onPress={(e) => callCarrum(e)} title="1-888-855-7806" />
      </HelpText>
    </CenteredScrollScreen>
  );
};

export default ExpiredAuthLink;
