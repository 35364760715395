import React from 'react';
import PropTypes from 'prop-types';

import { ScrollView, StyleSheet, View } from 'react-native';
import { Card, Icon, Text } from 'react-native-elements';

import theme from 'app/util/theme';

const ProcedureQuality = ({ qualityMetrics }) => (
  <ScrollView contentContainerStyle={styles.container}>
    {qualityMetrics && qualityMetrics.length > 0 ? (
      <View>
        <Icon
          size={106}
          color={theme.colors.primary}
          name="table-star"
          type="material-community"
          style={{ marginBottom: theme.spacing * 2.5 }}
        />

        <Text style={{ textAlign: 'center' }}>Carrum Quality Advantage</Text>

        <Card containerStyle={{ marginHorizontal: 0 }}>
          <Card.Title>Carrum vs the National Average</Card.Title>

          <View style={{ paddingHorizontal: 14 }}>
            <View style={styles.metric}>
              <Text
                style={StyleSheet.flatten([styles.label, { flex: 2 }])}
              ></Text>
              <Text
                style={StyleSheet.flatten([
                  styles.label,
                  { textAlign: 'center' },
                ])}
              >
                Carrum
              </Text>
              <Text
                style={StyleSheet.flatten([
                  styles.label,
                  { textAlign: 'center' },
                ])}
              >
                National
              </Text>
            </View>
            {qualityMetrics.map((metric, index) => (
              <View key={index} style={styles.metric}>
                <Text style={StyleSheet.flatten([styles.label, { flex: 2 }])}>
                  {metric.title}
                </Text>
                <Text
                  style={{
                    color: theme.colors.success,
                    flex: 1,
                    textAlign: 'center',
                  }}
                >
                  {metric.carrum || 'N/A'}
                </Text>
                <Text style={{ flex: 1, textAlign: 'center' }}>
                  {metric.national || 'N/A'}
                </Text>
              </View>
            ))}
          </View>
        </Card>
      </View>
    ) : (
      <View>
        <Icon size={206} name="build-circle" />
        <Text h2 h2Style={StyleSheet.flatten([styles.emptyContentTitle])}>
          No Content
        </Text>
        <Text style={StyleSheet.flatten([styles.emptyContent])}>
          Wow. This is embarrasing, but there&apos;s nothing here.
        </Text>
        <Text style={StyleSheet.flatten([styles.emptyContent])}>
          Don&apos;t worry, our content team is working on adding more details
          now. In the meantime, you can still schedule your surgery.
        </Text>
      </View>
    )}
  </ScrollView>
);

ProcedureQuality.propTypes = {
  qualityMetrics: PropTypes.array,
};

ProcedureQuality.defaultProps = {
  qualityMetrics: [],
};

export default ProcedureQuality;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: theme.breakpoints.small,
    width: '100%',
    paddingTop: theme.spacing * 2.5,
    paddingBottom: theme.spacing * 12,
    paddingHorizontal: theme.spacing * 1.25,
  },

  emptyContentTitle: {
    marginTop: theme.spacing * 2.5,
    textAlign: 'center',
  },

  emptyContent: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing * 1.25,
    marginBottom: theme.spacing,
  },

  metric: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing / 2,
  },

  label: {
    flex: 1,
  },
});
