import styled, { css } from 'styled-components/native';

import TrackableButton from '../TrackableButton';

interface ButtonWrapperProps {
  theme: any;
  isActive: boolean;
}

export const ButtonWrapper = styled(TrackableButton).attrs(
  ({ theme, isActive }: ButtonWrapperProps) => ({
    titleStyle: {
      color: isActive ? theme.colors.white : theme.colors.primary,
      textTransform: 'none',
    },
    buttonStyle: {
      backgroundColor: isActive ? theme.colors.primary : theme.colors.white,
      marginBottom: theme.spacing,
      marginTop: theme.spacing,
    },
    buttonContainerStyle: {},
  })
)<ButtonWrapperProps>`
  ${({ theme }) => css`
    border-radius: ${theme.spacing / 4}px;
  `};
`;
