import { TouchableOpacity } from 'react-native';

import styled, { css } from 'styled-components/native';

import { Text } from 'app/components/Common/Styled';

export { default as EnvironmentSwitch } from 'app/components/Common/EnvironmentSwitch';

export const CurrentEnvironmentContainer = styled(TouchableOpacity).attrs({
  activeOpacity: 1,
})`
  flex: 1;
  justify-content: flex-end;
  opacity: 0;
`;

export const CurrentEnvironmentText = styled(Text)`
  ${({ theme }) => css`
    background-color: #ccc;
    font-size: ${theme.fontSizes.small}px;
    padding: ${theme.spacing / 2}px;
    width: 100%;
  `}
`;

export const CurrentEnvironmentBoldText = styled(CurrentEnvironmentText)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
  `}
`;
