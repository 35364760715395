import {
  defaultPersonalInformation as defaultFormState,
  languageOptions,
  personalInformationValidations as validations,
  TestID,
} from 'app/components/MySettings/constants';

import {
  getPersonalInformationFormValuesFromUser as getFormValuesFromUser,
  updateUserDetails,
} from 'app/components/MySettings/helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useSession,
  useState,
  useValidations,
} from 'app/components/MySettings/hooks';

import {
  AssociatedFieldLabel,
  AssociatedFieldView,
  CheckBox,
  FormWrapper,
  InputField,
  InputWrapper,
  PhoneInput,
  Select,
  MySettingsContainer,
  StickyNavFooter,
} from 'app/components/MySettings/styles';

import {
  NavigationMode,
  PersonalInformationValidationHook,
} from 'app/components/MySettings/types';

const PersonalInformationSettings = ({
  headerComponent,
  nextRoute,
  submitButtonText = 'Save',
  testIDRoot,
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { user } = useSession();
  const [formValues, setFormValues] = useState({
    ...defaultFormState,
    ...getFormValuesFromUser(user),
  });

  const showInterpreterRequiredField =
    formValues.language?.toLowerCase() !== 'english';

  /**
   * If the language option changes and we no longer present the "Interpreter
   * Required" checkbox, set that value to false in the form state.
   */
  useEffect(() => {
    if (!showInterpreterRequiredField && formValues.interpreterRequired) {
      setFormValues({ ...formValues, interpreterRequired: false });
    }
  }, [formValues.language, showInterpreterRequiredField]);

  const { errors, isValid }: PersonalInformationValidationHook = useValidations(
    { formValues, validations }
  );

  const onSubmit = useCallback(async () => {
    if (!isValid) return;

    const {
      interpreterRequired,
      language,
      phoneNumber,
      preferredName,
      voicemailsAllowed,
    } = formValues;

    const currentDetails = {
      profile: {
        ...user.profile,
        interpreterRequired,
        language,
        phoneNumber,
        preferredName,
        voicemailsAllowed,
      },
    };

    const isSuccess = await dispatch(updateUserDetails(currentDetails));

    if (isSuccess) push(nextRoute);
  }, [isValid, formValues, push]);

  return (
    <>
      <MySettingsContainer testID={TestID.MySettings.PersonalInformation}>
        <FormWrapper>
          {headerComponent}

          <InputWrapper>
            <PhoneInput
              label="Phone Number"
              error={errors.phoneNumber}
              input={{
                name: 'phoneNumber',
                onChange: (phoneNumber: string) =>
                  setFormValues({ ...formValues, phoneNumber }),
                value: formValues.phoneNumber,
              }}
            />
          </InputWrapper>

          <AssociatedFieldView>
            <CheckBox
              label={
                <AssociatedFieldLabel>
                  I give Carrum Health permission to leave voicemails containing
                  patient health information at this number.
                </AssociatedFieldLabel>
              }
              input={{
                name: 'voicemailsAllowed',
                onChange: (voicemailsAllowed) =>
                  setFormValues({ ...formValues, voicemailsAllowed }),
                value: formValues.voicemailsAllowed,
              }}
            />
          </AssociatedFieldView>

          <InputWrapper>
            <InputField
              label="Preferred Name"
              input={{
                name: 'preferredName',
                onChange: (preferredName) =>
                  setFormValues({ ...formValues, preferredName }),
                value: formValues.preferredName,
              }}
            />
          </InputWrapper>

          <InputWrapper>
            <Select
              label="Spoken Language"
              error={errors.language}
              input={{
                name: 'language',
                onChange: (language) =>
                  setFormValues({ ...formValues, language }),
                value: formValues.language,
              }}
              options={languageOptions}
              unSelectedOption
            />
          </InputWrapper>

          {showInterpreterRequiredField && (
            <AssociatedFieldView>
              <CheckBox
                label={
                  <AssociatedFieldLabel>
                    I require an interpreter. / Necesito un interprete de
                    idiomas.
                  </AssociatedFieldLabel>
                }
                input={{
                  name: 'interpreterRequired',
                  onChange: (interpreterRequired) =>
                    setFormValues({ ...formValues, interpreterRequired }),
                  value: formValues.interpreterRequired,
                }}
              />
            </AssociatedFieldView>
          )}
        </FormWrapper>
      </MySettingsContainer>

      <StickyNavFooter
        navigationConfig={[
          {
            disabled: !isValid,
            onPress: onSubmit,
            testID: testIDRoot['PersonalInformationNavFooterSubmitButton'],
            text: submitButtonText,
          },
        ]}
        navigationMode={NavigationMode.Centered}
        testID={testIDRoot['PersonalInformationNavFooter']}
      />
    </>
  );
};

export default PersonalInformationSettings;
