import styled, { css } from 'styled-components/native';

import { Button, LinearProgress } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Field as FieldRaw } from 'redux-form';

import Anchor from 'app/components/Common/Anchor';
import { default as CheckBoxGroupRaw } from 'app/components/Common/CheckBoxGroup';
import { default as InputRaw } from 'app/components/Common/Input';
import { Text, View } from 'app/components/Common/Styled';
import { GooglePlacesInput as GooglePlacesInputRaw } from 'app/components/Common/GooglePlacesInput';

export { default as CheckBox } from 'app/components/Common/CheckBox';
export { default as RadioGroup } from 'app/components/Common/RadioGroup';
export { default as Select } from 'app/components/Common/Select';
export { default as SignatureInput } from 'app/components/Common/SignatureInput';
export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';
export { default as TextArea } from 'app/components/Common/TextArea';

const getMaxWidth = ({ breakpoints, spacing, windowDimensions }) => {
  switch (true) {
    case windowDimensions.width <= breakpoints.xsmall:
      return spacing * 27.5;
    case windowDimensions.width <= breakpoints.small:
      return spacing * 42.5;
    default:
      return spacing * 45.5;
  }
};

const getTopMargin = ({ breakpoints, spacing, windowDimensions }) => {
  switch (true) {
    case windowDimensions.width >= breakpoints.xlarge:
      return spacing * 3.5; // 24 + 56 === 80
    case windowDimensions.width >= breakpoints.medium:
      return spacing * 2.25; // 24 + 36 === 60
    default:
      return spacing / 2; // 24 + 8 === 30
  }
};

export const AcknowledgementText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body1}px;
    padding-top: ${theme.spacing * 2}px;
    padding-bottom: ${theme.spacing * 2}px;
  `}
`;

export const CheckBoxGroup = styled(CheckBoxGroupRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginVertical: theme.spacing * 2,
    },
  };
})``;

export const Field = styled(FieldRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginVertical: theme.spacing * 2,
    },
  };
})``;

export const FlexWrapper = styled.View`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${theme.spacing}px;
  `}
`;

export const FormQuestions = styled(KeyboardAwareScrollView).attrs({
  enableOnAndroid: true,
  extraHeight: 48,
})`
  ${({ theme }) => css`
    align-self: flex-start;
    flex: 0 1 auto;
    padding: 0px;
    width: 100%;
  `}
`;

export const FormSteps = styled(LinearProgress).attrs(({ theme }) => {
  return {
    color: theme.colors.lightBlue,
    trackColor: theme.colors.white,
    variant: 'determinate',
  };
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2}px;
    margin-top: ${theme.spacing * 2}px;
  `}
`;

export const GooglePlacesInput = styled(GooglePlacesInputRaw).attrs(
  ({ theme }) => ({
    theme: {
      ...theme,
      Input: {
        ...theme.Input,
        containerStyle: {
          ...theme.Input.containerStyle,
          marginVertical: theme.spacing * 2,
        },
      },
    },
  })
)``;

export const Input = styled(InputRaw)``;

export const InputLabel = styled(Text).attrs(({ hasError, theme }) => {
  return {
    color: hasError ? theme.colors.error : theme.colors.bodyText,
  };
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const NavigationButton = styled(Button).attrs(({ theme }) => {
  return {
    disabledStyle: {
      backgroundColor: theme.colors.disabledNavBackground,
    },
    disabledTitleStyle: {
      color: theme.colors.white,
    },
  };
})`
  ${({ theme }) => css`
    min-height: ${theme.spacing * 3}px;
    min-width: ${theme.spacing * 10}px;
  `}
`;

export const NavigationButtons = styled(View)<NavigationButtonsProps>`
  ${({ isFirstQuestion, theme }) => css`
    display: ${theme.isNarrow ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: ${isFirstQuestion ? 'flex-end' : 'space-between'};
    width: 100%;
  `}
`;

export const NextButton = styled(NavigationButton).attrs({
  title: 'Next',
  type: 'solid',
})``;

export const PreviousButton = styled(NavigationButton).attrs(({ isHidden }) => {
  return {
    title: 'Previous',
    type: 'outline',
  };
})`
  ${({ isHidden }) => css`
    display: ${isHidden ? 'none' : 'flex'};
  `}
`;

export const QuestionText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body1}px;
  `}
`;

export const QuestionWrapper = styled(FlexWrapper)`
  ${({ theme }) => css`
    align-items: flex-start;
    max-width: ${getMaxWidth(theme)}px;
    padding: ${theme.spacing / 2}px;
    margin-bottom: ${theme.spacing * 4}px;
    width: 100%;
  `}
`;

export const SaveLink = styled(Anchor).attrs(({ theme }) => {
  return {
    titleStyle: {
      fontFamily: theme.fontFamilyMedium,
      fontSize: theme.fontSizes.body2,
    },
  };
})``;

export const SaveLinkWrapper = styled.View`
  /* NOTE: margin-top is dependent on parent/grandparent padding. assumption? (t.s/2) / (t.s) */
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: flex-end;
    margin-top: ${getTopMargin(theme)}px;
    width: 100%;
  `}
`;
