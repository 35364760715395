import styled from 'styled-components/native';
import { Icon as RNEIcon } from 'react-native-elements';

import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

export const Container = styled.View`
  align-items: flex-start;
  flex: 1 0 auto;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing}px;
  padding-horizontal: ${({ theme }) => theme.spacing}px;
`;

export const Details = styled.View`
  flex: 1;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing / 2}px;
`;

export const Icon = styled(RNEIcon).attrs({
  reverse: true,
  color: theme.colors.primary,
})``;

export const Paragraph = styled(Text)`
  padding-right: ${({ theme }) => theme.spacing}px;
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
`;

export const Title = styled(Text).attrs({ h3: true })``;
