import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';

import theme, { shadows } from 'app/util/theme';
import { logEvent, EVENTS } from 'app/util/analytics';

import ProcedureIcon from 'app/components/Common/ProcedureIcon';
import Tabs from 'app/components/Common/Tabs';

import ProcedureConfirmationPanel from './ProcedureConfirmationPanel';
import ProcedureContent from './ProcedureContent';
import ProcedureLocations from './ProcedureLocations';
import ProcedureQuality from './ProcedureQuality';
import ProcedureSavings from './ProcedureSavings';

import { CostEstimatePropTypes, ProcedurePropTypes } from 'app/util/propTypes';

import defaultConfirmationContent from './defaultConfirmationContent';

const TABS = ['Details', 'Savings', 'Quality', 'Locations'];

/**
 * Displays the procedure details page, allowing users
 * to learn more about a procedure offering and create
 * an episode of care.
 */
export default class ProcedureDetails extends Component {
  /**
   * @property {function} createEpisode A function to call when creating an episode (required).
   * @property {function} getCostEstimate A function to call to get a cost estimate (required).
   * @property {function} getHospitals A function to call to get available hospitals (required).
   * @property {object} location An object with lat/lng representing the current user's location (required).
   * @property {array} possibleHospitals An array of possible hospitals for ths procedure (required).
   * @property {object} procedure An offered procedure with details to display (required).
   * @property {function} push A function to call when navigating forward (required).
   * @property {object} costEstimate A cost estimate for this procedure (defaults to null).
   * @property {boolean} costEstimateLoading True if the cost estimate is loading (defaults to false).
   * @property {boolean} memberIdError True if there is an error with the member ID (defaults to false).
   * @property {boolean} memberIdRequired True if a member ID is required for a cost estimate (defaults to false).
   */
  static propTypes = {
    createEpisode: PropTypes.func.isRequired,
    getCostEstimate: PropTypes.func.isRequired,
    getHospitals: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    possibleHospitals: PropTypes.array.isRequired,
    procedure: ProcedurePropTypes.isRequired,
    push: PropTypes.func.isRequired,

    costEstimate: PropTypes.shape(CostEstimatePropTypes),
    costEstimateLoading: PropTypes.bool,
    memberIdError: PropTypes.bool,
    memberIdRequired: PropTypes.bool,
  };

  static defaultProps = {
    costEstimate: null,
    costEstimateLoading: false,
    memberIdError: false,
    memberIdRequired: false,
  };

  state = {
    confirming: false,
    dimensions: Dimensions.get('window'),
    error: false,
    loading: false,
    success: false,
    transitioning: false,
  };

  componentDidMount() {
    this.getCostEstimate();
    this.getHospitals();
    this.subscription = Dimensions.addEventListener(
      'change',
      this.onDimensionsChange
    );
  }

  componentWillUnmount() {
    clearTimeout(this.errorTimeout);
    clearTimeout(this.successTimeout);
    this.subscription?.remove();
  }

  /**
   * Fetches the cost estimate for the current procedure.
   */
  getCostEstimate = () => {
    this.props.getCostEstimate(this.props.procedure.key);
  };

  /**
   * Fetches the available hospitals for this procedure.
   */
  getHospitals = () => {
    this.props.getHospitals(this.props.procedure.id, this.props.location);
  };

  /**
   * Navigates back to the procedures page.
   */
  onBackPress = () => {
    this.props.push('/procedures');
  };

  /**
   * Cancels the confirmation and selection.
   */
  onDismiss = () => {
    logEvent(EVENTS.procedures.dismiss, {
      procedure: this.props.procedure.name,
    });
    this.setState({ confirming: false });
  };

  /**
   * Handles resize events in the app.
   */
  onDimensionsChange = (event) => {
    this.setState({ dimensions: event.window });
  };

  /**
   * Displays an error animation using the FAB.
   */
  onError = () => {
    this.setState({ error: true });
    this.errorTimeout = setTimeout(
      () => this.setState({ error: false, loading: false }),
      480
    );
  };

  /**
   * Shows the confirmation modal.
   */
  onSelect = () => {
    logEvent(EVENTS.procedures.confirm, {
      procedure: this.props.procedure.name,
    });
    this.setState({ confirming: true });
  };

  /**
   * Calls `props.createEpisode()` with the current procedure.
   * Calls either `.onError()` or `.onSuccess()` depending on
   * the result.
   *
   * @return {promise} A promise that resolves after attempting to create an episode.
   */
  onSubmit = async () => {
    this.setState({ loading: true });

    const isSuccess = await this.props.createEpisode(this.props.procedure);

    if (!isSuccess) return this.onError();

    this.setState({ success: true });
    this.successTimeout = setTimeout(this.onSuccess, 480);
  };

  /**
   * Logs an event in GA and navigats to the patient dashboard.
   */
  onSuccess = () => {
    logEvent(EVENTS.episode.create, { procedure: this.props.procedure.name });
    this.props.push('/dashboard');
  };

  onTabPress = (index) => {
    logEvent(EVENTS.procedures[`view${TABS[index]}`], {
      procedure: this.props.procedure.name,
    });
    setTimeout(() => this.setState({ transitioning: true }));
    setTimeout(() => this.setState({ transitioning: false }), 280);
  };

  render() {
    const { content, images, qualityMetrics, name } = this.props.procedure;
    const { width } = this.state.dimensions;

    return (
      <View style={styles.container} testID="ProcedureDetails">
        <View
          style={StyleSheet.flatten([
            styles.breadcrumbs,
            width > theme.breakpoints.small
              ? styles.breadcrumbsLargeScreen
              : styles.breadcrumbsSmallScreen,
          ])}
        >
          <Icon
            containerStyle={styles.backIcon}
            style={{ width: 20 }}
            onPress={this.onBackPress}
            name={Platform.select({
              ios: 'chevron-left',
              default: 'arrow-back',
            })}
          />

          {Boolean(images?.icon) && (
            <ProcedureIcon
              containerStyle={{
                alignSelf: 'center',
                marginRight: theme.spacing / 2,
              }}
              icon={images.icon}
              size="small"
            />
          )}

          <Text
            numberOfLines={2}
            style={{ marginLeft: theme.spacing / 4, flex: 1, maxWidth: 300 }}
          >
            {name}
          </Text>
        </View>

        <Tabs
          tabListStyle={
            width > theme.breakpoints.small
              ? styles.tabListLargeScreen
              : styles.tabListSmallScreen
          }
          onTabPress={this.onTabPress}
          tabs={TABS}
        >
          <ProcedureContent content={content} />
          <ProcedureSavings
            costEstimate={this.props.costEstimate}
            costEstimateLoading={this.props.costEstimateLoading}
            memberIdError={this.props.memberIdError}
            memberIdRequired={this.props.memberIdRequired}
            onEstimatePress={this.getCostEstimate}
            onKeyboardDidHide={() => this.setState({ transitioning: false })}
            onKeyboardDidShow={() => this.setState({ transitioning: true })}
          />
          <ProcedureQuality qualityMetrics={qualityMetrics} />
          <ProcedureLocations
            hospitals={this.props.possibleHospitals}
            location={this.props.location}
          />
        </Tabs>

        <ProcedureConfirmationPanel
          confirmation={content?.confirmation || defaultConfirmationContent}
          error={this.state.error}
          loading={this.state.loading}
          isVisible={this.state.confirming}
          onHide={this.onDismiss}
          onShow={this.onSelect}
          onSubmit={this.onSubmit}
          success={this.state.success}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  contentContainer: {
    paddingTop: theme.spacing * 2.5,
    paddingBottom: theme.spacing * 5,
  },

  emptyContentTitle: {
    marginTop: theme.spacing * 2.5,
    textAlign: 'center',
  },

  emptyContent: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing * 1.25,
    marginBottom: theme.spacing,
  },

  breadcrumbs: {
    backgroundColor: '#fff',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: theme.spacing / 2,
    paddingVertical: theme.spacing,
  },

  breadcrumbsSmallScreen: {
    marginBottom: Platform.select({
      android: -theme.spacing * 1.25,
      ios: -theme.spacing / 2,
    }),
  },

  breadcrumbsLargeScreen: {
    ...shadows.small,
  },

  backIcon: {
    marginRight: 16,
  },

  fab: {
    right: '4%',
    bottom: '4%',
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
  },

  htmlView: {
    ...Platform.select({
      web: {
        height: 'fit-content',
      },
    }),
  },

  tabListLargeScreen: {
    backgroundColor: '#fff',
    marginLeft: 400,
    marginTop: -theme.spacing * 2.5,
    marginRight: 20,
    minWidth: 300,
  },

  tabListSmallScreen: {
    backgroundColor: '#fff',
    marginTop: Platform.select({
      ios: -4,
    }),
  },
});
