import React from 'react';

import PropTypes from 'prop-types';
import { View } from 'react-native';

import { shadows } from 'app/util/theme';

import { Container, Icon, Subtitle, Title } from './styles';

const propTypes = {
  onBackPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,

  subtitle: PropTypes.string,
};

/**
 * Renders a top navigation bar with a title, subtitle, and back navigation.
 */
const TitleBar = ({ onBackPress, subtitle, title }) => (
  <Container style={{ ...shadows.small }}>
    <Icon onPress={onBackPress} />

    <View>
      <Title>{title}</Title>
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </View>
  </Container>
);

TitleBar.propTypes = propTypes;
export default TitleBar;
