import {
  PROFILE_STEP_RULES,
  profileStepRoutes,
  Routes,
  TestID,
} from './constants';

import { createEscalation, getIsLoggedIn } from './helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useEpisode,
  useHistory,
  useNextPhaseRoute,
  usePossibleHospitals,
  useRef,
  useSelector,
  useSession,
  useState,
} from './hooks';

import { Container, ProgressNav, Redirect, Route, Switch } from './styles';

import { PageUpdateArg, ProfilePage, State } from './types';

import BenefitsComplete from './Benefits/BenefitsComplete';
import BenefitsWelcome from './Benefits/BenefitsWelcome';
import Questionnaire from './Questionnaire';
import SaveProgress from './SaveProgress';
import StopScreenPage from './StopScreen';

import { AccountSettingsHeader } from './SaveProgress/styles';

export const CompleteYourProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { refresh, loading, episode } = useEpisode();
  const { nextRoute } = useNextPhaseRoute();
  const session = useSession();
  const sessionUser = session?.user || {};

  const refreshInterval = useRef();
  const {
    data: { records: hospitals },
    refresh: refreshHospitals,
  } = usePossibleHospitals();

  const [pageState, _setPageState] = useState<PageUpdateArg>({
    page: ProfilePage.Welcome,
    stopScreenProps: {},
  });

  const setPageState = (updates: PageUpdateArg) => {
    _setPageState({ ...pageState, ...updates });
  };

  const createNoHospitalsEscalation = useCallback(() => {
    dispatch(
      createEscalation({
        description: 'Patient does not have COEs available.',
        escalationType: 'no_coes_found',
        escalationStatus: 'active',
        exceptionStatus: 'not_applicable',
        exceptionType: null,
      })
    );

    setPageState({ page: ProfilePage.StopScreen });
  }, [hospitals, setPageState]);

  const onCompleteProfile = () => {
    refreshInterval.current = setInterval(refresh, 1500);
  };

  useEffect(() => {
    if (loading) return;
    if (!refreshInterval?.current) return;

    if (nextRoute !== `/${Routes.ProfileCreate}`) history.push(nextRoute);

    return () => clearInterval(refreshInterval.current);
  }, [loading]);

  /**
   * Refresh the current episode when the component mounts, and fetch the
   * available hospitals when the profile is completed.
   */
  useEffect(() => {
    if (pageState.page === ProfilePage.Welcome) {
      Promise.resolve(() => refresh()).then(() => {
        if (!episode) history.push('/');
      });
    } else if (pageState.page === ProfilePage.Completed) {
      refreshHospitals();
    }
  }, [pageState]);

  /**
   * Create an escalation indicating that no hospitals are available if that is
   * the case upon completing the profile.
   */
  useEffect(() => {
    if (pageState.page !== ProfilePage.Completed) return;
    if (hospitals.length !== 0) return;

    createNoHospitalsEscalation();
  }, [hospitals]);

  const procedure = useSelector(
    (state: State.RootState) => state.episode?.procedure || ''
  );

  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  /**
   * Determine and assign only the steps required for a patient based on specific
   * rules involving their employer's partnerships and their procedure's category.
   */
  const updateProfileSteps = useCallback(() => {
    const clientPartnerships = sessionUser?.employer?.partnerships || [];
    const employerClientType = sessionUser?.employer?.type;
    const selectedProcedureCategory = procedure?.category;
    const selectedProcedureServiceType = procedure?.serviceType;
    const stepKeys = {
      excludable: [],
      included: [],
    };

    /**
     * Update the array of keys for steps to potentially exclude.
     *
     * NOTE: Steps for keys in this array will be excluded *unless* another rule
     *       having type "inclusion" previously matched.
     */
    const excludeKeysFrom = (rule) => {
      rule.stepKeys.forEach((key) => {
        if (
          !stepKeys.excludable.includes(key) &&
          !stepKeys.included.includes(key)
        )
          stepKeys.excludable.push(key);
      });
    };

    /** Remove one profile step that matches the specified value. */
    const excludeProfileStep = (profileSteps, inputKey) => {
      const profileStepIndex = profileSteps.indexOf(inputKey);
      if (profileStepIndex > -1) profileSteps.splice(profileStepIndex, 1);
    };

    /** Remove multiple profile steps that match the specified values. */
    const excludeProfileSteps = (stepKeys) => {
      stepKeys.forEach((key) => {
        excludeProfileStep(profileStepRoutes, key);
      });
    };

    /**
     * Update the array of (keys for) steps to always include.
     *
     * NOTE: Steps for keys in this array *will never be excluded*, even when
     *       another rule having type "exclusion" matches before or after.
     */
    const includeKeysFrom = (rule) => {
      rule.stepKeys.forEach((key) => {
        if (!stepKeys.included.includes(key)) {
          stepKeys.included.push(key);
          excludeProfileStep(stepKeys.excludable, key);
        }
      });
    };

    /**
     * Determine whether the patient's employer has a partnership that matches the
     * rule.
     */
    const partnershipMatches = ({ partnerName }) => {
      return (
        clientPartnerships.length > 0 &&
        clientPartnerships.some((partner) => partner.name === partnerName)
      );
    };

    /**
     * Determine whether the patient's client type matches the rule's clientType
     */
    const clientMatches = ({ clientType }) => {
      return employerClientType === clientType;
    };

    /**
     * Determine whether the patient's procedure has a category or service type
     * that matches the rule.
     */
    const procedureMatches = ({ procedureCategories }) => {
      return procedureCategories?.includes(selectedProcedureCategory);
    };

    /**
     * Determine whether the patient's procedure has a category or service type
     * that matches the rule.
     */
    const serviceTypeMatches = ({ serviceType }) => {
      if (serviceType?.[0] === '!') {
        return serviceType.slice(1) !== selectedProcedureServiceType;
      } else {
        return serviceType === selectedProcedureServiceType;
      }
    };

    /** Iterate over the rules and determine the profile steps a patient requires. */
    PROFILE_STEP_RULES.forEach((rule) => {
      const hasQualifyingProcedureForPartnership =
        procedureMatches(rule) && partnershipMatches(rule);

      const hasQualifyingClientType = clientMatches(rule);
      const hasQualifyingServiceType = serviceTypeMatches(rule);

      if (
        hasQualifyingProcedureForPartnership ||
        hasQualifyingServiceType ||
        hasQualifyingClientType
      ) {
        if (rule.type === 'inclusion') includeKeysFrom(rule); // always include
        if (rule.type === 'exclusion') excludeKeysFrom(rule); // maybe exclude
      }
    });

    /** Finally, exclude profile steps not necessary for the patient. */
    excludeProfileSteps(stepKeys.excludable);
  }, [session, procedure, profileStepRoutes, sessionUser]);

  /** Determine which subset of possible profile steps to present. */
  useEffect(() => {
    if (isLoggedIn && episode && session) updateProfileSteps();
  }, []);

  /** Return a numerical index for the correct profile step.  */
  const getTargetRouteIndex = () => {
    let routeIndex = session?.user?.profile?.currentStep
      ? session?.user?.profile?.currentStep - 1
      : 0;

    // handle changed settings for users at a step that no longer matches
    if (session?.user?.profile?.currentStep > profileStepRoutes.length) {
      routeIndex = 0;
    }

    return routeIndex;
  };

  return (
    <Container testID={TestID.CompleteYourProfile.Page}>
      {/** Left nav for seeing overall progress */}
      <ProgressNav open>
        {/** Renders the individual pages based on mapping above */}
        <Switch>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.Overview}`}>
            <BenefitsWelcome
              nextPageKey={profileStepRoutes[getTargetRouteIndex()]}
            />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.Complete}`}>
            <BenefitsComplete onNextPage={onCompleteProfile} />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/${Routes.SaveProgress}`}>
            <SaveProgress
              headerComponent={<AccountSettingsHeader />}
              nextRoute={`/${Routes.ProfileCreate}/${Routes.Complete}`}
              testIDRoot={TestID.CompleteYourProfile}
              enableStickyNavFooter={false}
            />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/:step/stop`}>
            <StopScreenPage
              body={pageState?.stopScreenProps?.body}
              ctaTitle={pageState?.stopScreenProps?.ctaTitle}
              title={pageState?.stopScreenProps?.title}
              footer={pageState?.stopScreenProps?.footer}
              onPress={pageState?.stopScreenProps?.onPress}
            />
          </Route>
          <Route exact path={`/${Routes.ProfileCreate}/:step`}>
            <Questionnaire
              onGoToStopScreen={(stopScreenProps) => {
                setPageState({ page: ProfilePage.StopScreen, stopScreenProps });
                history.push(`${history.location.pathname}/stop`);
              }}
              submitFinalStep={() => {
                setPageState({ page: ProfilePage.Completed });
              }}
            />
          </Route>
          <Route>
            <Redirect to={`/${Routes.ProfileCreate}/${Routes.Overview}`} />
          </Route>
        </Switch>
      </ProgressNav>
    </Container>
  );
};

export default CompleteYourProfile;
