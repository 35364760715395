import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

import {
  Animated,
  Easing,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Icon, Text } from 'react-native-elements';

import { useNativeDriver } from 'app/util/constants';
import theme, { shadows } from 'app/util/theme';
import { playSound } from 'app/util/soundUtils';
import { DotIndicator } from 'app/components/Common/svg-components';

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity);

/**
 * A floating action button with animated transitions.
 * Supports simple icon buttons or buttons with text.
 */
export default class FloatingActionButton extends Component {
  static propTypes = {
    onPress: PropTypes.func,

    color: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    reverseColor: PropTypes.string,
    shake: PropTypes.bool,
    show: PropTypes.bool,
    sound: PropTypes.string,
  };

  static defaultProps = {
    color: theme.colors.default,
    containerStyle: {},
    disabled: false,
    icon: null,
    label: '',
    loading: false,
    reverseColor: '#333',
    show: true,
    sound: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      offset: new Animated.Value(0),
      size: new Animated.Value(0),
    };

    this.shake = throttle(this.shake, 280);
    this.show = throttle(this.show, 280);
    this.hide = throttle(this.hide, 280);
    this.pulse = throttle(this.pulse, 280);

    if (props.show) this.showTimeout = setTimeout(this.show, 140);
    if (props.shake) this.shake();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show === prevProps.show &&
      this.props.shake === prevProps.shake
    )
      return;

    this.showTimeout = setTimeout(this.props.show ? this.show : this.hide, 40);

    if (this.props.shake) this.shake();
  }

  componentWillUnmount() {
    clearTimeout(this.showTimeout);
  }

  chime = () => {
    if (!this.props.sound) return;

    playSound(this.props.sound);
  };

  hide = () => {
    Animated.timing(this.state.size, {
      duration: 100,
      toValue: 0,
      easing: Easing.in(Easing.ease),
      useNativeDriver,
    }).start();
  };

  show = () => {
    Animated.timing(this.state.size, {
      duration: 100,
      toValue: 1.0,
      easing: Easing.in(Easing.ease),
      useNativeDriver,
    }).start(this.chime);
  };

  pulse = () => {
    Animated.sequence([
      Animated.timing(this.state.size, {
        duration: 140,
        toValue: 0,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver,
      }),
      Animated.timing(this.state.size, {
        duration: 140,
        toValue: 1,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver,
      }),
    ]).start();
  };

  shake = () => {
    const config = {
      duration: 30,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver,
    };

    Animated.sequence([
      Animated.timing(this.state.offset, { ...config, toValue: -5 }),
      Animated.timing(this.state.offset, { ...config, toValue: 5 }),
      Animated.timing(this.state.offset, { ...config, toValue: -5 }),
      Animated.timing(this.state.offset, { ...config, toValue: 5 }),
      Animated.timing(this.state.offset, { ...config, toValue: -5 }),
      Animated.timing(this.state.offset, { ...config, toValue: 5 }),
      Animated.timing(this.state.offset, { ...config, toValue: -5 }),
      Animated.timing(this.state.offset, { ...config, toValue: 5 }),
      Animated.timing(this.state.offset, { ...config, toValue: 0 }),
    ]).start();
  };

  render() {
    return (
      <AnimatedTouchableOpacity
        testID={`FloatingActionButton-${
          this.props.icon || this.props.label.toLowerCase()
        }`}
        activeOpacity={0.5}
        disabled={this.props.disabled}
        onPress={this.props.onPress}
        pointerEvents={this.props.show ? 'auto' : 'none'}
        style={StyleSheet.flatten([
          styles.container,
          this.props.containerStyle,
          { visibility: this.props.show ? 'visible' : 'hidden' },
          {
            transform: [
              { scale: this.state.size },
              { translateX: this.state.offset },
            ],
          },
        ])}
      >
        {this.props.loading ? (
          <View
            style={StyleSheet.flatten([
              styles.container,
              styles.extended,
              { backgroundColor: this.props.color },
            ])}
          >
            <DotIndicator style={{ marginVertical: -theme.spacing / 8 }} />
          </View>
        ) : this.props.label ? (
          <View
            style={StyleSheet.flatten([
              styles.container,
              styles.extended,
              { backgroundColor: this.props.color },
            ])}
          >
            {this.props.icon && (
              <Icon
                name={this.props.icon}
                color={this.props.reverseColor}
                containerStyle={StyleSheet.flatten([
                  styles.icon,
                  styles.iconWithLabel,
                ])}
              />
            )}
            <Text
              style={StyleSheet.flatten([
                styles.label,
                { color: this.props.reverseColor },
              ])}
            >
              {this.props.label}
            </Text>
          </View>
        ) : (
          <Icon
            raised
            reverse
            name={this.props.icon}
            color={this.props.color}
            reverseColor={this.props.reverseColor}
            containerStyle={styles.icon}
          />
        )}
      </AnimatedTouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderRadius: theme.spacing * 2,
    ...shadows.small,
  },

  extended: {
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.spacing * 2,
    paddingHorizontal: theme.spacing * 1.5,
    paddingVertical: theme.spacing,
  },

  icon: {
    margin: 0,
  },

  iconWithLabel: {
    marginRight: theme.spacing * 0.6,
    marginLeft: theme.spacing * -0.3,
  },

  label: {
    textTransform: 'uppercase',
  },
});
