import styled, { css } from 'styled-components/native';

import Input from 'app/components/Common/Input';
import { Text } from 'app/components/Common/Styled';

export { ContinueButton } from 'app/components/CompleteYourProfile/SaveProgress/styles';

export const InputField = styled(Input).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export { LegalContent } from 'app/components/Common/LegalContent';

export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';

export const TogglePasswordText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;
