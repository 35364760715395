/**
 * Determine whether an input string is a valid phone number.
 *
 * @param    {string}  phoneNumber  the phone number to validate
 *
 * @returns  {string}               whether the input phone number is valid
 */
export function isValidPhoneNumber(phoneNumber: string) {
  if (typeof phoneNumber !== 'string') return false;

  return phoneNumber.replace(/\D/g, '').length >= 10;
}
