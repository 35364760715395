import {
  profileStepRoutes,
  Routes,
  TestID,
  theme,
} from 'app/components/CompleteYourProfile/constants';

import {
  getNextProfileRoute,
  getPreviousProfileRoute,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useEffect,
  useHistory,
  useNextPageForProfileCompletion,
  useState,
} from 'app/components/CompleteYourProfile/hooks';

import {
  FormContainer,
  Header,
  LogBox,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import { Location } from 'app/components/CompleteYourProfile/types';

import {
  CheckBox,
  CheckboxWrapper,
  GooglePlacesInput,
  GooglePlacesInputWrapper,
  View,
} from './styles';

LogBox.ignoreLogs(['VirtualizedLists should never be nested']); // TODO: https://carrumhealth.atlassian.net/browse/TEC-3061

export interface CurrentlyLiveHereValues {
  location: {
    isDifferentLocation: boolean;
    mailing: Location;
    physical: Location;
  };
}

interface Props {
  isSaving: boolean;
  onChange: (values: CurrentlyLiveHereValues) => void;
  onNext: () => void;
  values: CurrentlyLiveHereValues;
}

export const CurrentlyLiveHerePage = (props: Props) => {
  const history = useHistory();
  const [physicalLocation, setPhysicalLocation] = useState<Location>(
    // not using destructured properties because it was getting confusing which was local and which was props
    props.values.location.physical
  );

  const [mailingLocation, setMailingLocation] = useState<Location>(
    // not using destructured properties because it was getting confusing which was local and which was props
    props.values.location.mailing
  );

  const [isDifferentLocation, setIsDifferentLocation] = useState<boolean>(
    props.values.location.isDifferentLocation
  );

  const isLastProfileStep =
    profileStepRoutes[profileStepRoutes.length - 1] === Routes.Address;

  const updateProfile = () => {
    props.onChange({
      location: {
        isDifferentLocation,
        physical: physicalLocation,
        mailing: mailingLocation,
      },
    });
  };

  /**
   * Update profile
   */
  useEffect(() => {
    updateProfile();
  }, [physicalLocation, mailingLocation, isDifferentLocation]);

  const goToNextPage = useNextPageForProfileCompletion({
    isWelcomePage: false,
    nextPageKey: Routes.SaveProgress,
  });

  const handleIsDifferentLocationChange = (value: boolean) => {
    if (value === true) {
      // clear out mailing location
      setMailingLocation({
        address: {
          city: '',
          postalCode: '',
          state: '',
          street: '',
          unit: '',
        },
        lat: 0,
        lng: 0,
      });
    } else {
      // set mailing location to physical location
      setMailingLocation(physicalLocation);
    }

    setIsDifferentLocation(value);
  };

  return (
    <StepScreen testID={TestID.CompleteYourProfile.CurrentlyLiveHere}>
      <FormContainer>
        <View>
          <Header title="Do you currently live at this address?" />
          <GooglePlacesInputWrapper>
            <GooglePlacesInput
              input={{
                name: TestID.CompleteYourProfile.PhysicalAddress,
                onChange: setPhysicalLocation,
                value: physicalLocation,
              }}
              label="Physical Address"
              theme={theme}
            />
          </GooglePlacesInputWrapper>
          <CheckboxWrapper>
            <CheckBox
              input={{
                name: TestID.CompleteYourProfile.MailingAddressSame,
                onChange: handleIsDifferentLocationChange,
                value: isDifferentLocation,
              }}
              label="My mailing address is different from above"
            />
          </CheckboxWrapper>
        </View>
        {isDifferentLocation && (
          <GooglePlacesInputWrapper>
            <GooglePlacesInput
              input={{
                name: TestID.CompleteYourProfile.MailingAddress,
                onChange: setMailingLocation,
                value: mailingLocation,
              }}
              label="Mailing Address"
              theme={theme}
            />
          </GooglePlacesInputWrapper>
        )}
      </FormContainer>
      <ProfileCTAs
        isNextDisabled={
          !props.values.location.physical ||
          (props.values.location.isDifferentLocation &&
            !props.values.location.mailing)
        }
        isSaving={props.isSaving}
        onNext={async () => {
          await props.onNext(); // save profile
          if (isLastProfileStep) {
            goToNextPage();
          } else {
            history.push(getNextProfileRoute(Routes.Address));
          }
        }}
        onPrevious={() => history.push(getPreviousProfileRoute(Routes.Address))}
      />
    </StepScreen>
  );
};

export default CurrentlyLiveHerePage;
