import styled, { css } from 'styled-components/native';

import Anchor from 'app/components/Common/Anchor';
import { Animated } from 'react-native';
import { Button as ButtonRaw } from 'react-native-elements';
import { CareImage } from 'app/components/Common/svg-components/ValuePropositions/CareImage';
import { CostImage } from 'app/components/Common/svg-components/ValuePropositions/CostImage';
import PhoneInputRaw from 'app/components/Common/PhoneInput';
import { QualityImage } from 'app/components/Common/svg-components/ValuePropositions/QualityImage';
import { ScrollView, Text, View } from 'app/components/Common/Styled';
import SizedImage from 'app/components/Common/SizedImage';

import { imageSize, theme, valuePropositionsColumnWidth } from './constants';

import { DefaultTheme } from './types';

/** Return a spacing constant appropriate for the current device size  */
const getInnerSpacing = ({ isNarrow, spacing }: DefaultTheme) =>
  isNarrow ? spacing : spacing * 2.5;

/** Return a font size constant appropriate for the current device size. */
const getBaseFontSize = ({ isNarrow }: DefaultTheme) =>
  isNarrow ? 'h3' : 'small';

const getRegistrationFormColumnWidth = ({ theme, marginAdjustment }) => {
  const windowWidth = theme.windowDimensions.width;
  const maxColumnWidth = theme.breakpoints.xsmall;
  const hasVisibleValuePropositionsColumn =
    windowWidth > theme.breakpoints.medium;
  const columnWidthOffset = hasVisibleValuePropositionsColumn
    ? valuePropositionsColumnWidth
    : 0;
  let columnWidth = windowWidth - columnWidthOffset;
  columnWidth = columnWidth > maxColumnWidth ? maxColumnWidth : columnWidth;
  return columnWidth - marginAdjustment;
};

const AnimatedView = Animated.createAnimatedComponent(View);

const BenefitImageContainer = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 0 ${theme.spacing * 2}px;
  `}
`;

const BenefitImagesRow = styled(View)`
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-evenly;

  /* Hide these images on small devices to make best use of available screen */
  ${({ theme }) => (theme.isNarrow ? 'display: none' : '')}
`;

const BenefitLabel = styled(Text)`
  flex: 1 0 auto;
  flex-wrap: wrap;
  text-align: center;
`;

export const ContinueButton = styled(ButtonRaw).attrs(({ theme }) => ({
  buttonStyle: {
    alignSelf: 'center',
    margin: getInnerSpacing(theme),
    width: theme.spacing * 12.5,
  },
}))``;

// @ts-ignore
export { default as DateOfBirthPicker } from 'app/components/Common/DatePicker';

export const InputContainer = styled(View)`
  ${({ theme }) => css`
    margin: 0px ${theme.isNarrow ? '0px' : 'auto'};
    padding: ${theme.spacing * 0.5}px 0;
    // Children of ScrollViews need a fixed height
    height: ${theme.spacing * 7}px;
  `}
`;

export { LegalContent } from 'app/components/Common/LegalContent';

export const LogoDivider = styled(View)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.bodyText};
    height: ${theme.spacing * 3}px;
    margin: 0 ${theme.spacing / 2}px;
    width: 0px;
  `}
`;

export { default as LogoForCarrum } from 'app/components/App/Navigation/CarrumLogo';

export const LogoForEmployer = styled(SizedImage).attrs(({ theme }) => {
  return {
    maxHeight: theme.spacing * 2.25,
    containerStyle: {
      marginRight: theme.spacing / 2,
    },
  };
})``;

export const LogosContainer = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: ${theme.spacing * 2}px;
    margin-bottom: ${theme.spacing * 2}px;
  `}
`;

export const OneClickWelcomeContainer = styled(ScrollView)`
  flex: 1 1 auto;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
`;

export const PhoneInput = styled(PhoneInputRaw).attrs(({ theme }) => ({
  containerStyle: {
    marginVertical: theme.spacing * 0.75,
  },
}))``;

export const RegistrationFormColumn = styled(View)`
  ${({ theme }) => {
    const horizontalMarginSize = theme.isNarrow ? theme.spacing : 0;
    return css`
      width: ${getRegistrationFormColumnWidth({
        theme,
        marginAdjustment: horizontalMarginSize * 2,
      })}px;
      margin: 0px
        ${horizontalMarginSize === 0 ? 'auto' : horizontalMarginSize + 'px'};
      padding-bottom: ${theme.spacing * 2.5}px;
    `;
  }}
`;

export { Routes } from 'app/util/routes';

export const ValuePropositionsColumn = () => {
  if (theme.windowDimensions.width <= theme.breakpoints.medium) return null;
  return (
    <ValuePropositionsColumnContainer>
      <BenefitImagesRow>
        <BenefitImageContainer>
          <QualityImage height={imageSize} width={imageSize} />
          <BenefitLabel>Quality Advantages</BenefitLabel>
        </BenefitImageContainer>
        <BenefitImageContainer>
          <CareImage height={imageSize} width={imageSize} />
          <BenefitLabel>Dedicated Care Specialist</BenefitLabel>
        </BenefitImageContainer>
        <BenefitImageContainer>
          <CostImage height={imageSize} width={imageSize} />
          <BenefitLabel>Lower Out-of-Pocket Cost</BenefitLabel>
        </BenefitImageContainer>
      </BenefitImagesRow>
    </ValuePropositionsColumnContainer>
  );
};

const ValuePropositionsColumnContainer = styled(AnimatedView)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-around;
    max-width: ${valuePropositionsColumnWidth}px;
    ${theme.isNarrow ? 'display: none' : ''}
  `}
`;

export const WelcomeTextBody = styled(Text).attrs(({ theme }) => ({
  size: getBaseFontSize(theme),
}))`
  ${({ theme }) => css`
    align-self: center;
    font-size: ${theme.fontSizes.body3}px;
    margin: ${theme.spacing}px 0 ${theme.spacing * 2}px;
    text-align: center;
  `}
`;

export const WelcomeTextContainer = styled(View)`
  align-self: center;
`;

export const WelcomeTextHeader = styled(Text).attrs({
  size: 'h1',
})`
  align-self: center;
  font-weight: bold;
  text-align: center;
  margin: ${theme.spacing / 2}px;
`;

export const WelcomeTextWrongUserLink = styled(Anchor).attrs(({ theme }) => ({
  titleStyle: {
    fontSize: theme.fontSizes.small,
    fontFamily: theme.fontFamilyMedium,
  },
}))`
  text-align: center;
`;

export const WelcomeTextWrongUserLinkContainer = styled(View)`
  ${({ theme }) => css`
    align-self: center;
    margin: ${theme.spacing / 2}px 0 ${theme.spacing * 1.5}px;
  `}
`;
