import styled, { css, DefaultTheme } from 'styled-components/native';

import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';
import { Platform } from 'react-native';
import { ScrollView } from 'app/components/Common/Styled';
import { Text } from 'app/components/Common/Styled';
import TrackableButton from 'app/components/Common/TrackableButton';
import { TextProps } from 'react-native';
import { View } from 'app/components/Common/Styled';

/**
 * Return the font size value for body text, using the same breakpoints as
 * other component text.
 */
const getBodyTextFontSize = (theme: DefaultTheme) => {
  switch (true) {
    case theme.windowDimensions.width < theme.breakpoints.xsmall:
      return theme.rawFontSizes.small;
    case theme.windowDimensions.width < theme.breakpoints.large:
      return theme.rawFontSizes.medium;
    default:
      return theme.rawFontSizes.large;
  }
};

/**
 * Return the font size value for subtitle text, using the same breakpoints as
 * other component text.
 */
const getSubtitleTextFontSize = (theme: DefaultTheme) => {
  switch (true) {
    case theme.windowDimensions.width < theme.breakpoints.xsmall:
      return theme.rawFontSizes.xlarge;
    case theme.windowDimensions.width < theme.breakpoints.large:
      return theme.rawFontSizes.xxlarge;
    default:
      return theme.rawFontSizes.xxxlarge;
  }
};

/**
 * Return the font size value for title text, using the same breakpoints for
 * other component text.
 */
const getTitleTextFontSize = (theme: DefaultTheme) => {
  switch (true) {
    case theme.windowDimensions.width < theme.breakpoints.xsmall:
      return theme.rawFontSizes.xxlarge;
    case theme.windowDimensions.width < theme.breakpoints.large:
      return theme.rawFontSizes.xxxlarge;
    default:
      return theme.rawFontSizes.xxxxlarge;
  }
};

/**
 * Return the amount of space between the text and the benefits card
 * container.
 */
const getUpperContentWrapperMarginBottom = (theme: DefaultTheme) => {
  switch (true) {
    case theme.windowDimensions.width < theme.breakpoints.xsmall:
      return theme.spacing * 1.5;
    case theme.windowDimensions.width < theme.breakpoints.large:
      return theme.spacing * 2.25;
    default:
      return theme.spacing * 4;
  }
};

export const AlignmentWrapper = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    margin: 0 auto;
    max-width: ${theme.spacing * 50}px;
  `}
`;

export { default as BenefitsOverview } from 'app/components/CompleteYourProfile/Benefits/BenefitsOverview';

export const BenefitsWelcomeTitle = styled(Text).attrs({
  medium: true,
})`
  ${({ theme }) => css`
    font-size: ${getTitleTextFontSize(theme)}px;
    margin-top: ${theme.isNarrow ? theme.spacing * 1.25 : theme.spacing * 2}px;
  `}
`;

export const BodyText = styled(Text)`
  ${({ theme }) => css`
    font-size: ${getBodyTextFontSize(theme)}px;
  `}
`;

export { default as ButtonList } from 'app/components/Common/ButtonList';

export { default as CarrumGoldExperienceBenefits } from 'app/components/Common/CarrumGoldExperienceBenefits';

export const Container = styled(View)`
  flex: 1 1 auto;
`;

export const CompletedText = styled(Text).attrs(({ theme }) => ({
  align: theme.isNarrow ? 'left' : 'center',
  size: 'body1',
}))`
  ${({ theme }) =>
    !theme.isNarrow &&
    css`
      margin: ${({ theme }) => `0 ${theme.spacing * 6}`}px;
    `}
`;

export const ContinueButton = styled(TrackableButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white}
    font-size: ${theme.fontSizes.medium};
    width: ${theme.spacing * 10}px;
  `}
`;

export const ContinueButtonWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing * 2.5}px;
    padding-bottom: ${theme.spacing * 2}px;
  `}
`;

export { Fade } from 'app/components/Common/Animated';

export const FooterText = styled(Text).attrs({
  size: 'body1',
})``;

export const FooterTextWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing}px;
  `}
`;

export const FormContainer = styled(
  Platform.OS === 'web' ? View : ScrollView
).attrs({
  keyboardAware: true,
})`
  ${({ theme }) => css`
    z-index: ${theme.zIndexes.overlay};
    padding: 0 ${theme.isNarrow ? theme.spacing : 0}px;
    padding-bottom: ${theme.isNarrow ? 5 * theme.spacing : 0}px;
  `}
`;

export { default as Header } from 'app/components/CompleteYourProfile/Questionnaire/Header';

export { LogBox } from 'react-native';

export { MemoryRouter } from '@cross-platform/react-router-native';

export { NavigationMode } from 'app/components/Common/StickyNavFooter/types';

export { default as ProfileCTAs } from 'app/components/CompleteYourProfile/Questionnaire/ProfileCTAs';

export { default as ProgressNav } from 'app/components/Common/ProgressNav';

export { Route } from '@cross-platform/react-router-native';

export const StepScreen = styled(View)`
  flex: ${Platform.OS === 'web' ? '0 0 auto' : 1};
`;

export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';

export const SubtitleText = styled(Text)`
  ${({ theme }) => css`
    font-size: ${getSubtitleTextFontSize(theme)}px;
  `}
`;

export const SubtitleTextWrapper = styled(View)`
  ${({ theme }) => css`
    margin: ${theme.isNarrow ? theme.spacing * 1.25 : theme.spacing * 2}px 0;
  `}
`;

export { Switch } from '@cross-platform/react-router-native';

export const TitleText = styled(Text).attrs<TextProps>({
  align: 'center',
  medium: true,
  size: 'hero',
})`
  margin: ${({ theme }) => theme.spacing}px 0;
`;

export const UpperContentWrapper = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${getUpperContentWrapperMarginBottom(theme)}px;
    padding: 0 ${theme.spacing}px;
  `}
`;

export { View };

export const Wrapper = styled(
  KeyboardAwareScrollView
).attrs<KeyboardAwareScrollViewProps>(({ theme }) => ({
  contentContainerStyle: {
    alignItems: 'center',
    backgroundColor: theme.colors.sand,
    justifyContent: 'flex-start',
    minHeight: '100%',
    paddingBottom: theme.spacing * 4,
  },
}))``;

export { Redirect } from '@cross-platform/react-router-native';
