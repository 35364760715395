import axios from 'axios';

import { CORE_API_URL } from 'app/util/constants';
import { unformatObject } from 'app/util/reducerUtils';

/**
 * Makes a PATCH request to the Core Service User API
 * update the patient record.
 *
 * @param {object} params Params to apply to the user record.
 * @return {promise} A promise that resolves after updating the user record.
 */
export default (eligiblePatient) => {
  return (dispatch, getState) => {
    const { coreToken, user } = getState().session;

    return axios.patch(
      `${CORE_API_URL}/eligible_patients/${user.eligiblePatient.id}`,
      unformatObject({ eligiblePatient }),
      { headers: { Authorization: `Bearer ${coreToken}` } }
    );
  };
};
