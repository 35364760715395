import styled from 'styled-components/native';

export const CardContent = styled.View`
  flex: 1 0 auto;
  padding: ${({ theme }) => theme.spacing}px;
`;

export const Container = styled.View`
  flex: 1 0 auto;
`;

export const ProcedureIconView = styled.View`
  padding: ${({ theme }) => theme.spacing / 2}px;
  margin-right: ${({ theme }) => theme.spacing / 4}px;
`;

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
`;
