export const extractBranding = (session) =>
  session?.user?.employer?.images?.logoMobile;

export const extractIncompleteFormCount = (submissions) => {
  return submissions.filter(({ completedOn }) => !completedOn).length;
};

export const extractUnreadMessageCount = (currentUserId, messages) => {
  const lastReadMessage = Array(...messages).filter(
    ({ readBy }) =>
      readBy.map((id) => parseInt(id)).indexOf(parseInt(currentUserId)) !== -1
  )[0];

  return Array(...messages).filter(
    ({ authorId, id, readBy }) =>
      parseInt(authorId) !== parseInt(currentUserId) &&
      parseInt(id) >= (lastReadMessage ? parseInt(lastReadMessage.id) : 0) &&
      readBy.map((id) => parseInt(id)).indexOf(parseInt(currentUserId)) === -1
  ).length;
};
