import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, View } from 'react-native';
import { Icon, Text, Tooltip } from 'react-native-elements';

import theme, { fontFamilyBold } from 'app/util/theme';
import { categorizeBmi } from 'app/util/profileUtils';

const bmiLabels = {
  underweight: 'Underweight',
  healthy: 'Healthy Weight',
  overweight: 'Overweight',
  obese: 'Obese',
  morbidly_obese: 'Morbidly Obese',
};

const bmiDescriptions = {
  underweight: 'A BMI under 18.5 is considered underweight.',
  healthy: 'A BMI between 18.5 and 24 is considered a healthy weight.',
  overweight: 'A BMI between 25 and 29 is considered overweight.',
  obese: 'A BMI over 30 is considered obese.',
  morbidly_obese: 'A BMI over 40 is considered morbidly obese.',
};

const bmiStatuses = {
  underweight: 'warning',
  healthy: 'success',
  overweight: 'warning',
  obese: 'error',
  morbidly_obese: 'error',
};

const BmiStatus = ({ bmi, containerStyle }) => {
  const bmiCategory = bmi ? categorizeBmi(bmi) : '';
  const color = theme.colors[bmiStatuses[bmiCategory]];
  const label = bmiLabels[bmiCategory];
  const description = bmiDescriptions[bmiCategory];

  return (
    <View style={StyleSheet.flatten([styles.container, containerStyle])}>
      <View style={styles.row}>
        <Text>BMI:</Text>
        <Text style={styles.bmi}>{bmi}</Text>
      </View>

      <Tooltip
        width={280}
        height={100}
        popover={
          <View>
            <Text style={styles.bmiTextHeading}>{label}</Text>
            <Text style={styles.bmiTextDescription}>{description}</Text>
          </View>
        }
      >
        <View style={styles.tooltipContent}>
          <Text style={{ color, fontFamily: fontFamilyBold }}>{label}</Text>
          <Icon
            size={20}
            color="#333"
            name="help-outline"
            style={styles.tooltipIcon}
          />
        </View>
      </Tooltip>
    </View>
  );
};

BmiStatus.propTypes = {
  bmi: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

BmiStatus.defaultProps = {
  bmi: '',
  containerStyle: {},
};

export default BmiStatus;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },

  row: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.spacing / 4,
  },

  bmi: {
    fontFamily: fontFamilyBold,
    marginLeft: theme.spacing / 4,
  },

  bmiTextContainer: {
    flexDirection: 'row',
  },

  bmiTextHeading: {
    color: '#fff',
    fontFamily: fontFamilyBold,
  },

  bmiTextDescription: {
    color: '#fff',
  },

  tooltipContainer: {
    alignItems: 'flex-end',
    flex: 1,
  },

  tooltipContent: {
    flexDirection: 'row',
  },

  tooltipIcon: {
    marginLeft: theme.spacing / 4,
  },
});
