import React, { useEffect, useState } from 'react';

import { useHistory } from '@cross-platform/react-router-native';

import { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
import { parameterize } from 'app/util/methods';

import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';
import HtmlView from 'app/components/Common/HtmlView';

import {
  AcknowledgementText,
  CheckBox,
  CheckBoxGroup,
  Field,
  FlexWrapper,
  FormQuestions,
  GooglePlacesInput,
  Input,
  NavigationButtons,
  NextButton as SaveButton,
  QuestionText,
  QuestionWrapper,
  RadioGroup,
  SaveLink,
  SaveLinkWrapper,
  Select,
  SignatureInput,
  StickyNavFooter,
  TextArea,
} from '../styles';
import { findNextLinkedQuestion, findNextUnlinkedQuestion } from '../helpers';
import { debounce } from 'lodash';

/**
 * Return the correct form component for a given type.
 *
 * @param {string} type The type of component to render.
 * @return {Component} A React component for the given type.
 */
const formComponentFromString = (type) => {
  switch (type) {
    case 'address':
      return GooglePlacesInput;
    case 'confirmation':
      return CheckBox;
    case 'checkbox':
      return CheckBoxGroup;
    case 'radio':
      return RadioGroup;
    case 'select':
      return Select;
    case 'signature':
      return SignatureInput;
    case 'string':
      return Input;
    case 'text':
      return TextArea;
  }
};

interface FormDetailsLayoutProps {
  initialize: (sub: any) => void;
  iterations?: any[];
  onSave: (formKey, order) => void;
  onSubmit: (formKey, order) => any;
  patientName: string;
  values: any;
}

/**
 * Render the details of a patient's form.  Allow entries to be changed and
 * submitted through the API.
 */
export const FormDetailsLayout = ({
  iterations = [],
  initialize,
  onSave,
  onSubmit,
  patientName,
  values,
}: FormDetailsLayoutProps) => {
  const submissions = [...iterations];
  const history = useHistory();

  const earliestIncompleteForm =
    submissions.find(({ completedOn }) => !completedOn) ||
    submissions.reverse()[submissions.length - 1];

  const [hasChanges, setHasChanges] = useState(false);
  const currentOrder = earliestIncompleteForm.order;
  const [errors, setErrors] = useState<any>({});
  const submission = iterations[currentOrder];

  let initializeTimeout = 0 as any;
  let savedTimeout = 0 as any;

  useEffect(() => {
    initialize(submission);

    return () => {
      clearTimeout(initializeTimeout);
      clearTimeout(savedTimeout);
    };
  }, []);

  // Capitalize the first letter of the string and the first letter after each
  // space.
  // NOTE: This transformation was added per product request for TEC-2326.
  const capitalizeFirstLetters = (input) =>
    input
      .split(' ')
      .map((substring) => {
        const word = substring.toLowerCase();
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

  /**
   * Returns the current version of the form being edited.
   * In some cases, forms are recurring and can have multiple
   * "instances" of the same form, so return the correct instance
   * based on the `currentOrder` state variable.
   */
  const currentIteration = () => iterations[currentOrder];

  // Immediately return to the forms list.
  const onCancel = () => {
    if (!hasChanges) {
      initializeTimeout = setTimeout(() => {
        history.goBack();
      });
    }
  };

  // Kick off form validation as users update the form.
  const onChange = () => {
    setHasChanges(true);
    setTimeout(validateAll); // timeout avoid race conditions with redux-forms
    debouncedSave();
  };

  // Reset the form state.
  const completeForm = (isSuccess) => {
    if (!isSuccess) return;

    setHasChanges(false);
  };

  const saveForm = async () => {
    if (!validateAll()) return;

    const submission = currentIteration();
    const isSuccess = await onSave(submission.formKey, submission.order);

    return completeForm(isSuccess);
  };

  const submitForm = async () => {
    if (!validateAll()) return;

    const submission = currentIteration();
    const isSuccess = await onSubmit(submission.formKey, submission.order);

    if (!isSuccess) return;

    completeForm(isSuccess);
    onCancel();
  };

  const validateAll = async () => {
    submission.formQuestions.forEach((q, idx) => validateQuestionByIndex(idx));
  };

  const validateQuestionByIndex = (questionIndex: number) => {
    const question = submission.formQuestions[questionIndex];
    const key = parameterize(question.label);
    const value = values[key];
    const hasValue = Array.isArray(value) ? value.length > 0 : Boolean(value);

    switch (true) {
      case question.inputType === 'signature' &&
        value?.toUpperCase() !== patientName:
        setErrors({
          [question.label]: `Please enter "${capitalizeFirstLetters(
            patientName
          )}" to confirm.`,
        });
        return false;
      case question.required && !hasValue:
        setErrors({ [question.label]: 'A response is required.' });
        return false;
      default:
        setErrors({ [question.label]: null });
        return true;
    }
  };

  const renderAcknowledgement = ({ label, content }) => {
    return (
      <>
        {content ? (
          <HtmlView html={content} />
        ) : (
          <AcknowledgementText>{label}</AcknowledgementText>
        )}
      </>
    );
  };

  /**
   * Render an input for a given form question.
   *
   * @return {Component} Return a React Component for the question.
   */
  const renderInput = ({ inputType, label, options, required, content }) => {
    const FormComponent = formComponentFromString(inputType);

    const name = parameterize(label);

    const inputOptions = (options || []).map((value) => ({
      label: value,
      value,
    }));

    const optionsStyle =
      inputType === 'checkbox' ? { flexDirection: 'column' } : {};

    const containerStyle =
      inputType === 'confirmation'
        ? { flexDirection: 'column-reverse', marginLeft: 0 }
        : {};

    const submission = currentIteration();

    return (
      <>
        {content ? (
          <HtmlView html={content} />
        ) : (
          <QuestionText hasError={errors[label]}>
            {required ? `${label} *` : label}
          </QuestionText>
        )}
        {inputType === 'signature' && (
          <QuestionText>
            Enter &quot;{capitalizeFirstLetters(patientName)}
            &quot; to confirm.
          </QuestionText>
        )}

        {/* @ts-ignore */}
        <Field
          component={FormComponent}
          containerStyle={containerStyle}
          disabled={Boolean(submission.completedOn)}
          error={errors[label]}
          label={inputType === 'confirmation' ? 'Confirm' : ''}
          name={name}
          onBlur={(e) => e?.preventDefault()}
          onCancel={onCancel}
          onChange={onChange}
          options={inputOptions}
          optionsStyle={optionsStyle}
          returnKeyType="next"
          unSelectedOption={inputType === 'select' ? true : undefined}
        />
      </>
    );
  };

  // calculate the chain of linked questions, omitting those that are not relevant based on responses
  const buildLinkedQuestionsList = () => {
    const result = [...submission.formQuestions];

    const assessQuestionTree = (question) => {
      const nextQuestion =
        findNextLinkedQuestion(question, submission.formQuestions) ||
        findNextUnlinkedQuestion(question, submission.formQuestions);

      result.push({ ...nextQuestion });

      // break out if we reach the end of the chain
      if (submission.formQuestions.indexOf(nextQuestion) == -1) return;

      assessQuestionTree(nextQuestion);
    };

    assessQuestionTree(result[0]);
    return result;
  };

  const { layout } = submission;

  const questionsByLabel = {};
  buildLinkedQuestionsList().forEach((q) => {
    return (questionsByLabel[q.label] = q);
  });

  const renderLayoutRow = (row) => {
    row.columns.map((column) => {
      if (column.rows) {
        column.rows.map(renderLayoutRow);
      } else if (column.questions) {
        renderColumnQuestions(column.questions);
      }
    });
  };

  const renderColumnQuestions = (questions) => {
    questions.map((label) => {
      const q = questionsByLabel[label];
      return (
        <FormQuestions key={label}>
          {q.inputType === 'acknowledgement'
            ? renderAcknowledgement(q)
            : renderInput(q)}
        </FormQuestions>
      );
    });
  };

  // TODO: finish this logic
  const isFormValid = true;

  const debouncedSave = debounce(async () => {
    saveForm();
  }, 500);

  return (
    <FlexWrapper testID={TestID.FormDetails.Component}>
      <QuestionWrapper>
        <SaveLinkWrapper>
          <SaveLink
            onPress={saveForm}
            testID={TestID.FormDetails.SaveLink}
            title={'Save and close'}
            to={`/${Routes.Dashboard}`}
          />
        </SaveLinkWrapper>

        {layout.rows.map(renderLayoutRow)}

        <NavigationButtons
          isFirstQuestion
          testID={TestID.FormDetails.NavButtons}
        >
          <SaveButton
            disabled={!isFormValid}
            onPress={submitForm}
            testID={TestID.FormDetails.SaveButton}
          />
        </NavigationButtons>
      </QuestionWrapper>
      <StickyNavFooter
        navigationConfig={[
          {
            disabled: !isFormValid,
            onPress: submitForm,
            text: 'Next',
          },
        ]}
        navigationMode={NavigationMode.PreviousAndNext}
        testID={TestID.FormDetails.NavFooter}
      />
    </FlexWrapper>
  );
};

export default FormDetailsLayout;
