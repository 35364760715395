import styled from 'styled-components/native';

import AnchorWithIcon from 'app/components/Common/AnchorWithIcon';
import Text from 'app/components/Common/Styled/Text';
import theme from 'app/util/theme';

export const BackAnchor = styled(AnchorWithIcon).attrs({
  icon: 'arrow-back',
})``;

export const BackAnchorContainer = styled.View`
  align-items: center;
  left: 0;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
`;

export const BackTitle = styled(Text)`
  color: ${theme.colors.primary};
  flex: 1 0 auto;
`;

export const Container = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing}px;
  position: relative;
`;

export const Header = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing}px;
`;
