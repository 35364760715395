import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';
import * as Updates from 'expo-updates';

import Anchor from 'app/components/Common/Anchor';

import { logError } from 'app/util/methods';
import theme from 'app/util/theme';

/**
 * A wrapper component that displays a generic error
 * message when an unhandled exception occurs in the app.
 *
 * @example
 * <ErrorBoundary>
 *   <SomeComponentThatMightBreak />
 * </ErrorBoundary>
 */
export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    logError(error);
  }

  /**
   * Reloads the experience in the current device.
   */
  reload() {
    // eslint-disable-next-line no-undef
    if (Platform.OS === 'web') return window.location.reload();

    Updates.reloadAsync();
  }

  render() {
    if (this.state.hasError) {
      return (
        <View style={styles.container}>
          <Icon name="bug-report" size={120} />
          <Text h1 h1Style={StyleSheet.flatten([styles.title])}>
            Something Went Wrong
          </Text>
          <Text style={StyleSheet.flatten([styles.paragraph])}>
            We encountered a problem running the app. Our engineers have been
            notified and are working on a solution right now.
          </Text>
          <Anchor
            icon="refresh"
            onPress={this.reload}
            title="Reload the app"
            titleStyle={styles.button}
            to="#"
          />
          <Text style={StyleSheet.flatten([styles.paragraph])}>
            If this problem continues, please call us at 1-888-855-7806.
          </Text>
        </View>
      );
    }

    return this.props.children;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing * 1.25,
  },

  title: {
    textAlign: 'center',
  },

  paragraph: {
    textAlign: 'center',
    marginBottom: theme.spacing * 1.25,
  },

  button: {
    marginBottom: theme.spacing * 1.25,
  },
});
