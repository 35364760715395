import React, { useCallback } from 'react';

import { Text } from 'app/components/Common/Styled';
import { FacilityCard } from 'app/components/SelectYourDoctor/Cards';
import { isSilverHospital } from 'app/components/SelectYourDoctor/helpers';
import { useEpisode, usePossibleHospitals } from 'app/hooks';

import ScrollPagination from '../ScrollPagination';

const HospitalsList = () => {
  const {
    tempCoeSelection: { productOffering },
  } = useEpisode();
  const {
    data: { records: hospitals },
    loading,
    next,
  } = usePossibleHospitals({ fetch: true });

  if (hospitals.length === 0 && loading) {
    return <Text>Please wait while we load your selections...</Text>;
  }

  if (hospitals.length === 0 && !loading) {
    return <Text>No options found.</Text>;
  }

  return (
    <>
      {hospitals
        .filter(
          (hospital) =>
            productOffering.code === 'both' || !isSilverHospital(hospital)
        )
        .map((hospital) => (
          <FacilityCard
            id={hospital.id}
            key={hospital.id}
            isSilver={isSilverHospital(hospital)}
            {...hospital}
          />
        ))}

      <ScrollPagination onScrollBottom={next} />
    </>
  );
};

export default HospitalsList;
