import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from '@cross-platform/react-router-native';

import { logEvent, EVENTS } from 'app/util/analytics';
import { parameterize, trimWhitespace } from 'app/util/methods';
import { Routes } from 'app/util/routes';
import { TestID } from 'app/util/test-id';

import FormDetails from './FormDetails';
import FormDetailsLayout from './FormDetailsLayout';
import FormList from './FormList';
import EnvironmentSwitch from '../Common/EnvironmentSwitch';
import { nonProdEnvironments } from '../App/Navigation/constants';
import { EnvironmentTypes } from '../Login/types';

export default class Forms extends Component {
  /**
   * @property {function} initialize A function to initialize the redux-form values (required).
   * @property {function} fetchSubmissions A function to fetch submissions from the API (required).
   * @property {object} formValues The redux-form values (required).
   * @property {string} patientName The patient's name (required).
   * @property {function} showLocalNotification A function to display local notifications (required).
   * @property {array} submissions An array of Submission records from the API (required).
   * @property {function} updateSubmission A function to update the current episode (required).
   * @property {object} submission The current Submission record (defaults to null).
   * @property {boolean} loading True if the current form is loading (defaults to false).
   */
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    fetchSubmissions: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    patientName: PropTypes.string.isRequired,
    showLocalNotification: PropTypes.func.isRequired,
    submissions: PropTypes.array.isRequired,
    updateSubmission: PropTypes.func.isRequired,

    iterations: PropTypes.array,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    iterations: [],
  };

  /**
   * Initializes the redux form state for a given submission object.
   *
   * @param {object} submission A submission object with a `formKey` and `formQuestions`.
   */
  initialize = ({ formName, formQuestions }) => {
    const form = {};

    formQuestions.forEach(({ inputType, label, response }) => {
      const key = parameterize(label);

      if (inputType === 'signature')
        return (form[key] = response === true ? this.props.patientName : '');
      if (typeof response !== 'undefined') return (form[key] = response);

      form[key] = inputType === 'checkbox' ? [] : '';
    });

    this.props.initialize(form);

    logEvent(EVENTS.forms.view, { form: formName });
  };

  /**
   * Applies responses to a submission object.
   *
   * @param {object} submission A Submission record.
   * @param {object} responses An object with form responses where the label is the key and the response is the value.
   * @return {object} A submission with form responses applied.
   */
  applyChanges = (submission, responses) => {
    const result = { ...submission };

    Object.keys(responses).forEach((key) => {
      const response = responses[key];
      const question = result.formQuestions.find(
        ({ label }) => parameterize(label) === key
      );
      question.response =
        question.inputType === 'signature'
          ? response.toUpperCase() === this.props.patientName
          : response;
    });

    return result;
  };

  /**
   * Displays a local message to the user indicating save success.
   */
  notify = (isComplete) => {
    this.props.showLocalNotification({
      id: Math.random(),
      icon: 'check',
      title: isComplete ? 'Form submitted.' : 'Changes saved.',
      dismissIn: isComplete ? 5000 : 2000,
      status: isComplete ? 'success' : 'info',
    });
  };

  /**
   * Submits form responses to the API with the
   * appropriate timestamps and resolves with a
   * boolean indicating success.
   *
   * @param {string} key The key of the submission record to save.
   * @param {integer} order The order of the submission record to save.
   * @param {boolean} isComplete True if the form is being submitted for completion (defaults to false).
   * @return {promise} A promise that resolves after submitting the record.
   */
  submitChanges = async (key, order = 0, isComplete = false) => {
    const submission = this.applyChanges(
      this.props.submissions.find(
        (submission) => submission.formKey === key && submission.order === order
      ),
      this.props.formValues
    );

    submission.startedOn = submission.startedOn || new Date();

    if (isComplete) submission.completedOn = new Date();

    const isSuccess = await this.props.updateSubmission(
      submission.id,
      submission
    );

    if (isSuccess) {
      logEvent(isComplete ? EVENTS.forms.submit : EVENTS.forms.save, {
        form: submission.formName,
      });
    }

    return isSuccess;
  };

  /**
   * Saves the form responses for a submission with
   * a given form key and sets the started on date.
   *
   * @param {string} key The key of the submission record to save.
   * @param {integer} order The order of the submission record to save.
   * @return {promise} A promise that resolves after saving the record.
   */
  onSave = (key, order = 0) => this.submitChanges(key, order, false);

  /**
   * Saves the form responses for a submission with
   * a given form key and sets the submitted on date.
   *
   * @param {string} key The key of the submission record to save.
   * @param {integer} order The order of the submission record to save.
   * @return {promise} A promise that resolves after submitting the record.
   */
  onSubmit = (key, order = 0) => this.submitChanges(key, order, true);

  render() {
    const { fetchSubmissions, formValues, iterations, submissions } =
      this.props;

    return (
      <View testID={TestID.Forms.Page} style={styles.container}>
        <Switch>
          <Route
            exact
            path="/forms"
            render={() => (
              <FormList
                forms={submissions}
                refresh={fetchSubmissions}
                loading={this.props.loading}
              />
            )}
          />
          <Route
            exact
            path="/forms/:id"
            render={() => {
              if (iterations.length == 0)
                return <Redirect to={`/${Routes.Forms}`} />;
              else {
                return (
                  <>
                    <EnvironmentSwitch
                      environments={[EnvironmentTypes.Production]}
                    >
                      <FormDetails
                        onSave={this.onSave}
                        onSubmit={this.onSubmit}
                        values={trimWhitespace(formValues || {})}
                        initialize={this.initialize}
                        loading={this.props.loading}
                        patientName={this.props.patientName}
                        iterations={iterations}
                      />
                    </EnvironmentSwitch>
                    <EnvironmentSwitch environments={nonProdEnvironments}>
                      {iterations?.some((i) => !!i.layout) ? (
                        <FormDetailsLayout
                          onSave={this.onSave}
                          onSubmit={this.onSubmit}
                          values={trimWhitespace(formValues || {})}
                          initialize={this.initialize}
                          loading={this.props.loading}
                          patientName={this.props.patientName}
                          iterations={iterations}
                        />
                      ) : (
                        <FormDetails
                          onSave={this.onSave}
                          onSubmit={this.onSubmit}
                          values={trimWhitespace(formValues || {})}
                          initialize={this.initialize}
                          loading={this.props.loading}
                          patientName={this.props.patientName}
                          iterations={iterations}
                        />
                      )}
                    </EnvironmentSwitch>
                  </>
                );
              }
            }}
          />
        </Switch>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
