import { Animated, Platform, Pressable } from 'react-native';
import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';
import theme from 'app/util/theme';

const AnimatedView = Animated.createAnimatedComponent(View);
const AnimatedTouchableOpacity = Animated.createAnimatedComponent(Pressable);

const { darkBlue, lightBlue } = theme.colors;

export const BottomSection = styled(View)`
  flex-direction: row;
  height: 120px;
  justify-content: space-evenly;
`;

export const MainContentsWrapper = styled(AnimatedView)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const MenuToggleButton = styled(AnimatedTouchableOpacity)<{
  width: number;
}>`
  align-items: center;
  border-color: ${darkBlue};
  border-width: 2px;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  overflow: visible;
  position: absolute;
  right: 0;
  top: 50%;
  width: ${({ width }) => width}px;
  z-index: ${theme.zIndexes.progressNavigation};
`;

export const NavWrapper = styled(AnimatedView)<{
  width: number;
}>`
  background: ${lightBlue};
  bottom: 0;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: space-between;
  left: 0;
  max-width: ${({ width }) => width}px;
  position: ${Platform.select({
    default: 'absolute',
    web: 'fixed',
  })};
  top: 0;
  width: ${({ width }) => width}px;
  z-index: ${theme.zIndexes
    .progressNavigation}; /** Allows nav menu button to be clicked when menu is closed */
`;

export const PositionRelativeWrapper = styled(View)`
  flex: 1 0 auto;
  flex-direction: row;
  position: relative;
`;

// Take up same vertical space as BottomSection
export const TopSpacer = BottomSection;
