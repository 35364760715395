import { formatData } from 'app/util/reducerUtils';
import {
  RECEIVE_CURRENT_CONVERSATION,
  RECEIVE_CONVERSATION,
  RECEIVE_CONVERSATIONS,
  RECEIVE_MESSAGES,
  RECEIVE_MESSAGES_OPEN,
  RECEIVE_MESSAGE,
  RECEIVE_USER_STATUS,
  RECEIVE_USER_STATUSES,
  RECEIVE_WEBSOCKET_CONNECTED,
} from 'app/actions/messageActions';
import { RECEIVE_LOGOUT } from 'app/actions/sessionActions';

const nullState = {
  connected: false,
  open: false,
  conversationId: null,
  conversations: [],
  userStatuses: [],
};

export { nullState };

export default (state = nullState, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_CONVERSATION:
      return { ...state, conversationId: action.conversationId };
    case RECEIVE_CONVERSATIONS:
      return { ...state, conversations: action.conversations.map(formatData) };
    case RECEIVE_CONVERSATION: {
      const conversations = Array(...state.conversations);
      const conversation = formatData(action.conversation);
      const index = conversations.findIndex(
        ({ id }) => parseInt(id) === parseInt(conversation.id)
      );

      if (index !== -1 && conversation.delete) {
        conversations.splice(index, 1);
      } else if (index !== -1) {
        conversations[index] = conversation;
      } else {
        conversations.push(conversation);
      }

      return { ...state, conversations };
    }
    case RECEIVE_MESSAGES_OPEN:
      return { ...state, open: action.isOpen };
    case RECEIVE_MESSAGES: {
      const conversations = Array(...state.conversations);
      const conversation = conversations.find(
        ({ id }) => parseInt(id) === parseInt(action.conversationId)
      );

      conversation.messages = Array(...conversation.messages).concat(
        action.messages.map(formatData)
      );

      return { ...state, conversations };
    }
    case RECEIVE_MESSAGE: {
      const conversations = Array(...state.conversations);
      const conversation = conversations.find(
        ({ id }) => parseInt(id) === parseInt(action.conversationId)
      );
      const message = formatData(action.message);
      const index = conversation.messages.findIndex(
        ({ id }) => parseFloat(id) === parseFloat(message.id)
      );

      conversation.messages = Array(...conversation.messages);

      if (index !== -1 && message.delete) {
        conversation.messages.splice(index, 1);
      } else if (index !== -1) {
        conversation.messages[index] = message;
      } else if (!message.delete) {
        conversation.messages.unshift(message);
      }

      if (!message.pending) {
        conversation.messages = conversation.messages.filter(
          ({ pending }) => !pending
        );
      }

      return { ...state, conversations };
    }
    case RECEIVE_USER_STATUSES:
      return { ...state, userStatuses: action.userStatuses.map(formatData) };
    case RECEIVE_USER_STATUS: {
      const userStatuses = Array(...state.userStatuses);
      const userStatus = formatData(action.userStatus);
      const index = userStatuses.findIndex(
        ({ userId }) => parseInt(userId) === parseInt(userStatus.userId)
      );

      if (index !== -1) {
        userStatuses[index] = userStatus;
      } else {
        userStatuses.push(userStatus);
      }

      return { ...state, userStatuses };
    }
    case RECEIVE_WEBSOCKET_CONNECTED:
      return { ...state, connected: action.isConnected };
    case RECEIVE_LOGOUT:
      return action.hard ? nullState : state;
    default:
      return state;
  }
};
