import {
  stepOneValidations as validations,
  stepOneDefaultFormValues as defaultFormValues,
  TestID,
} from 'app/components/Register/constants';

import { isMobileDevice, makeCall } from 'app/components/Register/helpers';

import {
  useCallback,
  useRef,
  useState,
  useValidations,
} from 'app/components/Register/hooks';

import {
  ButtonWrapper,
  DatePicker,
  FormError,
  FormView,
  HalfWidthButton,
  Input,
  LegalContent,
  MemberCardImage,
  Paragraph,
  ScreenWrapper,
  StyledCardImage,
  InstructiveText,
  View,
} from 'app/components/Register/styles';

import { StepOneProps } from 'app/components/Register/types';

const BypassSsnRegistrationContent = () => (
  <View>
    <InstructiveText>
      Each person covered by a health insurance plan has a member ID number.
      Please find your health insurance card, and enter the last 4 digits of
      your member ID.
    </InstructiveText>
    <StyledCardImage resizeMode="contain" source={MemberCardImage} />
  </View>
);

const StepOne = ({
  onSubmit,
  bypassSsnRegistration,
  error,
  loading,
}: StepOneProps) => {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const { errors, isValid, validateField } = useValidations({
    formValues,
    validations,
  });

  const dateOfBirthInput = useRef<HTMLInputElement>();
  const employerRegistrationIdInput = useRef<HTMLInputElement>();
  const lastNameInput = useRef<HTMLInputElement>();

  // True if there are any incomplete form values
  const isIncomplete = Object.values(formValues).some(
    (value) => typeof value === 'undefined'
  );

  /**
   * Assign focus to a specific input (if possible).
   *
   * @param  inputRef  the input ref to receive focus
   */
  const assignFocusTo = (
    inputRef: React.MutableRefObject<HTMLInputElement>
  ) => {
    if (inputRef?.current) inputRef.current.focus();
  };

  /**
   * Calls the `onSubmit` prop with the current form values if
   * the component is not loading and there are no errors.
   */
  const handleFormSubmission = useCallback(() => {
    if (!isValid) return;

    onSubmit(formValues);
  }, [formValues, isValid, onSubmit]);

  return (
    <ScreenWrapper testID={TestID.Register.StepOne}>
      <>
        <Paragraph>
          For your security, we need to verify your eligibility. Your
          information must match your medical insurance ID card.
        </Paragraph>

        <FormView>
          <Input
            label="First Name"
            autoCapitalize="words"
            autoCorrect={false}
            blurOnSubmit={false}
            error={errors.firstName}
            input={{
              name: TestID.Register.FirstNameInput,
              value: formValues.firstName,
              onBlur: () => validateField('firstName'),
              onChange: (firstName) => {
                setFormValues({ ...formValues, firstName });
              },
            }}
            onSubmitEditing={() => assignFocusTo(lastNameInput)}
            returnKeyType="next"
          />

          <Input
            label="Last Name"
            autoCapitalize="words"
            autoCorrect={false}
            error={errors.lastName}
            input={{
              name: TestID.Register.LastNameInput,
              value: formValues.lastName,
              onBlur: () => validateField('lastName'),
              onChange: (lastName) => {
                setFormValues({ ...formValues, lastName });
              },
            }}
            onSubmitEditing={() => assignFocusTo(dateOfBirthInput)}
            returnKeyType="next"
            textInputRef={lastNameInput}
          />

          <DatePicker
            label="Date of Birth"
            error={errors.dateOfBirth}
            forwardRef
            input={{
              name: TestID.Register.DateOfBirthInput,
              value: formValues.dateOfBirth,
              onBlur: () => validateField('dateOfBirth'),
              onChange: (dateOfBirth) => {
                setFormValues({ ...formValues, dateOfBirth });
              },
            }}
            onSubmitEditing={() => assignFocusTo(employerRegistrationIdInput)}
            placeholder="Select a Date"
            ref={dateOfBirthInput}
          />

          <Input
            label={
              bypassSsnRegistration
                ? 'Last 4 of Member ID'
                : 'Last 4 of Social Security Number'
            }
            error={errors.employerRegistrationId}
            info={
              bypassSsnRegistration
                ? {
                    content: <BypassSsnRegistrationContent />,
                    title: 'What is Insurance Member ID?',
                  }
                : undefined
            }
            input={{
              name: TestID.Register.EmployerRegistrationIdInput,
              value: formValues.employerRegistrationId,
              onBlur: () => validateField('employerRegistrationId'),
              onChange: (employerRegistrationId) => {
                setFormValues({ ...formValues, employerRegistrationId });
              },
            }}
            keyboardType={bypassSsnRegistration ? 'default' : 'number-pad'}
            maxLength={4}
            onSubmitEditing={handleFormSubmission}
            returnKeyType="done"
            textInputRef={employerRegistrationIdInput}
            type={bypassSsnRegistration ? 'alphanumeric' : 'numeric'}
          />

          {error ? (
            <FormError testID={TestID.Register.ErrorMessage}>{error}</FormError>
          ) : null}
        </FormView>
      </>

      <ButtonWrapper>
        {!isMobileDevice || !error ? null : (
          <HalfWidthButton
            isFullWidth={!isMobileDevice || !error}
            onPress={makeCall}
            title="Call Us"
            type="outline"
          />
        )}
        <HalfWidthButton
          disabled={loading || isIncomplete || !isValid}
          isFullWidth={!isMobileDevice || !error}
          loading={loading}
          onPress={handleFormSubmission}
          raised
          testID={TestID.Register.ContinueButton}
          title="Continue"
        />
      </ButtonWrapper>
      <LegalContent />
    </ScreenWrapper>
  );
};

export default StepOne;
