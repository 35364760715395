import { useSelector } from 'react-redux';

import * as episodeSelectors from 'app/selectors/episode';
import * as sessionSelectors from 'app/selectors/session';
import { State } from 'types/state';

interface UsePlatformHook {
  isPlatformTwo: boolean;
  isPlatformOne: boolean;
}

/**
 * Determine the correct platform version for the current experience based
 * on the current user's employer and episode (if any), with the platform
 * version defined on the episode taking precedence.
 */
const usePlatform = (): UsePlatformHook => {
  const { episode, session } = useSelector((store: State.RootState) => store);
  const hasEpisode = episodeSelectors.getIsEpisodeCreated(episode);

  const isPlatformOne: boolean = hasEpisode
    ? episodeSelectors.getIsPlatformOne(episode)
    : sessionSelectors.getIsPlatformOne(session);
  const isPlatformTwo: boolean = hasEpisode
    ? episodeSelectors.getIsPlatformTwo(episode)
    : sessionSelectors.getIsPlatformTwo(session);

  return {
    isPlatformTwo,
    isPlatformOne,
  };
};

export default usePlatform;
