import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';

import { SurgeonPropTypes } from 'app/util/propTypes';

import SurgeonCard from '../SurgeonCard';

const propTypes = {
  onPress: PropTypes.func.isRequired,
  ...SurgeonPropTypes,
};

const SurgeonResult = memo(({ onLayout, onPress, ...surgeonCardProps }) => {
  return (
    <TouchableOpacity onPress={onPress} onLayout={onLayout}>
      <SurgeonCard {...surgeonCardProps} />
    </TouchableOpacity>
  );
});

SurgeonResult.displayName = 'SurgeonResult';
SurgeonResult.propTypes = propTypes;
export default SurgeonResult;
