import { Field as FieldRaw } from 'redux-form';
import styled, { css } from 'styled-components/native';

import MapViewRaw from '@cross-platform/react-native-maps';
import { View } from 'app/components/Common/Styled';
export { Marker } from '@cross-platform/react-native-maps';

export const CheckBoxView = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const Field = styled(FieldRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export const MapView = styled(MapViewRaw)`
  ${({ theme }) => css`
    width: 100%;
    height: ${theme.spacing * 11}px;
    margin-top: ${theme.spacing}px;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const Row = styled(View)`
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing / 4}px;
`;

export const SmallInputContainer = styled(View)<{ mr?: boolean }>`
  flex: 1;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing / 4}px`}
`;
