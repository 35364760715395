import styled from 'styled-components/native';

import { default as CheckBoxRaw } from 'app/components/Common/CheckBox';
import { GooglePlacesInput as GooglePlacesInputRaw } from 'app/components/Common/GooglePlacesInput';
import { View as ViewRaw } from 'app/components/Common/Styled';

export const CheckBox = CheckBoxRaw;

export const CheckboxWrapper = styled(ViewRaw)`
  flex: 1 0 auto;
  margin-top: ${({ theme }) => theme.spacing}px;
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
`;

export const GooglePlacesInput = GooglePlacesInputRaw;

export const GooglePlacesInputWrapper = styled(ViewRaw)`
  flex: 1 0 auto;
  margin-top: ${({ theme }) => theme.spacing}px;
`;

export const View = styled(ViewRaw)``;
