import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Confirmation from 'app/components/Confirmation';

import {
  getCostEstimate,
  createEpisodeFromState,
  updateCurrentEpisode,
} from 'app/actions/episodeActions';
import { updateInsurance } from 'app/actions/userActions';
import { fetchSubmissions } from 'app/actions/submissionActions';

import { push } from 'app/util/history';
import { extractFormValues } from 'app/util/methods';
import { hasMemberIdError, isMemberIdRequired } from 'app/util/userUtils';

const mapStateToProps = ({ episode, form, session }) => ({
  sessionLoading: session.loading,
  estimateLoading: episode.loading || episode.costEstimateLoading,
  oopEstimate: episode.oopEstimate,
  distance: episode.distance,
  episode: episode.inquiry
    ? null
    : episode.episode || extractFormValues(form, 'episode'),
  inquiry: episode.inquiry,
  formValues: extractFormValues(form, 'episode'),
  memberIdError: hasMemberIdError(
    session.user,
    episode.costEstimate || episode.oopEstimate,
    episode.costEstimateLoading
  ),
  memberIdRequired: isMemberIdRequired(session.user),
  deductibleType:
    session.user &&
    session.user.employer &&
    session.user.employer.deductibleType,
  hsaFlag:
    session.user &&
    session.user.eligiblePatient &&
    session.user.eligiblePatient.insurance &&
    session.user.eligiblePatient.insurance.hsaFlag,
});

const mapDispatchToProps = (dispatch) => ({
  push,
  getCostEstimate: (procedureKey) => dispatch(getCostEstimate(procedureKey)),
  fetchSubmissions: () => dispatch(fetchSubmissions()),
  createEpisode: () => dispatch(createEpisodeFromState()),
  updateEpisode: (params) => dispatch(updateCurrentEpisode(params)),
  updateInsurance: (params) => dispatch(updateInsurance(params)),
});

const ConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);

export default reduxForm({
  form: 'episode',
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(ConfirmationContainer);
