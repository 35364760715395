import { Divider as DividerRaw } from 'react-native-elements';

import styled, { css } from 'styled-components/native';

import ModalRaw, { ModalProps } from 'app/components/Common/Modal';
import { Text as TextRaw, View } from 'app/components/Common/Styled';
import {
  CheckCircle,
  CheckCircleProps,
} from 'app/components/Common/svg-components';
import AnchorRaw, { AnchorProps } from 'app/components/Common/Anchor';

export const Anchor = styled(AnchorRaw).attrs<AnchorProps>(({ theme }) => ({
  titleStyle: {
    textAlign: 'center',
    marginBottom: theme.spacing / 2,
    marginTop: theme.spacing / 2,
    fontSize: theme.fontSizes.h2,
    fontFamily: theme.fontFamilyMedium,
  },
}))<AnchorProps>``;

export const CallYouText = styled(TextRaw)`
  ${({ theme }) => css`
    margin: 0 auto;
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.h2}px;
    padding-bottom: ${theme.spacing / 2}px;
    padding-top: ${theme.spacing / 2}px;
  `}
`;

export const CarrumPhoneText = styled(TextRaw)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.body3}px;
    margin: 0 auto;
    padding-top: ${theme.spacing / 2}px;
    padding-bottom: ${theme.spacing / 2}px;
    text-align: center;
  `};
`;

export const ConfirmationBody = styled(TextRaw)`
  text-align: center;
`;

export const ConfirmationHeader = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.success};
    font-family: ${theme.fontFamilyBold};
    margin: ${theme.spacing / 2}px auto;
  `}
`;

export const DetailsText = styled(TextRaw)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xsmall}px;
    margin: ${theme.spacing / 4}px auto;
  `}
`;

export const DetailsTextBold = styled(DetailsText)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilyBold};
  `}
`;

export const Divider = styled(DividerRaw)`
  margin-top: ${({ theme }) =>
    theme.isNarrow ? `${theme.spacing / 2}px` : `${theme.spacing}px`};
`;

export const GreenCheckMark = styled(CheckCircle).attrs<CheckCircleProps>(
  ({ theme }) => ({
    color: theme.colors.success,
    size: 67,
  })
)<CheckCircleProps>`
  margin: 0 auto;
`;

export const Header = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.h2}px;
    margin: ${theme.spacing / 2}px auto;
    padding-top: ${theme.spacing}px;
  `}
`;

export const Modal = styled(ModalRaw).attrs(({ theme }) => ({
  overlayStyleOverrides: {
    backgroundColor: theme.colors.sand,
  },
}))<ModalProps>``;

export const PhoneNumberDescriptionWrapper = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing / 2}px;
    margin-top: ${theme.spacing / 2}px;
  `};
`;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
    padding: ${theme.spacing / 4}px;
  `}
`;

export const SubText = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
    font-style: italic;
    padding-bottom: ${theme.spacing / 2}px;
  `}
`;
