import { useEffect } from 'react';

import {
  addNotificationReceivedListener,
  addNotificationResponseReceivedListener,
  Notification,
  NotificationContent,
  NotificationResponse,
} from 'expo-notifications';
import { useHistory } from '@cross-platform/react-router-native';
import { useDispatch } from 'react-redux';

import {
  dismissLocalNotification,
  showLocalNotification,
} from 'app/actions/uiActions';
import { useAppState } from 'app/hooks';
import { logError } from 'app/util/methods';

export const useNotifications = () => {
  const dispatch = useDispatch();
  const { appState } = useAppState();
  const { push } = useHistory();

  /**
   * Handles pressing both native push notifications and local in-app
   * notifications, dismissing an in-app notification (if any).
   *
   * If pressing a notification with an embedded `redirect` property,
   * redirect to that location in the application.
   */
  const onNotificationPress = (
    response: NotificationResponse & NotificationContent
  ) => {
    console.log('Notification pressed', response);

    /**
     * If pressing on a native notification, you'll receive a `NotificationResponse`.
     * If pressing on an in-app notifcation, you'll receive a `NotificationContent`.
     */
    const data =
      response?.data || response?.notification?.request?.content?.data;

    if (data?.redirect) push(data?.redirect);

    dispatch(dismissLocalNotification(data));
  };

  /**
   * Handle receiving push notifications and display a local in-app
   * notification if the app is in the active state.
   */
  const onNotificationReceived = (notification: Notification) => {
    console.log('Notification received', notification);

    const data = notification?.request?.content?.data;

    if (!data) return;

    dispatch(showLocalNotification(data));
  };

  /**
   * Listen for notifications being received by the application
   * when active to allow showing in-app notifications.
   */
  useEffect(() => {
    if (appState !== 'active') return;

    let subscription;

    try {
      subscription = addNotificationReceivedListener(onNotificationReceived);
    } catch (error) {
      logError('Unable to listen for push notifications', error);
    }

    return () => subscription?.remove();
  }, [appState]);

  /**
   * Listen for notifications being interacted with by the application.
   */
  useEffect(() => {
    let subscription;

    try {
      subscription =
        addNotificationResponseReceivedListener(onNotificationPress);
    } catch (error) {
      logError('Unable to listen for pressing push notifications', error);
    }

    return () => subscription?.remove();
  }, []);

  return {
    onNotificationPress,
    onNotificationReceived,
  };
};

export default useNotifications;
