import styled, { css } from 'styled-components/native';
import { Image } from 'react-native';

import { Fade } from 'app/components/Common/Animated';
import { AbsoluteFixedContainer, View } from 'app/components/Common/Styled';
import { loadingAnimationTimeout } from 'app/components/ProcedureSearch/ProcedureSearchPage/constants';

export const LoadingPuzzleContainer = styled(AbsoluteFixedContainer).attrs({
  fill: true,
  pointerEvents: 'none',
})`
  z-index: ${({ theme }) => theme.zIndexes.overlay};
`;

export const LoadingPuzzleFadeWrapper = styled(Fade).attrs({
  delay: loadingAnimationTimeout,
  out: true,
})``;

export const LoadingPuzzleBackgroundContainer = styled(View)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.sand};
  justify-content: center;
  height: ${({ theme }) => theme.windowDimensions.height}px;
  width: ${({ theme }) => theme.windowDimensions.width}px;
`;

export const LoadingPuzzleGif = styled(Image)`
  ${({ theme }) => css`
    height: ${theme.isNarrow ? theme.spacing * 10 : theme.spacing * 30}px;
    width: ${theme.isNarrow ? theme.spacing * 15 : theme.spacing * 45}px;
    max-width: 100%;
  `}
`;
