import styled, { css } from 'styled-components/native';

import { checkMarkGreen } from 'app/components/Common/svg-components/CheckMark';
import { GooglePlacesInput as GooglePlacesInputRaw } from 'app/components/Common/GooglePlacesInput';
import ModalRaw from 'app/components/Common/Modal';
import { default as RadioGroupRaw } from 'app/components/Common/RadioGroup';
import { Text as TextRaw, View } from 'app/components/Common/Styled';

export { ModalOption } from 'app/components/Common/Modal';
export { ScrollView, View } from 'app/components/Common/Styled';
export { CheckMark } from 'app/components/Common/svg-components';
export { default as TextArea } from 'app/components/Common/TextArea';

export const DescriptionWrapper = styled(View)`
  margin-bottom: ${({ theme }) => theme.spacing}px;
  text-align: left;
`;

export const GooglePlacesInput = GooglePlacesInputRaw;

export const GooglePlacesInputWrapper = styled(View)`
  flex: 1 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

export const InputWrapper = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
    background-color: ${theme.colors.sand};
  `}
`;

export const Modal = styled(ModalRaw).attrs(({ theme }) => ({
  overlayStyle: {
    backgroundColor: theme.colors.sand,
    paddingLeft: theme.spacing,
    paddingRight: theme.spacing,
    maxWidth: theme.spacing * 48,
  },
}))``;

export const RadioGroup = styled(RadioGroupRaw)``;

export const RequestReceivedText = styled(TextRaw).attrs({
  h3: true,
  h3Style: {
    color: checkMarkGreen,
  },
})`
  margin-top: ${({ theme }) => theme.spacing}px;
  text-align: center;
`;

export const RequestReceivedWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing * 3}px;
    margin-top: ${theme.spacing * 3}px;
  `}
`;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.body2}px;
  `}
`;
