import React from 'react';
import PropTypes from 'prop-types';

import { useWindowDimensions } from 'react-native';

import { ProcedurePropTypes } from 'app/util/propTypes';
import theme from 'app/util/theme';

import {
  Anchor,
  AnchorWithIcon,
  ListItem,
  ProcedureContentColumn,
  ProcedureDescription,
  ProcedureIcon,
  ProcedureTitle,
  ProcedureTitleRow,
  TouchableHighlight,
} from './styles';

/**
 * Renders a search result for a given procedure.
 */
const ProcedureSearchResult = ({ onPress, procedure }) => {
  const { width } = useWindowDimensions();
  const isLargeScreen = width > theme.breakpoints.small;

  return (
    <TouchableHighlight
      onPress={onPress}
      testID="ProcedureSearch--search-result"
    >
      <ListItem>
        <ProcedureTitleRow>
          {Boolean(procedure.images && procedure.images.icon) && (
            <ProcedureIcon icon={procedure.images.icon} />
          )}
        </ProcedureTitleRow>

        <ProcedureContentColumn isLargeScreen={isLargeScreen}>
          <ProcedureTitle isLargeScreen={isLargeScreen}>
            {procedure.name}
          </ProcedureTitle>
          {Boolean(procedure.content && procedure.content.description) && (
            <ProcedureDescription html={procedure.content.description} />
          )}
          {isLargeScreen && (
            <Anchor
              onPress={onPress}
              title="Click here to see available COEs, savings, and more"
              to={`/procedures/${procedure.key}`}
            />
          )}
        </ProcedureContentColumn>

        <AnchorWithIcon onPress={onPress} to={`/procedures/${procedure.key}`} />
      </ListItem>
    </TouchableHighlight>
  );
};

ProcedureSearchResult.propTypes = {
  onPress: PropTypes.func.isRequired,
  procedure: ProcedurePropTypes.isRequired,
};

export default ProcedureSearchResult;
