import axios from 'axios';
import { isEmpty } from 'lodash';

import { showGlobalError } from 'app/actions/uiActions';
import { unformatObject } from 'app/util/reducerUtils';
import { location } from 'app/util/history';
import { CARE_API_URL } from 'app/util/constants';
import { State } from 'types/state';
import { Redux } from 'types/redux';
import { Escalation } from 'types/escalation';
import { logError } from 'app/util/methods';

export const RECEIVE_ESCALATION = 'RECEIVE_ESCALATION';

export interface ReceiveEscalationAction extends Redux.Action {
  escalation: Escalation;
}

export function receiveEscalation(
  escalation: Escalation
): ReceiveEscalationAction {
  return {
    type: RECEIVE_ESCALATION,
    escalation,
  };
}

/**
 * Creates a new escalation in the API then fetch escalations from the API.
 *
 * @param {object} params - the data to use for creating a new escalation.
 * @return {promise} - a promise that resolves with the escalation data.
 */
export function createEscalation(params: any = {}) {
  return function (dispatch, getState: () => State.RootState) {
    const state = getState();

    const careConciergeId = state.episode?.episode?.careConcierge?.id;
    const employer = {
      ...state.session?.user?.employer,
      ...state.episode?.episode?.eligiblePatient?.employer,
    };

    if (
      isEmpty(params.eligiblePatient) &&
      !isEmpty(state.session.user?.eligiblePatient)
    ) {
      const {
        eligiblePatient: {
          address,
          carrumId,
          dateOfBirth,
          employerRegistrationId,
          firstName,
          lastName,
          insurance,
        },
      } = state.session.user;

      params.eligiblePatient = {
        address,
        carrumId,
        dateOfBirth,
        employer,
        employerRegistrationId,
        firstName,
        lastName,
        insurance,
      };
    }

    if (!params.episodeId) params.episodeId = state.episode?.episode?.id;

    if (isEmpty(employer)) {
      return logError('Tried to create escalation without employer data', {
        episodeId: params.episodeId,
        carrumId: params.eligiblePatient.carrumId,
      });
    }

    if (!params.escalationDate) {
      params.escalationDate = new Date().toISOString();
    }

    if (!params.ownerIds) {
      params.ownerIds = [];

      // add care concierge assigned to episode if available
      if (careConciergeId) params.ownerIds.push(careConciergeId);
    }

    if (!params.procedureKey) {
      params.procedureKey = state.episode?.procedure?.key;
      params.procedureMandated = state.episode?.procedure?.mandated;
    }

    params.patientAppReferralUri = location.pathname;

    return axios
      .post(`${CARE_API_URL}/escalations`, unformatObject(params), {
        headers: {
          Authorization: `Bearer ${getState().session.coreToken}`,
        },
      })
      .then((escalation) => dispatch(receiveEscalation(escalation.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}
