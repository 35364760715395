import React from 'react';

import { useSelector } from 'react-redux';

import { getIsLoggedIn } from 'app/selectors/session';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import { navigationPaths } from '../../constants';
import { GuestLinksContainer, HelpLink, NavigationLink } from './styles';

/**
 * Render navigation links pertaining only to non-authenticated users.
 * */
const GuestLinks = () => {
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (isLoggedIn) return null;

  return (
    <GuestLinksContainer testID={TestID.Navigation.GuestLinks}>
      <NavigationLink
        key={navigationPaths.register}
        name="Create Account"
        path={navigationPaths.register}
        testID={TestID.Navigation.CreateAccountLink}
      />
      <NavigationLink
        key={navigationPaths.logIn}
        name="Log In"
        path={navigationPaths.logIn}
        testID={TestID.Navigation.LogInLink}
      />
      <HelpLink />
    </GuestLinksContainer>
  );
};

export default GuestLinks;
