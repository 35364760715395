import React, { useState } from 'react';
import {
  Header,
  InfoIcon,
  InfoIconContainer,
  Modal,
  Paragraph,
} from './styles';

import { riskySmokingStatuses } from 'app/constants/smoking-statuses';

const RiskWarningModal = ({ userProfile }) => {
  const getModalIsVisible = () => {
    const result =
      userProfile.bmi > 35 ||
      riskySmokingStatuses.includes(userProfile.smokingStatus);
    return result;
  };

  const [modalIsVisible, setModalIsVisible] = useState(getModalIsVisible());

  const closeModal = () => {
    setModalIsVisible(false);
  };

  return (
    <Modal
      isVisible={modalIsVisible}
      onClose={closeModal}
      options={[
        {
          keepCasing: true,
          onPress: closeModal,
          title: 'Understood, continue',
          type: 'solid',
        },
      ]}
      size="small"
    >
      <InfoIconContainer>
        <InfoIcon />
      </InfoIconContainer>
      <Header>Before you continue...</Header>
      <Paragraph>
        Some of our providers are not available based on the answers to your
        profile questions. Not to worry, you will still be able to select from
        top providers.
      </Paragraph>
      <Paragraph>
        Please click the button below to continue to select a provider. If you
        get denied surgery by your selected provider, your care specialist will
        work with you to find an appropriate alternative.
      </Paragraph>
    </Modal>
  );
};

export default RiskWarningModal;
