import React from 'react';

import { useSelector } from 'react-redux';

import { useSession } from 'app/hooks';
import { getIsLoggedIn } from 'app/selectors/session';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import { extractBranding } from '../helpers';
import { ClientLogoContainer, ClientLogoImage } from './styles';

/** Render a client logo. */
const ClientLogo = () => {
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const session = useSession();
  const branding = extractBranding(session);
  const hasClientLogo = !!branding;

  if (!isLoggedIn || !hasClientLogo) return null;

  return (
    <ClientLogoContainer testID={TestID.Navigation.ClientLogo}>
      <ClientLogoImage maxHeight={36} source={branding} />
    </ClientLogoContainer>
  );
};

export default ClientLogo;
