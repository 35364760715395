import React from 'react';

import styled from 'styled-components/native';

import { Text, View } from 'app/components/Common/Styled';
import {
  ProgressNavComplete,
  ProgressNavCurrent,
  ProgressNavIncomplete,
} from 'app/components/Common/svg-components';
import theme from 'app/util/theme';
import { ProgressLinkStatus } from '../../types';

const { darkBlue, opacity70, white } = theme.colors;

interface ProgressLinkProps {
  status: ProgressLinkStatus;
  title: string;
}

const ProgressLink = ({
  status = ProgressLinkStatus.incomplete,
  title = '',
}: ProgressLinkProps) => {
  const Icon = {
    complete: ProgressNavComplete,
    current: ProgressNavCurrent,
    incomplete: ProgressNavIncomplete,
  }[status];

  const titleColor = {
    complete: white + opacity70,
    current: white,
    incomplete: darkBlue,
  }[status];

  const titleStyle = {
    color: titleColor,
    fontSize: theme.fontSizes.body1,
    marginLeft: theme.spacing * 1.25,
  };

  return (
    <Row>
      <Icon size={32} />
      <Text style={titleStyle}>{title}</Text>
    </Row>
  );
};

export default ProgressLink;

const Row = styled(View)`
  flex-direction: row;
`;
