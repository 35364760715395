import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import { updateUserDetails } from 'app/actions/userActions';
import {
  launchCamera,
  launchImageLibrary,
  showLocalNotification,
} from 'app/actions/uiActions';
import { createUpload, destroyUpload } from 'app/actions/uploadActions';
import Profile from 'app/components/Profile';
import { location, push } from 'app/util/history';
import { extractFormValues } from 'app/util/methods';
import { extractProfileValues } from 'app/util/profileUtils';
import { setAutoLogout } from 'app/actions/uiActions';
import { getIsPlatformTwo } from 'app/selectors/episode';

const mapStateToProps = ({ episode, session, form }) => ({
  episode:
    episode.episode && episode.episode.confirmed
      ? {
          ...episode.episode,
          procedure: episode.procedure,
          currentStep: episode.episode.currentStep
            ? episode.steps.find(
                ({ order }) => order === episode.episode.currentStep.order
              )
            : null,
        }
      : {},
  user: session.user,
  formValues: extractFormValues(form, 'profile'),
  registeredFields: form?.profile?.registeredFields || {},
  pathname: location.pathname,
  isPlatformTwo: getIsPlatformTwo(episode),
});

const mapDispatchToProps = (dispatch) => ({
  push,
  change: (field, value) =>
    Promise.resolve(dispatch(change('profile', field, value))),
  createUpload: (url) => dispatch(createUpload(url)),
  destroyUpload: (url) => dispatch(destroyUpload(url)),
  initialize: (user) => {
    const {
      location,
      mailingAddressSame,
      subscriberFullSsn, // eslint-disable-line
      ...profileValues
    } = extractProfileValues(user);

    dispatch(change('profile', 'email', user.email));
    dispatch(change('profile', 'profileImage', user.profileImage));
    dispatch(change('profile', 'location', location));
    dispatch(change('profile', 'mailingAddressSame', mailingAddressSame));
    Object.keys(profileValues).forEach((key) =>
      dispatch(change('profile', `profile.${key}`, profileValues[key]))
    );
  },
  launchCamera: (options) => dispatch(launchCamera(options)),
  launchImageLibrary: (options) => dispatch(launchImageLibrary(options)),
  setAutoLogout: (shouldAutoLogout) =>
    dispatch(setAutoLogout(shouldAutoLogout)),
  showLocalNotification: (options) => dispatch(showLocalNotification(options)),
  updateUserDetails: (user) => dispatch(updateUserDetails(user)),
});

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default reduxForm({
  form: 'profile',
  updateUnregisteredFields: true,
  initialValues: {
    email: '',
    profileImage: '',
    mailingAddressSame: false,
    location: {
      address: {
        street: '',
        unit: '',
        city: '',
        state: '',
        postalCode: '',
      },
      lat: '',
      lng: '',
    },
    profile: {
      bmi: '',
      ethnicity: '',
      gender: '',
      heightFeet: '',
      heightInches: '',
      dependent: false,
      language: '',
      maritalStatus: '',
      phoneNumber: '',
      preferredName: '',
      religion: '',
      subscriberFullSsn: '',
      weight: '',
      address: {
        street: '',
        unit: '',
        city: '',
        state: '',
        postalCode: '',
      },
      mailingAddress: {
        street: '',
        unit: '',
        city: '',
        state: '',
        postalCode: '',
      },
      location: {
        latitude: '',
        longitude: '',
      },
      insurance: {
        memberId: '',
      },
    },
  },
})(ProfileContainer);
