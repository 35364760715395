import React from 'react';

// @ts-ignore
import LoadingPuzzleUrl from 'app/assets/images/loading-puzzle.gif';
import { TestID } from 'app/components/ProcedureSearch/ProcedureSearchPage/constants';
import {
  LoadingPuzzleBackgroundContainer,
  LoadingPuzzleContainer,
  LoadingPuzzleFadeWrapper,
  LoadingPuzzleGif,
} from './styles';

const LoadingScreen = () => {
  return (
    <LoadingPuzzleContainer>
      <LoadingPuzzleFadeWrapper>
        <LoadingPuzzleBackgroundContainer>
          <LoadingPuzzleGif
            testID={TestID.ProcedureSearch.LoadingAnimation}
            resizeMode="contain"
            source={LoadingPuzzleUrl}
          />
        </LoadingPuzzleBackgroundContainer>
      </LoadingPuzzleFadeWrapper>
    </LoadingPuzzleContainer>
  );
};

export default LoadingScreen;
