import {
  smokingStatuses,
  Routes,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import {
  getNextProfileRoute,
  getPreviousProfileRoute,
} from 'app/components/CompleteYourProfile/helpers';

import { useHistory } from 'app/components/CompleteYourProfile/hooks';

import { SmokingStatusProps } from 'app/components/CompleteYourProfile/types';

import {
  ButtonList,
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

const SmokingStatus = ({
  value,
  isSaving,
  onChange,
  onNext,
}: SmokingStatusProps) => {
  const history = useHistory();

  return (
    <StepScreen testID={TestID.CompleteYourProfile.SmokingStatus}>
      <FormContainer>
        <Header title="Do you currently smoke or did so in the past?" />
        <ButtonList
          mode="single"
          initialValue={value}
          key={'Smoking'}
          onChange={onChange}
          options={smokingStatuses}
        />
      </FormContainer>
      <ProfileCTAs
        isNextDisabled={!value}
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() =>
            history.push(getNextProfileRoute(Routes.SmokingStatus))
          );
        }}
        onPrevious={() =>
          history.push(getPreviousProfileRoute(Routes.SmokingStatus))
        }
      />
    </StepScreen>
  );
};

export default SmokingStatus;
