import { HeaderProps } from 'app/components/CompleteYourProfile/types';

import {
  BodyText,
  DescriptionWrapper,
  ProgressWrapper,
  TitleText,
  TitleWrapper,
} from './styles';

const Header = ({ description, title }: HeaderProps) => {
  return (
    <>
      <ProgressWrapper>
        {!!title && (
          <TitleWrapper>
            <TitleText>{title}</TitleText>
          </TitleWrapper>
        )}

        {!!description && (
          <DescriptionWrapper>
            <BodyText>{description}</BodyText>
          </DescriptionWrapper>
        )}
      </ProgressWrapper>
    </>
  );
};

export default Header;
