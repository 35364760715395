import styled, { css, DefaultTheme } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { default as ContentLinks } from './ContentLinks';
export { default as GuestLinks } from './GuestLinks';
export { default as ProfileLinks } from './ProfileLinks';

import { NavigationLinksContainerProps } from '../types';

export const NavigationLinksContainer = styled(View)``;

export const NavigationLinksSectionContainer = styled(
  View
)<NavigationLinksContainerProps>`
  ${({ layout: specifiedLayout, theme }) => {
    const isHorizontal = (theme: DefaultTheme) =>
      theme.windowDimensions.width > theme.breakpoints.small;
    const defaultLayout = isHorizontal(theme) ? 'row' : 'column';

    return css`
      flex-direction: ${specifiedLayout || defaultLayout};
    `;
  }}
`;
