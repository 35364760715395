import { isEmpty } from 'lodash';
import { getIsPlatformTwo } from 'app/selectors/session';
import createEpisode from './create-episode';

/**
 * Creates a new episode from the current form and session state.
 *
 * @return {promise} A promise that resolves after creating a new episode.
 */
export default ({ procedure } = { procedure: {} }) => {
  return (dispatch, getState) => {
    const { episode, form, session } = getState();

    if (isEmpty(procedure)) {
      procedure = form.episode.values.procedure;
    }

    const { location, physician, provider } = form.episode.values;
    const { id, email, referralSource, eligiblePatient, employer } =
      session.user;

    const productOfferingCode = getIsPlatformTwo(session) ? 'gold' : 'standard';
    const productOffering = episode.productOfferingsList.find(
      ({ code }) => code === productOfferingCode
    );

    return dispatch(
      createEpisode({
        location,
        physician,
        productOffering,
        oopEstimate: episode.oopEstimate,
        provider: provider
          ? { ...provider, distance: episode.distance }
          : undefined,
        eligiblePatient: { ...eligiblePatient, employer },
        memberFullSsn: eligiblePatient.memberFullSsn,
        subscriberFullSsn: eligiblePatient.subscriberFullSsn,
        procedureKey: procedure.key,
        user: { id, email, referralSource },
        inquiryMethod: 'online',
        status: 'active',
        confirmed: true,
        verified: true,
      })
    );
  };
};
