import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPossibleHospitals } from 'app/actions/episodeActions';
import useEpisode from 'app/hooks/useEpisode';

export interface UsePossibleHospitalsOrPhysiciansOptions {
  fetch?: boolean; // If true, fetch API data on render
  sortBy?: string; // Specify a query param for API sorting
}

/**
 * Returns a factory for building a React hook against the possible hospitals
 * or physicians in the platform.  This is useful because both lists are driven
 * by the same API calls, so we can use the same logic for both sets of data.
 *
 * @example
 * import usePossibleHospitalsOrPhysicians from './usePossibleHospitalsOrPhysicians';
 * const usePossibleHospitals = usePossibleHospitalsOrPhysicians('hospitals');
 * const usePossiblePhysicians = usePossibleHospitalsOrPhysicians('physicians');
 *
 * const MyHookyComponent = () => {
 *   const { data: { records: hospitals } } = usePossibleHospitals();
 *   const { data: { records: physicians } } = usePossiblePhysicians();
 *
 *   return <>{...}</>;
 * };
 */
const usePossibleHospitalsOrPhysicians = (type: string) => {
  return (options: UsePossibleHospitalsOrPhysiciansOptions = {}) => {
    const { sortBy = 'distance', fetch = false } = options;
    const {
      loading,
      possibleHospitals,
      possiblePhysicians,
      possibleHospitalsPage: page,
      possibleHospitalsNextPage: nextPage,
      procedure,
      refresh: refreshEpisode,
    } = useEpisode();
    const dispatch = useDispatch();

    // Return the correct list of records as `data.records` based on `type`
    const records =
      type === 'hospitals' ? possibleHospitals : possiblePhysicians;

    /**
     * Attempt to fetch the records for this hook immediately opon loading the
     * selected procedure for the current episode (if any) if this hook is
     * rendered with `options.fetch = true`.
     */
    useEffect(() => {
      if (!fetch || loading || possibleHospitals.length) return;

      refresh();
    }, [procedure, loading]);

    /**
     * Returns a callback function that consuming components can
     * call to refresh the data from this hook.
     */
    const refresh = useCallback(
      async (page = 1) => {
        if (loading) return; // Prevent parallel API calls.
        if (!procedure?.id) return refreshEpisode(); // Require a selected procedure.

        await dispatch(getPossibleHospitals(procedure?.id, sortBy, page));
      },
      [dispatch, loading, procedure?.id]
    );

    /**
     * Returns a callback function that consuming components can
     * call to fetch the next page of data from this hook.
     */
    const next = useCallback(async () => {
      if (page === nextPage || !nextPage) return; // Return if there is no next page.

      await refresh(nextPage);
    }, [page, nextPage]);

    return {
      data: { records },
      loading,
      refresh,
      next: nextPage && page !== nextPage ? next : null,
    };
  };
};

export default usePossibleHospitalsOrPhysicians;
