import { Component, PropTypes } from 'app/components/Login/constants';

import { validateActivation } from 'app/components/Login/helpers';

import {
  ActivityIndicator,
  Linking,
  Redirect,
  Routes,
  View,
} from 'app/components/Login/styles';

/**
 * Render a redirect component. Get activation params from the URI and use them
 * to obtain the activation status of the token from the API. Redirect to the
 * correct route depending on the response.
 *
 * @property  {object}  location  a react-router location object
 */
export default class ActivationRedirect extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  state = {
    error: false,
    redirect: null,
  };

  componentDidMount() {
    this.handleRedirect();
  }

  /**
   * Get the url query params and use them to call the `activate_account`
   * endpoint in the core service. Update the state with the correct redirect
   * route depending on the user's activation status.
   */
  handleRedirect = () => {
    if (!this.props.location || !this.props.location.search)
      return this.onError();

    const { queryParams } = Linking.parse(this.props.location.search);

    return validateActivation(queryParams)
      .then(this.onActivate)
      .catch(this.onError);
  };

  onActivate = (resp) => {
    this.setState({ redirect: `/${resp.data.path}` });
  };

  onError = () => {
    this.setState({ error: true });
  };

  render() {
    if (this.state.error)
      return <Redirect to={`/${Routes.InvalidActivation}`} />;

    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

    return (
      <View style={styles.container}>
        <ActivityIndicator />
      </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};
