import {
  ScrollView,
  MessagesHeaderContainer,
  MessagesHeaderContent,
  MessagesHeaderRow,
  MessagesHeaderTitle,
  MessagesHeaderUserStatus,
  MessagesHeaderUserText,
} from 'app/components/Messages/styles';

import { MessagesHeaderProps } from 'app/components/Messages/types';

const MessagesHeader = ({
  isRendered = false,
  participants,
}: MessagesHeaderProps) => {
  if (!isRendered) return null;

  return (
    <MessagesHeaderContainer>
      <MessagesHeaderContent>
        <MessagesHeaderRow>
          <MessagesHeaderTitle>My Care Team</MessagesHeaderTitle>
        </MessagesHeaderRow>
        <ScrollView horizontal>
          {participants.map(({ id, name, status }) => (
            <MessagesHeaderRow key={id}>
              <MessagesHeaderUserStatus status={status} />
              <MessagesHeaderUserText>{name}</MessagesHeaderUserText>
            </MessagesHeaderRow>
          ))}
        </ScrollView>
      </MessagesHeaderContent>
    </MessagesHeaderContainer>
  );
};

export default MessagesHeader;
