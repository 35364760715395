import { Platform, View } from 'react-native';
import styled, { css } from 'styled-components/native';

// @ts-ignore
import { default as DatePickerRaw } from 'app/components/Common/DatePicker';
export { default as DisclaimerText } from 'app/components/Common/DisclaimerText';
import InputRaw from 'app/components/Common/Input';
import TextAreaRaw from 'app/components/Common/TextArea';
import TextRaw from 'app/components/Common/Styled/Text';

export { NavigationMode } from 'app/components/Common/StickyNavFooter/types';
export { View } from 'react-native';

export const AlignmentWrapper = styled(View)`
  ${({ theme }) => css`
    max-width: ${theme.breakpoints.xsmall}px;
    width: 100%;
    flex: 1 0 auto;
    height: 100%;
    margin: 0 auto;
  `}
`;

export const Input = styled(InputRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export const TextArea = styled(TextAreaRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
    inputStyle: {
      height: theme.spacing * 5,
    },
  };
})``;

export const Text = styled(TextRaw)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const Wrapper = styled(View)`
  ${({ theme }) => css`
    background-color: ${theme.colors.sand};
    flex: ${Platform.OS === 'web' ? '0 0 auto' : 1};
  `}
`;

export const DatePicker = styled(DatePickerRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;
