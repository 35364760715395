import React from 'react';

import styled from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

import ProgressLink from './ProgressLink';
import { ProgressLinkStatus } from '../types';
import { cloneDeep } from 'lodash';
import { useEpisode } from 'app/hooks';
import { parameterize } from 'app/util/methods';

interface ProgressLink {
  route: string;
  status: ProgressLinkStatus;
}

const ProgressSection = () => {
  const { journeyPhase, journeyPhases, steps } = useEpisode();

  const areAllStepsCompleted = steps.every(({ complete }) => complete);

  const progressLinksWithoutCurrent: ProgressLink[] = journeyPhases?.map(
    (phase) => ({
      route: parameterize(phase.code),
      title: phase.name,
      status: getStatus(
        phase?.ordinal < journeyPhase?.ordinal || areAllStepsCompleted
      ),
    })
  );

  const progressLinks = addCurrentStepStyle(progressLinksWithoutCurrent);

  return (
    <AlignmentWrapper>
      {progressLinks?.map(({ status, title }) => (
        <ProgressLink key={title} status={status} title={title} />
      ))}
    </AlignmentWrapper>
  );
};

export default ProgressSection;

export const AlignmentWrapper = styled(View)`
  align-items: flex-start;
  flex: 1 0 auto;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing * 3.5}px;
  max-height: 600px;
`;

const getStatus = (isComplete: boolean): ProgressLinkStatus =>
  isComplete ? ProgressLinkStatus.complete : ProgressLinkStatus.incomplete;

const addCurrentStepStyle = (links: ProgressLink[]): ProgressLink[] => {
  const updatedLinks = cloneDeep(links);

  const currentStep = updatedLinks?.find(
    (link) => link.status === ProgressLinkStatus.incomplete
  );

  if (currentStep) {
    const indexOfCurrentStep = updatedLinks?.indexOf(currentStep);
    const updatedCurrentStep = {
      ...currentStep,
      status: ProgressLinkStatus.current,
    };

    updatedLinks.splice(indexOfCurrentStep, 1, updatedCurrentStep);
  }

  return updatedLinks;
};
