import React from 'react';

import { useWindowDimensions } from 'react-native';
import Html from 'react-native-render-html';

import { routeTo } from 'app/util/routeUtils';
import theme, { fontFamily, fontFamilyBold } from 'app/util/theme';

import {
  baseStyle,
  customHTMLElementModels,
  renderersProps,
} from './constants';
import { htmlStyles, renderHtmlTemplate } from './helpers';
import { ScrollView, Video } from './styles';

interface HtmlViewProps {
  // The HTML content to render (defaults to '').
  html?: string;
  // Additional styling definitions to add (defaults to {}).
  tagsStyles?: any;
  // Override color for the body text (defaults to '').
  textColor?: string;
}

/**
 * Renders a scrollable container wtih HTML content.
 *
 * @example
 * import HtmlView from 'app/components/Common/HtmlView';
 *
 * <HtmlView html="<p>This is a paragraph</p>" />
 */
export const HtmlView = ({
  html = '',
  tagsStyles = {},
  textColor = '',
}: HtmlViewProps) => {
  const { width } = useWindowDimensions();
  const maxWidth = width - theme.spacing * 2;

  return (
    <ScrollView>
      <Html
        baseStyle={baseStyle}
        contentWidth={maxWidth}
        customHTMLElementModels={customHTMLElementModels}
        renderers={renderers}
        renderersProps={renderersProps}
        source={{ html: renderHtmlTemplate(html) }}
        tagsStyles={{
          ...htmlStyles({ maxWidth, textColor }),
          ...tagsStyles,
        }}
      />
    </ScrollView>
  );
};

/**
 * Passed to an `<Html />` component from `react-native-render-html`
 * as the `renderers` prop.
 *
 * Creates a mapping between HTML tags and custom render implementations
 * to provide granular control over how tags render as components.
 */
const renderers = {
  /**
   * Add a custom renderer to parse a custom <react-video /> tag into a <Video />
   * component that renders in web and mobile.
   *
   * NOTE: <react-video /> is used here because <video /> appears to be reserved.
   */
  'react-video': ({ tnode: { attributes } }) => <Video {...attributes} />,
};

export default HtmlView;
