import { TouchableOpacity } from 'react-native';

import styled, { css } from 'styled-components/native';

export {
  RedCLogo as CarrumLogoNarrow,
  RedHorizontalLogo as CarrumLogoWide,
} from 'app/components/Common/svg-components';

export const CarrumLogoContainer = styled(TouchableOpacity)`
  ${({ theme }) => css`
    justify-content: center;
    padding-left: ${theme.spacing * 0.5}px;
    padding-right: ${theme.spacing * 1.5}px;
  `}
`;
