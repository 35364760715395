import axios from 'axios';

import { CARE_API_URL } from 'app/util/constants';
import { unformatObject } from 'app/util/reducerUtils';
import { showGlobalError } from 'app/actions/uiActions';

import { receiveEpisodeLoading, receiveInquiry } from './action-types';
import { getIsPlatformTwo } from 'app/selectors/session';

/**
 * Makes a POST request to the Care Service API
 * to create a new inquiry.
 *
 * @param {object} inquiry - inquiry details to save in the API.
 * @return {promise} a promise that resolves after the inquiry is created.
 */
export default (inquiry) => {
  return async (dispatch, getState) => {
    try {
      const { coreToken } = getState().session;

      dispatch(receiveEpisodeLoading(true));

      const response = await axios.post(
        `${CARE_API_URL}/inquiries`,
        unformatObject({ inquiry }),
        {
          headers: { Authorization: `Bearer ${coreToken}` },
        }
      );

      if (!getIsPlatformTwo(getState().session)) {
        dispatch(receiveInquiry(response.data.data));
        dispatch(receiveEpisodeLoading(false));
      }

      return true;
    } catch (error) {
      dispatch(showGlobalError(error));
      dispatch(receiveEpisodeLoading(false));

      return false;
    }
  };
};
