import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet } from 'react-native';
import { Card, Text } from 'react-native-elements';
import * as Linking from 'expo-linking';
import MapView, { Marker } from '@cross-platform/react-native-maps';

import { toAddressString } from 'app/util/methods';
import theme from 'app/util/theme';

const formatCoords = (coords) => ({
  lat: parseFloat(coords.lat || coords.latitude),
  lng: parseFloat(coords.lng || coords.longitude),
  latitude: parseFloat(coords.lat || coords.latitude),
  longitude: parseFloat(coords.lng || coords.longitude),
  latitudeDelta: 0.0922,
  longitudeDelta: 0.0421,
});

/**
 * Opens the native map application with a given address.
 *
 * @param {string} address An address to search for.
 */
const openMapURL = (address) => {
  const provider = Platform.OS === 'ios' ? 'apple' : 'google';
  Linking.openURL(
    `http://maps.${provider}.com/?daddr=${toAddressString(address)}`
  );
};

const LocationCard = ({ distance, location, provider }) => {
  let mapView = useRef();
  return (
    <Card>
      <Card.Title>Location</Card.Title>
      <Card.Divider style={styles.locationDivider} />

      <MapView
        pitchEnabled={false}
        rotateEnabled={false}
        scrollEnabled={false}
        zoomEnabled={false}
        ref={(ref) => (mapView = ref)}
        onMapReady={() => {
          if (provider && Object.keys(provider).length) {
            mapView.fitToSuppliedMarkers(['origin', 'destination'], false);
          }
        }}
        onPress={() => (provider ? openMapURL(provider.address) : null)}
        style={styles.map}
        region={formatCoords(location)}
      >
        <Marker
          identifier="origin"
          coordinate={formatCoords(location)}
          position={formatCoords(location)}
        />
        {provider && Object.keys(provider).length ? (
          <Marker
            pinColor="#007EFF"
            identifier="destination"
            coordinate={formatCoords(provider)}
            position={formatCoords(provider)}
          />
        ) : null}
      </MapView>
      <Text style={styles.locationMessage}>
        {provider && Object.keys(provider).length && distance
          ? `Distance to hospital: ${distance}. All travel arrangements are covered by Carrum Health.`
          : typeof location.address === 'object'
          ? toAddressString(location.address)
          : location.address}
      </Text>
    </Card>
  );
};

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,

  distance: PropTypes.string,
};

export default LocationCard;

const styles = StyleSheet.create({
  locationDivider: {
    marginBottom: 0,
  },

  locationMessage: {
    marginTop: theme.spacing,
    marginLeft: theme.spacing,
    marginRight: theme.spacing,
    textAlign: 'center',
  },

  map: {
    width: '100%',
    height: 180,
  },

  cardContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  surgeonAvatar: {
    marginHorizontal: theme.spacing * 1.25,
  },

  bold: {
    fontWeight: '500',
    marginBottom: theme.spacing / 2,
  },

  instructions: {
    paddingHorizontal: theme.spacing * 1.25,
    textAlign: 'center',
    marginBottom: theme.spacing * 2.5,
  },

  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing / 4,
    paddingHorizontal: theme.spacing,
  },

  message: {
    marginTop: theme.spacing / 2,
    paddingHorizontal: theme.spacing,
  },

  center: {
    textAlign: 'center',
  },

  anchor: {
    color: '#2196f3',
  },

  divider: {
    margin: theme.spacing / 2,
  },
});
