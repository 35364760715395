import {
  completeYourProfileJourneyPhaseCode,
  profileStepRoutes,
} from './constants';

import { AccountSettingsFormState, User } from './types';

import { createEscalation } from 'app/actions/escalationActions';

export { calculateBmi } from 'app/util/profileUtils';

export { cloneDeep } from 'lodash';

export { createEscalation };

/**
 * Returns an object with properties of a `AccountSettingsFormState`
 * destructured from the current user object in the sesssion.
 */
export const getAccountSettingsFormValuesFromUser = (user: User) => {
  const { email } = user;

  const formValues: AccountSettingsFormState = { email };

  Object.keys(formValues).forEach((field) => {
    if (typeof formValues[field] === 'undefined') delete formValues[field];
  });

  return formValues;
};

/**
 * Determine whether the user has completed a task specified by name.
 *
 * @param  {string}  taskName  name used to identify a specific task
 * @param  {array}   task      array of task objects to search within
 *
 */
const isTaskComplete = (taskName, tasks) => {
  const taskIsComplete = !!tasks.find((task) => {
    return task.meta.event == taskName && task.complete === true;
  });

  const taskExists = tasks.map((task) => task.meta?.event).includes(taskName);

  return taskIsComplete || !taskExists;
};

/**
 * Return true if the journey phase for the "Complete Your Profile" section has
 * been completed.
 *
 * @TODO: This could be refactored since there is some duplicate code
 *        in app/selectors/episode.ts.
 */
export const isProfileJourneyPhaseComplete = (journeyPhases): boolean => {
  const journeyPhase = journeyPhases?.find(
    ({ code }) =>
      code.replace(/\_/g, '-') === completeYourProfileJourneyPhaseCode
  );

  const isComplete = !journeyPhase?.steps?.some(({ complete }) => !complete);
  // Returns false if the matching journey phase is found and has incomplete steps.
  return isComplete;
};

export const dispatchCreateEscalation = (
  description: string,
  escalationType: string,
  dispatch
) => {
  dispatch(
    createEscalation({
      description,
      escalationType,
      escalationStatus: 'active',
      exceptionStatus: 'not_applicable',
      exceptionType: null,
    })
  );
};

export { getIsDifferentAddress } from 'app/util/addressUtils';

export { getIsLoggedIn } from 'app/selectors/session';

export const getNextProfileRoute = (currentRoute: string) => {
  const currentRouteIndex = profileStepRoutes.findIndex(
    (r) => r === currentRoute
  );

  if (currentRouteIndex === profileStepRoutes.length - 1) return;

  return profileStepRoutes[currentRouteIndex + 1];
};

export const getPreviousProfileRoute = (currentRoute: string) => {
  const currentRouteIndex = profileStepRoutes.findIndex(
    (r) => r === currentRoute
  );

  if (currentRouteIndex === 0) return;

  return profileStepRoutes[currentRouteIndex - 1];
};

export {
  getUserInsurance,
  getUserLocation,
  getUserMailingAddress,
  getUserName,
  getUserPhoneNumber,
  getUserProfile,
} from 'app/selectors/session';

export { isValidPhoneNumber } from 'app/components/Common/PhoneInput/utils';

export { logout } from 'app/actions/sessionActions';

export { merge } from 'lodash';

export { updateEpisode } from 'app/actions/episodeActions';

export { updateUserDetails } from 'app/actions/userActions';
