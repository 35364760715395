import React from 'react';
import PropTypes from 'prop-types';

import { View, StyleSheet } from 'react-native';
import { Card, Text } from 'react-native-elements';

import theme from 'app/util/theme';

const InquiryConfirmation = ({ description }) => (
  <View style={styles.container}>
    <Text style={StyleSheet.flatten([styles.instructions])}>
      Unfortunately, the procedure or treatment you entered is not currently
      offered by Carrum Health. However, our team will continue to review your
      request and will contact you if and when we can service your healthcare
      needs.
    </Text>

    <Card>
      <Card.Title>Additional Procedure Information</Card.Title>
      <Card.Divider />

      <View style={styles.cardContainer}>
        <Text>
          Condition summary and treatment options recommended by your physician:
        </Text>
        <Text h4 style={styles.description}>
          {description}
        </Text>
      </View>
    </Card>
  </View>
);

InquiryConfirmation.propTypes = {
  description: PropTypes.string,
};

InquiryConfirmation.defaultProps = {
  description: '',
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: theme.breakpoints.small,
  },

  cardContainer: {
    paddingHorizontal: theme.spacing * 1.25,
  },

  instructions: {
    marginBottom: theme.spacing * 1.25,
    paddingHorizontal: theme.spacing * 1.25,
  },

  description: {
    marginTop: theme.spacing * 1.25,
  },
});

export default InquiryConfirmation;
