import { Animated, Platform, SafeAreaView } from 'react-native';
import styled, { css } from 'styled-components/native';

import { View } from 'app/components/Common/Styled';

export { default as CarrumLogo } from './CarrumLogo';
export { default as ClientLogo } from './ClientLogo';
export { default as MenuIcon } from './MenuIcon';
export { default as NavigationLinks } from './NavigationLinks';
export { default as NeedHelpCta } from './GetHelpButton';
export { default as ProfileAvatar } from './ProfileAvatar';
export { default as ProfileMenu } from './ProfileMenu';

const RowContainer = styled(View)`
  ${({ theme }) => css`
    background-color: white;
    height: 100%;
    min-height: ${theme.navigationHeight - 2}px;
    justify-content: center;
  `}
`;

export const CenterContainer = styled(RowContainer)`
  align-items: center;
  flex-direction: row;
`;

export const LeftContainer = styled(RowContainer)`
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
`;

export const RightContainer = styled(RowContainer)`
  ${({ theme }) => css`
    align-items: center;
    flex-direction: row;
    padding-right: ${Platform.select({
      default: 0,
      ios: theme.spacing * 0.5,
    })}px;
  `}
`;

interface NavigationHeaderProps {
  theme: any;
  topInset?: number;
}

export const NavigationHeader = styled(SafeAreaView)<NavigationHeaderProps>`
  ${({ theme, topInset }) => {
    return css`
      align-items: center;
      background-color: ${theme.colors.white};
      border-bottom-color: #eeeeee;
      border-bottom-width: 1px;
      box-shadow: 0px 2px 2px ${theme.colors.black}33;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: ${theme.spacing}px;
      padding-top: ${Platform.select({
        default: topInset,
        web: 0,
      })}px;
    `;
  }}
`;

export const NavigationHeaderAnimation = styled(Animated.View)`
  ${({ theme }) => css`
    left: 0;
    position: ${Platform.select({
      default: 'absolute',
      web: 'fixed',
    })};
    right: 0;
    z-index: ${theme.zIndexes.appNavigation};
    height: ${theme.navigationHeight}px;
  `}
`;
