import React from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet, View, useWindowDimensions } from 'react-native';

import { Badge, Text } from 'react-native-elements';

import { usePlatform } from 'app/hooks';
import theme from 'app/util/theme';
import AnchorWithIcon from 'app/components/Common/AnchorWithIcon';

const ProgressStepper = ({
  label,
  onBackPress,
  onNextPress,
  progress,
  step,
  steps,
}) => {
  const { isPlatformTwo } = usePlatform();

  const { width } = useWindowDimensions();
  const showLabels = width > theme.breakpoints.xsmall;
  const iconSize = showLabels ? theme.fontSizes.h3 : theme.fontSizes.hero;

  if (isPlatformTwo) return null;

  return (
    <View style={styles.container}>
      <View style={styles.stepsContainer}>
        <AnchorWithIcon
          disabled={!onBackPress}
          icon="chevron-left"
          iconSize={iconSize}
          onPress={onBackPress}
          title={showLabels ? 'Previous' : ''}
        />

        <Text numberOfLines={1} style={StyleSheet.flatten([styles.label])}>
          {label}
        </Text>

        <AnchorWithIcon
          containerStyle={styles.anchorNextContainer}
          disabled={!onNextPress}
          icon="chevron-right"
          iconRight
          iconSize={iconSize}
          onPress={onNextPress}
          title={showLabels ? 'Next' : ''}
        />
      </View>

      <View
        style={StyleSheet.flatten([
          styles.dotsContainer,
          { width: steps * 12 },
        ])}
      >
        {Array(steps)
          .fill()
          .map((_, index) => (
            <Badge
              key={index}
              badgeStyle={step === index + 1 ? null : styles.badgeDisabled}
            />
          ))}
      </View>

      <View style={styles.progressBarContainer}>
        <View style={[styles.progressBar, { width: `${progress}%` }]} />
      </View>
    </View>
  );
};

ProgressStepper.propTypes = {
  label: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,

  onBackPress: PropTypes.func,
  onNextPress: PropTypes.func,
};

ProgressStepper.defaultProps = {
  onBackPress: null,
  onNextPress: null,
};

export default ProgressStepper;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing * 1.25,
    backgroundColor: '#fff',
    borderTopColor: '#eee',
    borderTopWidth: 1,
  },

  stepsContainer: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  label: {
    flex: 1,
    textAlign: 'center',
  },

  anchorNextContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  dotsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing / 2,
  },

  progressBarContainer: {
    marginTop: theme.spacing / 4,
    backgroundColor: '#eee',
    flex: 1,
    width: '100%',
  },

  progressBar: {
    backgroundColor: theme.colors.primary,
    flex: 1,
    height: 8,
  },

  badgeDisabled: {
    backgroundColor: '#ccc',
  },
});
