import { Platform } from 'react-native';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import sha1 from 'crypto-js/sha1';
import * as Linking from 'expo-linking';
import Sentry from '@cross-platform/sentry-expo';

import {
  GOOGLE_API_KEY,
  PATIENT_FAQ_URL,
  PATIENT_SILVER_FAQ_URL,
} from 'app/util/constants';

export function formatDateRailsToDisplay(str) {
  if (!str) {
    return;
  }
  const dateArr = str.split('-');
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
}

export function formatDateDisplayToRails(str) {
  if (!str) {
    return;
  }
  const dateArr = str.split(/\/|-/);
  return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
}

export function decryptDigest(digest) {
  return CryptoJS.AES.decrypt(digest.toString(), 'employeeApp').toString(
    CryptoJS.enc.Utf8
  );
}

const dateForEligiblity = (date) =>
  /^\d{2}-\d{2}-\d{4}$/.test(date) ? formatDateDisplayToRails(date) : date;
/**
 * TODO: Write docs
 */
export function generateEligibilityDigest(params, digest) {
  const salt = [
    params.firstName.toLowerCase(),
    params.lastName.toLowerCase(),
    dateForEligiblity(params.dateOfBirth),
    params.employerRegistrationId,
  ].join('');

  return sha1(salt + decryptDigest(digest)).toString();
}

/**
 * Formats an address object into a string.
 *
 * @param {object} address An address object.
 * @param {boolean} withCountry If true include 'United States'
 * @return {string} A formatted address string.
 */
export const toAddressString = (address) =>
  [
    address.unit ? `${address.street} Unit ${address.unit}` : address.street,
    address.city,
    `${address.state} ${address.postalCode}`,
  ]
    .filter((isPresent) => isPresent)
    .join(', ');

/**
 * Formats a phone number string.
 *
 * @param {string} rawPhoneNumber A raw phone number string.
 * @return {string} A formatted phone number string.
 */
export const formatPhoneNumber = (rawPhoneNumber) => {
  const strippedNumber = rawPhoneNumber.replace(/\D/g, '');

  return `(${strippedNumber.slice(0, 3)}) ${strippedNumber.slice(
    3,
    6
  )}-${strippedNumber.slice(6, 10)}`;
};

/**
 * Takes a given number and returns a formatted
 * currency string in USD.
 *
 * @param {integer} number - the number to format
 * @return {string} a formatted currency string.
 */
export const toCurrencyString = (number) =>
  '$' +
  parseInt(number)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+)/g, '$1,');

/**
 * Append time and timezone offset to a date string to support `Date.parse()`.
 *
 * @example
 * toDateTimeString('01/02/2020'); // '01/02/2020 00:00:00';
 * toDateTimeString('01/02/2020', '08:00'); // '01/02/2020 08:00:00';
 */
export const toDateTimeString = (date, time = '00:00', offset = '00') =>
  `${date} ${time}:${offset}`;

/**
 * Filters through the address components from
 * a Google API response and finds an object
 * with a given type.
 *
 * @param {array} components - an array of Google API address components.
 * @param {string} type - the component type to find.
 * @return {object} the address component with the given type.
 *
 * Example:
 *
 *  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {...}).then(resp => {
 *    const { address_components } = resp.data.results[0];
 *    console.log('street number', getAddressComponent(address_components, 'street_number'));
 *  });
 */
export const getAddressComponent = (components, type) =>
  components.filter(({ types }) => types.indexOf(type) !== -1)[0] || {};

/**
 * Parses an array of Google API address components
 * and returns a formatted object.
 *
 * @param {array} components - an array of Google API address components.
 * @return {object} a formatted address object.
 *
 * Example:
 *
 *  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {...}).then(resp => {
 *    const { address_components } = resp.data.results[0];
 *    console.log('address', formatGoogleAddress(address_components));
 *  });
 */
export const formatGoogleAddress = (components) => ({
  street: [
    getAddressComponent(components, 'street_number').short_name,
    getAddressComponent(components, 'route').short_name,
  ].join(' '),
  unit: (
    getAddressComponent(components, 'subpremise').short_name || ''
  ).toUpperCase(),
  city:
    getAddressComponent(components, 'locality').long_name ||
    getAddressComponent(components, 'administrative_area_level_3').long_name ||
    getAddressComponent(components, 'neighborhood').long_name,
  state: getAddressComponent(components, 'administrative_area_level_1')
    .short_name,
  postalCode: getAddressComponent(components, 'postal_code').short_name,
});

/**
 * Makes a GET request to Google's Geocode API
 * to fetch a canonical representation of an
 * address.
 *
 * @param {string|object} address A string or address object.
 * @return {object} A formatted address object.
 */
export const fetchLocationFromAddress = async (address) => {
  address = typeof address === 'string' ? address : toAddressString(address);

  const response = await axios.get(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: { key: GOOGLE_API_KEY, address },
    }
  );

  let result = response?.data?.results && response?.data?.results[0];

  if (response?.data?.error_message) {
    logError(response.data.error_message, { address });
  }

  if (
    response?.data?.geometry?.location &&
    response?.data?.geometry.location.lat === 0 &&
    response?.data?.geometry.location.lng === 0
  ) {
    logError('got [0,0] as lat/lng', { address, response });
    result = null;
  }

  if (!result) return;

  return {
    address: formatGoogleAddress(result.address_components),
    lat: result.geometry.location.lat,
    lng: result.geometry.location.lng,
  };
};

/**
 * Makes a GET request to Google's Location API
 * to fetch the distance between two locations.
 *
 * @param {string} origin A string with lat/lng of the origin.
 * @param {string} address A string with lat/lng of the destination.
 * @return {object} An object describing the distance between locations.
 */
export const fetchDistanceFromAddress = async (origin, destination) => {
  let result;

  if (Platform.OS === 'web') {
    result = await fetchDistanceFromAddressWeb(origin, destination);
  } else {
    result = await fetchDistanceFromAddressNative(origin, destination);
  }

  return result;
};

export const fetchDistanceFromAddressNative = async (origin, destination) => {
  const response = await axios.get(
    'https://maps.googleapis.com/maps/api/directions/json',
    {
      params: {
        origin,
        destination,
        key: GOOGLE_API_KEY,
      },
    }
  );

  if (!response.data || response.data.routes.length === 0) return;
  const { distance } = response.data.routes[0].legs[0];

  return distance;
};

export const fetchDistanceFromAddressWeb = (origin, destination) => {
  return new Promise((resolve, reject) => {
    /* eslint-disable no-undef */
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: new google.maps.LatLng(
          origin.split(',')[0],
          origin.split(',')[1]
        ),
        destination: new google.maps.LatLng(
          destination.split(',')[0],
          destination.split(',')[1]
        ),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === 'OK') {
          resolve(response?.routes?.[0]?.legs?.[0]?.distance);
        } else {
          reject(
            `Unable to fetch distance due to Google Maps API error: ${status}`
          );
        }
      }
    );
    /* eslint-enable no-undef */
  });
};

/**
 * Returns true if a given filename has an image extension.
 *
 * @param {string} filename The filename to parse.
 * @return {boolean} True if the given filename is an image.
 */
export const isImage = (filename) =>
  /^data:image|\.(jpg|jpeg|png|gif)$/i.test(filename);

/**
 * Returns true if a given filename has a video extension.
 *
 * @param {string} filename The filename to parse.
 * @return {boolean} True if the given filename is a video.
 */
export const isVideo = (filename) =>
  /^data:video|\.(mp4|m4a|ogg)$/i.test(filename);

/**
 * Returns true if a given filename has an image or video extension.
 *
 * @param {string} filename The filename to parse.
 * @return {boolean} True if the given filename is an image or video.
 */
export const isMedia = (filename) => isImage(filename) || isVideo(filename);

/**
 * Returns the page number from a pagination link url
 *
 * @param  {string} url The pagination link from the JSON API response
 * @return {number}     The page number in the link
 */
export const pageNumberFromUrl = (url) => {
  let queryParams;

  try {
    ({ queryParams } = Linking.parse(url));
  } catch (error) {
    return null;
  }

  return Number(queryParams['page[number]']);
};

/**
 * Replaces non-alphanumeric characters with dashes
 * and down cases the string.
 *
 * @param {string} string The string to parameterize.
 * @return {string} A paramterized string.
 */
export const parameterize = (string) =>
  string.toLowerCase().replace(/[^\w]/g, '-').replace(/-$/, '');

/**
 * Removes leading and trailing white spaces from any
 * string values in a given object.
 *
 * @param {object} source An object with string values to trim.
 * @return {object} An object with trimmed string values.
 */
export const trimWhitespace = (source) => {
  const result = {};

  Object.keys(source).forEach((key) => {
    result[key] = source[key]?.trim ? source[key].trim() : source[key];
  });

  return result;
};

/**
 * Log errors locally in development or using Sentry
 * in production or staging.
 *
 * @param {object} error A native error object.
 * @param {object} data Additional error data.
 */
export function logError(error, data = undefined) {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') return console.error(error, data);

  try {
    Sentry.captureException(error, data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to log error in Sentry', e);
  }
}

export const isEmpty = (object) => !object || Object.keys(object).length === 0;

/**
 * Extracts an object with the current values
 * of a form of a given name.
 *
 * @param {object} form The form reducer object.
 * @param {string} name The name of the form.
 * @return {object} An object with form values.
 */
export const extractFormValues = (form, name) =>
  (form && form[name] && form[name].values) || {};

/**
 * Exclude a given message from being logged using `console.warn`.
 *
 * @param {string} warning A warning message to exclude from logs.
 */
export const ignoreConsoleWarning = (warning) => {
  if (!console.warn) return;
  const _warn = console.warn;

  console.warn = (...args) => {
    if (args[0] === warning) return;
    _warn(...args);
  };
};

/**
 * Check if current date is within a number of days of given date
 *
 * @param {date} originDate date to compare to.
 * @param {number} dayRange nuber of days in comparison window.
 * @return {boolean} whether we are within N days or not.
 */
export const isWithinDateRangeOf = (originDate, dayRange) => {
  const dateSetAsDate = new Date(originDate).getTime();
  const dayRangeInMilliseconds = 1000 * 60 * 60 * 24 * dayRange;

  return new Date().getTime() < dateSetAsDate + dayRangeInMilliseconds;
};

export const getFaqUrl = (clientData) => {
  return clientData?.customContent?.faqUrl ?? PATIENT_FAQ_URL;
};

export const getSilverFaqUrl = (clientData) => {
  return clientData?.customContent?.faqUrl ?? PATIENT_SILVER_FAQ_URL;
};
