import { TouchableOpacity } from 'react-native';

import { Badge as BadgeRaw, Text } from 'react-native-elements';
import styled, { css, DefaultTheme } from 'styled-components/native';

import { navigationLinkStyles } from '../../constants';
import { LayoutProp, NavigationLinkPressableProps } from '../../types';

const hasTopNav = (theme: DefaultTheme) => {
  return theme.windowDimensions.width > theme.breakpoints.small;
};

const getNavigationLinkBottomBorderStyle = (
  isActive: boolean,
  layout: LayoutProp,
  theme: DefaultTheme
) => {
  switch (true) {
    case hasTopNav(theme) && isActive && layout === 'row':
      return css`
        border-bottom-color: ${theme.colors.black};
        border-bottom-width: ${navigationLinkStyles.underlineHeight}px;
      `;
    case !hasTopNav(theme):
      return css`
        border-bottom-color: ${theme.colors.defaultDark};
        border-bottom-width: 1px;
      `;
  }
};

const getNavigationLinkTextStyle = (
  isActive: boolean,
  layout: LayoutProp,
  theme: DefaultTheme
) => {
  switch (true) {
    case hasTopNav(theme) && isActive:
      const topPaddingAdjustment =
        layout === 'row' ? navigationLinkStyles.underlineHeight / 2 : 0;
      return css`
        color: ${theme.colors.darkBlue};
        font-family: ${theme.fontFamilyMedium};
        font-size: ${theme.fontSizes.body2}px;
        padding: ${theme.spacing * 0.75}px;
        padding-top: ${theme.spacing * 0.75 + topPaddingAdjustment}px;
      `;
    case hasTopNav(theme):
      return css`
        color: ${theme.colors.mediumBlue};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizes.body2}px;
        padding: ${theme.spacing * 0.75}px;
      `;
    case !hasTopNav(theme):
      return css`
        color: ${theme.colors.primary};
        font-family: ${theme.fontFamilyBold};
        font-size: ${theme.fontSizes.body3}px;
        margin-bottom: ${theme.spacing / 8}px;
        padding: ${theme.spacing / 2}px;
      `;
  }
};

export const Badge = styled(BadgeRaw).attrs(({ theme }) => {
  return {
    badgeStyle: {
      backgroundColor: theme.colors.badge,
      borderRadius: navigationLinkStyles.badgeHeight / 2,
      height: navigationLinkStyles.badgeHeight,
      minWidth: navigationLinkStyles.badgeHeight,
    },
    containerStyle: {
      paddingBottom: theme.spacing / 2,
      marginLeft: hasTopNav(theme) ? theme.spacing / -2 : -4,
      marginRight: hasTopNav(theme) ? theme.spacing / 2 : 0,
    },
    status: 'primary',
    textStyle: {
      fontFamily: theme.fontFamilyBold,
      fontSize: theme.fontSizes.xsmall,
    },
  };
})``;

export const NavigationLinkPressable = styled(
  TouchableOpacity
)<NavigationLinkPressableProps>`
  ${({ isActive, layout, theme }) => css`
    align-items: center;
    flex-direction: row;
    ${getNavigationLinkBottomBorderStyle(isActive, layout, theme)}
  `}
`;

export const NavigationLinkText = styled(Text)<NavigationLinkPressableProps>`
  ${({ isActive, layout, theme }) => css`
    ${getNavigationLinkTextStyle(isActive, layout, theme)}
  `}
`;
