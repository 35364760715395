import { TestID } from 'app/components/Login/constants';

import {
  useEffect,
  useRef,
  useState,
  useDispatch,
} from 'app/components/Login/hooks';

import {
  Button,
  ButtonContainer,
  FormErrorContainer,
  FormView,
  Header,
  Icon,
  Input,
  MagicLinkButton,
  Message,
  ParagraphText,
  Text,
  TogglePasswordButton,
} from 'app/components/Login/styles';

import { PasswordProps } from 'app/components/Login/types';
import { requestMagicLink } from 'app/components/Login/helpers';

const Password = ({
  disabled = false,
  error = '',
  formValues = {},
  loading = false,
  onChange,
  onSubmit,
  softLogout,
  setLoginStep,
}: PasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordInput = useRef(null);

  const focusPassword = () => passwordInput?.current?.focus();

  const togglePassword = () => setShowPassword(!showPassword);
  const email = formValues?.email;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!formValues?.email) {
      setLoginStep('email');
      return;
    }
    focusPassword();
  }, []);

  const sendMagicLink = async () => {
    if (!email) return;

    const success = dispatch(requestMagicLink(email));

    if (success) {
      // proceed to confirmation screen
      setLoginStep('magic-link-sent');
    }
  };

  return (
    <FormView testID={TestID.Login.PasswordForm}>
      {error ? (
        <FormErrorContainer>
          <Message>{error}</Message>
        </FormErrorContainer>
      ) : softLogout ? (
        <Text></Text>
      ) : null}
      {formValues?.email && <Header>{formValues.email}</Header>}
      <Input
        label="Password"
        errorMessage={error}
        input={{
          name: TestID.Login.PasswordInput, // passed on to `testID`
          onChange: (value) => onChange('password', value),
        }}
        name="password"
        onSubmitEditing={onSubmit}
        returnKeyType="go"
        rightIcon={
          <TogglePasswordButton onPress={togglePassword}>
            <Icon name={showPassword ? 'visibility' : 'visibility-off'} />
          </TogglePasswordButton>
        }
        secureTextEntry={!showPassword}
        textInputRef={passwordInput}
      />

      <ButtonContainer>
        <Button
          title="Log in"
          disabled={disabled}
          loading={loading}
          onPress={onSubmit}
          testID={TestID.Login.SubmitPasswordButton}
        />
      </ButtonContainer>
      <ParagraphText>
        Forgot Password? Hard to type? Get a magic link sent to your email that
        will sign you in instantly!
      </ParagraphText>
      <ButtonContainer>
        <MagicLinkButton
          disabled={!formValues?.email}
          onPress={sendMagicLink}
          testID={TestID.Login.SendMagicLink}
          title="Send Magic Link"
        />
      </ButtonContainer>
    </FormView>
  );
};

export default Password;
