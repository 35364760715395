import {
  boolOptions,
  Routes,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import {
  dispatchCreateEscalation,
  getNextProfileRoute,
  getPreviousProfileRoute,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useDispatch,
  useHistory,
} from 'app/components/CompleteYourProfile/hooks';

import {
  ButtonList,
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import { RecommendationProps } from 'app/components/CompleteYourProfile/types';

const Recommendation = ({
  value,
  isFirstStep,
  isLastStep,
  isNextDisabled,
  isSaving,
  onChange,
  onNext,
}: RecommendationProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const createNoSurgeryRecEscalation = () => {
    dispatchCreateEscalation(
      'Patient does not have a surgery recommendation.',
      'no_surgery_recommendation',
      dispatch
    );
  };

  return (
    <StepScreen testID={TestID.CompleteYourProfile.Recommendation}>
      <FormContainer>
        <Header
          description="This includes your primary doctor or any specialist."
          title="Have you received a recommendation for this procedure from any of your doctors?"
        />
        <ButtonList
          key={TestID.CompleteYourProfile.Recommendation}
          initialValue={value}
          mode="single"
          onChange={onChange}
          options={boolOptions}
        />
      </FormContainer>
      <ProfileCTAs
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        isNextDisabled={isNextDisabled}
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() => {
            if (typeof value === 'boolean' && !value) {
              createNoSurgeryRecEscalation();
            }
            history.push(getNextProfileRoute(Routes.Recommendation));
          });
        }}
        onPrevious={() =>
          history.push(getPreviousProfileRoute(Routes.Recommendation))
        }
      />
    </StepScreen>
  );
};

export default Recommendation;
