import { Avatar } from 'react-native-elements';
import { useSelector } from 'react-redux';

import { useSession } from 'app/hooks';
import { getIsLoggedIn } from 'app/selectors/session';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';
import { State } from 'types/state';

/**
 * Display the profile avatar only to authenticated users. Display as an image
 * or as initials.
 */
const ProfileAvatar = () => {
  const {
    user: { profileImage },
  } = useSession();
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn) return null;

  return (
    <Avatar
      testID={TestID.Navigation.ProfileAvatar}
      rounded
      size={theme.fontSizes.h1}
      source={profileImage ? { uri: profileImage } : null}
      icon={
        profileImage
          ? null
          : {
              color: theme.colors.mediumBlue,
              name: 'user',
              type: 'font-awesome',
            }
      }
      containerStyle={{
        backgroundColor: theme.colors.backgroundLightBlue,
      }}
    />
  );
};

export default ProfileAvatar;
