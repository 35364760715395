import { Animated, Easing } from 'react-native';

import { useNativeDriver } from 'app/util/constants';

const hideAnimationConfig = {
  duration: 120,
  easing: Easing.out(Easing.ease),
  useNativeDriver,
};

const showAnimationConfig = {
  duration: 240,
  easing: Easing.out(Easing.ease),
  useNativeDriver,
};

export const panelOffset = 380;
const fadeIn = { ...showAnimationConfig, toValue: 1.0 };
const fadeOut = { ...hideAnimationConfig, toValue: 0.0 };
const slideIn = { ...showAnimationConfig, toValue: 0 };
const slideOut = { ...hideAnimationConfig, toValue: panelOffset };

export const collapse = ({ fade, slide }, callback) => {
  callback();

  Animated.parallel([
    Animated.timing(fade, fadeOut),
    Animated.timing(slide, slideOut),
  ]).start();
};

export const expand = ({ fade, slide }, callback) => {
  callback();

  Animated.parallel([
    Animated.timing(fade, fadeIn),
    Animated.timing(slide, slideIn),
  ]).start();
};
