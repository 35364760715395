import axios from 'axios';
import { UPLOAD_API_URL } from 'app/util/constants';

export const RECEIVE_UPLOAD = 'RECEIVE_UPLOAD';
export const DELETE_UPLOAD = 'DELETE_UPLOAD';

/**
 * Ensures there is a . between the file name and the extension.
 * This is required because `DocumentPicker.getDocumentAsync()`
 * does not always separate the file extension in the name.
 */
const ensureFileExtension = (path) => {
  const extension = /(j|m)peg$/.test(path) ? path.slice(-4) : path.slice(-3);
  return /\w+\.\w{3,4}/.test(path)
    ? path
    : [path.slice(0, extension.length * -1), extension].join('.');
};

const dataURItoBlob = (dataURI) => {
  const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const extension = mimeType.split('/')[1];
  const basename = [
    new Date().toISOString(),
    String(Math.random()).split('.')[1],
  ].join('-');
  const filename = `${basename}.${extension}`;

  if (typeof atob === 'undefined') return [dataURI, filename];

  // eslint-disable-next-line no-undef
  const byteString = atob(dataURI.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (var i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  // eslint-disable-next-line no-undef
  const blob = new Blob([arrayBuffer], { type: mimeType });
  return [blob, filename];
};

/**
 * Uploads a file to the Upload Service API
 *
 * @param {string} uri A path to a local file to upload.
 * @return {promise} A promise that resolves with the current conversations.
 */
export const createUpload = (uri) => {
  return async (dispatch, getState) => {
    const token = getState().session.coreToken;
    const data = new FormData();

    if (/^data:/.test(uri)) {
      const [blob, filename] = dataURItoBlob(uri);
      data.append('upload', blob, filename);
    } else {
      data.append('upload', {
        uri,
        name: ensureFileExtension(uri.split('/').pop()),
        type: 'application/form-data',
      });
    }

    const response = await axios.post(`${UPLOAD_API_URL}/uploads`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch(receiveUpload(response.data.data));
    return response.data.data.attributes.url;
  };
};

/**
 * Deletes a file from the Upload Service API
 *
 * @param {string} file A path to a remote file to delete.
 * @return {promise} A promise that resolves with the current conversations.
 */
export const destroyUpload = (file) => {
  return (dispatch, getState) => {
    const token = getState().session.coreToken;
    const id = file
      .replace(UPLOAD_API_URL, '')
      .replace(/\/rails\/active_storage\/blobs\/(redirect\/|)/, '')
      .split('/')[0];

    return axios
      .delete(`${UPLOAD_API_URL}/uploads/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        dispatch(deleteUpload(file));
        return file;
      });
  };
};

export function receiveUpload(upload) {
  return {
    type: RECEIVE_UPLOAD,
    upload,
  };
}

export function deleteUpload(upload) {
  return {
    type: DELETE_UPLOAD,
    upload,
  };
}
