import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { ListItem, Text } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from '@cross-platform/react-router-native';

import Anchor from 'app/components/Common/Anchor';
import FloatingActionButton from 'app/components/Common/FloatingActionButton';
import { DotIndicator } from 'app/components/Common/svg-components';
import { LetUsKnow } from 'app/components/LetUsKnow';
import { Routes } from 'app/util/routes';
import { logEvent, EVENTS } from 'app/util/analytics';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';
import { fetchPossibleProcedures } from 'app/actions/episodeActions';
import { getIsLoggedIn, getIsPlatformOne } from 'app/selectors/session';
import { State } from 'types/state';
import { Procedure } from 'types/procedure';

import ProcedureSearchBar from './ProcedureSearchBar';
import ProcedureSearchEmptyContent from './ProcedureSearchEmptyContent';
import ProcedureSearchResult from './ProcedureSearchResult';

const ProcedureSearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [isLetUsKnowVisible, setIsLetUsKnowVisible] = useState(false);

  const possibleProcedures = useSelector(
    (state: State.RootState) => state.episode?.possibleProcedures || []
  );
  const isPlatformOne = useSelector((state: State.RootState) =>
    getIsPlatformOne(state.session)
  );
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  const input = useRef<HTMLInputElement>();

  /**
   * Logs an event in GA and navigates to the procedure details page.
   *
   * @param {object} procedure The selected procedure.
   */
  const onItemPress = (procedure: Procedure.OfferedProcedure) => {
    logEvent(EVENTS.procedures.viewDetails, { procedure: procedure.name });
    history.push(`/procedures/${procedure.key}`);
  };

  /**
   * Fetch procedures using the current search query,
   * log the event in GA, and display the results.
   * * this function is debounced to once per 500ms.
   * * this function is memoized (useCallback) to ensure debounce is preserved between renders.
   */
  const fetchProcedures = useCallback(
    debounce(async (search) => {
      const searchStr = search.toLocaleLowerCase().trim();
      await dispatch(fetchPossibleProcedures(searchStr));

      logEvent(EVENTS.procedures.search, {
        search: searchStr,
        results: possibleProcedures.length,
      });

      setSearching(false);
    }, 500),
    []
  );

  const openOtherProcedurePage = () => setIsLetUsKnowVisible(true);

  const closeOtherProcedurePage = () => setIsLetUsKnowVisible(false);

  /**
   * Handles search string changes
   * * useEffect was causing severe performance issues here, so an independent event handler is now used instead.
   */
  const handleSearchChange = (search: string) => {
    setSearch(search);
    if (search) {
      setSearching(true);
      fetchProcedures(search);
    }
  };

  const clearSearch = () => {
    handleSearchChange('');

    if (input?.current) input.current.focus();
  };

  return (
    <View style={styles.container} testID={TestID.ProcedureSearch.Page}>
      <ScrollView
        contentContainerStyle={StyleSheet.flatten([
          styles.contentContainer,
          search ? null : { flex: 1 },
        ])}
      >
        {searching ? (
          <DotIndicator style={styles.loadingIndicator} />
        ) : search ? (
          <View
            testID={TestID.ProcedureSearch.ProcedureList}
            style={styles.searchContainer}
          >
            {possibleProcedures.length > 0 ? (
              possibleProcedures.map((procedure) => (
                <ProcedureSearchResult
                  key={procedure.key}
                  onPress={() => onItemPress(procedure)}
                  procedure={procedure}
                />
              ))
            ) : (
              <ListItem
                containerStyle={{
                  flexDirection: 'column',
                  marginHorizontal: 0,
                }}
              >
                <ListItem.Title>No Matches</ListItem.Title>
                <View style={{ paddingHorizontal: theme.spacing }}>
                  <Text style={{ textAlign: 'center' }}>
                    We couldn&apos;t find any results for &quot;
                    {search.trim()}.&quot;
                  </Text>
                </View>
              </ListItem>
            )}

            <Text
              style={{ textAlign: 'center', marginTop: theme.spacing * 2.5 }}
            >
              Can&apos;t find what you&apos;re looking for?
            </Text>

            {isPlatformOne ? (
              <Anchor
                to={`/${Routes.OtherProcedure}`}
                title="Let us know"
                titleStyle={{
                  textAlign: 'center',
                  fontSize: theme.fontSizes.body2,
                }}
              />
            ) : (
              <Anchor
                onPress={openOtherProcedurePage}
                title="Let us know"
                titleStyle={{
                  textAlign: 'center',
                  fontSize: theme.fontSizes.body2,
                }}
              />
            )}
          </View>
        ) : (
          <ProcedureSearchEmptyContent
            onLetUsKnowPress={openOtherProcedurePage}
            testID={TestID.ProcedureSearch.EmptyContentWrapper}
          />
        )}

        <FloatingActionButton
          show={!search && !searching}
          onPress={history.goBack}
          containerStyle={styles.fab}
          icon={Platform.select({
            default: 'arrow-back',
            ios: 'chevron-left',
          })}
        />
      </ScrollView>

      <ProcedureSearchBar
        onChangeText={(search) => handleSearchChange(search)}
        onClear={clearSearch}
        onCancel={history.goBack}
        value={search}
        // @ts-ignore
        inputRef={input}
      />

      {/** Option to request a new procedure */}
      {isLetUsKnowVisible && <LetUsKnow onClose={closeOtherProcedurePage} />}
    </View>
  );
};

export default ProcedureSearch;

const searchBarHeight = theme.spacing * 4.5;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },

  contentContainer: {
    paddingVertical: searchBarHeight,
  },

  loadingIndicator: {
    alignSelf: 'center',
    marginVertical: theme.spacing * 1.25,
  },

  searchContainer: {
    flex: 1,
    backgroundColor: 'transparent',
  },

  fab: {
    alignSelf: 'center',
    width: 52,
    bottom: Platform.select({
      default: 40,
      android: 10,
    }),
    // @ts-ignore
    position: Platform.select({
      default: 'absolute',
      web: 'fixed',
    }),
  },
});
