import { isEqual } from 'lodash';
import { Location } from 'types/location';

export function isAddressComplete(location: Location) {
  return (
    !!location?.address?.city &&
    !!location?.address?.postalCode &&
    !!location?.address?.state &&
    !!location?.address?.street
  );
}

export function getIsDifferentAddress(physical: Location, mailing: Location) {
  if (
    isAddressComplete(physical) &&
    isAddressComplete(mailing) &&
    !isEqual(physical.address, mailing.address)
  ) {
    return true;
  }

  return false;
}
