import React from 'react';
import PropTypes from 'prop-types';

import { ScrollView, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';

import HtmlView from 'app/components/Common/HtmlView';
import { teaserOffset } from 'app/components/Common/TeaserPanel';
import theme from 'app/util/theme';

const ProcedureContent = ({ content }) => (
  <ScrollView contentContainerStyle={styles.container}>
    {content && content.details ? (
      <HtmlView html={content.details} />
    ) : (
      <View style={styles.emptyContentContainer}>
        <Icon size={206} name="build-circle" />
        <Text h2 h2Style={StyleSheet.flatten([styles.emptyContentTitle])}>
          No Content
        </Text>
        <Text style={StyleSheet.flatten([styles.emptyContent])}>
          Wow. This is embarrassing, but there&apos;s nothing here.
        </Text>
        <Text style={StyleSheet.flatten([styles.emptyContent])}>
          Don&apos;t worry, our content team is working on adding more details
          now. In the meantime, you can still schedule your surgery.
        </Text>
      </View>
    )}
  </ScrollView>
);

ProcedureContent.propTypes = {
  content: PropTypes.shape({
    details: PropTypes.string,
  }),
};

ProcedureContent.defaultProps = {
  content: {},
};

export default ProcedureContent;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: theme.breakpoints.large,
    padding: theme.spacing * 2.5,
    paddingBottom: teaserOffset,
  },

  emptyContentContainer: {
    maxWidth: theme.breakpoints.small,
  },

  emptyContentTitle: {
    marginTop: theme.spacing * 2.5,
    textAlign: 'center',
  },

  emptyContent: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing * 1.25,
    marginBottom: theme.spacing,
  },
});
