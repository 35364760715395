import { MINIMUM_PASSWORD_LENGTH } from 'app/util/constants';

import {
  errorMessages as accountSettingsErrorMessages,
  validations as accountSettingsValidations,
} from 'app/components/Common/AccountSettingsForm/constants';

import { isFormat, isPresent } from 'app/util/validations';

const errorMessages = {
  dateOfBirth: { missing: 'Please select your birth month, day, and year' },
  employerRegistrationId: {
    invalid: 'Enter the last 4 digits of your Social Security Number',
    missing: 'Enter the last 4 digits of your Social Security Number',
  },
  firstName: { missing: 'Please enter your First Name' },
  lastName: { missing: 'Please enter your Last Name' },
  phoneNumber: {
    invalid: 'Please enter a 10-digit Phone Number',
    missing: 'Please enter your Phone Number',
  },
  referralSource: {
    missing: 'Please let us know how you became aware of Carrum',
  },
};

const validations = {
  stepOne: {
    firstName: [isPresent(errorMessages.firstName.missing)],
    lastName: [isPresent(errorMessages.lastName.missing)],
    dateOfBirth: [isPresent(errorMessages.dateOfBirth.missing)],
    employerRegistrationId: [
      isPresent(errorMessages.employerRegistrationId.missing),
      isFormat(/\d{4}/, errorMessages.employerRegistrationId.invalid),
    ],
  },
  stepTwo: {
    email: accountSettingsValidations.email,
    password: accountSettingsValidations.password,
    passwordConfirmation: accountSettingsValidations.passwordConfirmation,
    phoneNumber: [
      isPresent(errorMessages.phoneNumber.missing),
      isFormat(/^\(\d{3}\) \d{3} \d{4}$/, errorMessages.phoneNumber.invalid),
    ],
    referralSource: [isPresent(errorMessages.referralSource.missing)],
  },
};

export { Component } from 'react';

export const defaultFormValues = {
  stepOne: {
    dateOfBirth: undefined,
    employerRegistrationId: undefined,
    firstName: undefined,
    lastName: undefined,
  },
  stepTwo: {
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
    referralSource: '',
    skipEmailValidation: false,
  },
};

export { DeviceType } from 'expo-device';

export { emailValidationError } from 'app/actions/sessionActions';

export const errorMessagesForStepOneTests = {
  firstName: errorMessages.firstName.missing,
  lastName: errorMessages.lastName.missing,
  dateOfBirth: errorMessages.dateOfBirth.missing,
  employerRegistrationId: errorMessages.employerRegistrationId.missing,
};

export const errorMessagesForStepTwoTests = {
  email: accountSettingsErrorMessages.email.missing,
  phoneNumber: errorMessages.phoneNumber.missing,
  password: accountSettingsErrorMessages.password.missing,
  passwordConfirmation:
    accountSettingsErrorMessages.passwordConfirmation.mismatch,
  referralSource: errorMessages.referralSource.missing,
};

export { EVENTS } from 'app/util/analytics';

export const expiredAuthLinkConfig = {
  guidanceText:
    "The registration link you got is valid for one-time use only. If you'd like to continue from where you left off, just click the button below and we'll send you another link.",
  headerText: 'Registration Link Failed',
  linkText: 'Send Magic Link',
};

export const invalidFieldValuesForStepTwoTests = {
  email: '',
  phoneNumber: '',
  password: '',
  passwordConfirmation: 'a',
  referralSource: '',
};

export { MINIMUM_PASSWORD_LENGTH };

export const phoneNumber = '18888557806';

export { Platform } from 'react-native';

export { default as PropTypes } from 'prop-types';

export const stepOneDefaultFormValues = defaultFormValues.stepOne;

export const stepOneErrorMessagees = errorMessages;

export const stepOneValidations = validations.stepOne;

export const stepTwoDefaultFormValues = defaultFormValues.stepTwo;

export const stepTwoErrorMessagees = errorMessages.steptwo;

export const stepTwoValidations = validations.stepTwo;

export const submitButtonText = 'Continue';

export { TestID } from 'app/util/test-id';

export const validFieldValuesForStepOneTests = {
  firstName: 'Unregistered',
  lastName: 'Tester',
  dateOfBirth: '1/02/2020',
  employerRegistrationId: '1234',
};

export const validFieldValuesForStepTwoTests = {
  email: `unregistered.tester@carrumhealth.com`,
  phoneNumber: '1231231234',
  password: 'Carrum@123',
  passwordConfirmation: 'Carrum@123',
  referralSource: 'Carrum Health Website',
};
