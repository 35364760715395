import axios from 'axios';

import { CARE_API_URL } from 'app/util/constants';

import {
  receiveEpisode,
  receiveEpisodeJourneyPhases,
  receiveEpisodeLoading,
} from './action-types';
import fetchEpisodeDetails from './fetch-episode-details';
import populateEpisodeFormValues from './populate-episode-form-values';

/**
 * Fetches data for the current episode of the
 * logged in user (if any), and updates the store.
 *
 * @return {promise} a promise that resolves when the data is fetched.
 */
export default () => {
  return async (dispatch, getState) => {
    const {
      episode: { loading },
      session: { user, coreToken },
    } = getState();

    // Return early if there's no active user
    if (!user.id) return;
    // Return early if we're already loading episode data.
    if (loading) return;

    await dispatch(receiveEpisodeLoading(true));

    const response = await axios.get(
      `${CARE_API_URL}/current_episode/${user.id}`,
      {
        params: { include: 'journey_phases' },
        headers: { Authorization: `Bearer ${coreToken}` },
      }
    );

    if (response?.data?.data?.id) {
      await dispatch(receiveEpisode(response.data.data));
      await dispatch(receiveEpisodeJourneyPhases(response.data.included || []));
      await dispatch(fetchEpisodeDetails());
    }

    await dispatch(populateEpisodeFormValues());
    await dispatch(receiveEpisodeLoading(false));
  };
};
