import { Audio } from 'expo-av';
import React, { FC, useState } from 'react';
import { Icon } from 'react-native-elements';

import { ButtonRow, DeleteButton, PlayButton, RecordButton } from './styles';

interface SpokenNameFieldMobileProps {
  createUpload: any;
  destroyUpload: any;
  setUploadUri: any;
  setAutoLogout: any;

  spokenNameUrl: string;
}

const defaultProps = {
  spokenNameUrl: '',
};

const audioModeOptions = {
  allowsRecordingIOS: true,
  playsInSilentModeIOS: true,
};

/**
 * Record using MP4 format to have cross-platform playback support.
 */
const recordingOptions = {
  android: Audio.RecordingOptionsPresets.HIGH_QUALITY,
  ios: {
    ...Audio.RecordingOptionsPresets.HIGH_QUALITY,
    extension: '.mp4',
  },
};

RecordButton.displayName = 'RecordButton';
DeleteButton.displayName = 'DeleteButton';

const SpokenNameFieldMobile = ({
  createUpload,
  destroyUpload,
  setAutoLogout,
  setUploadUri,
  spokenNameUrl,
}: SpokenNameFieldMobileProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [audioRecording, setAudioRecording] = useState(new Audio.Recording());
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSound, setPlaybackSound] = useState();

  async function startRecording() {
    setAutoLogout(false);
    try {
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync(audioModeOptions);
      //@ts-ignore
      await audioRecording.prepareToRecordAsync(recordingOptions);
      await audioRecording.startAsync();
      setIsRecording(true);
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  }

  async function stopRecording() {
    await audioRecording.stopAndUnloadAsync();
    const uri = audioRecording.getURI();
    setIsRecording(false);
    await setIsSaving(true);
    if (spokenNameUrl) {
      await deleteRecording();
    }
    const newSpokenNameUrl = await createUpload(uri);
    await setIsSaving(false);
    await setUploadUri(newSpokenNameUrl);
    await setAudioRecording(new Audio.Recording());
    setAutoLogout(true);
  }

  async function stopSound() {
    if (isPlaying) {
      //@ts-ignore
      await playbackSound.stopAsync();
      setIsPlaying(false);
    }
  }

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync({ uri: spokenNameUrl });
    //@ts-ignore
    setPlaybackSound(sound);
    setIsPlaying(true);
    sound.setOnPlaybackStatusUpdate((status) => {
      //@ts-ignore
      if (status.didJustFinish) setIsPlaying(false);
    });
    await sound.playAsync();
  }

  async function deleteRecording() {
    try {
      await destroyUpload(spokenNameUrl);
    } catch (error) {
      console.error(error);
    }
    setUploadUri(undefined);
  }

  return (
    <ButtonRow>
      <RecordButton
        disabled={isSaving}
        type="outline"
        title={
          isSaving
            ? 'Saving'
            : isRecording
            ? 'Stop recording'
            : `${spokenNameUrl?.length > 0 ? 'Re-' : ''}Record Spoken Name`
        }
        onPress={isRecording ? stopRecording : startRecording}
      />
      {spokenNameUrl?.length > 0 && !isSaving && (
        <PlayButton
          onPress={isPlaying ? stopSound : playSound}
          icon={
            <Icon
              color="white"
              name={isPlaying ? 'stop' : 'play-arrow'}
              type="material"
            />
          }
        />
      )}
      {spokenNameUrl?.length > 0 && !isSaving && (
        <DeleteButton
          type="clear"
          onPress={deleteRecording}
          icon={<Icon color="red" name="delete-forever" type="material" />}
        />
      )}
    </ButtonRow>
  );
};

SpokenNameFieldMobile.defaultProps = defaultProps;

export default SpokenNameFieldMobile;
