import axios from 'axios';
import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/util/constants';

/**
 * Post a request to the core service to send a magic link to the associated
 * user email.
 *
 * @param   {string}   userEmail  the email address for the user
 * @return  {promise}             a promise that resolves when the request is posted
 */
export default (userEmail) => {
  return async (dispatch) => {
    try {
      return axios.post(`${CORE_API_URL}/users/request_password_reset`, {
        magic_link: true,
        email: userEmail,
      });
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
};
