import {
  GoldBackground,
  GoldText,
  SilverBackground,
  SilverText,
  TabText,
  TabRow,
  TabWrapper,
} from './ExperienceTabs/styles';

export const experienceModalContent = {
  columnTitles: [
    <TabWrapper>
      <TabRow>
        <GoldText>Carrum&nbsp;</GoldText>
        <GoldBackground>Gold</GoldBackground>
      </TabRow>

      <TabText>Experience</TabText>
    </TabWrapper>,

    <TabWrapper>
      <TabRow>
        <SilverText>Carrum&nbsp;</SilverText>
        <SilverBackground>Silver</SilverBackground>
      </TabRow>

      <TabText>Experience</TabText>
    </TabWrapper>,
  ],
  rowTitles: [
    'Provider\nQuality\nEvaluation',
    'Care\nConcierge\nSupport',
    'Out-of-\nPocket\nCost',
  ],
  columns: [
    {
      quality: {
        header: 'Double-certified',
        details:
          'Based on extensive public and private data, on-site visits and interviews',
      },
      care: {
        header: 'Premium',
        details:
          '• Appointment scheduling and preparation help\n• Arrange and cover travel\n• Organizing medical records\n• Care coordination after surgery',
      },
      cost: {
        header: 'Estimated',
        details: '$0',
      },
    },
    {
      quality: {
        header: 'Single-certified',
        details: 'Based on extensive public and private data',
      },
      care: {
        header: 'Basic',
        details: '• Appointment scheduling and preparation help',
      },
      cost: {
        header: 'Estimated',
        details: '$3,400 - MOCKED',
      },
    },
    {
      quality: {
        header: '-',
        details: '',
      },
      care: {
        header: '-',
        details: '',
      },
      cost: {
        header: 'Estimated',
        details: '$3,400 - MOCKED',
      },
    },
  ],
};
