import React, { useEffect, useMemo, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { Avatar } from 'react-native-elements';
import { useDispatch } from 'react-redux';
import { first } from 'lodash';
import { useHistory, useParams } from '@cross-platform/react-router-native';

import { receiveTempCoeSelection } from 'app/actions/episodeActions';
import ExperienceLabel from 'app/components/Common/ExperienceLabel';
import PhysicianBiography from 'app/components/Common/PhysicianBiography';
import { Text } from 'app/components/Common/Styled';
import { usePossiblePhysicians } from 'app/hooks';
import { isEmpty } from 'app/util/methods';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';
import { SelectYourDoctorRoutes } from 'app/components/SelectYourDoctor/routes';
import SurgeonVideo from 'app/components/SurgeonDetail/SurgeonVideo';

import { Episode } from 'types/episode';

import {
  ActionRow,
  CenteredRow,
  ContentWidthWrapper,
  NameHeader,
  SandPaddingWrapper,
  ScrollView,
  SelectButton,
  Spacer,
  Specialties,
  SubHeader,
  SubSection,
  TitleHeader,
  TopBackLink,
  WhitePaddingWrapper,
} from './styles';
import StatusScreen from '../StatusScreen';

interface MatchParams {
  id: string;
}

const DoctorDetails = () => {
  const match = useParams<MatchParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [physician, setPhysician] = useState<Episode.PossiblePhysician>(
    {} as Episode.PossiblePhysician
  );
  const {
    data: { records: possiblePhysicians },
    loading,
    refresh: refreshPossiblePhysicians,
  } = usePossiblePhysicians();

  // Fetch physicians when mounting if none are loaded.
  useEffect(() => {
    if (isEmpty(possiblePhysicians)) refreshPossiblePhysicians();
  }, []);

  useEffect(() => {
    const physician = possiblePhysicians.find(({ id }) => id == match.id);

    setPhysician(physician);

    return () => {
      setPhysician(undefined);
    };
  }, [match.id, possiblePhysicians]);

  const selectThisDoctor = async () => {
    await dispatch(
      receiveTempCoeSelection({
        doctor: physician,
        facility: physician.hospital,
        productOffering: physician.hospital.productOffering,
      })
    );

    history.push(`${SelectYourDoctorRoutes.Confirmation}`);
  };

  const isSmallScreen = useMemo(() => width < theme.breakpoints.large, [width]);

  if (loading || !physician) {
    return <StatusScreen error={!physician} loading={loading} />;
  }
  const subSections = [
    'Education',
    'Certifications',
    'Internships',
    'Residencies',
    'Fellowships',
    'Affiliations',
  ];

  return (
    <>
      <ScrollView testID={TestID.SelectYourDoctor.DoctorDetails}>
        <SandPaddingWrapper>
          <WhitePaddingWrapper>
            <ContentWidthWrapper>
              <TopBackLink />
              <CenteredRow>
                <Avatar
                  // @ts-ignore
                  source={
                    Platform.OS === 'web'
                      ? physician.profileImage
                      : { uri: physician.profileImage }
                  }
                  size={theme.spacing * 10}
                  rounded
                />
              </CenteredRow>
              <CenteredRow>
                <NameHeader>{physician.name}</NameHeader>
              </CenteredRow>
              {physician.title?.length > 0 && (
                <CenteredRow>
                  <TitleHeader>{physician.title}</TitleHeader>
                </CenteredRow>
              )}
              <CenteredRow>
                <ExperienceLabel
                  isSilver={
                    physician.hospital?.productOffering?.code === 'silver'
                  }
                />
              </CenteredRow>
              {physician.specialties?.length > 0 && (
                <CenteredRow>
                  <Specialties>{physician.specialties.join(', ')}</Specialties>
                </CenteredRow>
              )}
              {physician.details && (
                <SubSection>
                  <SubHeader>Biography</SubHeader>
                  {/* @ts-ignore */}
                  <PhysicianBiography text={physician.details} />
                </SubSection>
              )}
              {subSections.map((subSectionName) => {
                const subSectionKey = subSectionName.toLowerCase();
                if (physician[subSectionKey]?.length > 0)
                  return (
                    <SubSection key={subSectionKey}>
                      <Text bold>{subSectionName}</Text>
                      {physician[subSectionKey].map((item) => (
                        <Text key={item}>&#9;&bull; {item}</Text>
                      ))}
                    </SubSection>
                  );
              })}
              {physician.videos?.map((video) => (
                <SurgeonVideo {...video} key={video.url} />
              ))}
            </ContentWidthWrapper>
          </WhitePaddingWrapper>
        </SandPaddingWrapper>
      </ScrollView>

      {/* Add extra space to allow room for the StickNavFooter */}
      <Spacer />

      <ActionRow smallScreen={isSmallScreen}>
        <SelectButton
          testID={TestID.SelectYourDoctor.SelectButton}
          isSmallScreen={isSmallScreen}
          title="Select this doctor"
          onPress={selectThisDoctor}
          icon={
            isSmallScreen
              ? { color: 'white', name: 'arrow-forward', size: 24 }
              : undefined
          }
          iconRight={isSmallScreen}
        />
      </ActionRow>
    </>
  );
};

export default DoctorDetails;
