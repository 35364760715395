import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Card, Text } from 'react-native-elements';

import ProcedureIcon from 'app/components/Common/ProcedureIcon';
import theme from 'app/util/theme';

export const ProcedureCard = ({ icon, name }) => (
  <Card>
    <Card.Title>Procedure</Card.Title>
    <Card.Divider />

    <View style={styles.cardContainer}>
      <ProcedureIcon icon={icon} containerStyle={styles.avatar} />

      <Text h2 h2Style={{ marginBottom: 0 }}>
        {name}
      </Text>
    </View>
  </Card>
);

ProcedureCard.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

ProcedureCard.defaultProps = {
  icon: '',
};

export default ProcedureCard;

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  avatar: {
    marginHorizontal: theme.spacing * 1.25,
  },
});
