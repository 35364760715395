import React, { useEffect, useState } from 'react';

import { defaultInputProps, Input, InputProps, WetSignature } from './styles';

/** An input component with cursive signature duplicated below. */

const SignatureInput = (props: InputProps) => {
  const {
    input: {
      onBlur = () => {},
      onChange = () => {},
      onChangeText = () => {},
      ...inputProps
    },
  } = props;

  const [wetSignature, setWetSignature] = useState('');

  /** Handle changes to value of the signature input field.  */
  const handleSignatureChange = (event) => {
    const wetSignature = event.nativeEvent?.text || '';
    setWetSignature(wetSignature);
  };

  return (
    <>
      <Input
        {...inputProps}
        {...props}
        onChange={(value) => handleSignatureChange(value)}
      />
      <WetSignature>{wetSignature}</WetSignature>
    </>
  );
};

SignatureInput.defaultProps = defaultInputProps;

export default SignatureInput;
