import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

import { updateCurrentUser } from 'app/actions/userActions';
import { logError } from 'app/util/methods';

export const registerForPushNotifications = () => {
  return async (dispatch, getState) => {
    try {
      // Only request permissions on mobile devices.
      if (Platform.OS === 'web' || !Constants.isDevice) return;

      let { status } = await Notifications.getPermissionsAsync();

      if (status !== 'granted') {
        ({ status } = await Notifications.requestPermissionsAsync());
      }

      if (status !== 'granted') return;

      const { data: notificationToken } =
        await Notifications.getExpoPushTokenAsync();

      if (getState().session.user.notificationToken === notificationToken)
        return;

      dispatch(updateCurrentUser({ notificationToken }));
    } catch (error) {
      logError('Unable to register for push notifications', error);
    }
  };
};

/**
 * Increments the current badge count by a given amount
 * on iOS devices.
 *
 * @param {integer} amount The amount to change the badge number by.
 * @return {promise} A promise that resolves after updating the badge number.
 *
 */
export const incrementBadgeNumber = (amount) => {
  return async () => {
    if (Platform.OS !== 'ios') return;

    try {
      const number = await Notifications.getBadgeCountAsync();
      Notifications.setBadgeCountAsync(number + amount);
    } catch (error) {
      logError('Unable to increment badge number', error);
    }
  };
};

/**
 * Sets the current badge count to a given number
 * on iOS devices.
 *
 * @param {integer} number The number to set the badge count to.
 * @return {promise} A promise that resolves after updating the badge number.
 *
 */
export const setBadgeNumber = (number) => {
  return async () => {
    if (Platform.OS !== 'ios') return;

    try {
      await Notifications.setBadgeCountAsync(number);
    } catch (error) {
      logError('Unable to set badge number', error);
    }
  };
};
