import { Platform } from 'react-native';

import styled from 'styled-components/native';

import MobileOnlyScrollView from 'app/components/Common/MobileOnlyScrollView';
import {
  AbsoluteFixedContainer,
  Text,
  View,
} from 'app/components/Common/Styled';
import { DotIndicator as DotIndicatorRaw } from 'app/components/Common/svg-components';
import TrackableButton from 'app/components/Common/TrackableButton';

export const ActionButton = styled(TrackableButton).attrs(
  ({ theme, ...props }) => ({
    ...props,
    icon: {
      color: theme.colors.white,
      name: 'arrow-forward',
      size: 24,
    },
  })
)`
  background-color: transparent;
`;

export const ActionRow = styled(View)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  bottom: 0;
  flex-direction: row;
  flex: 1 0 auto;
  height: ${({ theme }) => theme.spacing * 5}px;
  justify-content: flex-end;
  left: 0;
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  right: 0;

  ${({ theme }) => (theme.isNarrow ? '' : 'display: none;')}
`;

export const Button = styled(TrackableButton)``;

export const ButtonContainerMobile = styled(View)`
  margin-top: ${({ theme }) => theme.spacing}px;
`;

export const ButtonContainerWeb = styled(View)`
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing}px;
  ${({ theme }) => (theme.isNarrow ? 'display: none;' : '')}
`;

export const Container = styled(View)`
  background-color: ${({ theme }) => theme.colors.sand};
  flex: 1 0 auto;
  flex-direction: column;
  padding: ${({ theme: { isNarrow, spacing } }) =>
    isNarrow ? spacing : spacing * 5}px;
  padding-bottom: ${({ theme: { isNarrow, spacing } }) =>
    isNarrow ? spacing * 3 : spacing}px;
`;

export const DotIndicator = styled(DotIndicatorRaw)``;

export const DotIndicatorWrapper = styled(AbsoluteFixedContainer).attrs({
  fill: true,
})`
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
`;

export const FooterWrapper = styled(View)`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
`;

export const Header = styled(Text)`
  color: ${({ theme }) => theme.colors.bodyText};
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  font-size: ${({ theme }) => theme.fontSizes.hero}px;
  margin-bottom: ${({ theme: { isNarrow, spacing } }) =>
    isNarrow ? spacing : spacing * 3}px;
  text-align: left;
`;

export { default as ProgressNav } from 'app/components/Common/ProgressNav';

export const ScrollView = styled(MobileOnlyScrollView)`
  padding-bottom: ${({ theme: { isNarrow, spacing } }) =>
    isNarrow ? spacing * 3 : 0}px;
`;

export const TestimonialCard = styled(View)``;

export const TestimonialsContainer = styled(View)``;

export const TestimonialsRight = styled(View)``;

export const VideoContainer = styled(View)``;
