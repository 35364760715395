export { isEmpty } from 'lodash';

import { Platform } from './constants';

import { requestMagicLink } from './helpers';

import { SendMagicLinkProps } from './types';

import * as SecureStore from '@cross-platform/expo-secure-store';

export { callCarrum } from 'app/util/call';

export { getItemAsync } from '@cross-platform/expo-secure-store';

export { isGuestAccount } from 'app/util/userUtils';

export const isValid = ({ email, password }) => email && password;

export { isWithinDateRangeOf } from 'app/util/methods';

export { logError } from 'app/util/methods';

export { logEvent } from 'app/util/analytics';

export { loginCoreUser, receiveSessionError } from 'app/actions/sessionActions';

export { requestMagicLink } from 'app/actions/userActions';

export { SecureStore };

/**
 * Deliver magic link email then navigate to the confirmation screen.
 *
 * @param  {string}    email         email address for delivery of magic link
 * @param  {function}  dispatch      method to dispatch actions for redux store
 * @param  {function}  setLoginStep  method to set the login step in state
 */
export const sendMagicLink = async ({
  email,
  dispatch,
  resend = false,
  history = null,
  setLoginStep = null,
}: SendMagicLinkProps) => {
  if (!email) return;

  const success = dispatch(requestMagicLink(email));

  if (success && setLoginStep) setLoginStep('magic-link-sent');

  if (resend && history) history.push(`/login?email=${email}&resent=true`);
};

/**
 * Persist authentication credentials to secure storage for native devices.
 *
 * @param  {string}  email     the user's email address
 * @param  {string}  password  the user's password
 */
export const storeCredentials = ({ email, password }) => {
  if (Platform.OS === 'web') return;

  const options = {
    keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
  };

  SecureStore.setItemAsync('email', email, options);
  SecureStore.setItemAsync('password', password, options);
};

/**
 * Parse out the email address and reset password token from the current path.
 *
 * @param    {object}  location  the `location` property from react-router
 * @returns  {object}            an object with `email` and `token` properties
 */
export const parseQueryParams = (location) => {
  const email = decodeURIComponent(
    location.search.match(/email=([^&$]+)/)[1]
  ).replace(' ', '+'); // Preserve plus signs in email addresses
  const token = decodeURIComponent(location.search.match(/token=([^&$]+)/)[1]);

  return { email, token };
};

export { StyleSheet } from 'react-native';

export { validateActivation } from 'app/actions/sessionActions';
