import React, { FC, useMemo, useRef } from 'react';
import { Platform, View } from 'react-native';
import { Button } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useHistory } from '@cross-platform/react-router-native';

import { Text } from 'app/components/Common/Styled';
import AddressForm from 'app/components/Profile/AddressForm';
import EmergencyContactForm from 'app/components/Profile/EmergencyContactForm';
import InsuranceForm from 'app/components/Profile/InsuranceForm';
import MakeupForm from 'app/components/Profile/MakeupForm';
import SettingsForm from 'app/components/Profile/SettingsForm';

import { getFieldsBySection } from '../helpers';
import {
  ActionButton,
  ActionRow,
  ButtonRow,
  Container,
  FloatingButton,
  FormContainer,
  Info,
  LinearProgress,
  StepLabel,
  StepNumber,
} from './styles';
import { usePlatform } from 'app/hooks';
import theme from 'app/util/theme';

interface WizardProps {
  // TODO: specify these types vvv
  onSave: (...args: any[]) => any;
  createUpload: (...args: any[]) => any;
  destroyUpload: (...args: any[]) => any;
  notify: (...args: any[]) => any;
  setUploadUri: (...args: any[]) => any;
  setAutoLogout: (...args: any[]) => any;

  currentStep: number;
  spokenNameUrl: string;
  formErrors: any;
  formValues: any;
  profile: any;
}

const defaultProps = {
  formValues: {},
  formErrors: {},
  spokenNameUrl: '',
};

const wizardSteps = [
  'Settings',
  'Physical Makeup',
  'Address',
  'Insurance Information',
  'Emergency Contact',
];

const Wizard = ({
  currentStep,
  formErrors,
  formValues,
  onSave: saveProfile,
  createUpload,
  destroyUpload,
  setAutoLogout,
  setUploadUri,
  spokenNameUrl,
}: WizardProps) => {
  const history = useHistory();
  const scrollView = useRef<any>(); // TODO: specify type
  const { isPlatformTwo } = usePlatform();

  const scrollToTop = () => {
    if (scrollView?.current) scrollView.current.scrollToPosition(0, 0, true);
  };

  const onProceed = () => {
    if (currentStep === wizardSteps.length) {
      saveProfile(0, false, true);
      // show benefits overview if this is the current step in the profile
      if (isPlatformTwo) history.push('/profile/complete');
    } else {
      saveProfile(currentStep + 1, false, true);
    }
    scrollToTop();
  };

  const isIncomplete = useMemo(() => {
    const fieldsBySection = getFieldsBySection(formValues);
    return fieldsBySection[currentStep - 1].some((value) => !value);
  }, [formValues, currentStep]);

  return (
    <Container testID="Wizard--stepper">
      <KeyboardAwareScrollView
        enableOnAndroid
        extraScrollHeight={70}
        testID="Wizard"
        contentContainerStyle={{ paddingBottom: theme.spacing * 6 }}
        ref={scrollView}
      >
        <FormContainer>
          <LinearProgress
            value={currentStep / wizardSteps.length}
            color="primary"
            variant="determinate"
          />
          <View testID="Wizard--info">
            <StepNumber>
              {currentStep} / {wizardSteps.length}
            </StepNumber>
            <StepLabel>{wizardSteps[currentStep - 1]}</StepLabel>
            <Info>Please confirm or enter the information below:</Info>
          </View>
          {currentStep === 1 && (
            <SettingsForm
              formErrors={formErrors}
              setAutoLogout={setAutoLogout}
              setUploadUri={setUploadUri}
              spokenNameUrl={spokenNameUrl}
              createUpload={createUpload}
              destroyUpload={destroyUpload}
              showInterpreterRequiredField={Boolean(
                formValues?.profile?.language?.toLowerCase() !== 'english'
              )}
            />
          )}
          {currentStep === 2 && (
            <MakeupForm formErrors={formErrors} bmi={formValues.profile?.bmi} />
          )}
          {currentStep === 3 && (
            <AddressForm
              formErrors={formErrors}
              location={formValues.location}
              mailingAddressSame={formValues.mailingAddressSame}
            />
          )}
          {currentStep === 4 && (
            <InsuranceForm
              formErrors={formErrors}
              dependent={Boolean(formValues.profile?.dependent)}
              providerName={formValues.profile?.insurance?.name}
              planName={formValues.profile?.insurance?.planName}
              isHsaEligible={Boolean(formValues.profile?.insurance?.hsaFlag)}
            />
          )}
          {currentStep === 5 && (
            <EmergencyContactForm formErrors={formErrors} />
          )}
          {Platform.OS === 'web' && (
            <ButtonRow>
              <FloatingButton
                onPress={() => {
                  if (currentStep === 1) return history.replace('/');
                  saveProfile(currentStep - 1);
                }}
                title={currentStep === 1 ? 'Cancel' : 'Prev.'}
                type="outline"
              />
              <FloatingButton
                testID="Wizard--proceed-button"
                disabled={isIncomplete}
                onPress={onProceed}
                title={currentStep === wizardSteps.length ? 'Submit' : 'Next'}
              />
            </ButtonRow>
          )}
        </FormContainer>
      </KeyboardAwareScrollView>

      {Platform.OS !== 'web' && (
        <ActionRow>
          <ActionButton
            icon={{
              color: 'white',
              name: currentStep === 1 ? 'close' : 'arrow-back',
              size: 24,
            }}
            onPress={() => {
              if (currentStep === 1) return history.replace('/');
              saveProfile(currentStep - 1);
            }}
            title={currentStep === 1 ? 'Cancel' : 'Prev.'}
          />
          <ActionButton
            testID="Wizard--proceed-button"
            disabled={isIncomplete}
            icon={{ color: 'white', name: 'arrow-forward', size: 24 }}
            iconRight
            onPress={onProceed}
            title={currentStep === wizardSteps.length ? 'Submit' : 'Continue'}
            titleStyle={{ marginRight: theme.spacing / 4 }}
          />
        </ActionRow>
      )}
    </Container>
  );
};

Wizard.defaultProps = defaultProps;

export default Wizard;
