import React, { CSSProperties, ReactNode } from 'react';
import { useWindowDimensions } from 'react-native';

import theme from 'app/util/theme';

import {
  Container,
  HomeLeft,
  HomeRight,
  MainWrapper,
  SvgWrapper,
  ThemeProvider,
} from './styles';

interface ProcedureSearchProps {
  children: ReactNode;
  containerStyle?: CSSProperties;
  testID?: string;
}

/**
 * Displays the procedure search page, allowing users to
 * filter and browse procedures by name, description, or
 * tag.
 */
const ProcedureSearchPageWrapper = ({
  children,
  containerStyle,
  testID,
}: ProcedureSearchProps) => {
  const { width } = useWindowDimensions();
  const showBackgroundSvgs = width > theme.breakpoints.medium;

  return (
    <ThemeProvider>
      <Container testID={testID} style={containerStyle}>
        {showBackgroundSvgs && (
          <SvgWrapper side="left">
            <HomeLeft />
          </SvgWrapper>
        )}

        <MainWrapper>{children}</MainWrapper>

        {showBackgroundSvgs && (
          <SvgWrapper side="right">
            <HomeRight />
          </SvgWrapper>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ProcedureSearchPageWrapper;
