import { useEffect, useState } from 'react';
import { uniq } from 'lodash';

//@ts-ignore
import { useHistory } from '@cross-platform/react-router-native';
import { View } from 'app/components/Common/Styled';
import { TestID } from 'app/util/test-id';

import { ButtonWrapper } from './styles';
import { getButtonListTestID } from './utils';

export interface ButtonListOption {
  label: string;
  value: any;
}

export type ButtonListMode = 'single' | 'multi';

interface ButtonListProps {
  initialValue?: any;
  onChange: (value) => void;
  options: ButtonListOption[];
  mode: ButtonListMode;
}

export const ButtonList = ({
  initialValue,
  onChange,
  options,
  mode = 'single',
}: ButtonListProps) => {
  const history = useHistory();
  const [activeButtons, setActiveButtons] = useState(
    typeof initialValue === 'object' ? initialValue : []
  );
  const [activeButton, setActiveButton] = useState(initialValue);

  /**
   * Send the event up
   */
  useEffect(() => {
    mode === 'single' ? onChange(activeButton) : onChange(activeButtons);
  }, [activeButtons, activeButton]);

  const handleMultiChange = (value: string) => {
    if (activeButtons.includes(value)) {
      // remove button from active list
      setActiveButtons(
        activeButtons.filter((buttonValue) => buttonValue !== value)
      );
    } else {
      // add button to active list
      setActiveButtons(uniq([...activeButtons, value]));
    }
  };

  const handleSingleChange = (value: string) => {
    setActiveButton(value);
  };

  const handleButtonChange = (value: string) => {
    if (mode === 'multi') {
      // multi mode
      handleMultiChange(value);
    } else {
      // single mode
      handleSingleChange(value);
    }
  };

  return (
    <View testID={TestID.ButtonList.Component}>
      {options.map(({ label, value }) => {
        let isActive = false;
        if (mode === 'multi') {
          // multi mode
          isActive = activeButtons.includes(value);
        } else {
          // single mode
          isActive = activeButton === value;
        }

        return (
          <ButtonWrapper
            testID={label}
            key={label}
            isActive={isActive}
            onPress={() => handleButtonChange(value)}
            title={label}
            pageLocation={history.location.pathname}
          />
        );
      })}
    </View>
  );
};

export default ButtonList;
