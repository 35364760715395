import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Card, Icon, Image, Text } from 'react-native-elements';

import { isEmpty, toAddressString } from 'app/util/methods';
import { AddressPropTypes } from 'app/util/propTypes';
import theme from 'app/util/theme';

const HospitalCard = ({ address, name, images }) => (
  <Card
    wrapperStyle={{ overflow: 'hidden' }}
    containerStyle={{ paddingBottom: 0, overflow: 'hidden' }}
  >
    <Card.Title>Hospital</Card.Title>
    <Card.Divider />

    <View style={styles.cardContainer}>
      {images && images.logoIcon ? (
        <Image source={{ uri: images.logoIcon }} containerStyle={styles.logo} />
      ) : (
        <Icon
          name="hospital-o"
          type="font-awesome"
          size={60}
          containerStyle={styles.icon}
        />
      )}

      <View style={styles.textContainer}>
        <Text h2 h2Style={{ flex: 1, flexWrap: 'wrap', marginBottom: 0 }}>
          {name || 'Hospital not selected'}
        </Text>
        <Text>
          {isEmpty(address)
            ? 'Select a surgeon to update your hospital selection.'
            : toAddressString(address)}
        </Text>
      </View>
    </View>

    {Boolean(images && images.mainImage) && (
      <Image style={styles.mainImage} source={{ uri: images.mainImage }} />
    )}
  </Card>
);

HospitalCard.propTypes = {
  address: PropTypes.shape(AddressPropTypes),
  images: PropTypes.shape({
    logo: PropTypes.string,
    logoIcon: PropTypes.string,
    mainImage: PropTypes.string,
  }),
  name: PropTypes.string,
};

export default HospitalCard;

const styles = StyleSheet.create({
  card: {
    paddingBottom: 0,
  },

  cardContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: theme.spacing / 2,
  },

  logo: {
    height: 60,
    width: 60,
    marginHorizontal: theme.spacing * 1.5,
  },

  icon: {
    marginHorizontal: theme.spacing * 1.5,
  },

  mainImage: {
    height: 180,
    width: '100%',
  },

  textContainer: {
    flex: 1,
    paddingRight: theme.spacing / 2,
    paddingBottom: theme.spacing / 2,
  },
});
