import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { withTheme } from 'react-native-elements';

import PropTypes from 'prop-types';
import { GooglePlacesAutocomplete } from '@cross-platform/react-native-google-places-autocomplete';

import { View } from 'app/components/Common/Styled';
import FloatingLabel from 'app/components/Common/FloatingLabel';
import {
  toAddressString,
  fetchLocationFromAddress,
  formatGoogleAddress,
} from 'app/util/methods';
import { GOOGLE_API_KEY } from 'app/util/constants';
import { AddressPropTypes } from 'app/util/propTypes';
import theme, { default as defaultTheme, fontFamily } from 'app/util/theme';

const isAddressObject = (address) =>
  address?.street && address?.city && address?.state && address?.postalCode;

/**
 * An input wrapper for redux-form that handles
 * autocomplete using the Google Places API.
 *
 * @example
 * import { Field } from 'redux-form';
 * import GooglePlacesInput from 'app/components/Common/GooglePlacesInput';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myAddressField"
 *      label="Enter your address"
 *      component={GooglePlacesInput}
 *    />
 *   </View>
 * );
 */
export const GooglePlacesInput = ({
  input: { onChange, value, name },
  label,
  theme = defaultTheme,
}) => {
  const formattedValue = isAddressObject(value.address)
    ? toAddressString(value.address)
    : typeof value.address === 'string'
    ? value.address
    : '';

  const onSelect = (detailsWeb, detailsNative) => {
    if (Platform.OS === 'web') {
      onSelectWeb(detailsWeb);
    } else {
      onSelectNative(detailsNative);
    }
  };

  const onSelectNative = (details) => {
    const address = formatGoogleAddress(details.address_components);
    const coords = details.geometry.location;
    onChange({ ...coords, address });
  };

  const onSelectWeb = async (details) => {
    const result = await fetchLocationFromAddress(details.label);
    onChange({ ...result });
  };

  return (
    <View style={theme.Input.containerStyle}>
      {label ? (
        <View style={{ marginLeft: theme.spacing / 2 }}>
          <FloatingLabel>{label}</FloatingLabel>
        </View>
      ) : null}

      <GooglePlacesAutocomplete
        testID={typeof name === 'string' ? name : ''}
        fetchDetails
        returnKeyType="search"
        nearbyPlacesAPI="GoogleReverseGeocoding"
        minLength={2}
        debounce={200}
        listViewDisplayed={false}
        styles={{
          textInput: StyleSheet.flatten([
            theme.Input.inputStyle,
            styles.textInput,
          ]),
          textInputContainer: StyleSheet.flatten([
            theme.Input.inputContainerStyle,
            styles.textInputContainer,
          ]),
          description: StyleSheet.flatten([
            theme.Input.inputStyle,
            styles.description,
          ]),
        }}
        onPress={onSelect}
        getDefaultValue={() => formattedValue}
        selectProps={{
          inputProps: {
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'off',
          },
          defaultInputValue: formattedValue,
          onChange: onSelect,
          onInputChange: (address, event) => {
            if (!event || event.action !== 'input-change') return;
            onChange({ address, lat: '', lng: '' });
          },
          styles: {
            container: (provided) => ({
              ...provided,
            }),
            input: (provided) => ({
              ...provided,
              ...theme.Input.inputStyle,
              ...styles.textInput,
              fontFamily,
              borderWidth: 0,
            }),
            control: (provided) => ({
              ...provided,
              fontFamily,
              borderWidth: 0,
              boxShadow: 'none',
            }),
            menu: (provided) => ({
              ...provided,
              fontFamily,
            }),
          },
        }}
        query={{
          key: GOOGLE_API_KEY,
          language: 'en',
          components: 'country:us', // Limit results to the US
        }}
      />
    </View>
  );
};

/**
 * @property {object} input An object with props passed down from redux-form.
 * @property {function} input.onChange A function to call when the value changes.
 * @property {boolean} input.value An object with an `address` property.
 * @property {string|object} input.value.address An address object or string to display.
 * @property {string} label A label to display above the select.
 */
GooglePlacesInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        address: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape(AddressPropTypes),
        ]),
      }),
    ]),
  }).isRequired,
  label: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

GooglePlacesInput.defaultProps = {
  label: '',
};

export default withTheme(GooglePlacesInput);

const styles = StyleSheet.create({
  textInput: {
    height: 32,
    paddingLeft: theme.spacing / 8,
    paddingRight: theme.spacing / 8,
  },

  textInputContainer: {
    borderTopWidth: 0,
    backgroundColor: 'transparent',
  },

  description: {
    fontSize: theme.fontSizes.small,
    fontWeight: '100',
    height: 32,
  },

  label: {
    marginLeft: defaultTheme.spacing / 2,
  },
});
