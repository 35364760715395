import { View } from 'react-native';
import { LinearProgress as LinearProgressRaw } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import { Text as TextRaw } from 'app/components/Common/Styled';

export const BodyText = styled(TextRaw).attrs({
  size: 'body1',
})``;

export const DescriptionWrapper = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing * 2}px;
  `}
`;

export const ProgressWrapper = styled(View)`
  ${({ theme }) => css`
    padding: ${theme.spacing}px 0;
  `}
`;

export const TitleText = styled(TextRaw).attrs({
  medium: true,
  size: 'h1',
})`
  ${({ theme }) => css`
    margin: ${theme.spacing / 4}px 0;
  `}
`;

export const TitleWrapper = styled(View)``;
