import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

import SelectRaw from 'app/components/Common/Select';
import TextArea from 'app/components/Common/TextArea';

export const Select = styled(SelectRaw).attrs(({ theme }) => {
  return {
    containerStyle: { marginBottom: theme.spacing },
  };
})``;

export const TextField = styled(TextArea).attrs(({ theme }) => {
  return {
    containerStyle: { marginBottom: theme.spacing },
  };
})``;

export const OncologyWrapper = styled(KeyboardAwareScrollView)`
  flex: 1 0 auto;
`;
