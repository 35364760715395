import React from 'react';

import { Text } from 'app/components/Common/Styled';
import { DoctorCard } from 'app/components/SelectYourDoctor/Cards';
import { isSilverDoctor } from 'app/components/SelectYourDoctor/helpers';
import { useEpisode, usePossiblePhysicians } from 'app/hooks';
import ScrollPagination from '../ScrollPagination';

const DoctorsList = () => {
  const {
    tempCoeSelection: { productOffering },
  } = useEpisode();
  const {
    data: { records: doctors },
    loading,
    next,
  } = usePossiblePhysicians({ fetch: true });

  if (doctors.length === 0 && loading) {
    return <Text>Please wait while we load your selections...</Text>;
  }

  if (doctors.length === 0 && !loading) {
    return <Text>No options found.</Text>;
  }

  return (
    <>
      {doctors
        .filter(
          (doctor) => productOffering.code === 'both' || !isSilverDoctor(doctor)
        )
        .map((doctor) => (
          <DoctorCard
            id={doctor.id}
            key={doctor.id}
            isSilve={isSilverDoctor(doctor)}
            {...doctor}
          />
        ))}

      <ScrollPagination onScrollBottom={next} />
    </>
  );
};

export default DoctorsList;
