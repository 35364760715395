import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Divider, Text } from 'react-native-elements';

import theme from 'app/util/theme';

export function CostEstimate({
  deductible,
  coinsurance,
  copay,
  travel,
  total,
}) {
  return (
    <View>
      <View style={styles.row}>
        <Text>Deductible:</Text>
        <Text>${deductible}</Text>
      </View>
      <View style={styles.row}>
        <Text>Coinsurance:</Text>
        <Text>${coinsurance}</Text>
      </View>
      <View style={styles.row}>
        <Text>Copay:</Text>
        <Text>${copay}</Text>
      </View>
      <View style={styles.row}>
        <Text>Travel:</Text>
        <Text>${travel}</Text>
      </View>

      <Divider style={styles.divider} />

      <View style={styles.row}>
        <Text>Total:</Text>
        <Text>${total}</Text>
      </View>
    </View>
  );
}

CostEstimate.propTypes = {
  deductible: PropTypes.string.isRequired,
  coinsurance: PropTypes.string.isRequired,
  copay: PropTypes.string.isRequired,
  travel: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing / 4,
    paddingHorizontal: theme.spacing,
  },

  divider: {
    margin: theme.spacing / 2,
  },
});

export default CostEstimate;
