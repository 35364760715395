import { profileStepRoutes } from 'app/util/routes';

import {
  ButtonListOption,
  QuestionnaireProfile,
  StopScreenPageValues,
} from './types';

import { MINIMUM_PASSWORD_LENGTH as MINIMUM_PASSWORD_LENGTH_RAW } from 'app/util/constants';

export { default as breakpoints } from 'app/util/breakpoints';

export const boolOptions: ButtonListOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const completeYourProfileJourneyPhaseCode = 'complete-your-profile';

export const MINIMUM_PASSWORD_LENGTH = MINIMUM_PASSWORD_LENGTH_RAW;

export { planTypeOptions } from 'app/constants/insurance';

export { PROFILE_STEP_RULES } from 'app/util/constants';

export { profileStepRoutes };

export { Routes } from 'app/util/routes';

export const stopScreenPageValues: StopScreenPageValues = {
  default: {
    body: 'Based on the information you provided, we are not ready to connect you to a provider at this time. Your care specialist will reach out to you shortly and discuss further options for your care.',
    ctaTitle: 'Message My Care Team',
    title: 'We will be in touch.',
  },
  isNotPatient: {
    body: 'Sorry, but the registered user must be the patient. It seems that you have created an account on behalf of the person who needs care. No Problem! Click below to create a new account in the name of the patient.',
    ctaTitle: 'Create Account',
    title: 'Before you continue...',
  },
  oncology: {
    body: "Thanks for completing your profile. Our qualified Care Navigators will be reaching out soon to schedule an \bOnboarding Call\b to discuss the services our Cancer Centers of Excellence provide and help pick one that is right for you.\n\nOnboarding Calls are typically scheduled within 1-2 business days. Meanwhile, if you have questions, please don't hesitate to message us or call 888-855-7806.",
    ctaTitle: 'Message My Care Team',
    title: "What's next...",
    footer:
      "Carrum has a national network of Cancer Centers of Excellence (COEs) that do leading-edge research and are committed to improving patient experience and outcomes. Our COEs rank among the world's best cancer centers and include established names like Memorial Sloan Kettering Cancer Center and City of Hope.",
  },
};

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';

export { smokingStatuses } from 'app/constants/smoking-statuses';

export const initProfile: QuestionnaireProfile = {
  location: {
    physical: {
      address: {
        city: '',
        postalCode: '',
        state: '',
        street: '',
        unit: '',
      },
      lat: 0,
      lng: 0,
    },
    mailing: {
      address: {
        city: '',
        postalCode: '',
        state: '',
        street: '',
        unit: '',
      },
      lat: 0,
      lng: 0,
    },
    isDifferentLocation: false,
  },
  grantVoicemailPermission: false,
  height: {
    feet: 0,
    inches: 0,
  },
  insurance: {
    membership: {
      groupNumber: '',
      insuranceType: '',
      medicareOrMedicaid: false,
      memberId: '',
      planType: '',
    },
  },
  phoneNumber: '',
  smoking: '',
  weight: 0,
};

export const emptyProfile: QuestionnaireProfile = {
  grantVoicemailPermission: false,
  height: {
    feet: null,
    inches: null,
  },
  insurance: {
    membership: {
      groupNumber: '',
      insuranceType: '',
      medicareOrMedicaid: false,
      memberId: '',
      planType: '',
    },
  },
  phoneNumber: '',
  smoking: '',
  weight: null,
  location: {
    physical: {
      address: {
        street: '',
        unit: '',
        city: '',
        state: '',
        postalCode: '',
      },
      lat: 0.0,
      lng: 0.0,
    },
    mailing: {
      address: {
        street: '',
        unit: '',
        city: '',
        state: '',
        postalCode: '',
      },
      lat: 0.0,
      lng: 0.0,
    },
    isDifferentLocation: false,
  },
};

export const mockProfile: QuestionnaireProfile = {
  chronicPain: true,
  doctorHasRecommended: true,
  grantVoicemailPermission: true,
  hasAlreadyScheduled: false,
  height: {
    feet: 5,
    inches: 7,
  },
  insurance: {
    membership: {
      groupNumber: '111',
      insuranceType: 'primary',
      medicareOrMedicaid: false,
      memberId: '1234',
      planType: 'HMO',
    },
  },
  isThePatient: true,
  phoneNumber: '555-555-5555',
  smoking: 'never_smoked',
  weight: 130,
  location: {
    physical: {
      address: {
        street: '1234 Blecker St',
        unit: '',
        city: 'New York City',
        state: 'NY',
        postalCode: '10128',
      },
      lat: 0.0,
      lng: 0.0,
    },
    mailing: {
      address: {
        street: '1234 Blecker St',
        unit: '',
        city: 'New York City',
        state: 'NY',
        postalCode: '10128',
      },
      lat: 0.0,
      lng: 0.0,
    },
    isDifferentLocation: false,
  },
};

export const totalSteps = Object.keys(profileStepRoutes).length;
