import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import OtherProcedure, { FormValues } from 'app/components/OtherProcedure';

import { extractFormValues } from 'app/util/methods';
import { Episode } from 'types/episode';

interface State {
  form: FormState;
}

interface FormState {
  episode: Episode.Episode;
}

export interface StateProps {
  formValues: FormValues;
}

const mapStateToProps = ({ form }: State): StateProps => ({
  formValues: extractFormValues(form, 'episode'),
});

const mapDispatchToProps = () => ({});

const OtherProcedureContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherProcedure);

export default reduxForm({
  form: 'episode',
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(OtherProcedureContainer);
