import React from 'react';
import { useWindowDimensions } from 'react-native';

import { useDispatch, useSelector } from 'react-redux';

import { setProfileMenuOpen } from 'app/actions/uiActions';
import { useUi } from 'app/hooks';
import { getIsLoggedIn } from 'app/selectors/session';
import { breakpoints } from 'app/util/breakpoints';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import {
  ProfileAvatar,
  ProfileMenuToggleIcon,
  ProfileMenuToggleContainer,
} from './styles';

const ProfileMenuToggle = () => {
  const dispatch = useDispatch();
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const { profileMenuOpen: profileMenuIsOpen } = useUi();

  if (!isLoggedIn || !hasTopNavLinks) return null;

  return (
    <ProfileMenuToggleContainer
      onBlur={() => {
        dispatch(setProfileMenuOpen(false));
      }}
      onFocus={() => {
        dispatch(setProfileMenuOpen(!profileMenuIsOpen));
      }}
      testID={TestID.Navigation.ProfileMenuToggleContainer}
    >
      <ProfileAvatar />
      <ProfileMenuToggleIcon isOpen={profileMenuIsOpen} />
    </ProfileMenuToggleContainer>
  );
};

export default ProfileMenuToggle;
