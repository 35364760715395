import { Routes } from 'app/components/CompleteYourProfile/constants';

import {
  isProfileJourneyPhaseComplete,
  getUserName,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useEpisode,
  useNextPageForProfileCompletion,
  useSelector,
} from 'app/components/CompleteYourProfile/hooks';

import {
  BenefitsOverview,
  BodyText,
  SubtitleText,
  SubtitleTextWrapper,
  BenefitsWelcomeTitle,
  Redirect,
} from 'app/components/CompleteYourProfile/styles';

import {
  BenefitsWelcomeProps,
  State,
} from 'app/components/CompleteYourProfile/types';

export const BenefitsWelcome = ({ nextPageKey }: BenefitsWelcomeProps) => {
  const goToNextPage = useNextPageForProfileCompletion({
    nextPageKey,
    isWelcomePage: true,
  });
  const { journeyPhases } = useEpisode();
  const userName = useSelector((state: State.RootState) =>
    getUserName(state.session)
  );

  /** Redirect to the "complete" state if this Journey Phase is complete. */
  if (isProfileJourneyPhaseComplete(journeyPhases)) {
    return <Redirect to={`/${Routes.ProfileCreate}/${Routes.Complete}`} />;
  }

  return (
    <BenefitsOverview
      mode="light"
      description={
        <>
          <SubtitleTextWrapper>
            <SubtitleText>Hi {userName},</SubtitleText>
          </SubtitleTextWrapper>

          <BodyText>
            Let's create a profile so we can assist you with your procedure.
            Click continue to answer a few simple questions and get started with
            the Carrum Gold Experience which includes:
          </BodyText>
        </>
      }
      onContinue={() => goToNextPage(nextPageKey, true)}
      title={<BenefitsWelcomeTitle>Complete your profile</BenefitsWelcomeTitle>}
    />
  );
};

export default BenefitsWelcome;
