import React, { Fragment } from 'react';
import { StyleSheet } from 'react-native';
import { Card, Text } from 'react-native-elements';

import PropTypes from 'prop-types';

import { DotIndicator } from 'app/components/Common/svg-components';
import theme from 'app/util/theme';

import CostEstimate from './CostEstimate';

const formatNumber = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const CostEstimateCard = ({
  loading,
  deductible,
  coinsurance,
  copay,
  travel,
  savings,
  displayEstimate,
}) => {
  if (loading) {
    return (
      <Card>
        <Card.Title>Out-of-Pocket Estimate</Card.Title>
        <Card.Divider />

        <Text style={styles.message}>
          Please wait while we calculate your out-of-pocket estimate...
        </Text>
        <DotIndicator style={styles.loadingIndicator} />
      </Card>
    );
  }

  return (
    <Card>
      <Card.Title>Out-of-Pocket Estimate</Card.Title>
      <Card.Divider />

      <Fragment>
        {displayEstimate ? (
          <CostEstimate
            deductible={formatNumber(deductible || 0)}
            coinsurance={formatNumber(coinsurance || 0)}
            copay={formatNumber(copay || 0)}
            travel={formatNumber(travel || 0)}
            total={formatNumber(
              (coinsurance || 0) +
                (copay || 0) +
                (deductible || 0) +
                (travel || 0)
            )}
          />
        ) : (
          <Text style={styles.message}>
            Your employer has elected to waive your coinsurance and any
            necessary travel costs. You are only required to pay your remaining
            deductible for the year.
          </Text>
        )}

        {savings === 0 && (
          <Text style={styles.message}>
            Your employer has elected to cover all out-of-pocket costs for your
            surgery.
          </Text>
        )}

        {Boolean(savings && savings > 0) && (
          <Text style={styles.message}>
            You&apos;re saving an estimated&nbsp;
            <Text h4 h4Style={styles.bold}>
              ${formatNumber(savings)}&nbsp;
            </Text>
            as compared to surgery through your standard health insurance plan.
          </Text>
        )}
      </Fragment>
    </Card>
  );
};

CostEstimateCard.propTypes = {
  loading: PropTypes.bool,
  coinsurance: PropTypes.number,
  deductible: PropTypes.number,
  travel: PropTypes.number,
  savings: PropTypes.number,
  displayEstimate: PropTypes.bool,
};

CostEstimateCard.defaultProps = {
  loading: false,
  coinsurance: 0,
  deductible: 0,
  travel: 0,
};

export default CostEstimateCard;

const styles = StyleSheet.create({
  message: {
    marginTop: theme.spacing / 2,
    paddingHorizontal: theme.spacing,
    fontSize: theme.fontSizes.body3,
  },

  divider: {
    margin: theme.spacing / 2,
  },

  bold: {
    fontSize: theme.fontSizes.body3,
  },

  loadingIndicator: {
    alignSelf: 'center',
    marginTop: theme.spacing / 2,
  },
});
