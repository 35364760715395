import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Avatar, withTheme } from 'react-native-elements';
import theme from 'app/util/theme';

export const ProcedureIcon = ({ containerStyle, icon, size, theme }) => (
  <Avatar
    rounded
    size={size}
    source={icon ? { uri: icon } : null}
    icon={icon ? null : { name: 'healing' }}
    avatarStyle={styles.avatar}
    containerStyle={StyleSheet.flatten([
      styles.container,
      containerStyle,
      { backgroundColor: theme.colors.primary },
    ])}
    placeholderStyle={{ backgroundColor: theme.colors.primary }}
    overlayContainerStyle={{ backgroundColor: theme.colors.primary }}
  />
);

ProcedureIcon.propTypes = {
  theme: PropTypes.object.isRequired,

  containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  icon: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ProcedureIcon.defaultProps = {
  containerStyle: null,
  icon: '',
  size: 'large',
};

const styles = StyleSheet.create({
  container: {
    padding: theme.spacing / 2,
  },
});

export default withTheme(ProcedureIcon);
