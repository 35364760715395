import React from 'react';
import { useWindowDimensions } from 'react-native';

import Alert from '@cross-platform/react-native-alert';
import { useDispatch, useSelector } from 'react-redux';

import { disconnectFromWebSocket } from 'app/actions/messageActions';
import { logout } from 'app/actions/sessionActions';

import { useEpisode, usePlatform } from 'app/hooks';
import { getIsEpisodeCreated } from 'app/selectors/episode';
import { getIsLoggedIn } from 'app/selectors/session';
import { breakpoints } from 'app/util/breakpoints';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import { navigationPaths } from '../../constants';
import { LayoutProp } from '../../types';
import { HelpLink, NavigationLink, ProfileLinksContainer } from './styles';

/** Render navigation links only for authenticated users. */
const ProfileLinks = ({ layout = 'row' }: { layout: LayoutProp }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );

  if (!isLoggedIn) return null;

  const episode = useEpisode();
  const isNarrow = useWindowDimensions().width <= breakpoints.small;
  const { isPlatformOne, isPlatformTwo } = usePlatform();

  const isEpisodeCreated = getIsEpisodeCreated(episode);
  const showProfileLink = isPlatformOne || (isPlatformTwo && isEpisodeCreated);

  const confirmLogout = () => {
    Alert.alert('Log Out', 'Do you want to log out of the app?', [
      { text: 'Cancel', style: 'cancel' },
      { text: 'OK', onPress: logoutUser },
    ]);
  };

  const logoutUser = () => {
    disconnectFromWebSocket();
    dispatch(logout(true));
  };

  return (
    <ProfileLinksContainer
      testID={TestID.Navigation.ProfileLinks}
      layout={layout}
    >
      {isPlatformOne && (
        <NavigationLink
          layout={layout}
          name="My Care Summary"
          path={navigationPaths.careSummary}
          testID={TestID.Navigation.MyCareSummaryLink}
        />
      )}
      <NavigationLink
        layout={layout}
        name="My Carrum ID Card"
        path={navigationPaths.carrumId}
        testID={TestID.Navigation.MyCarrumIDCardLink}
      />
      {showProfileLink && (
        <NavigationLink
          layout={layout}
          name="My Profile"
          path={navigationPaths.profile}
          testID={TestID.Navigation.MyProfileLink}
        />
      )}
      <NavigationLink
        layout={layout}
        name="My Settings"
        path={navigationPaths.settings}
        testID={TestID.Navigation.MySettingsLink}
      />
      {isNarrow && <HelpLink />}
      <NavigationLink
        layout={layout}
        name="Log Out"
        onPress={confirmLogout}
        testID={TestID.Navigation.LogOutLink}
      />
    </ProfileLinksContainer>
  );
};

export default ProfileLinks;
