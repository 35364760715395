import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ScrollView, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';

import Anchor from 'app/components/Common/Anchor';
import { DotIndicator } from 'app/components/Common/svg-components';
import theme, { fontFamilyBold } from 'app/util/theme';
import { Routes } from 'app/util/routes';

/**
 * @property {function} getProcedure A function to get the current procedure (required).
 * @property {string} procedureKey The key for the procedure being accessed (required).
 * @property {function} push A function to push a new route when navigating (required).
 */
const propTypes = {
  getProcedure: PropTypes.func.isRequired,
  procedureKey: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

const defaultProps = {};

const ProcedureNotFound = ({ getProcedure, procedureKey, push }) => {
  const [loading, setLoading] = useState(true);

  const onLoad = useCallback(() => setLoading(false), [setLoading]);

  useEffect(() => {
    getProcedure(procedureKey).then(onLoad);
  }, [getProcedure, onLoad, procedureKey]);

  if (loading) {
    return (
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.emptyContentContainer}>
          <DotIndicator style={{ alignSelf: 'center' }} />
          <Text h2 h2Style={StyleSheet.flatten([styles.emptyContentTitle])}>
            Looking for Procedure...
          </Text>
          <Text style={StyleSheet.flatten([styles.emptyContent])}>
            Please wait while we fetch information about this procedure.
          </Text>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.emptyContentContainer}>
        <Icon size={206} name="help-outline" />
        <Text h2 h2Style={StyleSheet.flatten([styles.emptyContentTitle])}>
          Procedure Not Found
        </Text>
        <Text style={StyleSheet.flatten([styles.emptyContent])}>
          We weren&apos;t able to find the procedure you&apos;re looking for.
          That may because of an error on our end, or because this procedure is
          not available in your area.
        </Text>
        <Anchor
          to={`/${Routes.Procedures}`}
          onPress={() => push('/procedures')}
          title="Try searching for something else"
          titleStyle={styles.anchor}
        />
        <Text style={StyleSheet.flatten([{ textAlign: 'center' }])}>or</Text>
        <Anchor
          to={`/${Routes.OtherProcedure}`}
          onPress={() => push('/other-procedure')}
          title="let us know what you're looking for."
          titleStyle={styles.anchor}
        />
      </View>
    </ScrollView>
  );
};

export default ProcedureNotFound;

ProcedureNotFound.propTypes = propTypes;
ProcedureNotFound.propTypes = defaultProps;

const styles = StyleSheet.create({
  anchor: {
    textAlign: 'center',
    fontFamily: fontFamilyBold,
    fontSize: theme.fontSizes.body2,
  },

  container: {
    alignSelf: 'center',
    maxWidth: theme.breakpoints.large,
    paddingTop: theme.spacing * 2.5,
    paddingBottom: theme.spacing * 12,
  },

  emptyContentContainer: {
    maxWidth: theme.breakpoints.small,
  },

  emptyContentTitle: {
    marginTop: theme.spacing * 2.5,
    textAlign: 'center',
  },

  emptyContent: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing * 1.25,
    marginBottom: theme.spacing,
  },
});
