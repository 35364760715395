import React from 'react';

import { useSelector } from 'react-redux';
import { useWindowDimensions } from 'react-native';

import { useEpisode, useMessage, useSession, useSubmission } from 'app/hooks';
import {
  getIsEpisodeCreated,
  getIsEpisodeTraveling,
} from 'app/selectors/episode';
import { getIsLoggedIn } from 'app/selectors/session';
import { getHasConversation } from 'app/selectors/message';
import { breakpoints } from 'app/util/breakpoints';
import { TestID } from 'app/util/test-id';
import { State } from 'types/state';

import { navigationPaths } from '../../constants';
import {
  extractIncompleteFormCount,
  extractUnreadMessageCount,
} from '../../helpers';
import { ContentLinksContainer, HelpLink, NavigationLink } from './styles';

/** Render navigation links pertaining to content. */
const ContentLinks = () => {
  const episode = useEpisode();
  const message = useMessage();
  const submission = useSubmission();
  const { user } = useSession();

  const conversation = message.conversations[0];
  const incompleteFormsCount = extractIncompleteFormCount(
    submission.submissions
  );
  const unreadMessagesCount = conversation
    ? extractUnreadMessageCount(user.id, conversation.messages)
    : 0;

  const hasConversation = getHasConversation(message);
  const isEpisodeCreated = getIsEpisodeCreated(episode);
  const isEpisodeTraveling = getIsEpisodeTraveling(episode);
  const isLoggedIn = useSelector((state: State.RootState) =>
    getIsLoggedIn(state.session)
  );
  const isNarrow = useWindowDimensions().width <= breakpoints.small;

  if (!isLoggedIn) return null;

  return (
    <ContentLinksContainer testID={TestID.Navigation.ContentLinks}>
      {isEpisodeCreated && (
        <NavigationLink
          name="Home"
          path={navigationPaths.home}
          testID={TestID.Navigation.HomeLink}
        />
      )}
      {hasConversation && (
        <NavigationLink
          badgeCount={unreadMessagesCount}
          name="Messages"
          path={navigationPaths.messages}
          testID={TestID.Navigation.MessagesLink}
        />
      )}
      {isEpisodeCreated && (
        <NavigationLink
          badgeCount={incompleteFormsCount}
          name="Forms"
          path={navigationPaths.forms}
          testID={TestID.Navigation.FormsLink}
        />
      )}
      {isEpisodeTraveling && (
        <NavigationLink
          name="Travel"
          path={navigationPaths.travel}
          testID={TestID.Navigation.TravelLink}
        />
      )}
      {!isNarrow && <HelpLink />}
    </ContentLinksContainer>
  );
};

export default ContentLinks;
