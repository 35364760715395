import { Question } from 'types/submission';

/**
 * Some questions are conditional based on the given response,
 * so attempt to find a previous question that presents the current
 * question conditionally.
 */
export const findPreviousLinkedQuestion = (
  question: Question,
  questions: Question[] = []
) =>
  questions.find(({ nextQuestions }) =>
    nextQuestions?.find(({ label }) => label === question.label)
  );

/**
 * Some questions are conditional based on the given response,
 * so attempt to find the next question that presents based on
 * the answer to the current question.
 */
export const findNextLinkedQuestion = (
  question: Question,
  questions: Question[] = []
) => {
  const currentIndex = questions.indexOf(question);
  const nextQuestions = question.nextQuestions || [];
  const nextQuestion = nextQuestions.find(
    ({ response }) => response === question.response
  );

  return questions
    .slice(currentIndex)
    .find(({ label }) => label === nextQuestion?.label);
};

/**
 * Some questions are conditional based on the given response,
 * so attempt to find the next question that will always present
 * regardless of the answer to the current question.
 */
export const findNextUnlinkedQuestion = (
  question: Question,
  questions: Question[] = []
) => {
  const linkedQuestion = findPreviousLinkedQuestion(question, questions);
  const currentIndex = questions.indexOf(question);

  if (!linkedQuestion) return questions[currentIndex + 1];

  return questions
    .slice(currentIndex)
    .find(
      ({ label }) =>
        linkedQuestion.nextQuestions
          .map(({ label }) => label)
          .indexOf(label) === -1
    );
};

/**
 * Get the current question number indexed by 1.
 */
export const getCurrentQuestionNumber = (
  question: Question,
  questions: Question[] = []
) => {
  return questions.indexOf(question) + 1;
};

/**
 * Get the current total number of questions in the form.
 */
export const getTotalQuestionCount = (questions: Question[] = []) => {
  return questions.length;
};
