import { Field as FieldRaw } from 'redux-form';
import styled from 'styled-components/native';

import TextRaw from 'app/components/Common/Styled/Text';

export const CheckboxFieldView = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing / 4}px;
`;

export const Field = styled(FieldRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;

export const MessageAnchorText = styled(TextRaw)`
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StaticFieldsView = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
`;

export const TipText = styled(TextRaw)`
  margin-top: ${({ theme }) => theme.spacing / 2}px;
  font-size: ${({ theme }) => theme.fontSizes.small}px;
`;

export const Text = styled(TextRaw)`
  color: rgb(67, 72, 77);
`;
