import { Routes, TestID } from 'app/components/CompleteYourProfile/constants';

import {
  getNextProfileRoute,
  getPreviousProfileRoute,
  getUserPhoneNumber,
  isValidPhoneNumber,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useEffect,
  useHistory,
  useSelector,
  useState,
} from 'app/components/CompleteYourProfile/hooks';

import {
  FormContainer,
  Header,
  ProfileCTAs,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import {
  State,
  TellUsMoreProps,
} from 'app/components/CompleteYourProfile/types';

import {
  Anchor,
  CheckBox,
  DisclaimerText,
  GrantPermissionWrapper,
  HeightFeetInput,
  HeightInchesInput,
  HeightInputsWrapper,
  PhoneInput,
  PhoneNumberText,
  PhoneNumberWrapper,
  PhysicalAttributesWrapper,
  WeightInput,
  WeightInputWrapper,
} from './styles';

export const TellUsMorePage = ({
  isSaving,
  onChange,
  onNext,
  values,
}: TellUsMoreProps) => {
  const history = useHistory();
  const [heightFeet, setHeightFeet] = useState<string>(
    values.height.feet?.toString() || ''
  );
  const [heightInches, setHeightInches] = useState<string>(
    values.height.inches?.toString() || ''
  );
  const [weight, setWeight] = useState<number>(values.weight);

  const profilePhoneNumber = useSelector((state: State.RootState) =>
    getUserPhoneNumber(state.session)
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    values.phoneNumber || profilePhoneNumber
  );
  const [grantVoicemailPermission, setGrantVoicemailPermission] =
    useState<boolean>(values.grantVoicemailPermission);

  /** Prevents non-numeric entries for text input (does not handle decimals) */
  const handleNumericChange =
    (value: string, maxValue: number) => (func: Function) => {
      if (value === '' || !isNaN(value as any)) {
        // prevent going over max value
        if (Number(value) > maxValue) {
          value = maxValue.toString();
        }

        func(value);
      }
    };

  // height changes
  useEffect(() => {
    onChange({
      grantVoicemailPermission,
      height: {
        feet: heightFeet ? parseInt(heightFeet) : null,
        inches: heightInches ? parseInt(heightInches) : null,
      },
      phoneNumber,
      weight,
    });
  }, [heightFeet, heightInches, weight, phoneNumber, grantVoicemailPermission]);

  return (
    <StepScreen>
      <FormContainer>
        <Header title="Tell us more about yourself" />
        <PhysicalAttributesWrapper
          testID={TestID.CompleteYourProfile.TellUsMore}
        >
          <HeightInputsWrapper>
            <HeightFeetInput
              input={{
                name: TestID.CompleteYourProfile.HeightFeet,
                onChange: (value: string) =>
                  handleNumericChange(value, 7)(setHeightFeet),
              }}
              keyboardType="number-pad"
              label="Height (feet)"
              returnKeyType="done"
              value={heightFeet?.toString() || ''}
            />

            <HeightInchesInput
              input={{
                name: TestID.CompleteYourProfile.HeightInches,
                onChange: (value: string) =>
                  handleNumericChange(value, 11)(setHeightInches),
              }}
              keyboardType="number-pad"
              label="Height (inches)"
              returnKeyType="done"
              value={heightInches?.toString() || ''}
            />
          </HeightInputsWrapper>

          <WeightInputWrapper>
            <WeightInput
              input={{
                name: TestID.CompleteYourProfile.Weight,
                onChange: (value: string) =>
                  handleNumericChange(value, 999)(setWeight),
              }}
              keyboardType="number-pad"
              label="Weight (pound)"
              returnKeyType="done"
              value={weight?.toString() || ''}
            />
          </WeightInputWrapper>
        </PhysicalAttributesWrapper>

        <PhoneNumberWrapper>
          <PhoneNumberText>Your personal phone number</PhoneNumberText>
          <PhoneInput
            input={{
              name: TestID.CompleteYourProfile.PhoneInput,
              onChange: setPhoneNumber,
              value: phoneNumber?.toString() || '',
            }}
            keyboardType="number-pad"
            label="Phone Number"
            returnKeyType="done"
          />
        </PhoneNumberWrapper>

        <GrantPermissionWrapper>
          <CheckBox
            input={{
              name: TestID.CompleteYourProfile.GrantVoicemailPermission,
              value: grantVoicemailPermission,
              onChange: (value: boolean) => setGrantVoicemailPermission(value),
            }}
            label="I give Carrum Health permission to leave voicemails containing patient health information."
          />
        </GrantPermissionWrapper>

        <DisclaimerText>
          By clicking "Next" you consent to Carrum Health's&nbsp;
          <Anchor
            title="use and disclosure"
            to="https://www.carrumhealth.com/privacy-statement/"
          />
          &nbsp;of your protected health information.
        </DisclaimerText>
      </FormContainer>
      <ProfileCTAs
        isNextDisabled={
          !heightFeet ||
          !heightInches ||
          !weight ||
          !isValidPhoneNumber(phoneNumber)
        }
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() => {
            history.push(getNextProfileRoute(Routes.TellUsMore));
          });
        }}
        onPrevious={() =>
          history.push(getPreviousProfileRoute(Routes.TellUsMore))
        }
      />
    </StepScreen>
  );
};

export default TellUsMorePage;
