import React, { FC } from 'react';
import { useHistory } from '@cross-platform/react-router-native';

import Text from 'app/components/Common/Styled/Text';

import {
  AnchorContent,
  AnchorIcon,
  AnchorText,
  Container,
  Row,
} from './styles';

const LINKS = [
  {
    path: '/profile/settings',
    title: 'Settings',
    subtitle: 'Edit your basic profile settings.',
  },
  {
    path: '/profile/makeup',
    title: 'Physical Makeup',
    subtitle: 'Review your height, weight, etc...',
  },
  {
    path: '/profile/address',
    title: 'Address',
    subtitle: 'Review your location.',
  },
  {
    path: '/profile/insurance',
    title: 'Insurance',
    subtitle: 'Review your insurance details.',
  },
  {
    path: '/profile/emergency-contact',
    title: 'Emergency Contact',
    subtitle: 'Designate an emergency contact.',
  },
];

/**
 * A link menu for navigating the Profile page.
 */
const ProfileMenu: FC = () => {
  const history = useHistory();

  return (
    <Container testID="ProfileMenu">
      {LINKS.map((link, index) => (
        <Row border={index !== LINKS.length - 1} key={link.path}>
          <AnchorContent
            onPress={() => {
              history.push(link.path);
            }}
          >
            <AnchorText>
              <Text bold>{link.title}</Text>
              <Text size="small">{link.subtitle}</Text>
            </AnchorText>
            <AnchorIcon name="chevron-right" />
          </AnchorContent>
        </Row>
      ))}
    </Container>
  );
};

export default ProfileMenu;
