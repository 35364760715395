import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { StickyNavFooter as StickyNavFooterRaw } from 'app/components/Common/StickyNavFooter';
import TrackableButton from 'app/components/Common/TrackableButton';

export { default as DisclaimerText } from 'app/components/Common/DisclaimerText';
export { NavigationMode } from 'app/components/Common/StickyNavFooter/types';

export const Button = styled(TrackableButton).attrs(({ theme }) => {
  return {
    disabledStyle: {
      backgroundColor: theme.colors.disabledNavBackground,
    },
    disabledTitleStyle: {
      color: theme.colors.white,
    },
  };
})`
  ${({ theme }) => css`
    min-width: ${theme.spacing * 10}px;
  `}
`;

export const StickyNavFooter = styled(StickyNavFooterRaw)``;

export const NavigationButtonsWrapper = styled(View)`
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${theme.spacing * 2}px;
    ${theme.isNarrow && `display: none;`}
  `}
`;
