import styled from 'styled-components/native';
import { Button as RNEButton, Icon as RNEIcon } from 'react-native-elements';

import FloatingActionButton from 'app/components/Common/FloatingActionButton';
import HtmlViewRaw from 'app/components/Common/HtmlView';

import theme from 'app/util/theme';

export const CheckIcon = styled(RNEIcon).attrs({
  color: theme.colors.success,
  name: 'check',
})`
  margin-right: ${({ theme }) => theme.spacing / 4}px;
`;

export const ContinueButton = styled(RNEButton).attrs({
  raised: true,
  type: 'outline',
})``;

export const HtmlView = styled(HtmlViewRaw).attrs({ textColor: '#fff' })`
  height: fit-content;
`;

/**
 * Use absolute positioning on the floating action buttons to allow
 * them to transition in and out with animations smoothly.
 */
const fabContainerStyle = {
  position: 'absolute',
  bottom: 0,
  alignSelf: 'center',
};

export const FABError = styled(FloatingActionButton).attrs({
  containerStyle: fabContainerStyle,
  color: theme.colors.error,
  disabled: true,
  icon: 'close',
  reverseColor: '#fff',
  shake: true,
  sound: 'error',
})``;

export const FABSuccess = styled(FloatingActionButton).attrs({
  containerStyle: fabContainerStyle,
  color: theme.colors.success,
  disabled: true,
  icon: 'check',
  reverseColor: '#fff',
  sound: 'success',
})``;
