import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dimensions, StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-elements';

import {
  AddressPropTypes,
  OopEstimatePropTypes,
  HospitalPropTypes,
  ProcedurePropTypes,
  SurgeonPropTypes,
} from 'app/util/propTypes';

import { isEmpty } from 'app/util/methods';
import theme from 'app/util/theme';

import Anchor from 'app/components/Common/Anchor';

import CostEstimateCard from './CostEstimateCard';
import HospitalCard from './HospitalCard';
import LocationCard from './LocationCard';
import MemberIDCard from './MemberIDCard';
import ProcedureCard from './ProcedureCard';
import SurgeonCard from './SurgeonCard';

const EpisodeConfirmation = ({
  loading,
  distance,
  location,
  oopEstimate,
  physician,
  procedure,
  provider,
  confirmed,
  deductibleType,
  hsaFlag,

  memberIdDisabled,
  memberIdError,
  memberIdRequired,
  onConfirm,
  onClearMemberId,
  onRestart,
  onSubmitMemberId,
}) => {
  const success = !isEmpty(oopEstimate);
  const covered = !/not_covered/.test(deductibleType);
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const largeScreen = dimensions.width > theme.breakpoints.small;

  const onDimensionsChange = (event) => setDimensions(event.window);

  const subscription = Dimensions.addEventListener(
    'change',
    onDimensionsChange
  );

  useEffect(() => subscription.remove);

  return (
    <View>
      {!confirmed && (
        <Text style={styles.instructions}>
          Thank you for exploring your options with Carrum Health. Review your
          selections below, or&nbsp;
          <Anchor title="continue to your dashboard" onPress={onConfirm} />
        </Text>
      )}

      <View style={{ flexDirection: largeScreen ? 'row' : 'column' }}>
        <View style={largeScreen ? { flex: 1 } : null}>
          {!isEmpty(procedure) && (
            <ProcedureCard
              name={procedure.name}
              icon={(procedure.images && procedure.images.icon) || ''}
            />
          )}

          <SurgeonCard
            profileImage={physician ? physician.profileImage : ''}
            name={physician ? physician.name : ''}
          />

          <HospitalCard
            address={provider ? provider.address : {}}
            images={provider ? provider.images : {}}
            profileImage={provider ? provider.images : {}}
            name={provider ? provider.name : ''}
          />
        </View>

        <View style={largeScreen ? { flex: 1 } : null}>
          {memberIdError || memberIdRequired ? (
            <MemberIDCard
              disabled={memberIdDisabled}
              error={memberIdError}
              onReset={onClearMemberId}
              onSubmit={onSubmitMemberId}
            />
          ) : (
            <CostEstimateCard
              loading={loading}
              coinsurance={oopEstimate && oopEstimate.coinsurance}
              copay={oopEstimate && oopEstimate.copay}
              deductible={oopEstimate && oopEstimate.deductible}
              travel={oopEstimate && oopEstimate.travel}
              savings={oopEstimate && oopEstimate.savings}
              displayEstimate={success || (covered && !hsaFlag)}
            />
          )}

          {!isEmpty(provider) && !isEmpty(location) && (
            <LocationCard
              distance={distance}
              location={location}
              provider={provider}
            />
          )}
        </View>
      </View>

      {Boolean(onRestart) && (
        <Button
          type="clear"
          title="Cancel and start over"
          containerStyle={{
            paddingTop: theme.spacing * 1.25,
            marginVertical: theme.spacing * 2.5,
          }}
          titleStyle={{ color: '#f33' }}
          onPress={onRestart}
        />
      )}
    </View>
  );
};

EpisodeConfirmation.propTypes = {
  onClearMemberId: PropTypes.func.isRequired,
  onSubmitMemberId: PropTypes.func.isRequired,

  loading: PropTypes.bool,
  distance: PropTypes.string,
  location: PropTypes.shape(AddressPropTypes),
  oopEstimate: PropTypes.shape(OopEstimatePropTypes),
  physician: PropTypes.shape(SurgeonPropTypes),
  procedure: ProcedurePropTypes,
  provider: HospitalPropTypes,
  confirmed: PropTypes.bool,
  currentStep: PropTypes.object,
  travel: PropTypes.object,
  hsaFlag: PropTypes.bool,
  deductibleType: PropTypes.string,
  memberIdDisabled: PropTypes.bool,
  memberIdError: PropTypes.bool,
  memberIdRequired: PropTypes.bool,
  onConfirm: PropTypes.func,
  onRestart: PropTypes.func,
};

EpisodeConfirmation.defaultProps = {
  confirmed: false,
  hsaFlag: false,
  deductibleType: '',
  memberIdDisabled: false,
  memberIdError: false,
  memberIdRequired: false,
};

export default EpisodeConfirmation;

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: theme.spacing / 2,
  },

  subtitle: {
    flexWrap: 'wrap',
    marginBottom: 0,
  },

  instructions: {
    paddingHorizontal: theme.spacing * 1.25,
    textAlign: 'center',
    marginBottom: theme.spacing * 2.5,
  },

  message: {
    marginTop: theme.spacing / 2,
    paddingHorizontal: theme.spacing,
  },
});
