import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import SurgeonDetail from 'app/components/SurgeonDetail';
import {
  getEpisodeDistance,
  getPhysicianRating,
  receivePhysicianRating,
  updateCurrentEpisode,
} from 'app/actions/episodeActions';

const findPhysician = (match, physicians, rating) => {
  const physician = physicians.filter(({ id }) => id === match.params.id)[0];
  return physician ? { ...physician, rating } : null;
};

const mapStateToProps = ({ episode, form }, { match }) => ({
  form,
  hasEpisode: Boolean(episode && episode.episode),
  physician: findPhysician(
    match,
    episode.possiblePhysicians,
    episode.physicianRating
  ),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPhysicianRating: (physicianId) =>
      dispatch(getPhysicianRating(physicianId)),
    resetPhysicianRating: () => dispatch(receivePhysicianRating({})),
    selectPhysician: async (value) => {
      dispatch(change('episode', 'physician', value));
      dispatch(change('episode', 'provider', value.hospitals[0]));
    },
    getEpisodeDistance: () => dispatch(getEpisodeDistance()),
    updatePhysician: (value) =>
      dispatch(
        updateCurrentEpisode({
          physician: value,
          provider: value.hospitals[0],
        })
      ),
  };
};

const SurgeonDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurgeonDetail);

export default reduxForm({
  form: 'episode',
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(SurgeonDetailContainer);
