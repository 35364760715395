import React, { FC } from 'react';
import { useWindowDimensions } from 'react-native';

import theme from 'app/util/theme';

import { BackAnchor, BackAnchorContainer, Container, Header } from './styles';
import { Routes } from 'app/util/routes';

interface SectionHeaderProps {
  showBackLink?: boolean;
  showTitle?: boolean;
  title: string;
}
const defaultProps = {
  showBackLink: false,
  showTitle: false,
  title: '',
};

const SectionHeader = ({
  title,
  showBackLink,
  showTitle,
}: SectionHeaderProps) => {
  const { width } = useWindowDimensions();
  const showBackLinkTitle = width > theme.breakpoints.xsmall;

  return (
    <Container>
      {showBackLink && (
        <BackAnchorContainer>
          <BackAnchor
            testID="BackLink"
            to={`/${Routes.Profile}`}
            title={showBackLinkTitle ? 'Back' : ''}
          />
        </BackAnchorContainer>
      )}
      {showTitle && <Header h2>{title}</Header>}
    </Container>
  );
};

SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
