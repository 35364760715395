import styled, { css } from 'styled-components/native';

import { DefaultTheme, TextProps } from './types';

import { Button } from 'react-native-elements';
import CheckBoxRaw from 'app/components/Common/CheckBox/';
// @ts-ignore
import DatePickerRaw from 'app/components/Common/DatePicker';
import { Icon as IconRaw } from 'react-native-elements';
import { Image } from 'react-native';
import InputRaw from 'app/components/Common/Input';
import PhoneInputRaw from 'app/components/Common/PhoneInput';
import SelectRaw from 'app/components/Common/Select';
import { Text } from 'app/components/Common/Styled';
import { View } from 'app/components/Common/Styled';

/** Return the bottom margin style for an RNE container from theme. */
const attrsForMarginBottom = ({ theme }) => ({
  containerStyle: { marginBottom: theme.spacing },
});

const getEmailTextSize = (theme: DefaultTheme) => {
  switch (true) {
    case theme.windowDimensions.width < theme.breakpoints.xsmall:
      return theme.rawFontSizes.xsmall;
    default:
      return theme.rawFontSizes.xlarge;
  }
};

export { ExpiredAuthLink } from 'app/components/Common/ExpiredAuthLink';

/** Form Input Components */

export { default as Anchor } from 'app/components/Common/Anchor';

export { Button } from 'react-native-elements';

export const CheckBox = styled(CheckBoxRaw).attrs(attrsForMarginBottom)``;

export const DatePicker = styled(DatePickerRaw).attrs(attrsForMarginBottom)``;

export const Input = styled(InputRaw).attrs(attrsForMarginBottom)``;

export const PhoneNumberInput = styled(PhoneInputRaw).attrs(
  attrsForMarginBottom
)``;

export const Select = styled(SelectRaw).attrs(attrsForMarginBottom)``;

/** Form Layout Components */

export const BodyText = styled(Text).attrs({
  size: 'body2',
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
    padding: 0 ${theme.spacing}px;
    text-align: center;
  `}
`;

export const ButtonWrapper = styled(View)`
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px ${({ theme }) => theme.spacing}px;
`;

export { CenteredScrollScreen } from 'app/components/Common/Layout';

export const Container = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.sand};
    flex: 1 0 auto;
    justify-content: center;
  `}
`;

export const EmailText = styled(Text).attrs({
  ellipsizeMode: 'tail',
})`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamilyBold};
    font-size: ${getEmailTextSize(theme)}px;
    max-width: ${theme.breakpoints.small}px;
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const ErrorContainer = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    background-color: ${theme.colors.white};
    flex: 1;
    justify-content: center;
    max-width: ${theme.spacing * 53}px;
  `}
`;

export const FooterText = styled(Text).attrs({
  align: 'center',
  size: 'body2',
})`
  ${({ theme }) => css`
    max-width: ${theme.breakpoints.small}px;
  `}
`;

export const FooterWrapper = styled(View)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing}px;
  `}
`;

export const FormError = styled(Text).attrs({
  color: 'error',
  size: 'small',
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
  `}
`;

export const FormView = styled(View)`
  ${({ theme }) => css`
    padding: ${theme.spacing}px;
  `}
`;

export const HalfWidthButton = styled(Button).attrs<{ isFullWidth?: boolean }>(
  (props) => ({
    containerStyle: {
      width: props.isFullWidth ? '100%' : '48%',
    },
  })
)<{ isFullWidth?: boolean }>``;

export const Header = styled(Text).attrs(({ theme }) => ({
  h1: true,
  h1Style: {
    color: theme.colors.bodyText,
    textAlign: 'left',
    marginTop: 0,
  },
}))``;

export const HeaderContainer = styled(View)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing}px;
  `}
`;

export const Icon = styled(IconRaw).attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: 112,
}))``;

export const IconContainer = styled(View)`
  ${({ theme }) => css`
    margin: ${theme.spacing}px 0;
  `}
`;

export const InstructiveText = styled(Text)<TextProps>`
  ${({ centered, paragraph, marginBottom, theme }) => css`
    ${paragraph &&
    css`
      padding: 0 ${theme.spacing}px;
    `}
    ${centered &&
    css`
      text-align: center;
    `}
      ${marginBottom &&
    css`
      margin-bottom: ${theme.spacing}px;
    `}
  `}
`;

export { LegalContent } from 'app/components/Common/LegalContent';

// @ts-ignore
export { default as MemberCardImage } from 'app/assets/images/InsuranceCard.png';

export const Paragraph = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: 0px;
    padding: 0px ${theme.spacing}px;
  `}
`;

export { Routes } from 'app/util/routes';

export const ScreenWrapper = styled(View)`
  flex: 1 0 auto;
  justify-content: space-between;
`;

export { default as Stepper } from 'app/components/Common/Stepper';

export const StyledCardImage = styled(Image)`
  ${({ theme }) => css`
    align-self: center;
    display: flex;
    margin-top: ${theme.spacing * 2}px;
    max-height: ${theme.spacing * 18.75}px;
    min-height: ${theme.spacing * 18.75}px;
    width: 100%;
  `}
`;

export { Text } from 'app/components/Common/Styled';

export const TogglePasswordText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;

export { View } from 'app/components/Common/Styled';
