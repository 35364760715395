import styled, { css } from 'styled-components/native';

import { Text } from 'app/components/Common/Styled';

export {
  default as Input,
  defaultInputProps,
} from 'app/components/Common/Input';

export const WetSignature = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamilySignature};
    font-size: ${theme.rawFontSizes.xxxxlarge}px;
    margin-bottom: ${theme.spacing * 3}px;
  `}
`;
