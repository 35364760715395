import { TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import styled from 'styled-components/native';

import Text from 'app/components/Common/Styled/Text';
import UnorderedListRaw from 'app/components/Common/UnorderedList';

export const ArrowIcon = styled(Icon).attrs(({ active }) => ({
  name: active ? 'keyboard-arrow-up' : 'keyboard-arrow-down',
  size: 44,
}))``;

export const BodyContainer = styled.View`
  padding: ${({ theme }) => theme.spacing}px;
  width: 100%;
`;

export const Container = styled.View`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.default};
  border-radius: 2px;
  border-width: 1px;
  flex: 1 0 auto;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing / 2}px;
  margin-bottom: 0px;
`;

export const Divider = styled.View`
  border-color: ${({ theme }) => theme.colors.default};
  border-style: dashed;
  border-width: 1px;
  height: 1px;
  width: 100%;
`;

export const Paragraph = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing / 2}px;
`;

export const Title = styled(Text)`
  flex: 1 1 auto;
`;

export const TitleContainer = styled(TouchableOpacity)`
  align-items: center;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing}px;
  width: 100%;
`;

export const UnorderedList = styled(UnorderedListRaw).attrs(({ theme }) => ({
  containerStyle: { marginBottom: theme.spacing / 2 },
}))``;
