import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';

import { Icon as IconRaw } from 'react-native-elements';

import theme from 'app/util/theme';

export const AnchorContent = styled(TouchableOpacity)`
  align-items: center;
  color: ${theme.colors.primary};
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing}px;
  padding-top: ${({ theme }) => theme.spacing}px;
`;

export const AnchorIcon = styled(IconRaw).attrs({
  color: theme.colors.defaultDark,
  size: 40,
})``;

export const AnchorText = styled.View`
  flex: 1 0 auto;
`;

export const Container = styled.View`
  display: flex;
  flex: 1 0 auto;
  margin-top: 2%;
`;

export const Row = styled.View`
  border-bottom-color: ${theme.colors.default};
  border-bottom-width: ${({ border }) => (border ? '1' : '0')}px;
  flex: 1 0 auto;
`;
