import {
  RECEIVE_AUTOLOGOUT,
  RECEIVE_PANEL_OPEN,
  RECEIVE_PROFILE_MENU_OPEN,
  RECEIVE_LOCAL_NOTIFICATION,
  REMOVE_LOCAL_NOTIFICATION,
  RECEIVE_SHOULD_REMEMBER_DEVICE,
  RECEIVE_SET_CALL_US_MODAL_VISIBILITY,
  RECEIVE_SET_CONTACT_ME_MODAL_VISIBILITY,
} from 'app/actions/uiActions';

import { RECEIVE_LOGOUT } from 'app/actions/sessionActions';

const nullState = Object.freeze({
  autoLogout: true,
  isCallUsModalVisible: false,
  isContactMeModalVisible: false,
  isNsaModalVisible: false,
  panelOpen: false,
  profileMenuOpen: false,
  notifications: [],
  shouldRememberDevice: false,
  shouldRememberDateSet: null,
});

export { nullState };

const uiReducer = (state = nullState, action) => {
  switch (action.type) {
    case RECEIVE_AUTOLOGOUT: {
      return {
        ...state,
        autoLogout: state.shouldRememberDevice ? false : action.autoLogout,
      };
    }
    case RECEIVE_PANEL_OPEN:
      return { ...state, panelOpen: action.panelOpen };
    case RECEIVE_PROFILE_MENU_OPEN:
      return { ...state, profileMenuOpen: action.profileMenuOpen };
    case RECEIVE_SET_CALL_US_MODAL_VISIBILITY:
      return { ...state, isCallUsModalVisible: action.isVisible };
    case RECEIVE_SET_CONTACT_ME_MODAL_VISIBILITY:
      return { ...state, isContactMeModalVisible: action.isVisible };
    case RECEIVE_LOCAL_NOTIFICATION: {
      const notifications = [...state.notifications, action.notification];
      return { ...state, notifications };
    }
    case REMOVE_LOCAL_NOTIFICATION: {
      let notifications = [...state.notifications];

      if (typeof action.index !== 'undefined')
        notifications.splice(action.index, 1);
      if (action.redirect)
        notifications = notifications.filter(
          ({ redirect }) => redirect !== action.redirect
        );

      return { ...state, notifications };
    }
    case RECEIVE_SHOULD_REMEMBER_DEVICE:
      return {
        ...state,
        autoLogout: !action.shouldRememberDevice,
        shouldRememberDevice: action.shouldRememberDevice,
        shouldRememberDateSet: action.shouldRememberDateSet,
      };
    case RECEIVE_LOGOUT:
      return action.hard ? { ...state, notifications: [] } : state;
    default:
      return state;
  }
};

export default uiReducer;
