import { Icon } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import FocusableView from 'app/components/Common/FocusableView';

export { default as ProfileAvatar } from '../../ProfileAvatar';
import { ProfileMenuToggleIconProps } from '../../types';

export const ProfileMenuToggleIcon = styled(
  Icon
).attrs<ProfileMenuToggleIconProps>(({ isOpen, theme }) => ({
  color: theme.colors.darkBlue,
  containerStyle: { marginLeft: theme.spacing * -0.25 },
  name: isOpen ? 'arrow-drop-down' : 'arrow-right',
  size: theme.spacing * 1.75,
}))<ProfileMenuToggleIconProps>``;

export const ProfileMenuToggleContainer = styled(FocusableView)`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: ${theme.spacing * 0.5}px;
  `}
`;
