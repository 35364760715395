import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { uniqBy } from 'lodash';

import {
  createEpisodeFromState,
  getCostEstimate,
  fetchOfferedProcedure,
  getPossiblePhysicians,
  fetchPossibleProcedures,
} from 'app/actions/episodeActions';

import Procedures from 'app/components/Procedures';

import { goBack, push } from 'app/util/history';
import { hasMemberIdError, isMemberIdRequired } from 'app/util/userUtils';
import { getIsPlatformOne } from 'app/selectors/session';

const mapStateToProps = ({ episode, session }) => ({
  clientId: session.user?.eligiblePatient?.clientId,
  costEstimate: episode.costEstimate,
  costEstimateLoading: episode.costEstimateLoading,
  isPlatformOne: getIsPlatformOne(session),
  location: session?.user?.location ?? {
    lat: 37.7749,
    lng: 122.4194,
  },
  loggedIn: session.coreToken && !session.loading,
  memberIdError: hasMemberIdError(
    session.user,
    episode.costEstimate,
    episode.costEstimateLoading
  ),
  memberIdRequired: isMemberIdRequired(session.user),
  possibleProcedures: episode.possibleProcedures,
  possibleHospitals: uniqBy(
    episode.possiblePhysicians.map(({ hospital }) => hospital),
    'id'
  ),
});

const mapDispatchToProps = (dispatch) => ({
  goBack,
  push,
  createEpisode: (procedure) => {
    dispatch(change('episode', 'procedure', procedure));
    return dispatch(createEpisodeFromState());
  },
  getCostEstimate: (procedureKey) => dispatch(getCostEstimate(procedureKey)),
  getHospitals: (procedureId) =>
    dispatch(getPossiblePhysicians(procedureId, 'distance')),
  getProcedures: (search) => dispatch(fetchPossibleProcedures(search)),
  getProcedure: (key) => dispatch(fetchOfferedProcedure(key)),
});

const ProceduresContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Procedures);

export default reduxForm({
  form: 'episode',
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(ProceduresContainer);
