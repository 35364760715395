import React, { useState } from 'react';

import { Platform, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  createInquiryFromState,
  updateInquiry,
} from 'app/actions/episodeActions';
import { REQUEST_RECEIVED_TEXT } from 'app/util/constants';
import theme from 'app/util/theme';
import { Episode } from 'types/episode';
import { Location } from 'types/location';
import { TestID } from 'app/util/test-id';

import {
  CheckMark,
  DescriptionWrapper,
  FormValues,
  GooglePlacesInput,
  GooglePlacesInputWrapper,
  InputWrapper,
  Modal,
  ModalOption,
  RadioGroup,
  RequestReceivedText,
  RequestReceivedWrapper,
  ScrollView,
  Text,
  TextArea,
  View,
} from './styles';

const Container = Platform.select({
  default: ScrollView as any,
  web: View,
});

export enum PageOptions {
  LetUsKnow = 'Message us',
  Confirmation = 'Thank you!',
}

export enum contactMethodPreferenceOptions {
  Unknown = '',
  Yes = 'yes',
  No = 'no',
}

interface LetUsKnowProps {
  /**
   * Prompts the modal to close
   */
  onClose: () => void;
}

export function LetUsKnow({ onClose }: LetUsKnowProps) {
  const dispatch = useDispatch();

  const inquiry = useSelector<any>((state) => state?.episode?.inquiry) as
    | undefined
    | Episode.Inquiry;

  const [formValues, setFormValues] = useState<FormValues>({
    location: inquiry?.location || ({} as Location),
    description: inquiry?.description || '',
    contactMethodPreference: inquiry?.contactMethodPreference || undefined,
  });

  const [pageOption, setPageOption] = useState<PageOptions>(
    PageOptions.LetUsKnow
  );

  const onSubmit = async () => {
    const promise = inquiry?.id
      ? dispatch(updateInquiry(inquiry.id, { ...inquiry, ...formValues }))
      : dispatch(
          createInquiryFromState(
            formValues.description,
            formValues.location,
            formValues.contactMethodPreference
          )
        );

    const isSuccess = (await promise) as Promise<boolean>;

    if (isSuccess) {
      // show success page
      setPageOption(PageOptions.Confirmation);
    }
  };

  const addressChanged = (location: Location) => {
    setFormValues({ ...formValues, location });
  };

  const descriptionChanged = (description: string) => {
    setFormValues({ ...formValues, description });
  };

  const contactMethodPreferenceChanged = (
    contactMethodPreference: Episode.ContactMethod
  ) => {
    setFormValues({ ...formValues, contactMethodPreference });
  };

  const formIsValid = () => {
    return (
      !!formValues.description &&
      !isEmpty(formValues.location) &&
      formValues.contactMethodPreference !== undefined
    );
  };
  const isNarrow = useWindowDimensions().width < theme.breakpoints.xlarge;

  const modalOptions: ModalOption[] = [];

  if (pageOption === PageOptions.LetUsKnow) {
    modalOptions.push({
      disabled: !formIsValid(),
      onPress: onSubmit,
      raised: true,
      title: 'Continue',
      type: 'solid',
    });
  } else {
    modalOptions.push({
      disabled: false,
      onPress: onClose,
      raised: true,
      title: 'OK',
      type: 'solid',
    });
  }
  return (
    <Modal
      isVisible={true}
      onClose={onClose}
      options={modalOptions}
      size="large"
      title={pageOption}
      fullScreen={isNarrow}
    >
      <Container disableScrollViewPanResponder>
        {pageOption === PageOptions.Confirmation && (
          <RequestReceivedWrapper>
            <CheckMark size={80} />
            <RequestReceivedText>{REQUEST_RECEIVED_TEXT}</RequestReceivedText>
          </RequestReceivedWrapper>
        )}

        <DescriptionWrapper>
          {pageOption === PageOptions.LetUsKnow && (
            <Text>
              Please help us by describing your issue. We respond Monday to
              Friday between 6am-5pm PST, except major holidays. For medical
              emergencies, please call 911.
            </Text>
          )}

          {pageOption === PageOptions.Confirmation && <Text></Text>}
        </DescriptionWrapper>

        {pageOption === PageOptions.LetUsKnow && (
          <>
            <InputWrapper>
              <TextArea
                input={{
                  value: formValues.description,
                  onChange: descriptionChanged,
                }}
                multiline
                name="description"
                placeholder="Type your health concern or recommended care here..."
                returnKeyType="next"
                returnKeyLabel="Continue"
              />
            </InputWrapper>

            <GooglePlacesInputWrapper>
              <GooglePlacesInput
                input={{
                  value: formValues.location,
                  onChange: addressChanged as any,
                  name: TestID.ProcedureSearch.LetUsKnowAddress,
                }}
                label="Your Address or Zip Code"
                theme={theme}
              />
            </GooglePlacesInputWrapper>
            <RadioGroup
              inline
              input={{
                onChange: contactMethodPreferenceChanged,
                value: formValues.contactMethodPreference,
              }}
              label="How would you like us to contact you?"
              options={[
                {
                  label: 'Phone',
                  value: Episode.ContactMethod.Phone,
                },
                {
                  label: 'In-app messages',
                  value: Episode.ContactMethod.Email,
                },
              ]}
            />
          </>
        )}
      </Container>
    </Modal>
  );
}
