import styled from 'styled-components/native';
import { Field as FieldRaw } from 'redux-form';

import { zIndexes } from 'app/util/theme';

export const BMIContainer = styled.View`
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  margin-bottom: ${({ theme }) => theme.spacing * 2}px;
  padding-bottom: ${({ theme }) => theme.spacing}px;
  z-index: ${zIndexes.base};
`;

export const Row = styled.View`
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing / 4}px;
`;

export const SmallInputContainer = styled.View<{ mr: boolean }>`
  flex: 1;
  margin-right: ${({ mr, theme }) => (mr ? theme.spacing / 4 : 0)}px;
`;

export const Field = styled(FieldRaw).attrs(({ theme }) => {
  return {
    containerStyle: {
      marginBottom: theme.spacing,
    },
  };
})``;
