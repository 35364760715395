import React, { FC } from 'react';
import { Card, Text } from 'react-native-elements';

import AnchorWithIcon from 'app/components/Common/AnchorWithIcon';
import ProcedureIcon from 'app/components/Common/ProcedureIcon';
import ProfileMenu from 'app/components/Profile/ProfileMenu';
import theme from 'app/util/theme';

import { CardContent, Container, ProcedureIconView, Row } from './styles';
import { Routes } from 'app/util/routes';

interface ProfileSummaryProps {
  bmi: string;
  episode: any;
  insurance: any;
}

const ProfileSummary = ({ bmi, episode, insurance }: ProfileSummaryProps) => {
  const empty = !bmi && !(insurance && insurance.name);
  const step = episode && episode.currentStep;
  const tasks = step
    ? step.tasks.filter(
        ({ complete, keyType }) => keyType === 'patient' && !complete
      )
    : [];

  return (
    <Container>
      <Card>
        <CardContent>
          <ProfileMenu />
        </CardContent>
      </Card>

      {empty && (
        <Card>
          <Card.Title>Complete Your Profile</Card.Title>

          <CardContent>
            <Text>
              Select a section above to confirm and complete your profile
              information.
            </Text>
          </CardContent>
        </Card>
      )}

      {episode && episode.procedure ? (
        <Card>
          <Card.Title>Active Case</Card.Title>

          <CardContent>
            <Row>
              <ProcedureIconView>
                <ProcedureIcon
                  theme={theme} //TODO: re-visit, clean up
                  containerStyle={{}}
                  size="small"
                  icon={
                    (episode.procedure.images &&
                      episode.procedure.images.icon) ||
                    ''
                  }
                />
              </ProcedureIconView>
              <Text>{episode.procedure.name}</Text>
            </Row>

            <AnchorWithIcon
              icon="navigate-next"
              iconSize={15}
              buttonStyle={{ paddingVertical: theme.spacing / 4 }}
              containerStyle={{ marginTop: theme.spacing / 2 }}
              iconStyle={{ marginLeft: theme.spacing / 4 }}
              title={
                tasks.length > 0
                  ? `${tasks.length} Available Task${
                      tasks.length > 1 ? 's' : ''
                    }`
                  : 'My Progress'
              }
              to={`/${Routes.Dashboard}`}
            />

            <AnchorWithIcon
              icon="navigate-next"
              iconSize={15}
              buttonStyle={{ paddingVertical: theme.spacing / 4 }}
              containerStyle={{ padding: 0, marginTop: theme.spacing / 4 }}
              iconStyle={{ marginLeft: theme.spacing / 4 }}
              title="Form Submissions"
              to={`/${Routes.Forms}`}
            />

            <AnchorWithIcon
              icon="navigate-next"
              iconSize={15}
              buttonStyle={{ paddingVertical: theme.spacing / 4 }}
              containerStyle={{ padding: 0, marginTop: theme.spacing / 4 }}
              iconStyle={{ marginLeft: theme.spacing / 4 }}
              title="Care Summary"
              to={`/${Routes.Confirmation}`}
            />
          </CardContent>
        </Card>
      ) : null}
    </Container>
  );
};

export default ProfileSummary;

ProfileSummary.defaultProps = {
  bmi: '',
  episode: {},
  insurance: {},
};
