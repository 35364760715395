import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { JourneyPhaseIntroPage } from 'app/components/Common/Layout';
import StatusMessage from 'app/components/Common/StatusMessage';
import { DotIndicator } from 'app/components/Common/svg-components';
import { useEpisode, useSession } from 'app/hooks';

import { Routes } from 'app/util/routes';
//@ts-ignore
import { useHistory } from '@cross-platform/react-router-native';

import {
  Card,
  CardImage,
  CardImageWrapper,
  CardText,
  CardTextWrapper,
  CardsWrapper,
  CheckMark,
  CompleteText,
  CompleteTextWrapper,
  JourneyPhaseHtml,
  JourneyPhaseHtmlWrapper,
} from './styles';

const interpolateHtml = (html, user, episode) => {
  if (!html) return '';

  return html
    .replace('{user.firstName}', user?.firstName)
    .replace('{user.lastName}', user?.lastName)
    .replace('{episode.physician.name}', episode?.physician?.name);
};

const JourneyPhase = () => {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const { episode, journeyPhase, refresh, steps } = useEpisode();
  const { user } = useSession();
  const [isRefreshing, setIsRefreshing] = useState(false);

  // True if there is an episode but no remaining steps.
  const isJourneyComplete =
    !!episode && !steps.find(({ complete }) => !complete);
  // True if there is an incomplete step within the current Journey Phase
  const hasActiveStep = journeyPhase?.steps.find(
    ({ complete, order }) => !complete && order === episode?.currentStep?.order
  );

  /**
   * Respond to changes in the episode and attempt to fetch new data when all
   * of the steps for the current Journey Phase are complete, but the journey
   * itself is not.
   */
  useEffect(() => {
    if (!episode) return;

    if (!hasActiveStep && !isJourneyComplete) {
      refresh();
      setIsRefreshing(true);
    } else {
      setIsRefreshing(false);
    }
  }, [hasActiveStep, pathname, refresh]);

  // If the current route differs from the journey phase, redirect.
  useEffect(() => {
    if (!journeyPhase) return;
    if (!pathname) return;

    if (!pathname.startsWith(`/${journeyPhase.code}`)) {
      push(`/${journeyPhase.code}`);
    }
  }, [journeyPhase, pathname]);

  if (!journeyPhase && isRefreshing) return <DotIndicator />;
  if (!journeyPhase)
    return (
      <StatusMessage
        status="error"
        message="We could not find the page you are looking for."
        ctaText="Click here to return home"
        ctaPath="/"
      />
    );

  const htmlContent = interpolateHtml(
    episode.currentStep.helpContent || '',
    user,
    episode
  );

  const onContinue = () => {
    push(`/${Routes.Dashboard}`);
  };

  const stepIcon = ({ iconUrl }) =>
    Platform.select({
      default: { uri: iconUrl },
      web: iconUrl,
    });

  return (
    <JourneyPhaseIntroPage
      isRefreshing={isRefreshing}
      onContinue={onContinue}
      title={journeyPhase.name}
    >
      <JourneyPhaseHtmlWrapper>
        <JourneyPhaseHtml html={htmlContent} />
      </JourneyPhaseHtmlWrapper>
      <CardsWrapper>
        {journeyPhase.steps.map((step) => (
          <Card key={step.order} complete={step.complete}>
            <CardImageWrapper
              centered={step.complete}
              greyscale={step.order > episode.currentStep?.order}
            >
              {step.complete ? (
                <CheckMark />
              ) : (
                <CardImage source={stepIcon(step)} />
              )}

              {step.complete && (
                <CompleteTextWrapper>
                  <CompleteText>Completed</CompleteText>
                </CompleteTextWrapper>
              )}
            </CardImageWrapper>

            <CardTextWrapper
              greyscale={step.order > episode.currentStep?.order}
            >
              <CardText>{step.title}</CardText>
            </CardTextWrapper>
          </Card>
        ))}
      </CardsWrapper>
    </JourneyPhaseIntroPage>
  );
};

export default JourneyPhase;
