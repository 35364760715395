import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Animated } from 'react-native';

import usePanHandle from 'app/hooks/usePanHandle';

import { collapse, expand, panelOffset } from './animations';

import {
  AnimatedBackdrop,
  CloseIcon,
  Container,
  ContentWrapper,
  Handle,
  HandleWrapper,
  TeaserContentWrapper,
} from './styles';

export { teaserOffset } from './styles';

/**
 * @property {node} children A react node to render within the sliding panel when it is expanded (required).
 * @property {node} TeaserComponent A react node to render within the sliding panel when it is collapsed (required).
 * @property {boolean} isVisible If true, expands the sliding panel to reveal the children within (defaults to false).
 * @property {function} onHide A function to call when collapsing the panel (defaults to a null operation).
 * @property {function} onShow A function to call when expanding the panel (defaults to a null operation).
 */
const propTypes = {
  children: PropTypes.node.isRequired,
  TeaserComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,

  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
};

const defaultProps = {
  isVisible: false,
  onHide: () => {},
  onShow: () => {},
};

/**
 * Renders a sliding panel at the bottom of the screen that shows
 * teaser content and can be expanded to reveal additional content.
 *
 * @example
 * const [isVisible, setIsVisible] = useState(false);
 *
 * const RevealButton = () => (
 *   <Button
 *     title="Show me"
 *     onPress={() => setIsVisible(true)}
 *   />
 * );
 *
 * <TeaserPanel
 *   onHide={() => setIsVisible(false)}
 *   onShow={() => setIsVisible(true)}
 *   isVisible={isVisible}
 *   TeaserComponent={RevealButton}
 * >
 *   <Text>Hidden content here.</Text>
 * </TeaserPanel/>
 */
const TeaserPanel = ({
  children,
  isVisible,
  onHide,
  onShow,
  TeaserComponent,
}) => {
  let pan, panResponder, setBoundary;
  const [fade] = useState(new Animated.Value(0.0));
  // When fading in the content, fade out the teaser content.
  const teaserFade = fade.interpolate({
    inputRange: [0.0, 1.0],
    outputRange: [1.0, 0.0],
  });

  /**
   * Fade in/out content as the panel slides.
   */
  const onPanResponderMove = (_, { dy }) => {
    const fadeTo = isVisible
      ? (panelOffset - dy) / panelOffset
      : -dy / panelOffset;

    fade.setValue(fadeTo);
  };

  /**
   * Complete opening or closing the panel if the user moves
   * it far enough.
   */
  const onPanResponderRelease = (_, { dy }) => {
    if (isVisible && dy >= 100) {
      hidePanel();
    } else if (!isVisible && dy < -100) {
      showPanel();
    } else if (isVisible) {
      showPanel();
    } else if (!isVisible) {
      hidePanel();
    }
  };

  ({ pan, panResponder, setBoundary } = usePanHandle({
    initialOffset: { x: 0, y: panelOffset },
    onPanResponderMove,
    onPanResponderRelease,
  }));

  useEffect(() => {
    // Animate the component in/out when the `isVisible` prop changes.
    isVisible ? showPanel() : hidePanel();

    setBoundary({
      // Don't allow sliding off bottom screen;
      bottom: isVisible ? null : 50,
      // Don't allow sliding up when expanded or beyond panelOffset
      top: isVisible ? 1 : -panelOffset,
    });
  }, [isVisible, hidePanel, showPanel, setBoundary]);

  const hidePanel = useCallback(() => {
    pan.flattenOffset();
    collapse({ fade, slide: pan.y }, onHide);
  }, [fade, onHide, pan]);

  const showPanel = useCallback(() => {
    pan.flattenOffset();
    expand({ fade, slide: pan.y }, onShow);
  }, [fade, onShow, pan]);

  return (
    <Container
      expanded={isVisible}
      testID="TeaserPanel"
      style={{ transform: [{ translateY: pan.y }] }}
    >
      <AnimatedBackdrop style={{ opacity: fade }} />

      <TeaserContentWrapper
        pointerEvents={isVisible ? 'none' : 'auto'}
        style={{ opacity: teaserFade }}
      >
        <TeaserComponent />
      </TeaserContentWrapper>

      <ContentWrapper
        pointerEvents={isVisible ? 'auto' : 'none'}
        style={{ opacity: fade }}
      >
        <CloseIcon onPress={hidePanel} />
        {children}
      </ContentWrapper>

      <HandleWrapper {...panResponder.panHandlers}>
        <Handle />
      </HandleWrapper>
    </Container>
  );
};

TeaserPanel.defaultProps = defaultProps;
TeaserPanel.propTypes = propTypes;
export default TeaserPanel;
