import { CurrentlyLiveHereValues } from 'app/components/CompleteYourProfile/Questionnaire/CurrentlyLiveHere';

import { ReactNode } from 'react';

import { Mode } from 'app/components/Common/CarrumGoldExperienceBenefits/types';

type PhysicalHeight = {
  feet: number;
  inches: number;
};

export interface AlreadyScheduledProps {
  value: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  isSaving: boolean;
  onChange: (value: boolean) => void;
  onNext: () => void;
}

export type BenefitsCompleteProps = {
  onNextPage: () => void;
};

export type BenefitsOverviewProps = {
  allowModal?: boolean;
  description: ReactNode;
  isSaving?: boolean;
  mode: Mode;
  onContinue: () => void;
  showDetailsFooter?: boolean;
  title: ReactNode;
};

export type BenefitsWelcomeProps = {
  nextPageKey: string;
};

export { ButtonListOption } from 'app/components/Common/ButtonList';

export interface ChronicPainProps {
  value: boolean;
  isSaving: boolean;
  onChange: (value: boolean) => void;
  onNext: () => void;
}

export interface HeaderProps {
  description?: string;
  title?: string;
}

export interface InsuranceInfo {
  membership: {
    groupNumber: string;
    insuranceType: string; // primary vs secondary
    medicareOrMedicaid: boolean;
    memberId: string;
    planType: string;
  };
}

export interface InsuranceInfoPageProps {
  isSaving?: boolean;
  onChange: (values: InsuranceInfo) => void;
  onGoToStopScreen: (options?: any) => void;
  onNext: () => Promise<any>;
  values: QuestionnaireProfile;
}

export { Location } from 'types/location';

export interface OncologyPageProps {
  isSaving: boolean;
  onChange: (values: OncologyValues) => void;
  onGoToStopScreen: () => void;
  onNext: () => void;
  values: OncologyValues;
}

export interface OncologyValues {
  hasConfirmedDiagnosisForOncology?: boolean;
  isUnderActiveTreatmentForOncology?: boolean;
  howToHelpWithDiagnosisForOncology?: string;
}
export { NavigationMode } from 'app/components/Common/StickyNavFooter/types';

export type PageUpdateArg = {
  page?: ProfilePage;
  stopScreenProps?: StopScreenPageProps;
};

export interface PatientCheckProps {
  value: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  isNextDisabled: boolean;
  isSaving: boolean;
  onChange: (value: boolean) => void;
  onGoToStopScreen: any;
  onNext: () => void;
  patientName: string;
}

export type ProcedureCategory =
  | 'Total Joint Replacement'
  | 'Major Joint Replacement';

export interface ProfileCTAsProps {
  isFirstStep?: boolean;
  isLastStep?: boolean;
  isNextDisabled: boolean;
  isSaving: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  submitFinalStep?: () => void;
}

export enum ProfilePage {
  Completed = 'completed',
  Questionnaire = 'questionnaire',
  StopScreen = 'stop',
  Welcome = 'welcome',
  SaveProgress = 'save-progress',
}

export type ProfileStepRule = {
  type: 'exclusion' | 'inclusion';
  stepKeys: string[];
  procedureCategories?: ProcedureCategory[];
  partnerName?: 'Hinge Health';
  feature?: 'oncology';
  serviceType?: 'oncology' | '!oncology';
  clientType?: 'partner' | 'employer';
};

export interface ProgressProps {
  currentStep: number;
  totalSteps: number;
}

export interface QuestionnaireProfile extends CurrentlyLiveHereValues {
  chronicPain?: boolean;
  doctorHasRecommended?: boolean;
  grantVoicemailPermission: boolean;
  hasAlreadyScheduled?: boolean;
  height: PhysicalHeight;
  insurance: InsuranceInfo;
  isThePatient?: boolean;
  phoneNumber: string;
  smoking: string;
  weight: number; // pounds
  hasConfirmedDiagnosisForOncology?: boolean;
  isUnderActiveTreatmentForOncology?: boolean;
  howToHelpWithDiagnosisForOncology?: string;
}

export interface QuestionnaireProps {
  onGoToStopScreen: (props?: StopScreenPageProps) => PageUpdateArg;
  submitFinalStep: () => void;
}

export interface RecommendationProps {
  value: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  isNextDisabled: boolean;
  isSaving: boolean;
  onChange: (value: boolean) => void;
  onNext: () => void;
}

export type RenderBenefitsOverviewProps = {
  allowModal?: boolean;
  mode: Mode;
  showDetailsFooter?: boolean;
};

export interface SmokingStatusProps {
  value: string;
  isSaving: boolean;
  onChange: (value: string) => void;
  onNext: () => void;
}

export { State } from 'types/state';

export type StopScreenPageProps = {
  body?: string;
  ctaTitle?: string;
  footer?: string;
  onPress?: () => void;
  title?: string;
};

export type StopScreenPageValues = {
  [key: string]: StopScreenPageProps;
};

export interface TellUsMoreProps {
  isSaving: boolean;
  onChange: (values: TellUsMoreValues) => void;
  onNext: () => void;
  values: TellUsMoreValues;
}

export interface TellUsMoreValues {
  grantVoicemailPermission: boolean;
  height: PhysicalHeight;
  phoneNumber: string;
  weight: number;
}

export { User } from 'types/user';
