import {
  boolOptions,
  Routes,
  stopScreenPageValues,
  TestID,
} from 'app/components/CompleteYourProfile/constants';

import {
  getNextProfileRoute,
  logout,
  updateEpisode,
} from 'app/components/CompleteYourProfile/helpers';

import {
  useDispatch,
  useHistory,
  useEpisode,
} from 'app/components/CompleteYourProfile/hooks';

import {
  ButtonList,
  Header,
  ProfileCTAs,
  FormContainer,
  StepScreen,
} from 'app/components/CompleteYourProfile/styles';

import { PatientCheckProps } from 'app/components/CompleteYourProfile/types';

const PatientCheck = ({
  value,
  isFirstStep,
  isLastStep,
  isNextDisabled,
  isSaving,
  onChange,
  onGoToStopScreen,
  onNext,
  patientName,
}: PatientCheckProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { episode } = useEpisode();

  const handleIsNotPatient = async () => {
    // close episode and redirect to register page

    const success = await dispatch(
      updateEpisode(episode.id, {
        ...episode,
        status: 'closed',
        closure: {
          date: new Date().toISOString(),
          details: "Dependent was using the primary member's account",
          type: 'Closed By Member',
        },
      })
    );

    if (success) {
      dispatch(logout(true, `/${Routes.Register}`));
    }
  };

  return (
    <StepScreen testID={TestID.CompleteYourProfile.PatientCheck}>
      <FormContainer>
        <Header
          description="For optimal experience, Carrum needs the patient to be the registered user."
          title={`Is ${patientName} the patient?`}
        />
        <ButtonList
          key={TestID.CompleteYourProfile.PatientCheck}
          initialValue={value}
          mode="single"
          onChange={onChange}
          options={boolOptions}
        />
      </FormContainer>
      <ProfileCTAs
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        isNextDisabled={isNextDisabled}
        isSaving={isSaving}
        onNext={() => {
          Promise.resolve(onNext()).then(() => {
            if (typeof value === 'boolean' && !value) {
              onGoToStopScreen({
                ...stopScreenPageValues.isNotPatient,
                onPress: handleIsNotPatient,
              });
              return;
            } else {
              history.push(getNextProfileRoute(Routes.PatientCheck));
            }
          });
        }}
      />
    </StepScreen>
  );
};

export default PatientCheck;
