import { TestID } from 'app/components/Login/constants';

import { isGuestAccount, requestMagicLink } from 'app/components/Login/helpers';

import { useDispatch, useEffect, useState } from 'app/components/Login/hooks';

import {
  Button,
  ButtonContainer,
  Header,
  ImageContainer,
  MagicLinkSvg,
  MagicLinkView,
  ParagraphText,
} from 'app/components/Login/styles';

import { MagicLinkRequestProps } from 'app/components/Login/types';

const MagicLinkRequest = ({
  formValues = {},
  isResendRequest = false,
  loading = false,
  setLoginStep,
}: MagicLinkRequestProps) => {
  const dispatch = useDispatch();
  const [passwordHasBeenSet, setPasswordHasBeenSet] = useState(false);

  const email = formValues?.email;

  /**
   * Check secure storage to see if this user has a password when the component
   * mounts.
   */
  useEffect(() => {
    loadInitialState();
  }, []);

  useEffect(() => {
    if (!email && !isResendRequest) setLoginStep('email');
  }, []);

  const loadInitialState = async () => {
    const isGuest = await isGuestAccount(formValues.email);
    setPasswordHasBeenSet(!isGuest);
  };

  const sendMagicLink = async () => {
    if (!email) return;

    const success = dispatch(requestMagicLink(email));

    if (success) {
      // proceed to confirmation screen
      setLoginStep('magic-link-sent');
    }
  };

  return (
    <MagicLinkView>
      <Header>{email}</Header>
      <ImageContainer>
        <MagicLinkSvg />
      </ImageContainer>
      <ParagraphText>
        {passwordHasBeenSet ? '' : "Looks like you don't have a password yet. "}
        Get a magic link sent to your email that&apos;ll sign you in instantly!
      </ParagraphText>
      <ButtonContainer>
        <Button
          disabled={!formValues?.email}
          loading={loading}
          onPress={sendMagicLink}
          testID={TestID.Login.SendMagicLink}
          title="Send Magic Link"
        />
      </ButtonContainer>
    </MagicLinkView>
  );
};

export default MagicLinkRequest;
