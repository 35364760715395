import LoadingScreen from './LoadingScreen';
import { useEpisode } from 'app/hooks';

import { EVENTS, Routes, TestID, theme, WelcomePages } from './constants';

import {
  debounce,
  fetchPossibleProcedures,
  getProcedureSearchSuggestionMapping,
  getSuggestionsFromMapping,
  getWelcomePagesForClientType,
  isDownKey,
  isEnterKey,
  isUpKey,
  logEvent,
  receiveSetCallUsModalVisibility,
  routeToFAQ,
} from './helpers';

import {
  useCallback,
  useDispatch,
  useEffect,
  useHistory,
  useSession,
  useState,
  useWindowDimensions,
} from './hooks';

import {
  AboutIcon,
  AboutIconWrapper,
  ChatIcon,
  ChatIconWrapper,
  CtaContainer,
  DotIndicator,
  Footer,
  FooterWrapper,
  LetUsKnow,
  LetUsKnowButton,
  LogoWrapper,
  NoResultsText,
  NotWhatYoureLookingForText,
  Onboarding,
  PhoneIconWrapper,
  PhoneIcon,
  ProcedureSearchPageWrapper,
  RedVerticalLogo,
  SearchBar,
  SearchSuggestions,
  SubCategoryButtons,
  Title,
  TopSection,
} from './styles';

import { KeyboardEvent } from './types';

const ProcedureSearchPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSession();
  const { width } = useWindowDimensions();

  const { possibleProcedures, possibleProcedureCategories } = useEpisode();

  const [mapping, setMapping] = useState({});
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isLetUsKnowVisible, setIsLetUsKnowVisible] = useState(false);
  const [suggestionCursor, setSuggestionCursor] = useState<number | null>(null);

  const clientType = user?.employer?.type || 'employer';
  const isMediumOrLargerDevice = width > theme.breakpoints.xsmall; // base responsive design on size
  const query = search.toLowerCase().trim(); // case insensitive w/ no whitespace
  const searchSuggestions = getSuggestionsFromMapping(mapping, query);
  const queryHasResults =
    Boolean(query) && !isSearching && searchSuggestions.length !== 0;
  const queryHasNoResults =
    Boolean(query) && !isSearching && searchSuggestions.length === 0;

  /**
   * Set the `isSearching` boolean when a user beings typing and
   * store the search query in the `search` state variable.
   */
  const onSearch = useCallback(
    async (searchText: string) => {
      setSearch(searchText);
      debounce(() => setIsSearching(searchText.trim().length !== 0), 500);
    },
    [dispatch]
  );

  /** When the search query changes, fetch procedures. */
  useEffect(() => {
    if (query) dispatch(fetchPossibleProcedures(query));
  }, [dispatch, query]);

  /** When the component mounts, fetch all possible procedures. */
  useEffect(() => {
    dispatch(fetchPossibleProcedures('', true));
  }, [dispatch]);

  /** Set the searching state to false when a new procedures load. */
  useEffect(() => {
    setIsSearching(false);
  }, [possibleProcedures]);

  /** Log search queries in Google Analytics when new procedures load. */
  useEffect(() => {
    logSearch();
  }, [possibleProcedures]);

  /** Map procedure search suggestions to procedures when results are returned. */
  useEffect(() => {
    if (!query) return;
    if (!possibleProcedures?.length) return;

    const mapping = getProcedureSearchSuggestionMapping(
      possibleProcedures,
      query
    );

    setMapping(mapping);
  }, [possibleProcedures, query]);

  /** Log the current search query and results in Google Analytics. */
  const logSearch = useCallback(() => {
    if (!query) return;

    logEvent(EVENTS.procedures.search, {
      search: query,
      results: possibleProcedures.length,
    });
  }, [possibleProcedures, query]);

  const goToProcedureResults = (index: number) => {
    history.push(`/${Routes.Search}/${searchSuggestions[index][0]}`);
  };

  /** Handle key events for up, down, and enter. */
  const handleSearchKeyEvent = (event: KeyboardEvent) => {
    if (isUpKey(event) || isDownKey(event)) {
      updateSuggestionCursor(event);
    } else if (isEnterKey(event)) {
      handleEnterKeyPress();
    }
  };

  const handleEnterKeyPress = () => {
    if (!suggestionCursor) return;
    goToProcedureResults(suggestionCursor);
  };

  const updateSuggestionCursor = (event: KeyboardEvent) => {
    let newCursor = suggestionCursor;
    const maxCursor = searchSuggestions.length - 1;

    const handleUpKey = () => {
      if (newCursor === null) newCursor = 0; // reset to zero if not yet defined
      newCursor -= 1; // go to next item in the list
      if (newCursor < 0) newCursor = maxCursor; // go to end of list if cursor goes above list
    };

    const handleDownKey = () => {
      if (newCursor === null) {
        newCursor = 0; // reset to zero if not yet defined
      } else {
        newCursor += 1; // to go next item in the list
      }
      if (newCursor > maxCursor) newCursor = 0; // reset to zero if cursor goes beyond list
    };

    if (isUpKey(event)) {
      handleUpKey();
    } else if (isDownKey(event)) {
      handleDownKey();
    }

    setSuggestionCursor(newCursor);
  };

  return (
    <ProcedureSearchPageWrapper testID={TestID.ProcedureSearch.Page}>
      <TopSection>
        {isMediumOrLargerDevice && (
          <LogoWrapper>
            <RedVerticalLogo />
          </LogoWrapper>
        )}

        {(query.length === 0 || isMediumOrLargerDevice) && (
          <Title>Search for your health condition or procedure</Title>
        )}

        <SearchBar
          onBlur={() => setSuggestionCursor(null)}
          onChangeText={onSearch as any} // the typings are incorrect from the lib
          onKeyPress={handleSearchKeyEvent as any} // the typings are incorrect from the lib
          testID={TestID.ProcedureSearch.SearchInput}
          value={search}
        />

        {isSearching && (
          <DotIndicator testID={TestID.ProcedureSearch.LoadingIndicator} />
        )}

        {queryHasResults && (
          <SearchSuggestions
            onSelectItem={goToProcedureResults}
            searchSuggestions={searchSuggestions}
            suggestionCursor={suggestionCursor}
          />
        )}

        {queryHasNoResults && (
          <>
            <NoResultsText testID={TestID.ProcedureSearch.SearchResult}>
              No results found for &apos;{search.trim()}&apos;
            </NoResultsText>
          </>
        )}

        <SubCategoryButtons
          possibleProcedureCategories={possibleProcedureCategories}
        />
      </TopSection>

      <FooterWrapper>
        {queryHasNoResults && (
          <Footer>
            <NotWhatYoureLookingForText>
              Can&apos;t find what you&apos;re looking for?
            </NotWhatYoureLookingForText>
            <LetUsKnowButton
              title="Let us know"
              onPress={() => setIsLetUsKnowVisible(true)}
              type="clear"
            />
          </Footer>
        )}

        <CtaContainer>
          <AboutIconWrapper>
            <AboutIcon
              title={isMediumOrLargerDevice ? 'About Carrum' : 'About'}
              iconName="info"
              iconType="Material"
              onPress={routeToFAQ}
            />
          </AboutIconWrapper>

          <ChatIconWrapper>
            <ChatIcon
              title={isMediumOrLargerDevice ? 'Message Us' : 'Chat'}
              iconName="commenting"
              iconType="FontAwesome"
              onPress={() => setIsLetUsKnowVisible(true)}
            />
          </ChatIconWrapper>

          <PhoneIconWrapper>
            <PhoneIcon
              title="Talk To Us"
              fontSize={theme.fontSizes.small}
              iconName="phone"
              iconType="FontAwesome"
              onPress={() => {
                dispatch(receiveSetCallUsModalVisibility(true));
              }}
            />
          </PhoneIconWrapper>
        </CtaContainer>
      </FooterWrapper>

      {/** First time welcome screens */}
      {user.verified && (
        <Onboarding
          name="onboarding.app"
          pages={getWelcomePagesForClientType(WelcomePages, clientType)}
        />
      )}

      {/** Option to request a new procedure */}
      {isLetUsKnowVisible && (
        <LetUsKnow onClose={() => setIsLetUsKnowVisible(false)} />
      )}

      <LoadingScreen />
    </ProcedureSearchPageWrapper>
  );
};

export default ProcedureSearchPage;
